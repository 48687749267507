import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Collection } from 'src/app/models/collection';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { E5Service } from "../../../services/E5DocumentacionService.service";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { requiredMultipleFileType } from '../../../directives/upload-file-multiple-validator';
import { requiredFileSize } from '../../../directives/upload-file-size-validator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { UtilitiesAvalService } from 'src/app/services/aval/UtilitiesAvalService.service';
import { Location } from "@angular/common";

@Component({
  selector: 'app-m6-promotor-aval-subir-documentacion-aval',
  templateUrl: './m6-promotor-aval-subir-documentacion.component.html'
})
export class M6SubirDocumentacionAvalComponent implements OnInit,AfterViewInit {
  public status: number;
  public collectionsIdentificacion: Collection;
  public collectionsIdentificacion2: Collection;
  public collectionsComprobanteDomicilio: Collection;
  public collectionsComprobanteIngresos: Collection;
  public collectionsCedula: Collection;
  public IdSolicitud: any;
  public IdPersona: any;
  public loading: boolean;
  public errorMessages: string;
  public TipoIdentificacionVista:any;
  public NoComprobantes:number;
  public index:any;
  public opcional:boolean;
  public proceso:any;
  public avales:any;
  public collectionsConfirmacionIdentidad: Collection;

  frmE5 = new FormGroup({
    TipoIdentificacion: new FormControl(Validators.required),
    IdentificacionVigente: new FormControl(null, [requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]),
    IdentificacionVigente2: new FormControl(null, [requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]),
    TipoComprobante: new FormControl(Validators.required),
    ComprobanteDomicilio: new FormControl(null, [requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]),
    GrantiaAuto: new FormControl(null, [requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]),
    EvidenciaInmueble: new FormControl(null, [requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]),
    IdSolicitud: new FormControl(null),
    IdPersona:new FormControl(null),
    opcional:new FormControl(null),
    comprobacion_identidad: new FormControl(null, [requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]),
  });

  /* permissions */
  public IdRoleAval: any;
  public formatedPermissions: any;
  public filesPermissions: any;
  public informacionPropietario: any;

  public previous_data: any;

  constructor(
    private E5Service: E5Service,
    private route: ActivatedRoute,
    private router: Router,
    private modalError: NgbModal,
    private utilities: UtilitiesService,
    private avalService: UtilitiesAvalService,
    private location:Location,
  ) {
    this.status = 2;
    this.loading = false;
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.IdSolicitud = this.route.snapshot.params.id_solicitud;
    this.IdPersona = this.route.snapshot.params.id_persona;
    this.route.data.subscribe((data: Data) => {
      this.collectionsIdentificacion = data['collections'][0].data;
      this.collectionsComprobanteDomicilio = data['collections'][1].data;
      this.collectionsComprobanteIngresos = data['collections'][2].data;
      this.collectionsCedula = data['collections'][3].data;
      this.IdRoleAval = data['data'][0].data.IdTipoRelacion;
      this.informacionPropietario = data['data'][0].data.informacion_propietario;
      this.avales=data['data'][1].data.avales;
      this.previous_data = data['data'][0].data;
      this.opcional= data['data'][0].data.has_documentacion;
      this.collectionsConfirmacionIdentidad = data['collections'][0].data;
    });
    this.frmE5.patchValue({
      IdSolicitud: this.IdSolicitud,
      IdPersona:this.IdPersona,
      opcional:this.opcional
    })

    for (let aval of this.avales){
      if(aval.IdPersona==this.IdPersona){
          this.NoComprobantes=aval.informacion_aval.NoComprobantes               
      }

  };

    this.formatedPermissions = this.avalService.getPermissions(this.IdRoleAval);

    this.filesPermissions = this.avalService.getFiles(this.IdRoleAval);

    this.index=[];
    if ( this.filesPermissions.indexOf('ComprobanteIngresos') > -1) {
      for (let i=1;i<=this.NoComprobantes;i++){
        this.index.push({mes:i,tipo:'TipoComprobanteMes'+i,comprobante:'ComprobanteIngresosMes'+i,rejected:'RejectedComprobanteMes'+i});
        
        if (!this.opcional){
          this.frmE5.addControl('TipoComprobanteMes'+i, new FormControl(null, [ Validators.min(1)]));
          this.frmE5.addControl('ComprobanteIngresosMes'+i, new FormControl(null, [Validators.required,requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]));
          this.frmE5.controls['TipoComprobanteMes'+i].patchValue(0);
        }else{
          this.frmE5.addControl('TipoComprobanteMes'+i, new FormControl(null));
          this.frmE5.addControl('ComprobanteIngresosMes'+i, new FormControl(null, [requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]));
        }
      }
    }

    if ( this.filesPermissions.indexOf('Identificacion') > -1) {
      this.frmE5.patchValue({TipoIdentificacion: 0});
      if (!this.opcional){
        this.frmE5.get('IdentificacionVigente').setValidators([Validators.required,requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]);
        this.frmE5.get('TipoIdentificacion').setValidators(Validators.min(1));
        this.frmE5.get('IdentificacionVigente2').setValidators([Validators.required,requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]);
      }
    }

    if ( this.filesPermissions.indexOf('ComprobanteDomicilio') > -1) {
      this.frmE5.patchValue({TipoComprobante: 0});
      if (!this.opcional){
        this.frmE5.get('ComprobanteDomicilio').setValidators([Validators.required,requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]);
        this.frmE5.get('TipoComprobante').setValidators(Validators.min(1));
    
      }
    }

    /* if ( this.filesPermissions.indexOf('comprobacion_identidad') > -1 && this.opcional) {
      this.frmE5.get('comprobacion_identidad').setValidators([Validators.required,requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]);
    } */

    if ( this.filesPermissions.indexOf('GarantiaAuto') > -1 && !this.opcional) {
      this.frmE5.get('GrantiaAuto').setValidators([Validators.required,requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]);
    }

    if ( this.filesPermissions.indexOf('GarantiaRPP') > -1 && !this.opcional) {
      this.frmE5.get('EvidenciaInmueble').setValidators([Validators.required,requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]);
    }
  }

  redirect(){
    let redirect='/promotor/revision_solicitud/'

    if (this.proceso.etapa==4){
      redirect='/promotor/cotizacion/'
    }

    if (this.proceso.etapa==8){
      redirect='/promotor/solicitud_desembolso/'
    }
    this.router.navigateByUrl(redirect+this.IdSolicitud);
    this.modalError.dismissAll();
  }

  onSubmit(event: any, modalError, modalSuccess) {
    if (!this.frmE5.valid) {
      Object.keys(this.frmE5.value).forEach(element => {
          if ( this.frmE5.get(element).errors != null ) {
          this.frmE5.get(element).markAsDirty();
          }
      });
      return;
    }
    event.preventDefault();
    this.loading = true;
    let sendData = toFormData(this.frmE5.value);
    this.E5Service.sendAvalDocPromotor(sendData).subscribe(
      resp => {
        this.loading = false;
        this.proceso=resp['proceso'];
        this.modalError.open(modalSuccess, { ariaLabelledBy: 'modal-basic-title', centered: true });
      },
      errors => {
        this.loading = false;

        if ( errors.status == 0 ) {
          this.errorMessages = 'Error al cargar los documentos. La solicitud es muy pesada. Favor de subir documentos de menor tamaño.';
        }
        else{
          this.errorMessages = errors.error.message;
        }

        this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
      },
    )
  }

  resetValue(id) {
    this.frmE5.controls[id].patchValue(null);
    document.querySelector("#" + id).querySelectorAll('input')[0]
    document.querySelector("#" + id).querySelectorAll('input')[0].value = null;
  }
  handleTipo(event){
    this.TipoIdentificacionVista=!(event.target.value=='2' || event.target.value=='0')?true:false;
    if (! this.TipoIdentificacionVista){
      this.utilities.removeControlsValidity(this.frmE5, ['IdentificacionVigente2']);
    }else{
      this.frmE5.get('IdentificacionVigente2').setValidators([Validators.required,requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]);
    }
}
  back(){
    this.location.back();
  }
}


export function markAllAsDirty(form: FormGroup) {
  for (const control of Object.keys(form.controls)) {
    form.controls[control].markAsDirty();
  }
}

export function toFormData<T>(formValue: T) {
  const formData = new FormData();
  let total_mb = 0;
  for (const key of Object.keys(formValue)) {
    const value = formValue[key];

    if ( value !== null ) {
      if ( value instanceof File ) {
        total_mb = total_mb + (value.size/1000000);
      }
      formData.append(key, value);
    }
  }
  return formData;
}
