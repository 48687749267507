import { Component, OnInit, Input, ɵConsole } from "@angular/core";
import { MessagesService } from 'src/app/services/MessagesService.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { parse } from 'url';
import { K2ContratosService } from 'src/app/services/K2ContratosService.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { requiredMultipleFileType } from 'src/app/directives/upload-file-multiple-validator';
import { requiredFileSize } from 'src/app/directives/upload-file-size-validator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-temp-messages-promotor-success',
    templateUrl: './temp-messages-success.component.html',
})
export class TempMessagesSuccessPromotorComponent implements OnInit {
    @Input('messages') messages: any;
    public dataContrato: any;
    public Contratos: any;
    public data: any;
    public totalScore: number;
    public score: any;
    public burosc: any;
    public IdSolicitud: number;
    public loading: boolean;
    public errorMessages: string;
    public canUpdate: boolean;

    public frmTM = new FormGroup({
        Seguro: new FormControl(null, [Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]),
        _IdSeguro: new FormControl(null,[Validators.min(1)]),   
        IdSolicitud: new FormControl(null) 
    });
    
    constructor(private messagesService: MessagesService, 
        private utilities: UtilitiesService, 
        private router: Router, 
        private route: ActivatedRoute, 
        private K2Service: K2ContratosService,
        private fb: FormBuilder,
        private modalError: NgbModal,
    ) {
        this.IdSolicitud = this.route.snapshot.params.id;
    }

    ngOnInit() {

        this.route.data.subscribe((data: Data) => {
            this.dataContrato = data['solicitudPromotor'].data.Contrato;
            this.Contratos = data['solicitudPromotor'].data.revision_documentacion_legales;
            this.data = data['collections'][0].data; 
        });
        this.Contratos.forEach(documento => {
        if (documento._IdSeguro) {
            this.frmTM.patchValue({
                _IdSeguro: documento._IdSeguro
            })
        }            
        });
        this.data.variablesScore.forEach(score => {
            this.totalScore = this.totalScore + parseInt(score.Puntos);
        });
        this.canUpdate = this.utilities.hasPermission('Actualizar Seguro');
        this.score = this.route.snapshot.data.solicitudPromotor.data.variable;
        this.burosc = this.route.snapshot.data.solicitudPromotor.data.burosc;

        this.IdSolicitud = this.route.snapshot.params.id;
    
        this.frmTM.patchValue({
            IdSolicitud: this.IdSolicitud
        })
    }

    downloadBuro(){
        this.K2Service.downloadBuro(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Buro.pdf');
                a.click();
            },
            errors => {
            },
        )
    }
    formatData(obj){
        var formatDataSkelleton = {
            Seguro:null,
        }
        obj.map(function(item){
            if(item.hasOwnProperty("_IdSeguro")){
                formatDataSkelleton.Seguro = item;
            }
        });
        return formatDataSkelleton;
    }

    onSubmit(modalError){
        if (!this.frmTM.valid) {
            Object.keys(this.frmTM.value).forEach(element => {
            if ( this.frmTM.get(element).errors != null ) {
                this.frmTM.get(element).markAsDirty();
                
            }
            });
            return;
        }
        this.loading = true;
        let sendData = toFormData(this.frmTM.value);
        

        this.K2Service.updateDocumentoSeguro( sendData ).subscribe(
            resp => {

                this.loading = false;
                this.Contratos = resp['data'].documentos;
                this.frmTM.controls['Seguro'].reset()
            },
            errors => {
                this.loading = false;
                if ( errors.status == 0 ) {
                    this.errorMessages = 'Error al cargar el documento. Favor de subir documento de menor tamaño.';
                }
                else{
                    this.errorMessages = errors.error.message;
                }

                this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        );
    }
}

export function markAllAsDirty(form: FormGroup) {
    for (const control of Object.keys(form.controls)) {
        form.controls[control].markAsDirty();
    }
}

export function toFormData<T>(formValue: T) {
    const formData = new FormData();
    let total_mb = 0;
    for (const key of Object.keys(formValue)) {
    const value = formValue[key];
    if ( value !== null ) {
        if ( value instanceof File ) {
        total_mb = total_mb + (value.size/1000000);
        }
        
        formData.append(key, value);
    }
    }

    return formData;
}