export class B1Model{
    public MontoSolicitado:number;
    public Plazo:any;
    public IdDestinoCredito: Array<number>;
    public IdAlianza:number;
    public Agencia: string;
    public Escuela: string;
    public FechaInicio: any;
    public InicioPrograma: any;
    public Duracion:number;
    public IdPeriodicidad: number;
    public PaisEstudios:number;
    public CiudadEstudios:number;
    public CreditoOtraPersona:string;
    public NombreEstudiante:string;
    public IdGeneroEstudiante: number;
    public FechaNacimientoEstudiante: any;
    public EmailEstudiante:string;
    public IdUsuario : number;
    public IdPersona: number;
    public NumeroDisposiciones: any;
    NoDisposiciones: any;
    MontoMaximo: any;
    MontoMinimo: any;
    IdPeriodicidadCotizacion: any;
    PlazoMin: any;
    PlazoMax: any;
    AplicaDisposiciones: any;
  IdPeriodicidadDisposiciones: number;
  IdCotizacion: any;
  MontoPago: any;

    constructor(){}
}
