import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Data, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { K1MesaControl } from 'src/app/services/promotor/K1MesaControl.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { L1Tesoreria } from 'src/app/services/promotor/L1Tesoreria.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { Collection } from 'src/app/models/collection.model';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-l1-tesoreria',
    templateUrl: './l1-tesoreria.component.html'
})

export class L1TesoreriaComponent implements OnInit {
    public status: number;
    public IdSolicitud: number;
    public errorMessages: string;
    public frmL1: FormGroup;
    public loading: boolean;
    public hasComment:boolean;

    /* collections */
    public currentState:any;
    public resumen: any;
    public bancos: Collection;
    public cuentas: any;
    public cuentas1: any;
    public cuentas2: any;

    public role_user: number;

    API_URL: string;

    public canEdit: boolean;

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private L1Service: L1Tesoreria,
        private modalError: NgbModal,
        private router: Router,
        private utilities: UtilitiesService,
    ) {
        this.status = 8;

        this.API_URL = environment.API_URL.replace('api/v1/', '');
        this.canEdit = false;
    }

    ngOnInit(): void {
        this.IdSolicitud = this.route.snapshot.params.id;
        this.route.data.subscribe((data: Data) => {
            this.currentState = data['collections'][0].data;
            this.resumen = data['collections'][1].data;
            this.bancos = data['collections'][2].data.bancos;
            this.cuentas = data['collections'][2].data.cuentas;    
        });
        this.cuentas1=null;
        this.cuentas2=null;


        this.canEdit = this.utilities.hasPermission('Importe de pago');

        this.frmL1 = this.createFrmL1();

        this.resumen.informacionPago.forEach((desembolso, index) => {
            let keyBanco = 'Banco' + (index +1);
            let keyCuenta = 'Cuenta' + (index +1);

            if ( keyBanco == "Banco1" ) {
                this.frmL1.patchValue({ Banco1: 0 });

                this.frmL1.get('FechaPago1').setValidators(Validators.required);

                this.frmL1.get('Banco1').setValidators([Validators.required, Validators.min(1)]);

                this.frmL1.patchValue({ Cuenta1: 0 });

                this.frmL1.get('Cuenta1').setValidators(Validators.required);
                
                this.frmL1.get('ImportePago1').setValidators(Validators.required);

                this.frmL1.get('FolioOperacion1').setValidators(Validators.required);
            }

            if ( keyBanco == "Banco2" ) {
                this.frmL1.patchValue({ Banco2: 0 });

                this.frmL1.get('FechaPago2').setValidators(Validators.required);

                this.frmL1.get('Banco2').setValidators([Validators.required, Validators.min(1)]);

                this.frmL1.patchValue({ Cuenta2: 0 });

                this.frmL1.get('Cuenta2').setValidators(Validators.required);
                
                this.frmL1.get('ImportePago2').setValidators(Validators.required);

                this.frmL1.get('FolioOperacion2').setValidators(Validators.required);
            }


        });

        this.role_user = parseInt( localStorage.getItem('userRole') );
        this.hasComment=false;
    }

    submitL1(modalError){
        if (!this.frmL1.valid) {
            Object.keys(this.frmL1.value).forEach(element => {
              if ( this.frmL1.get(element).errors != null ) {
                this.frmL1.get(element).markAsDirty();
              }
            });
            return;
        }

        this.loading = true;
        this.frmL1.patchValue({IdSolicitud: this.IdSolicitud, FechaPago1: this.utilities.parseDate(this.frmL1.value.FechaPago1), FechaPago2: this.utilities.parseDate(this.frmL1.value.FechaPago2)});
        let sendData = this.frmL1.value;

        this.L1Service.sendPago(sendData).subscribe(
            resp => {
                this.router.navigateByUrl("promotor/dashboard");
                this.loading = false;
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
                this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        );
    }
    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }
    showReturnPromotor(){
        this.hasComment=true;
    }
    returnPromotor(modalError){
        if (this.frmL1.controls['ComentarioCambio'].untouched)
        {  
            this.errorMessages = "Falta un comentario para el promotor.";
            this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
        }
        if (this.frmL1.controls['ComentarioCambio'].value.length<5)
        {  
            this.errorMessages = "El comentario es muy corto.";
            this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
        }
        this.loading = true;
        let sendData = {
            IdSolicitud: this.IdSolicitud,
            ComentarioCambio:this.frmL1.controls['ComentarioCambio'].value,
            TipoComentario:4
        };

        this.L1Service.returnPromotor(sendData).subscribe(
            resp => {
                this.router.navigateByUrl("promotor/dashboard");
                this.loading = false;
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
                this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        );
    }
    handleBanco(event, index){
        let banco=event.target.value;
        let cuentasBanco=this.cuentas.filter(cuenta=>cuenta.idBanco==banco);
        if (index==1){
            this.cuentas1=[...cuentasBanco];
            this.frmL1.patchValue({ Cuenta1: 0 });
        }else if(index==2){
            this.cuentas2=[...cuentasBanco];
            this.frmL1.patchValue({ Cuenta2: 0 });
        }
    }

    createFrmL1(){
        return this.fb.group({
            IdSolicitud: [
                null,
            ],
            ComentarioCambio: [
                null,
            ],
            FechaPago1: [
                null,
                Validators.required
            ],
            FechaPago2: [
                null,
            ],
            Banco1: [
                null,
            ],
            Banco2: [
                null,
            ],
            Cuenta1: [
                null,
                Validators.required
            ],
            Cuenta2: [
                null,
            ],
            ImportePago1: [
                null,
                Validators.required
            ],
            ImportePago2: [
                null,
            ],
            FolioOperacion1: [
                null,
                Validators.required
            ],
            FolioOperacion2: [
                null,
            ],
            Comentarios1: [
                null,
            ],
            Comentarios2: [
                null,
            ]
        });
    }
}