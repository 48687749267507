import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute, Data } from '@angular/router';
import { Collection } from 'src/app/models/collection';
import { E2InformacionEmpleoComplemento } from "../../../models/E2InformacionEmpleoComplemento.model";
import { E2InformacionEmpleoComplementoService } from "../../../services/E2InformacionEmpleoComplementoService.service";
import { CollectionsService } from 'src/app/services/colecctions.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-e2-informacion-laboral',
  templateUrl: './e2-informacion-laboral.component.html',
  styleUrls: ['./e2-informacion-laboral.component.scss']
})
export class E2InformacionLaboralComponent implements OnInit, AfterViewInit {
  public status: number;
  public collectionsPais: Collection;
  public collectionsEstado: Collection;
  public collectionsCiudad: Collection;
  public collectionsMunicipio: Collection;
  public collectionsColonia: Collection;
  public data: E2InformacionEmpleoComplemento;
  public errorMessages: string;
  public _prevFunctionsExternal: boolean;
  public _prevFunctionsFamiliarExternal: boolean;
  public valid_country: boolean;
  public frmE2: FormGroup;
  public previous_data: any;
  public IdSolicitud: number;
  public loading: boolean;
  public address_id = ['IdCiudad', 'IdEstado', 'IdMunicipio', 'IdColonia'];
  public address = ['Ciudad', 'Estado', 'Municipio', 'Colonia'];
  public dataFormat = {
    Puesto: null,
    Extension: null,
    Telefono: null,
    Calle: null,
    NoExterior: null,
    NoInterior: null,
    IdPais: null,
    IdColonia: null,
    IdMunicipio: null,
    IdCiudad: null,
    IdEstado: null,
    // IdCodigoPostal: null,
    Colonia: null,
    Municipio: null,
    Ciudad: null,
    Estado: null,
    CodigoPostal: null,
    PoliticaExpuesta: null,
    PuestoPoliticaExpuesta: null,
    PeriodoPoliticaExpuesta: null,
    FamiliarPoliticaExpuesta: null,
    PuestoFamiliarPoliticaExpuesta: null,
    PeriodoFamiliarPoliticaExpuesta: null,
    IdSolicitud: null,
  };

  public has_puesto: boolean;

  constructor(
    private E2Service: E2InformacionEmpleoComplementoService,
    private route: ActivatedRoute,
    private colectionsService: CollectionsService,
    private utilitiesService: UtilitiesService,
    private fb: FormBuilder,
    private router: Router,
    private modalError: NgbModal,
  ) {
    this.status = 3;
    
    this.data = new E2InformacionEmpleoComplemento();
    this.loading = false;
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.IdSolicitud = this.route.snapshot.params.id;
    this.route.data.subscribe((data: Data) => {
      this.collectionsPais = data['collections'][0].data;
      this.collectionsColonia = data['collections'][1].data;
    });

    if (this.route.snapshot.data.IdSolicitud) {
      this.previous_data = this.route.snapshot.data.IdSolicitud.data.E2;
    }
    this.has_puesto = this.route.snapshot.data.IdSolicitud.data.E1.puesto != null ? true : false;


    this.frmE2 = this.createE2Form();

    if (this.previous_data) {
      if (this.previous_data.direccion.IdPais.toString() == "1") {
        this.valid_country = true;
        this.colectionsService.getStateByCountryId(this.previous_data.direccion.IdPais).subscribe(
          data => {
            this.collectionsEstado = data.data;
          }
        )
        this.colectionsService.getCitiesById(this.previous_data.direccion.IdPais, this.previous_data.direccion.IdEstado).subscribe(
          data => {
            this.collectionsCiudad = data.data;
          }
        )
        this.colectionsService.getMunicipalitiesById(this.previous_data.direccion.IdPais, this.previous_data.direccion.IdEstado).subscribe(
          data => {
            this.collectionsMunicipio = data.data;
          }
        )
        this.colectionsService.getCollectionsColoniasByPais(this.previous_data.direccion.IdPais, this.previous_data.direccion.IdEstado, this.previous_data.direccion.IdMunicipio).subscribe(
          data => {
            this.collectionsColonia = data.data;
          }, error => {
            // this.valid_country = false;
          }
        )
      } else {
        this.valid_country = false;
      }

      this.previous_data.empleo.PoliticaExpuesta == "1" ? this._prevFunctionsExternal = false : this._prevFunctionsExternal = true;
      this.previous_data.empleo.FamiliarPoliticaExpuesta == "1" ? this._prevFunctionsFamiliarExternal = false : this._prevFunctionsFamiliarExternal = true;

      this.dataFormat.Puesto = this.previous_data.empleo.Puesto;
      this.dataFormat.Telefono = this.previous_data.telefono.Telefono;
      this.dataFormat.Extension =  this.previous_data.telefono.Extension;
      this.dataFormat.IdPais = this.previous_data.direccion.IdPais;
      this.dataFormat.IdEstado = this.previous_data.direccion.IdEstado;
      this.dataFormat.IdCiudad = this.previous_data.direccion.IdCiudad;
      this.dataFormat.IdMunicipio = this.previous_data.direccion.IdMunicipio;
      this.dataFormat.IdColonia = this.previous_data.direccion.IdColonia;
      this.dataFormat.Estado = this.previous_data.direccion.Estado;
      this.dataFormat.Ciudad = this.previous_data.direccion.Ciudad;
      this.dataFormat.Municipio = this.previous_data.direccion.Municipio;
      this.dataFormat.Colonia = this.previous_data.direccion.Colonia;
      // this.dataFormat.IdCodigoPostal = this.previous_data.direccion.CodigoPostal;
      this.dataFormat.CodigoPostal = this.previous_data.direccion.CodigoPostal;
      this.dataFormat.Calle = this.previous_data.direccion.Calle;
      this.dataFormat.NoExterior = this.previous_data.direccion.NoExterior;
      this.dataFormat.NoInterior = this.previous_data.direccion.NoInterior;
      this.dataFormat.PoliticaExpuesta = this.previous_data.empleo.PoliticaExpuesta == "1" ? "yes" : "no";
      this.dataFormat.FamiliarPoliticaExpuesta = this.previous_data.empleo.FamiliarPoliticaExpuesta == "1" ? "yes" : "no";
      this.dataFormat.PuestoPoliticaExpuesta = this.previous_data.empleo.PuestoPoliticaExpuesta;
      this.dataFormat.PeriodoPoliticaExpuesta = this.previous_data.empleo.PeriodoPoliticaExpuesta;
      this.dataFormat.PuestoFamiliarPoliticaExpuesta = this.previous_data.empleo.PuestoFamiliarPoliticaExpuesta;
      this.dataFormat.PeriodoFamiliarPoliticaExpuesta = this.previous_data.empleo.PeriodoFamiliarPoliticaExpuesta;


      this.dataFormat.IdSolicitud = this.IdSolicitud;

      this.frmE2.patchValue(this.dataFormat);
    } else {
      this._prevFunctionsExternal = true;
      this._prevFunctionsFamiliarExternal = true;

      this.valid_country = true;
      this.frmE2.patchValue({IdPais: 1});

      this.colectionsService.getStateByCountryId(1).subscribe(
        data => {
          this.collectionsEstado = data.data;
        }
      )
      
      this.frmE2.patchValue({
        PoliticaExpuesta: "no",
        FamiliarPoliticaExpuesta: "no",
        IdSolicitud: this.IdSolicitud,
        IdEstado: 0,
        IdCiudad: 0,
        IdMunicipio: 0,
        IdColonia: 0,
      });
    }



  }

  handleChangeFunctionsExternal(evt) {
    var target = evt.target;
    if (target.value == "yes") {
      this._prevFunctionsExternal = false;
      let controls = {
        PuestoPoliticaExpuesta: [
          Validators.required,
          null
        ],
        PeriodoPoliticaExpuesta: [
          Validators.required,
          null
        ],
      }
      this.utilitiesService.addControlsValidity(this.fb, controls);
    } else {
      this._prevFunctionsExternal = true;
      let controls = ['PuestoPoliticaExpuesta', 'PeriodoPoliticaExpuesta'];
      this.utilitiesService.removeControlsValidity(this.frmE2, controls);
      this.frmE2.controls["PuestoPoliticaExpuesta"].setValue(null);
      this.frmE2.controls["PeriodoPoliticaExpuesta"].setValue(null);
    }
  }

  handleChangeFunctionsFamiliarExternal(evt) {
    var target = evt.target;
    if (target.value == "yes") {
      this._prevFunctionsFamiliarExternal = false;
      let controls = {
        PuestoFamiliarPoliticaExpuesta: [
          Validators.required,
          null
        ],
        PeriodoFamiliarPoliticaExpuesta: [
          Validators.required,
          null
        ],
      }
      this.utilitiesService.addControlsValidity(this.fb, controls);
    } else {
      this._prevFunctionsFamiliarExternal = true;
      let controls = ['PuestoFamiliarPoliticaExpuesta', 'PeriodoFamiliarPoliticaExpuesta'];
      this.utilitiesService.removeControlsValidity(this.frmE2, controls);
      this.frmE2.controls["PeriodoFamiliarPoliticaExpuesta"].setValue(null);
      this.frmE2.controls["PuestoFamiliarPoliticaExpuesta"].setValue(null);
    }
  }

  handleCountry(evt) {
    var index = evt.target.selectedIndex;
    var textCountry = evt.target[index].text;
    var indexCountry = evt.target.value;
    if (indexCountry == "1" && textCountry == "MEXICO") {
      this.address_id.forEach(element => {
        this.frmE2.get(element).setValidators(Validators.min(1));
      });
      this.frmE2.patchValue({CodigoPostal: ''});

      this.colectionsService.getStateByCountryId(indexCountry).subscribe(
        data => {
          this.valid_country = true;
          this.collectionsEstado = data.data;
          this.utilitiesService.removeControlsValidity(this.frmE2, this.address);
        }, error => {
          this.valid_country = false;
          this.utilitiesService.removeControlsValidity(this.frmE2, this.address_id);
        }
      )

    } else {
      this.valid_country = false;
      this.utilitiesService.removeControlsValidity(this.frmE2, this.address_id);
    }
  }

  handleState(evt) {
    var indexState = evt.target.value;
    var indexCountry = this.frmE2.controls['IdPais'].value;
    if (indexState != "0") {
      this.frmE2.patchValue({IdCiudad: 0, IdMunicipio: 0, IdColonia: 0, CodigoPostal: ''});
      this.colectionsService.getCitiesById(indexCountry, indexState).subscribe(
        data => {
          this.valid_country = true;
          this.collectionsCiudad = data.data;
        }, error => {
          this.valid_country = false;
        }
      )
      this.colectionsService.getMunicipalitiesById(indexCountry, indexState).subscribe(
        data => {
          this.valid_country = true;
          this.collectionsMunicipio = data.data;
        }, error => {
          this.valid_country = false;
        }
      )

    }

  }

  handleTown(){
    var IdPais = this.frmE2.controls['IdPais'].value ;
    var IdEstado = this.frmE2.controls['IdEstado'].value;
    var IdMunicipio = this.frmE2.controls['IdMunicipio'].value;

      if ( this.frmE2.controls['IdPais'].value && this.frmE2.controls['IdEstado'].value && this.frmE2.controls['IdMunicipio'].value ) {
        this.frmE2.patchValue({IdColonia: 0, CodigoPostal: ''});
        this.colectionsService.getCollectionsColoniasByPais(IdPais, IdEstado, IdMunicipio).subscribe(
          data => {
            this.collectionsColonia = data.data;
          }, error => {
            // this.valid_country = false;
          }
        )
      }
  }

  changeColonia(colonia){
    var index = colonia.target.selectedIndex;
    if ( colonia.target[index].getAttribute('CodigoPostal') != null ) {
      this.frmE2.patchValue({CodigoPostal: colonia.target[index].getAttribute('CodigoPostal')});
    }
  }

  onlyDigits(event){
      if (event.which != 8 && event.which != 0 && event.which < 48 || event.which > 57)
      {
          event.preventDefault();
      }
  }

  onSubmit(modalError) {
    if (!this.frmE2.valid) {
      Object.keys(this.frmE2.value).forEach(element => {
        if ( this.frmE2.get(element).errors != null ) {
          this.frmE2.get(element).markAsDirty();
        }
      });
      return;
    }

    this.loading = true;
    let tempPoliticaExpuesta = this.frmE2.controls["PoliticaExpuesta"].value == "yes" ? 1 : 0;
    let tempPoliticaFamiliarExpuesta = this.frmE2.controls["FamiliarPoliticaExpuesta"].value == "yes" ? 1 : 0;
    let sendData = this.frmE2.value;
    sendData.PoliticaExpuesta = tempPoliticaExpuesta;
    sendData.FamiliarPoliticaExpuesta = tempPoliticaFamiliarExpuesta;
    sendData.CodigoPostal = this.frmE2.controls["CodigoPostal"].value;

    if ( ! this.valid_country) {
      delete sendData["IdEstado"];
      delete sendData["IdCiudad"];
      delete sendData["IdColonia"];
      delete sendData["IdMunicipio"];
      // delete sendData["IdCodigoPostal"];
    }
    else{
      delete sendData["Estado"];
      delete sendData["Ciudad"];
      delete sendData["Colonia"];
      delete sendData["Municipio"];
      // delete sendData["CodigoPostal"];
    }

    this.E2Service.sendInformacionLaboralComplemento(sendData).subscribe(
      resp => {
        this.router.navigateByUrl("client/referencias/" + this.IdSolicitud);
      },
      errors => {
        this.loading = false;
        this.errorMessages = errors.error.message;
        this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
      },
    )
  }

  createE2Form() {
    return this.fb.group({
      Puesto: [
        null
      ],
      Extension: [
        Validators.required
      ],
      Telefono: [
        Validators.required
      ],
      Calle: [
        null
      ],
      NoExterior: [
        null
      ],
      NoInterior: [
        null
      ],
      IdPais: [
        Validators.required,
        Validators.min(1)
      ],
      IdColonia: [
        Validators.required,
        Validators.min(1)
      ],
      IdMunicipio: [
        Validators.required,
        Validators.min(1)
      ],
      IdCiudad: [
        Validators.required,
        Validators.min(1)
      ],
      IdEstado: [
        Validators.required,
        Validators.min(1)
      ],
      Colonia: [
        null
      ],
      Municipio: [
        null
      ],
      Ciudad: [
        null
      ],
      Estado: [
        null
      ],
      CodigoPostal: [
        null
      ],
      PoliticaExpuesta: [
        Validators.required
      ],
      PuestoPoliticaExpuesta: [
        null
      ],
      PeriodoPoliticaExpuesta: [
        null
      ],
      FamiliarPoliticaExpuesta: [
        Validators.required
      ],
      PuestoFamiliarPoliticaExpuesta: [
        null
      ],
      PeriodoFamiliarPoliticaExpuesta: [
        null
      ],
      IdSolicitud: [
        null
      ],
    })
  }
}