import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';

/* Resolvers begin */
import { FormHandlerResolver } from './resolvers/form-handler-resolver.service';
import { B3Resolver } from './resolvers/B3_resolver.service';
import { ARegistro_resolver } from './resolvers/ARegistro_resolver.service';
import { C1Resolver } from './resolvers/C1_resolver.service';
import { C2Resolver } from './resolvers/C2Resolver.service';
import { E1Resolver } from './resolvers/E1Resolver.service';
import { E2Resolver } from './resolvers/E2Resolver.service';
import { IdSolicitudResolver } from "./resolvers/IdSolicitudResolver.service";
import { E3Resolver } from './resolvers/E3Resolver.service';
import { E5Resolver } from './resolvers/E5Resolver.service';
import { LDashboardResolver } from './resolvers/LDashboardResolver.service';
import { MessagesResolver } from './resolvers/MessagesResolver.service';
import { K1Resolver } from './resolvers/K1Resolver.service';
import { SolicitudResolver } from './resolvers/SolicitudResolver.service';
import { D8PromotorResolver } from './resolvers/D8PromotorResolver.service';
import { D2RevisionResolver } from './resolvers/promotor/D2RevisionResolver.service';
import { D3RevisionAcreditacionResolver } from './resolvers/promotor/D3RevisionAcreditacionResolver.service';
import { D4AcreditacionRevisionEmpleoResolver } from './resolvers/promotor/D4RevisionAcreditacionEmpleoResolver.service';
import { D5AcreditacionRevisionAcreditacionResolver } from './resolvers/promotor/D5RevisionAcreditacionPreanalisisResolver.service';
import { D5AcreditacionRevisionReferenciasResolver } from './resolvers/promotor/D5RevisionAcreditacionReferenciasResolver.service';
import { D6RevisionAcreditacionDocumentacionResolver } from './resolvers/promotor/D6RevisionAcreditacionDocumentacionResolver.service';
import { F1CotizacionResolver } from './resolvers/promotor/F1CotizacionResolver.service';
import { C3RevisionSolicitudResolver } from './resolvers/promotor/C3RevisionSolicitudResolver.service';
import { G1LineaCreditoResolver } from './resolvers/promotor/G1LineaCreditoResolver.service';
import { J1DesembolsoResolver } from './resolvers/promotor/J1DesembolsoResolver.service';
import { K1MesaControlResolver } from './resolvers/promotor/K1MesaControlResolver.service';
import { H2CambioCondicionesResolver } from './resolvers/promotor/H2CambioCondicionesResolver.service';
import { I2RevisionContratosResolver } from './resolvers/promotor/I2RevisionContratosResolver.service';
import { B1CapturaInformacionResolver } from './resolvers/aval/B1CapturaInformacionResolver.service';
import { B2CapturaInformacionFiscalResolver } from './resolvers/aval/B2CapturaInformacionFiscalResolver.service';
import { C1InformacionPersonalResolver } from './resolvers/aval/C1InformacionPersonalResolver.service';
import { C2InformacionLaboralResolver } from './resolvers/aval/C2InformacionLaboralResolver.service';
import { SolicitudAvalPrivilegesResolver } from './resolvers/aval/SolicitudAvalPrivilegesResolver';
import { M1PromotorInformacionPersonalResolver } from './resolvers/promotor/M1PromotorInformacionPersonalResolver.service';
import { AvalPreviousDataResolver } from './resolvers/aval/AvalPreviousDataResolver.service';
import { N1Resolver } from './resolvers/promotor/N1_resolver.service';
import {InfoInmuebleResolver} from './resolvers/promotor/InfoInmuebleResolver.service'
import {InfoAutoResolver} from './resolvers/promotor/InfoAutoResolver.service'

/* Resolvers end */

/* CLient begin */
import { ClientGuard } from './guards/client.guard';
import { ARegistroComponent } from './components/client/a-registro/a-registro.component';
import { A01CorreoUsadoComponent } from './components/client/a01-correo-usado/a01-correo-usado.component';
import { A12DatosPersonalesComponent } from './components/client/a12-datos-personales/a12-datos-personales.component';
import { A2InicioSesionComponent } from './components/client/a2-inicio-sesion/a2-inicio-sesion.component';
import { A21OlvideContraseniaComponent } from './components/client/a21-olvide-contrasenia/a21-olvide-contrasenia.component';
import { A22RestablecerContraseniaComponent } from './components/client/a22-restablecer-contrasenia/a22-restablecer-contrasenia.component';
import { B1DestinoCreditoComponent } from './components/client/b1-destino-credito/b1-destino-credito.component';
import { B2ConfirmacionRequisitosComponent } from './components/client/b2-confirmacion-requisitos/b2-confirmacion-requisitos.component';
import { B3InformacionPersonalComponent } from './components/client/b3-informacion-personal/b3-informacion-personal.component';
import { B4AutorizarBuroComponent } from './components/client/b4-autorizar-buro/b4-autorizar-buro.component';
import { C11InformacionFamiliarComponent } from './components/client/c11-informacion-familiar/c11-informacion-familiar.component';
import { C2InformacionLaboralComponent } from './components/client/c2-informacion-laboral/c2-informacion-laboral.component';
import { C13InformacionEconomicaComponent } from './components/client/c13-informacion-economica/c13-informacion-economica.component';
import { E1InformacionPersonalComponent } from './components/client/e1-informacion-personal/e1-informacion-personal.component';
import { E2InformacionLaboralComponent } from './components/client/e2-informacion-laboral/e2-informacion-laboral.component';
import { E3ReferenciasComponent } from './components/client/e3-referencias/e3-referencias.component';
import { E4ConfirmacionIdentidadComponent } from './components/client/e4-confirmacion-identidad/e4-confirmacion-identidad.component';
import { E5DocumentacionComponent } from './components/client/e5-documentacion/e5-documentacion.component';
import { F1InformacionAvalComponent } from "./components/client/f1-informacion-aval/f1-informacion-aval.component";
import { I1CargaDocumentosComponent } from './components/client/i1-carga-documentos/i1-carga-documentos.component';
import { K1InstruccionDepositoComponent } from './components/client/k1-instruccion-deposito/k1-instruccion-deposito.component';
import { K2ContratosComponent } from './components/client/k2-contratos/k2-contratos.component';
import { TempMessagesComponent } from './components/client/temp-messages/temp-messages.component';
import { LDashboardComponent } from './components/client/l-dashboard/l-dashboard.component';
/* Client end */

/* Promotor begin */
import { B1SolicitudesComponent } from './components/promotor/b1-solicitudes/b1-solicitudes.component';
import { C1PreanalisisComponent } from './components/promotor/c1-preanalisis/c1-preanalisis.component';
import { C2AvalesComponent } from './components/promotor/c2-avales/c2-avales.component';
import { C3RevisionSolicitudComponent } from './components/promotor/c3-revision-solicitud/c3-revision-solicitud.component';
import { D2RevisionSolicitudComponent } from './components/promotor/d2-revision-destino/d2-revision-destino.component';
import { D3RevisionAcreditacionComponent } from './components/promotor/d3-revision-acreditacion/d3-revision-acreditacion.component';
import { D4RevisionAcreditacionEmpleoComponent } from './components/promotor/d4-revision-acreditacion-empleo/d4-revision-acreditacion-empleo.component';
import { D5RevisionAcreditacionPreanalisisComponent } from './components/promotor/d5-revision-acreditacion-preanalisis/d5-revision-acreditacion-preanalisis.component';
import { D5RevisionAcreditacionReferenciasComponent } from './components/promotor/d5-revision-acreditacion-referencias/d5-revision-acreditacion-referencias.component';
import { D6RevisionAcreditacionDocumentacionComponent } from './components/promotor/d6-revision-acreditacion-documentacion/d6-revision-acreditacion-documentacion.component';
import { D8GarantiaAutoComponent } from './components/promotor/d8-garantia-auto/d8-garantia-auto.component';
import { D9EvidenciaInmueble } from './components/promotor/d9-evidencia-inmueble/d9-evidencia-inmueble.component';
import { E1EntrevistaComponent } from './components/promotor/e1-entrevista/e1-entrevista.component';
import { F1CotizacionComponent } from './components/promotor/f1-cotizacion/f1-cotizacion.component';
import { G1LineaCreditoComponent } from './components/promotor/g1-linea-credito/g1-linea-credito.component';
import { G2EnviarComiteComponent } from './components/promotor/g2-enviar-comite/g2-enviar-comite.component';
import { H1RevisionComiteComponent } from './components/promotor/h1-revision-comite/h1-revision-comite.component';
import { H2CambioCondicionesComponent } from './components/promotor/h2-cambio-condiciones/h2-cambio-condiciones.component';
import { I1EnvioContratosComponent } from './components/promotor/i1-envio-contratos/i1-envio-contratos.component';
import { I2RevisionContratosComponent } from './components/promotor/i2-revision-contratos/i2-revision-contratos.component';
import { I3SubirContratosComponent } from './components/promotor/i3-subir-contratos/i3-subir-contratos.component';
import { I4InstruccionDepositoComponent } from './components/promotor/i4-instruccion-deposito/i4-instruccion-deposito.component';
import { J1DesembolsoComponent } from './components/promotor/j1-desembolso/j1-desembolso.component';
import { K1MesaControlComponent } from './components/promotor/k1-mesa-control/k1-mesa-control.component';
import { L1TesoreriaComponent } from './components/promotor/l1-tesoreria/l1-tesoreria.component';
import { MessagesComponent } from './components/promotor/messages/messages.component';
import { M1PromotorAvalInformacionPersonalComponent } from './components/promotor/m1-promotor-aval-informacion-personal/m1-promotor-aval-informacion-personal.component';
import { M2PromotorAvalInformacionFiscal } from './components/promotor/m2-promotor-aval-informacion-fiscal/m2-promotor-aval-informacion-fiscal.component';
import { TempMessagesPromotorComponent } from './components/promotor/temp-messages/temp-messages.component';
import { DetalleComponent } from './components/promotor/messages/detalle.component';
/* Promotor end */

/* Aval begin */
import { B1CapturaInformacionComponent } from './components/aval/b1-captura-informacion/b1-captura-informacion.component';
import { B2CapturaInformacionFiscalComponent } from './components/aval/b2-captura-informacion-fiscal/b2-captura-informacion-fiscal.component';
import { B3CapturaInformacionBuro } from './components/aval/b3-autorizar-buro/b3-autorizar-buro.component';
import { C1InformacionPersonal } from './components/aval/c1-informacion-personal/c1-informacion-personal.component';
import { C2InformacionLaboralAval } from './components/aval/c2-informacion-laboral/c2-informacion-laboral-aval.component';
import { C3InformacionEconomicaComponent } from './components/aval/c3-informacion-economica/c3-informacion-economica-aval.component';
import { C4ConfirmacionIdentidadComponent } from './components/aval/c4-confirmacion-identidad/c4-confirmacion-identidad-aval.component';
import { C5DocumentacionAvalComponent } from './components/aval/c5-documentacion/c5-documentacion.component';
import { AvalGuard } from './guards/aval.guard';
/* Aval end */

/* Aditionals Begin */
import { Admin2CatalogosComponent } from './components/admin/2-catalogos/admin-2-catalogos.component';
import { AdminParametrosGeneralesComponent } from './components/admin/1.4-parametros-generales/admin-parametros-generales.component';

import { PromotorAvalInformacionResolver } from './resolvers/promotor/PromotorAvalInformationResolver.service';
import { M2PromotorInformacionFiscalResolver } from './resolvers/promotor/M2PromotorInformacionFiscalResolver.service';
import { M3PromotorAvalInformacionFamiliar } from './components/promotor/m3-promotor-aval-informacion-familiar/m3-promotor-aval-informacion-familiar.component';
import { M3PromotorInformacionFamiliarResolver } from './resolvers/promotor/M3PromotorInformacionFamiliarResolver.service';
import { M4PromotorAvalInformacionLaboralComponent } from './components/promotor/m4-promotor-aval-informacion-laboral/m4-promotor-aval-informacion-laboral.component';
import { M4PromotorInformacionLaboralResolver } from './resolvers/promotor/M4PromotorInformacionLaboralResolver.service';
import { M5PromotorAvalInformacionEconomicaComponent } from './components/promotor/m5-promotor-aval-informacion-economica/m5-promotor-aval-informacion-economica.component';
import { TempMessagesAvalComponent } from './components/aval/temp-messages/temp-messages.component';
import { B1SolicitudesResolver } from './resolvers/promotor/B1SolicitudesResolver.service';
import { M6PromotorAvalDocumentacionComponent } from './components/promotor/m6-promotor-aval-documentacion/m6-promotor-aval-documentacion.component';
import { M6SubirDocumentacionAvalComponent } from './components/promotor/m6-promotor-aval-subir-documentacion/m6-promotor-aval-subir-documentacion.component';
import { M6PromotorDocumentacionResolver } from './resolvers/promotor/M6PromotorDocumentacionResolver.service';
import { F1InformacionClientResolver } from './resolvers/F1InformacionClientResolver.service';
import { APreguntasFrecuentes } from './components/client/a-preguntas-frecuentes/a-preguntas-frecuentes.component';
import { A23VerificarCorreoComponent } from './components/client/a23-verificar-correo/a23-verificar-correo.component';
import { A24CambioContrasenaComponent } from './components/client/a24-cambio-contrasena/a24-cambio-contrasena.component';
import { B1MotivoRechazoResolver } from './resolvers/promotor/B1MotivoRechazoResolver.service';
import { ReasignarSolicitudes } from './components/super_promotor/reasignar-solicitudes/reasignar-solicitudes.component';
import { PromotoresResolver } from './resolvers/promotor/PromotoresResolver.service';
import { D5RevisionCotizacionComponent } from './components/promotor/d5-revision-cotizacion/d5-revision-cotizacion.component';
import { PermissionsGuard } from './guards/PermissionsGuard';
import { PermissionsComponent } from './components/promotor/permissions/permissions.component';
import { LoginGuard } from './guards/login.guard';
import { D6SubirDocumentacionComponent } from './components/promotor/d6-subir-documentacion/d6-subir-documentacion';
import { MantenimientoComponent } from './components/mantenimiento/mantenimiento.component';
import { N1CambioEstructura } from './components/promotor/n1-cambio-estructura/n1-cambio-estructura.component';

/* Aditionals end */


const routes: Routes = [
    { path: 'mantenimiento', component: MantenimientoComponent},
    { path: 'login', canActivate: [LoginGuard], component: A2InicioSesionComponent },
    { path: 'registro', component: ARegistroComponent },
    { path: 'correo_usado', component: A01CorreoUsadoComponent },
    { path: 'preguntas_frecuentes', component: APreguntasFrecuentes },
    { path: 'login/recuperar_password', component: A21OlvideContraseniaComponent },
    { path: 'cambiar/contrasena', component: A24CambioContrasenaComponent },
    { path: 'restablecer/password/:token/:email', component: A22RestablecerContraseniaComponent },
    { path: 'verificar/correo/:token', component: A23VerificarCorreoComponent },
    /* INICIO Rutas para cliente */
    {
        path:'client',
        canActivate:[AuthGuard,ClientGuard],
        children:[
            {
                path:'dashboard',
                resolve:{
                    solicitudes: LDashboardResolver,
                    collections: ARegistro_resolver
                },
                component:LDashboardComponent
            },
            {
                path: 'datos_personales',
                component: A12DatosPersonalesComponent,
                resolve: {
                    collections: ARegistro_resolver
                }
            },
            {
                path: 'destino_credito',
                component: B1DestinoCreditoComponent,
                resolve:{
                    collections: FormHandlerResolver,
                },
                // children:[
                //     {
                //         path: ':id',
                //         component: B1DestinoCreditoComponent,
                //         resolve:{
                //             collections: FormHandlerResolver,
                //             IdSolicitud: IdSolicitudResolver,
                //         }
                //     }
                // ]
            },
            {
                path: 'destino_credito/:id',
                component: B1DestinoCreditoComponent,
                resolve:{
                    collections: FormHandlerResolver,
                    IdSolicitud: IdSolicitudResolver,
                },
            },
            {
                path: 'requisitos_credito/:id', component: B2ConfirmacionRequisitosComponent,
                resolve: {
                    IdSolicitud: IdSolicitudResolver
                }
            },
            {
                path: 'informacion_personal/:id', component: B3InformacionPersonalComponent,
                resolve: {
                    collections: B3Resolver,
                    IdSolicitud: IdSolicitudResolver
                }
            },
            {
                path: 'autorizar_buro/:id', component: B4AutorizarBuroComponent,
                resolve: {
                    IdSolicitud: IdSolicitudResolver
                }
            },
            {
                path: 'informacion_familiar/:id',
                component: C11InformacionFamiliarComponent,
                resolve: {
                    collections: C1Resolver,
                    IdSolicitud: IdSolicitudResolver
                }
            },
            {
                path: 'informacion_laboral/:id',
                component: C2InformacionLaboralComponent,
                resolve: {
                    collections: C2Resolver,
                    IdSolicitud: IdSolicitudResolver
                }
            },
            {
                path: 'informacion_economica/:id',
                component: C13InformacionEconomicaComponent,
                resolve: {
                    IdSolicitud: IdSolicitudResolver,
                }
            },
            {
                path: 'informacion_personal_extra/:id',
                component: E1InformacionPersonalComponent,
                resolve: {
                    collections: E1Resolver,
                    IdSolicitud: IdSolicitudResolver,
                }
            },
            {
                path: 'informacion_laboral_extra/:id',
                component: E2InformacionLaboralComponent,
                resolve: {
                    collections: E2Resolver,
                    IdSolicitud: IdSolicitudResolver,
                }
            },
            {
                path: 'referencias/:id',
                component: E3ReferenciasComponent,
                resolve: {
                    collections: E3Resolver,
                    IdSolicitud: IdSolicitudResolver,
                }
            },
            {
                path: 'confirmacion_identidad/:id',
                component: E4ConfirmacionIdentidadComponent,
                resolve: {
                    IdSolicitud: IdSolicitudResolver,
                }
            },
            {
                path: 'documentacion/:id',
                component: E5DocumentacionComponent,
                resolve: {
                    collections: E5Resolver,
                    IdSolicitud: IdSolicitudResolver
                }
            },
            {
                path: 'informacion_aval/:id',
                component: F1InformacionAvalComponent,
                resolve: {
                    data: F1InformacionClientResolver
                }
            },
            {
                path: 'carga_documentos/:id',
                component: I1CargaDocumentosComponent,
                resolve:{
                    solicitud: MessagesResolver
                }
            },
            {   path: 'instruccion_deposito/:id',
                component: K1InstruccionDepositoComponent,
                resolve: {
                    collections: K1Resolver,
                    IdSolicitud: IdSolicitudResolver,
                }
            },
            {
                path: 'contratos/:id',
                component: K2ContratosComponent,
                resolve: {
                    IdSolicitud: IdSolicitudResolver,
                }
            },
            {
                path: 'messages/:id',
                component: TempMessagesComponent,
                resolve:{
                    proceso: MessagesResolver
                }
            },
        ]
    },
    /* FIN Rutas para cliente */

    /* INICIO Rutas para promotor */
    {
        path:'promotor',
        canActivate:[AuthGuard],
        children:[
            {
                path: 'dashboard',
                component: B1SolicitudesComponent,
                resolve:{
                    collections: B1SolicitudesResolver
                }
            },
            {
                path: 'reasignar/solicitudes',
                component: ReasignarSolicitudes,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Reasignar Solicitudes'
                },
                resolve:{
                    collections: B1SolicitudesResolver,
                    promotores: PromotoresResolver
                }
            },
            {
                path: 'preanalisis/:id',
                component: C1PreanalisisComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Preanalisis y asignacion de avales y garantias'
                },
                resolve:{
                    solicitudPromotor: SolicitudResolver,
                    motivoRechazo: B1MotivoRechazoResolver
                }
            },
            {
                path: 'avales/:id',
                component: C2AvalesComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Preanalisis y asignacion de avales y garantias'
                },
                resolve:{
                    solicitudPromotor: SolicitudResolver,
                    collections: ARegistro_resolver
                }
            },
            {
                path: 'revision_solicitud/:id',
                component: C3RevisionSolicitudComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Revision informacion,Edicion informacion'
                },
                resolve:{
                    collections:C3RevisionSolicitudResolver
                }
            },
            {
                path: 'revision_destino/:id',
                component: D2RevisionSolicitudComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Revision informacion,Edicion informacion'
                },
                resolve:{
                    collections: D2RevisionResolver,
                    solicitudPromotor: SolicitudResolver,
                    motivoRechazo: B1MotivoRechazoResolver
                }

            },
            {
                path: 'revision_acreditacion/:id',
                component: D3RevisionAcreditacionComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Revision informacion,Edicion informacion'
                },
                resolve:{
                    solicitudPromotor: SolicitudResolver,
                    collections: D3RevisionAcreditacionResolver,
                    motivoRechazo: B1MotivoRechazoResolver
                }
            },
            {
                path: 'revision_acreditacion_empleo/:id',
                component: D4RevisionAcreditacionEmpleoComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Revision informacion,Edicion informacion'
                },
                resolve:{
                    solicitudPromotor: SolicitudResolver,
                    collections: D4AcreditacionRevisionEmpleoResolver,
                    motivoRechazo: B1MotivoRechazoResolver
                }
            },
            {
                path: 'revision_acreditacion_preanalisis/:id',
                component: D5RevisionAcreditacionPreanalisisComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Revision informacion,Edicion informacion'
                },
                resolve:{
                    solicitudPromotor: SolicitudResolver,
                    collections: D5AcreditacionRevisionAcreditacionResolver,
                    motivoRechazo: B1MotivoRechazoResolver
                }
            },
            {
                path: 'revision_cotizacion/:id',
                component: D5RevisionCotizacionComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Cotizacion en revision'
                },
                resolve: {
                    solicitudPromotor: SolicitudResolver,
                    collections: F1CotizacionResolver,
                    motivoRechazo: B1MotivoRechazoResolver
                }
            },
            {
                path: 'revision_acreditacion_referencias/:id',
                component: D5RevisionAcreditacionReferenciasComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Revision informacion,Edicion informacion'
                },
                resolve:{
                    solicitudPromotor: SolicitudResolver,
                    collections: D5AcreditacionRevisionReferenciasResolver,
                    motivoRechazo: B1MotivoRechazoResolver
                }
            },
            {
                path: 'revision_acreditacion_documentacion/:id',
                component: D6RevisionAcreditacionDocumentacionComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Revision informacion,Edicion informacion'
                },
                resolve:{
                    collections: D6RevisionAcreditacionDocumentacionResolver,
                    motivoRechazo: B1MotivoRechazoResolver,
                }
            },
            {
                path: 'subir_documentacion/:id',
                component: D6SubirDocumentacionComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Subir documentacion inicial'
                },
                resolve:{
                    collections: D6RevisionAcreditacionDocumentacionResolver,
                }
            },
            {
                path: 'garantia_auto/:id/:idPersona',
                component: D8GarantiaAutoComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Revision informacion,Edicion informacion'
                },
                resolve: {
                    infoGarantia: InfoAutoResolver,
                }
            },
            {
                path: 'evidencia_inmueble/:id/:idPersona',
                component: D9EvidenciaInmueble,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Revision informacion,Edicion informacion'
                },
                resolve: {
                    infoGarantia: InfoInmuebleResolver,
                    collections: D8PromotorResolver,
                }
            },
            {
                path: 'entrevista/:id',
                component: E1EntrevistaComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Entrevista'
                },
                resolve: {
                    solicitudPromotor: SolicitudResolver,
                    motivoRechazo: B1MotivoRechazoResolver
                }
            },
            {
                path: 'cotizacion/:id',
                component: F1CotizacionComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Cotizacion'
                },
                resolve: {
                    //solicitudPromotor: SolicitudResolver,
                    collections: F1CotizacionResolver,
                    //D2RevisionResolver,
                    motivoRechazo: B1MotivoRechazoResolver
                }
            },
            {
                path: 'linea_credito/:id',
                component: G1LineaCreditoComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Linea de credito'
                },
                resolve: {
                    solicitudPromotor: SolicitudResolver,
                }
            },
            {
                path: 'enviar_comite/:id',
                component: G2EnviarComiteComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Revision informacion comite,Edicion informacion comite,Autorizar solicitud comite,Cambios al promotor'
                },
                resolve: {
                    collections: G1LineaCreditoResolver,
                    solicitudPromotor: SolicitudResolver,
                    motivoRechazo: B1MotivoRechazoResolver
                }
            },
            {
                path: 'revision_comite/:id',
                component: H1RevisionComiteComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Revision informacion comite,Edicion informacion comite,Autorizar solicitud comite,Cambios al promotor'
                },
                resolve:{
                    collections : G1LineaCreditoResolver,
                    solicitudPromotor: SolicitudResolver,
                    motivoRechazo: B1MotivoRechazoResolver
                }
            },
            {
                path: 'cambiar_condiciones/:idSolciitud',
                component: H2CambioCondicionesComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Cambiar condiciones'
                },
                resolve: {
                    infoContrato: H2CambioCondicionesResolver
                }
            },
            {
                path: 'envio_contratos/:id',
                component: I1EnvioContratosComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Envio y revision de contratos'
                },
                resolve: {
                    solicitudPromotor: SolicitudResolver,
                }
            },
            {
                path: 'revision_contratos/:id',
                component: I2RevisionContratosComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Envio y revision de contratos'
                },
                resolve:{
                    collections: I2RevisionContratosResolver,
                }
            },
            {   path: 'instruccion_deposito/:id',
                component: I4InstruccionDepositoComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Subir documentacion contratacion'
                },
                resolve: {
                    collections: K1Resolver,
                    IdSolicitud: SolicitudResolver,
                }
            },
            { 
                path: 'subir_contratos/:id',
                component: I3SubirContratosComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Subir documentacion contratacion'
                },
                resolve: {
                    IdSolicitud: SolicitudResolver,
                }
            },
            { 
                path: 'solicitud_desembolso/:id', 
                component: J1DesembolsoComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Solicitud desembolso'
                },
                resolve:{
                    collections: J1DesembolsoResolver,
                    //solicitudPromotor: SolicitudResolver,
                }
            },
            {
                path: 'mesa_control/:id',
                component: K1MesaControlComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Enviar tesoreria'
                },
                resolve:{
                    collections: K1MesaControlResolver,
                }
            },
            {
                path: 'tesoreria/:id',
                component: L1TesoreriaComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Importe de pago'
                },
                resolve:{
                    collections: K1MesaControlResolver,
                    bancos: K1Resolver,
                    //solicitudPromotor: SolicitudResolver
                }
            },
            {
                path: 'revision/aval/personal/:id_solicitud/:id_persona/:tipo_aval',
                component: M1PromotorAvalInformacionPersonalComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Revision informacion,Edicion informacion'
                },
                resolve:{
                    collections: M1PromotorInformacionPersonalResolver,
                    data: PromotorAvalInformacionResolver
                }
            },
            {
                path: 'revision/aval/fiscal/:id_solicitud/:id_persona/:tipo_aval',
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Revision informacion,Edicion informacion'
                },
                component: M2PromotorAvalInformacionFiscal,
                resolve:{
                    collections: M2PromotorInformacionFiscalResolver,
                    data: PromotorAvalInformacionResolver
                } 
            },
            {
                path: 'revision/aval/familiar/:id_solicitud/:id_persona/:tipo_aval',
                component: M3PromotorAvalInformacionFamiliar,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Revision informacion,Edicion informacion'
                },
                resolve:{
                    collections: M3PromotorInformacionFamiliarResolver,
                    data: PromotorAvalInformacionResolver
                }
            },
            {
                path: 'revision/aval/laboral/:id_solicitud/:id_persona/:tipo_aval',
                component: M4PromotorAvalInformacionLaboralComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Revision informacion,Edicion informacion'
                },
                resolve:{
                    collections: M4PromotorInformacionLaboralResolver,
                    direcciones: E2Resolver,
                    data: PromotorAvalInformacionResolver
                }
            },
            {
                path: 'revision/aval/economica/:id_solicitud/:id_persona/:tipo_aval',
                component: M5PromotorAvalInformacionEconomicaComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Revision informacion,Edicion informacion'
                },
                resolve:{
                    data: PromotorAvalInformacionResolver
                }
            },
            {
                path: 'revision/aval/documentacion/:id_solicitud/:id_persona/:tipo_aval',
                component: M6PromotorAvalDocumentacionComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Revision informacion,Edicion informacion'
                },
                resolve:{
                    data: PromotorAvalInformacionResolver,
                    collections: M6PromotorDocumentacionResolver
                }
            },
            {
                path: 'revision/aval/subir_documentacion/:id_solicitud/:id_persona/:tipo_aval',
                component: M6SubirDocumentacionAvalComponent,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Subir documentacion inicial'
                },
                resolve:{
                    data: PromotorAvalInformacionResolver,
                    collections: M6PromotorDocumentacionResolver
                }
            },
            {
                path: 'cambiar_estructura/:id',
                component: N1CambioEstructura,
                canActivate: [PermissionsGuard],
                data: {
                    permission: 'Preanalisis y asignacion de avales y garantias'
                },
                resolve:{
                    solicitudPromotor: SolicitudResolver,
                    collections: N1Resolver,
                }
            },
            {
                path: 'espera/documentos/:id',
                component: MessagesComponent,
                resolve:{
                    solicitudPromotor: SolicitudResolver,
                    collections : G1LineaCreditoResolver,
                }
            },
            {
                path: 'detalle/documentos/:id',
                component: DetalleComponent,
                resolve:{
                    solicitudPromotor: SolicitudResolver,
                    collections : G1LineaCreditoResolver,
                }
            },
            {
                path: 'messages/:id',
                component: TempMessagesPromotorComponent,
                resolve:{
                    solicitudPromotor: SolicitudResolver,
                    collections : G1LineaCreditoResolver,
                }
            },
            {
                path: 'permisos/:permission',
                component: PermissionsComponent
            },
        ]
    },
    /* FIN Rutas para promotor */

    /* INICIO Rutas para aval */
    {
        path:'aval',
        canActivate:[AuthGuard],
        children:[
            {
                path:'capturar_informacion/:id',
                component:B1CapturaInformacionComponent,
                resolve:{
                    collections: B1CapturaInformacionResolver,
                    permissions: SolicitudAvalPrivilegesResolver,
                    data: AvalPreviousDataResolver,
                }
            },
            {
                path:'capturar_informacion_fiscal/:id',
                component:B2CapturaInformacionFiscalComponent,
                resolve:{
                    collections: B2CapturaInformacionFiscalResolver,
                    permissions: SolicitudAvalPrivilegesResolver,
                    data: AvalPreviousDataResolver,
                }
            },
            {
                path:'capturar_informacion_buro/:id',
                component:B3CapturaInformacionBuro,
                canActivate:[
                    AvalGuard
                ],
                resolve:{
                    permissions: SolicitudAvalPrivilegesResolver,
                    data: AvalPreviousDataResolver,
                },
            },
            {
                path:'informacion_personal/:id',
                component:C1InformacionPersonal,
                resolve:{
                    collections: C1InformacionPersonalResolver,
                    data: AvalPreviousDataResolver,
                },
                canActivate:[
                    AvalGuard
                ]
            },
            {
                path:'informacion_laboral/:id',
                component:C2InformacionLaboralAval,
                resolve:{
                    collections: C2InformacionLaboralResolver,
                    direcciones: E2Resolver,
                    permissions: SolicitudAvalPrivilegesResolver,
                    data: AvalPreviousDataResolver,
                },
                canActivate:[
                    AvalGuard
                ]
            },
            {
                path:'informacion_economica/:id',
                component:C3InformacionEconomicaComponent,
                resolve:{
                    data: AvalPreviousDataResolver,
                    permissions: SolicitudAvalPrivilegesResolver,
                },
                canActivate:[
                    AvalGuard
                ]
            },
            {
                path:'confirmacion_identidad/:id',
                component:C4ConfirmacionIdentidadComponent,
                canActivate:[
                    AvalGuard
                ],
                resolve:{
                    permissions: SolicitudAvalPrivilegesResolver,
                    data: AvalPreviousDataResolver,
                },
            },
            {
                path:'documentacion/:id',
                component:C5DocumentacionAvalComponent,
                resolve: { 
                    collections: E5Resolver,
                    permissions: SolicitudAvalPrivilegesResolver,
                    data: AvalPreviousDataResolver,
                },
                canActivate:[
                    AvalGuard
                ]
            },
            {
                path: 'messages/:id',
                component: TempMessagesAvalComponent,
                resolve: {
                    data: AvalPreviousDataResolver
                }
            },
        ]
    },
    /* FIN Rutas para aval */

    /* Inicio rutas para admin */
    { path: 'admin/catalogos', component: Admin2CatalogosComponent,

    },
    { path: 'admin/parametros_generales', component: AdminParametrosGeneralesComponent,

    },
    /* Fin rutas para admin */



    { path: '**', redirectTo: 'login' },
    //{ path: '**', redirectTo: 'mantenimiento' },
];

@NgModule({
    imports: [ RouterModule.forRoot(routes) ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
