import { ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';

export class CustomValidators {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('password').value; // get password from our password form control
    const password_confirmation: string = control.get('password_confirmation').value; // get password from our password_confirmation form control
    // compare is the password math
    if (password !== password_confirmation) {
      // if they don't match, set an error in our password_confirmation form control
      control.get('password_confirmation').setErrors({ NoPassswordMatch: true });
    }
  }

  static emailMatchValidator(control: AbstractControl) {
    const email: string = control.get('email').value;
    const email_confirmation: string = control.get('email_confirmation').value;

    if (email !== email_confirmation) {
      control.get('email_confirmation').setErrors({ NoEmailMatch: true });
    }
  }
}