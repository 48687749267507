import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'admin-sidebar',
  templateUrl: './admin-sidebar.component.html'
})
export class AdminSidebarComponent implements OnInit {
  @Input('status') status: any;
  @Input('userRole') userRole: number;

  constructor() { }

  ngOnInit() {
  }

}
