import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Data, Router } from '@angular/router';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { Observable } from 'rxjs';
import { TablePromotorModel } from 'src/app/models/TablePromotor.model';
import { CollectionsService } from 'src/app/services/colecctions.service';
import { PromotorC1PreanalisiService } from 'src/app/services/promotor/C1PreanalisisService.service';
import { K2ContratosService } from 'src/app/services/K2ContratosService.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Collection } from 'src/app/models/collection.model';

@Component({
    selector: 'app-c1-preanalisis',
    templateUrl: './c1-preanalisis.component.html',
    styleUrls: ['./c1-preanalisis.component.scss']
})

export class C1PreanalisisComponent implements OnInit{
    public status:number;
    public IdSolicitud: number;
    public persona: any;
    public personaDetalles: any;
    public empleo: any;
    public detallesSolicitud: any;
    public burosc: string;
    public loading: boolean;
    public creditscore: number;
    public score: any;
    public showBottomBuro: boolean;
    public estatus_qeq: number;
    public frmC1: FormGroup;
    public coincidencias: any;

    public motivoRechazo: Collection;
    
    constructor(
        public route: ActivatedRoute,
        private router: Router,
        private C1Service: PromotorC1PreanalisiService,
        private K2Service: K2ContratosService,
        private fb: FormBuilder,
        private modalCoincidencias: NgbModal,
        private utilities: UtilitiesService
    ){
        this.status = 1;
        this.IdSolicitud = this.route.snapshot.params.id;
        this.creditscore = 0;
        this.showBottomBuro = false;
        this.loading = false;
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    ngOnInit(): void {
        this.persona = this.route.snapshot.data.solicitudPromotor.data.persona;
        this.personaDetalles = this.route.snapshot.data.solicitudPromotor.data.personaDetalles;
        this.empleo = this.route.snapshot.data.solicitudPromotor.data.empleo;
        this.detallesSolicitud = this.route.snapshot.data.solicitudPromotor.data.detallesSolicitud;
        this.burosc = this.route.snapshot.data.solicitudPromotor.data.burosc;
        this.score = this.route.snapshot.data.solicitudPromotor.data.variable;
        this.estatus_qeq = this.route.snapshot.data.solicitudPromotor.data.estatus_qeq;

        this.coincidencias = this.route.snapshot.data.solicitudPromotor.data.coincidencias;

        this.route.data.subscribe((data: Data) => {
            this.motivoRechazo = data['motivoRechazo'][0].data;
        });


        if ( this.empleo ) {
            this.creditscore = this.creditscore + parseInt(this.empleo.PuntosGiro);
        }

        if ( parseInt(this.burosc) < 1 ) {
            this.showBottomBuro = true;
        }

        if ( this.personaDetalles && this.personaDetalles.untosVivienda ) {
            var puntoVivienda = this.personaDetalles.untosVivienda == '' ? 0 : parseInt(this.personaDetalles.untosVivienda);
            this.creditscore = this.creditscore + puntoVivienda;
        }

        this.frmC1 = this.createC1Form();

        this.frmC1.patchValue({
            EstatusQeq: this.estatus_qeq,
            IdSolicitud: this.IdSolicitud,
            EsPep: false
        });
    }

    declinarSubmit(MotivoRechazo){
        if ( MotivoRechazo.value == '' ) {
            alert('Debe selecciona un motivo de rechazo');
            return;
        }

        let data = {MotivoRechazo: MotivoRechazo.value};
        
        this.utilities.declinarSolicitud(this.IdSolicitud, data).subscribe(
            resp => {
                this.modalCoincidencias.dismissAll();
                this.router.navigateByUrl("promotor/dashboard");
            },
            errors => {
            }
        );
    }

    declinar(modalDeclinar){
        this.modalCoincidencias.open(modalDeclinar, { ariaLabelledBy: 'modal-basic-title', centered: false});

        // if ( window.confirm('¿Estás seguro que deseas declinar la solicitud?') ) {
        // }
    }

    downloadBuro(){
        this.loading = true;
        this.K2Service.downloadBuro(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Buro.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
            },
          )
    }

    consultarBuro(){
        this.C1Service.consultarBuroPromotor(this.IdSolicitud).subscribe(
            response => {
            },
            errors => {
            }
        );
    }

    verCoincidencias(modalCoincidencias){
        this.modalCoincidencias.open(modalCoincidencias, { ariaLabelledBy: 'modal-basic-title', centered: false, size: 'xl', scrollable: true });
    }

    onChangePep(evt){
        var target = evt.target;

        if (target.checked) {
            this.frmC1.patchValue({
                EsPep: true
            });
        } else {
            this.frmC1.patchValue({
                EsPep: false
            });
        }
    }

    saveCoincidencias(){
        this.loading = true;
        this.C1Service.sendCommentsQeq(this.frmC1.value).subscribe(
            response => {
                this.loading = false;
            },
            errors => {
                this.loading = false;
            }
        );
    }

    submit(): void{
        var sendData ={
            IdSolicitud: this.IdSolicitud
        }
        this.C1Service.sendUpdate(sendData).subscribe(
            response =>{
                this.router.navigateByUrl("promotor/avales/"+this.IdSolicitud);
            },
            errors => {
            }
        )
    }

    createC1Form() {
        return this.fb.group({
            IdSolicitud: [
                null
            ],
            ComentariosQeq: [
                null
            ],
            EsPep: [
                null
            ],
            EstatusQeq: [
                null
            ]
        })
    }

}