import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Collection } from 'src/app/models/collection';
import { CollectionsService } from 'src/app/services/colecctions.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { M3PromotorInformacionFamiliarService } from 'src/app/services/promotor/M3PromotorInformacionFamiliarService.service';

@Component({
    selector: 'app-m3-promotor-aval-informacion-familiar',
    templateUrl: './m3-promotor-aval-informacion-familiar.component.html',
})

export class M3PromotorAvalInformacionFamiliar implements OnInit, AfterViewInit {
    public status: number;
    public IdSolicitud: number;
    public IdPersona: number;
    public tipoAval: number;
    public editMode:boolean;
    public errorMessages: string;
    public loading: boolean;

    /* collections from resolver */
    public collectionsEstadoCivil: Collection;
    public collectionsRegimenConyugal: Collection;
    public collectionsTipoVivienda: Collection;
    
    /* Collections for component data */
    public data: any;
    public dataPersonSolicitud:any;

    /* Strings */
    public EstadoCivil:string;
    public RegimenConyugal:string;
    public TipoVivienda:string;
    public canEdit: boolean;
    public canDecline: boolean;

    constructor(
        private route: ActivatedRoute,
        private collectionsService: CollectionsService,
        private utilities: UtilitiesService,
        private M3Service: M3PromotorInformacionFamiliarService,
        private router: Router
    ) {
        this.status = 2;
        this.canEdit = false;
        this.canDecline = false;
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    ngOnInit() {
        this.IdSolicitud = this.route.snapshot.params.id_solicitud;
        this.IdPersona = this.route.snapshot.params.id_persona;
        this.tipoAval = this.route.snapshot.params.tipo_aval;
        this.editMode = false;
        this.route.data.subscribe((data: Data) => {
            this.collectionsEstadoCivil = data['collections'][0].data;
            this.collectionsRegimenConyugal = data['collections'][1].data;
            this.collectionsTipoVivienda = data['collections'][2].data;
            this.data = data['data'][0].data.informacion_familiar;
            this.dataPersonSolicitud = data['data'][0].data.informacion_propietario;
        });
        
        this.canEdit = this.utilities.hasPermission('Edicion informacion');
        this.canDecline = this.utilities.hasPermission('Declinar Solicitud');

        this.EstadoCivil = this.utilities.getFieldFromCollection(this.collectionsEstadoCivil, this.data.IdEstadoCivil, "name");
        if(this.data.IdEstadoCivil == 1){
            this.RegimenConyugal = this.utilities.getFieldFromCollection(this.collectionsRegimenConyugal, this.data.IdRegimenConyugal, "name");
        }
        this.TipoVivienda = this.utilities.getFieldFromCollection(this.collectionsTipoVivienda, this.data.IdTipoVivienda, "name");
        if ( this.data.FechaAntiguedadVivienda != null ) {
            this.data.FechaAntiguedadVivienda = this.data.FechaAntiguedadVivienda.split(" ")[0];
        }
    }

    triggerEdit(){
        this.editMode = true;
    }

    recibirCancelacion($event){
        this.editMode = false;
    }

    onSubmit(modalError){
        this.loading = true;
        this.M3Service.setDataComplete(this.IdSolicitud,this.IdPersona).subscribe(
            resp => {
                this.loading = false;
                this.router.navigateByUrl('promotor/revision/aval/laboral/'+this.IdSolicitud+'/'+this.IdPersona+'/'+this.tipoAval);
            }
        );
    }
    
    recibirGuardado($event){
        this.editMode = false;
        this.data = $event.data;
        this.EstadoCivil = this.utilities.getFieldFromCollection(this.collectionsEstadoCivil, $event.data.IdEstadoCivil, "name");
        if($event.data.IdEstadoCivil == 1){
            this.RegimenConyugal = this.utilities.getFieldFromCollection(this.collectionsRegimenConyugal, $event.data.IdRegimenConyugal, "name");
        }
        this.TipoVivienda = this.utilities.getFieldFromCollection(this.collectionsTipoVivienda, $event.data.IdTipoVivienda, "name");
        $event.data.FechaAntiguedadVivienda = $event.data.FechaAntiguedadVivienda.split(" ")[0];
    }
}