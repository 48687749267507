import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, Data } from '@angular/router';
import { E1PromotorService } from 'src/app/services/E1PromotorService.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { requiredMultipleFileType } from 'src/app/directives/upload-file-multiple-validator';
import { requiredFileSize } from 'src/app/directives/upload-file-size-validator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { Collection } from 'src/app/models/collection.model';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-e1-entrevista',
    templateUrl: './e1-entrevista.component.html'
})

export class E1EntrevistaComponent implements OnInit {
    public status: number;
    public IdSolicitud: number;
    public persona: any;
    public loading: boolean;
    public errorMessages: string;
    public HacerPagosAnticipados: boolean;

    public frmE1 : FormGroup;
    public entrevista: any;
    public motivoRechazo: Collection;

    public proceso: any;

    API_URL: string;

    public canDecline: boolean;

    constructor(
        private route: ActivatedRoute,
        private E1Service: E1PromotorService,
        private modalError: NgbModal,
        private router: Router,
        private fb: FormBuilder,
        private utilities: UtilitiesService
    ) {
        this.status = 3;
        this.API_URL = environment.API_URL.replace('api/v1/', '');
        this.canDecline = false;
    }

    handleAnticipados(event){
        var target = event.target;
        if (target.value == 'true') {
            this.HacerPagosAnticipados = true;
            this.frmE1.controls['RazonPagoAnticipado'].setValidators([Validators.required]);
            this.frmE1.controls['FrecuenciaPagoAnticipado'].setValidators([Validators.required]);
        }
        else{
            this.HacerPagosAnticipados = false;

            this.utilities.removeControlsValidity(this.frmE1, ['RazonPagoAnticipado', 'FrecuenciaPagoAnticipado']);
        }
    }

    ngOnInit(): void {
        this.proceso = this.route.snapshot.data.solicitudPromotor.proceso;

        this.IdSolicitud = this.route.snapshot.params.id;

        this.HacerPagosAnticipados = false;

        this.persona = this.route.snapshot.data.solicitudPromotor.data.persona;

        this.frmE1 = this.createFrmE1();

        this.entrevista = this.route.snapshot.data.solicitudPromotor.data.entrevista;

        this.canDecline = this.utilities.hasPermission('Declinar Solicitud');


        this.route.data.subscribe((data: Data) => {
            this.motivoRechazo = data['motivoRechazo'][0].data;
        });

        this.frmE1.patchValue({
            IdSolicitud: this.IdSolicitud
        })

        if ( this.entrevista != null ) {
            if ( parseInt(this.entrevista.PagosAnticipados) ) {
                this.HacerPagosAnticipados = true;
            }

            this.frmE1.patchValue({
                IdSolicitud: this.IdSolicitud,
                FormaPago: this.entrevista.IdFormaDePago,
                PagoExtranjero: parseInt(this.entrevista.PagosDesdeExtranjero) ? 'true' : 'false',
                PagoAnticipado: parseInt(this.entrevista.PagosAnticipados) ? 'true' : 'false',
                RazonPagoAnticipado: this.entrevista.Motivo,
                FrecuenciaPagoAnticipado: this.entrevista.Frecuencia,
                DesembolsoExtranjero: parseInt(this.entrevista.DesembolsoAlExtranjero) ? 'true': 'false',
                DesembolsoDosPartes: parseInt(this.entrevista.DesembolsoDosPartes) ? 'true' : 'false'
            });
        }
        else{
            this.frmE1.patchValue({
                IdSolicitud: this.IdSolicitud,
                FormaPago: 0,
                PagoExtranjero: 'false',
                PagoAnticipado: 'false',
                DesembolsoExtranjero: 'false',
                DesembolsoDosPartes: 'false'
            });
        }
    }

    declinarSubmit(MotivoRechazo){
        if ( MotivoRechazo.value == '' ) {
            alert('Debe selecciona un motivo de rechazo');
            return;
        }

        let data = {MotivoRechazo: MotivoRechazo.value};
        
        this.utilities.declinarSolicitud(this.IdSolicitud, data).subscribe(
            resp => {
                this.modalError.dismissAll();
                this.router.navigateByUrl("promotor/dashboard");
            },
            errors => {
            }
        );
    }

    declinar(modalDeclinar){
        this.modalError.open(modalDeclinar, { ariaLabelledBy: 'modal-basic-title', centered: false});
    }

    createFrmE1(){
        return this.fb.group({
            EvidenciaEntrevista:[
                null, 
                [
                    Validators.required, 
                    requiredMultipleFileType(['jpg','pdf','png']), 
                    requiredFileSize()
                ]
            ],
            FormaPago:[
                null,
                [Validators.required, Validators.min(1)]
            ],
            PagoExtranjero:[
                null,
                Validators.required
            ],
            PagoAnticipado:[
                null,
                Validators.required
            ],
            RazonPagoAnticipado:[
                null
            ],
            FrecuenciaPagoAnticipado:[
                null
            ],
            DesembolsoExtranjero:[
                null,
                Validators.required
            ],
            DesembolsoDosPartes:[
                null,
                Validators.required
            ],
            IdSolicitud:[
                null
            ],
        })
    }

    onSubmit(modalError){
        if (!this.frmE1.valid) {
            Object.keys(this.frmE1.value).forEach(element => {
              if ( this.frmE1.get(element).errors != null ) {
                this.frmE1.get(element).markAsDirty();
              }
            });
            return;
        }
        this.loading = true;
        let sendData = toFormData(this.frmE1.value);

        this.E1Service.sendInfoEntrevista( sendData ).subscribe(
            resp => {
                this.loading = false;
                this.router.navigateByUrl("promotor/cotizacion/"+this.IdSolicitud);
            },
            errors => {
                this.loading = false;
                if ( errors.status == 0 ) {
                    this.errorMessages = 'Error al cargar los documentos. La solicitud es muy pesada. Favor de subir documentos de menor tamaño.';
                }
                else{
                    this.errorMessages = errors.error.message;
                }

                this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        );
    }

    resetValue(id) {
        this.frmE1.controls[id].patchValue(null);
        document.querySelector("#" + id).querySelectorAll('input')[0]
        document.querySelector("#" + id).querySelectorAll('input')[0].value = null;
    }
}

export function markAllAsDirty(form: FormGroup) {
    for (const control of Object.keys(form.controls)) {
      form.controls[control].markAsDirty();
    }
  }
  
  export function toFormData<T>(formValue: T) {
    const formData = new FormData();
    let total_mb = 0;
    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      if ( value !== null ) {
        if ( value instanceof File ) {
          total_mb = total_mb + (value.size/1000000);
        }
        formData.append(key, value);
      }
    }
  
    return formData;
  }