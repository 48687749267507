import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { CollectionsService } from "../../services/colecctions.service";
import { Collection } from '../../models/collection.model';
import { Injectable } from '@angular/core';

@Injectable()
export class N1Resolver implements Resolve<Collection>{
    private Object = {};
    public IdSolicitud: number;
    constructor (private CollectionsService: CollectionsService){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<any>{  
        this.IdSolicitud = parseInt(route.paramMap.get('id')); 
        let test = forkJoin(
            this.CollectionsService.getCollectionsN1(this.IdSolicitud),
            this.CollectionsService.getParentezco( this.IdSolicitud ),
            this.CollectionsService.getCollectionsParentezco(),
        );
        return test;
    }
}