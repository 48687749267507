import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Collection } from '../models/collection.model';
import { tap, catchError, mergeMap } from 'rxjs/operators';
import { Observable, throwError, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { ErrorsService } from './errors.service';
import { Router } from '@angular/router';
import { TablePromotorModel } from '../models/TablePromotor.model';

@Injectable({
  providedIn: 'root'
})
export class CollectionsService {
  private API_URL: string;
  constructor(private http: HttpClient, private errorService: ErrorsService, private router: Router) {
    this.API_URL = environment.API_URL;
  }

  getCollectionsPlan(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/plan');
    return temp.pipe(
      tap(
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    )
  }
  getCollectionsPlanAlianza(idAlianza): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/planAlianza/'+ idAlianza);
    return temp.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getPromotores(){
    var temp = this.http.get<Collection>(this.API_URL + 'super/promotor/obtener/promotores');
    return temp.pipe(
      tap(
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    )
  }

  getSolicitudNecesitaSeguros(IdSolicitud): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'necesita/seguros/solicitud/' + IdSolicitud);
    return temp.pipe(
      tap(
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    )
  }

  getCollectionsAvales(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/tipo/avales');
    return temp.pipe(
      tap(
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    )
  }

  getCollectionsTipoInmueble(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/tipo/inmuebles');
    return temp.pipe(
      tap(
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    )
  }

  getBancos(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/banco');
    return temp.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }


  getBancosEmpresa(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/banco/empresa');
    return temp.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }  

  getParentezco(IdSolicitud): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'tiene/parentezco/' + IdSolicitud);
    return temp.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getCollectionsDestinoCredito(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/destinoCredito');
    return temp.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getCollectionsOcupaciones(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/ocupacion');
    return temp.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }


  getCollectionsTipoVivienda(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/tipoVivienda');
    return temp.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getCollectionsAgencia(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/alianza');
    return temp.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getCollectionsPlazoMensualidades(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/periodo');
    return temp.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getCollectionsPlazos(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/plazos');
    return temp.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getCollectionsPeriodos(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/periodo');
    return temp.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getCollectionsPaises(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/pais');
    return temp.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getCollectionsEstados(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/estado');
    return temp.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getCollectionsCiudades(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/ciudad');
    return temp.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getCollectionsColonias(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/colonia');
    return temp.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getCollectionsColoniasByPais(IdPais, IdEstado, IdMunicipio): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/colonia/' + IdPais + '/' + IdEstado + '/' + IdMunicipio);
    return temp.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getCollectionsGeneros(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/genero');
    return temp.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getCollectionsB3(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/estadoCivil');
    return temp.pipe(
      tap(
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    )
  }

  getCollectionsEstadoCivil(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/estadoCivil');
    return temp.pipe(
      tap(
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    )
  }

  getCollectionsSituacionLaboral(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/situacionLaboral');
    return temp.pipe(
      tap(
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    )
  }

  getCollectionsNacionalidad(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/nacionalidad');
    return temp.pipe(
      tap(
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    )
  }

  getCollectionsEstado(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/estado');
    return temp.pipe(
      tap(
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    )
  }

  getCollectionsRegimenConyugal(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/regimenConyugal');
    return temp.pipe(
      tap(
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    )
  }


  getCollectionsRegimenFiscal(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/regimenFiscal');
    return temp.pipe(
      tap(
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    )
  }

  getCollectionsParentezco(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/parentesco');
    return temp.pipe(
      tap(
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    )
  }

  getCollectionsEnteradoPor(): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/enteradoPor');
    return temp.pipe(
      tap(
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    )
  }

  getStateByCountryId(id): Observable<Collection>{
    var temp = this.http.get<Collection>(this.API_URL+'catalogos/estado/'+id);
    return temp.pipe(
      tap(
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    )
  }

  getCitiesById(idCountry,idState): Observable<Collection>{
    var temp = this.http.get<Collection>(this.API_URL+'catalogos/ciudad/'+idCountry+"/"+idState);
    return temp.pipe(
      tap(
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    )
  }
  getMunicipalitiesById(idCountry, idState): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/municipio/' + idCountry + "/" + idState);
    return temp.pipe(
      tap(
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    )
  }
  getSolicitudes(): Observable<any> {
    var temp = this.http.get<Collection>(this.API_URL + 'solicitudes');
    return temp.pipe(
      tap(
        data => {

        },
        error => {
          this.errorService.handleErrors(error)
        }
      )
    )
  }

  getPersonalInformation(IdSolicitud): Observable<any> {
    var temp = this.http.get<Collection>(this.API_URL + 'complementaria/informacion_personal/' + IdSolicitud);
    return temp.pipe(
      tap(
        data => {

        },
        error => {
          this.errorService.handleErrors(error)
        }
      )
    )
  }

  getIdentificacion(): Observable<any> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/identificacion');
    return temp.pipe(
      tap(
        data => {

        },
        error => {
          this.errorService.handleErrors(error)
        }
      )
    )
  }

  getComprobanteDomicilio(): Observable<any> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/comprobante');
    return temp.pipe(
      tap(
        data => {

        },
        error => {
          this.errorService.handleErrors(error)
        }
      )
    )
  }

  getComprobanteIngresos(): Observable<any> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/ingresos');
    return temp.pipe(
      tap(
        data => {

        },
        error => {
          this.errorService.handleErrors(error)
        }
      )
    )
  }

  getCedula(): Observable<any> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/cedula');
    return temp.pipe(
      tap(
        data => {

        },
        error => {
          this.errorService.handleErrors(error)
        }
      )
    )
  }

  getReferencia(): Observable<any> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/referencias');
    return temp.pipe(
      tap(
        data => {

        },
        error => {
          this.errorService.handleErrors(error)
        }
      )
    )
  }



  /* Seccion de pruebas para dashboard de promotor */

  getFilteredPromotor(parameters) {
    var temp = this.http.get<any>(this.API_URL + 'promotor/solicitudes?' + parameters);
    return temp.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getFilteredDirectiva(parameters) {
    var temp = this.http.get<any>(this.API_URL + 'directiva/solicitudes?' + parameters);
    return temp.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  obtenerSolicitudesPromotor() {
    var temp = this.http.get<any>(this.API_URL + 'promotor/solicitudes');
    return temp.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  obtenerSolicitudesSuperPromotor(){
    var temp = this.http.get<any>(this.API_URL + 'super/promotor/solicitudes');
    return temp.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  solicitudesDisponiblesPromotor() {
    var temp = this.http.get<any>(this.API_URL + 'promotor/solicitudes/disponibles');
    return temp.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  obtenerSolicitudesDirectiva() {
    var temp = this.http.get<any>(this.API_URL + 'directiva/solicitudes');
    return temp.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  /* Servicio para obtener detalles de alianza para seccion destino credito (Realizar cotizacion) */

  getAlianzaDetails(idAlianza): Observable<any> {
    var petition = this.http.get<any>(this.API_URL + 'consulta_buro/get_datos_plan/' + idAlianza);
    return petition.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getSolicitudPromotorDetails(idSolicitud): Observable<any> {
    var petition = this.http.get<any>(this.API_URL + 'promotor/solicitud/' + idSolicitud);
    return petition.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getHomePromotorStatus(IdSolicitud){
    var petition = this.http.get<any>(this.API_URL + 'promotor/revision/home/' + IdSolicitud);
    return petition.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getSolicitudDesembolsoPromotor(IdSolicitud){
    var petition = this.http.get<any>(this.API_URL + 'promotor/mesaControl/resumen/' + IdSolicitud);
    return petition.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getInformacionAvalPromotor(idSolicitud,idPersona){
    var petition = this.http.get<any>(this.API_URL + 'promotor/revsionAval/solicitud/' + idSolicitud + "/" + idPersona);
    return petition.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getNoIdentificacion(idSolicitud,idPersona){
    var petition = this.http.get<any>(this.API_URL + 'promotor/noIdentificacion/' + idSolicitud + "/" + idPersona);
    return petition.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getMotivosRechazo(){
    var petition = this.http.get<any>(this.API_URL + 'catalogos/motivoRechazo');
    return petition.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getPromotoresAsignados(){
    var petition = this.http.get<any>(this.API_URL + 'catalogos/promotorAsignado');
    return petition.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getEtapas(){
    var petition = this.http.get<any>(this.API_URL + 'catalogos/etapa');
    return petition.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getClientSolicitudData(IdSolicitud){
    var petition = this.http.get<any>(this.API_URL + 'solicitud/'+ IdSolicitud);
    return petition.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getEdades(){
    var temp = this.http.get<any>(this.API_URL + 'catalogos/edades');
    return temp.pipe(
      tap( // Log the result or error
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    );
  }

  getCollectionsN1(IdSolicitud): Observable<Collection> {
    var temp = this.http.get<Collection>(this.API_URL + 'catalogos/cambiar_estructura/'+IdSolicitud);
    return temp.pipe(
      tap(
        data => {

        },
        error => this.errorService.handleErrors(error)
      )
    )
  }
}
