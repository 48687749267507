import { Component, OnInit, Input } from "@angular/core";
import { UtilitiesService } from 'src/app/services/utilities_services.service';

@Component({
    selector: 'app-sidebar-promotor',
    templateUrl: './sidebar.component.html',
})

export class PromotorSidebarComponent implements OnInit {
    @Input ("targetElement") targetElement: string;
    @Input('IdSolicitud') IdSolicitud: any;

    constructor(
        private utilities: UtilitiesService
    ) {

    }

    ngOnInit(): void {

    }

    imprimirSolicitud(){
        this.utilities.imprimirSolicitud(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Detalle de la solicitud.pdf');
                a.click();
            },
            errors => {
            }
        );
    }

    imprimirSolicitud2(){
        this.utilities.imprimirSolicitud2(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Solicitud.pdf');
                a.click();
            },
            errors => {
            }
        );
    }
}
