import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

const INTERVAL_BEFORE_REFRESH : number = 60000;

@Injectable({
    providedIn: 'root'
})

export class CheckLoginService {
    private test = new Date();


    constructor(){
        
    }

    validateExpiracyToken(): Observable<any>{
        let expiracyTime = Number(localStorage.getItem("expires_in"))
        let expiracyMil = (expiracyTime - this.test.getTime()) - INTERVAL_BEFORE_REFRESH;

        const tokenObservable = Observable.create(observer => {
            if(localStorage.getItem("expires_in") === null || expiracyMil < 1 || typeof(expiracyMil) !== "number"){
                observer.error(new Error());
            }else{
                observer.next(expiracyMil);
            }
        });

        return tokenObservable;
    }

}