import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Data, Router } from '@angular/router';
import { UtilitiesService } from 'src/app/services/utilities_services.service';

@Component({
    selector: 'app-temp-messages',
    templateUrl: './temp-messages.component.html'
})
export class TempMessagesPromotorComponent implements OnInit {
    public proceso: any;
    public messageObject: any;
    public IdSolicitud: number;
    public status: number;
    constructor(public route: ActivatedRoute,public router: Router, public utilities: UtilitiesService) {
        this.IdSolicitud = this.route.snapshot.params.id;
        this.status = 7;
    }

    ngOnInit() {
        this.route.data.subscribe((data: Data) => {
            this.proceso = data['solicitudPromotor'].proceso;
        });

        this.messageObject = this.utilities.handleStateMessagesPromotor(this.proceso,this.IdSolicitud);
    }
}
