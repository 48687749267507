import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Data, RouterEvent, NavigationError, Router } from '@angular/router';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { UtilitiesAvalService } from 'src/app/services/aval/UtilitiesAvalService.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-l-dashboard',
    templateUrl: './l-dashboard.component.html',
    styleUrls: ['./l-dashboard.component.scss']
})
export class LDashboardComponent implements OnInit,AfterViewInit {
    public solicitudes : any;
    public solicitudesPropias: any;
    public solicitudesAval: any;
    public loading:boolean;
    public nuevos:boolean;
    public minEdad: number;
    public maxEdad: number;
    public edad: number;
    
    constructor(
        public route: ActivatedRoute,
        public router: Router, 
        public utilities: UtilitiesService,
        private avalService: UtilitiesAvalService,
        private modalError: NgbModal,) { 
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    ngOnInit() { 
        this.loading = false;
        this.route.data.subscribe((data: Data) => {
            this.solicitudes = data['solicitudes'].data;
            let edades=data['collections'][3].data;
            this.minEdad=Number(edades.find(element => element.variable == 'Edad Minima Contratante').valor);
            this.maxEdad=Number(edades.find(element => element.variable == 'Edad Maxima Contratante').valor);
            this.edad=Number(edades.find(element => element.variable == 'Edad Usuario').valor);

        });
        let avales = [];
        this.solicitudesAval = this.solicitudes.filter( (item) =>{
            return item.IdTipoRelacion != "1" ;
        });
        this.solicitudesPropias = this.solicitudes.filter( (item) =>{
            return item.IdTipoRelacion == "1" ;
        } );
        if (this.edad<=this.maxEdad && this.edad>=this.minEdad){
            this.nuevos=true;
        }
        else{
            this.nuevos=false;
        }
        
        
        
    }

    muestraError(modalError){
        this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
    }

    handleRedirectSolicitud(proceso,idSolicitud,Etapa){
        if (Etapa !== 'DECLINADA' ) {
            let redirect = this.utilities.handleRedirect(proceso);
            this.router.navigateByUrl(redirect+"/"+idSolicitud);
        }else{
        alert('Solicitud DECLINADA, por favor selecciona: " + Solicitar Nuevo Crédito" ó contáctanos.')
        }
    }

    handleRedirectAval(proceso,idSolicitud,Etapa){

        
        if (Etapa !== 'DECLINADA' ) {
            this.loading = true;
            let redirect = this.utilities.handleRedirectAval(proceso);
            this.router.navigateByUrl(redirect+"/"+idSolicitud);
        } else {
            alert('Solicitud DECLINADA, ya no se requiere información en esta Solicitud')
        }
    }
    
    nameAval(tipoAval){
        return this.avalService.getNameTipoAval(tipoAval);
    }
}