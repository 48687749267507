export class B3InformacionPersonal{
    public Rfc: string;
    public Curp:string;
    public Calle: string;
    public NoExterior: number;
    public NoInterior: string;
    public IdColonia: number;
    public IdMunicipio: number;
    public IdCiudad: number;
    public IdEstado: number;
    public IdPais: number;
    public IdCodigoPostal: number;
    public IdPersona: number;

    constructor( personal_information ){
        this.Rfc = personal_information.Rfc;
        this.Curp = personal_information.Curp;
        this.Calle = personal_information.Calle;
        this.NoExterior = personal_information.NoExterior;
        this.NoInterior = personal_information.NoInterior;
        this.IdColonia = personal_information.IdColonia;
        this.IdMunicipio = personal_information.IdMunicipio;
        this.IdCiudad = personal_information.IdCiudad;
        this.IdEstado = personal_information.IdEstado;
        this.IdPais = personal_information.IdPais;
        this.IdCodigoPostal = personal_information.IdCodigoPostal;
        this.IdPersona = personal_information.IdPersona;
    }

    set setIdPersona( idPersona:number){
        this.IdPersona = idPersona;
    }

}
