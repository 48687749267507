import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Data, Router } from '@angular/router';
import { UtilitiesService } from 'src/app/services/utilities_services.service';

@Component({
    selector: 'app-temp-messages',
    templateUrl: './temp-messages.component.html'
})
export class TempMessagesComponent implements OnInit {
    public proceso: any;
    public messageObject: any;
    public IdSolicitud: number;
    public user: any;
    constructor(public route: ActivatedRoute,public router: Router, public utilities: UtilitiesService) {
        this.IdSolicitud = this.route.snapshot.params.id;
        let temp = JSON.parse(localStorage.getItem("person"));
        this.user = temp.Nombre;
    }

    ngOnInit() {
        this.route.data.subscribe((data: Data) => {
            this.proceso = data['proceso'].proceso;
        });
        this.messageObject = this.utilities.handleStateMessages(this.proceso,this.IdSolicitud,this.user);
    }
}
