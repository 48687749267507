import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { ActivatedRoute, Router, Data } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CollectionsService } from 'src/app/services/colecctions.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Collection } from 'src/app/models/collection.model';
import { CustomValidators } from 'src/app/validators/validators';
import { PromotorD5RevisionAcreditacionReferencias } from "src/app/services/promotor/D5RevisionAcreditacionReferencias.service";
import { min } from 'rxjs/operators';

@Component({
    selector: 'app-d5-revision-acreditacion-referencias-edit',
    templateUrl: './d5-revision-acreditacion-referencias-edit.component.html'
})

export class D5RevisionAcreditacionReferenciasEditComponent implements OnInit {
    @Input('dataParent') dataParent: any;
    @Output() eventoCancelado = new EventEmitter<any>();
    @Output() datosGuardados = new EventEmitter<any>();

    

    public status: number;
    public IdSolicitud: number;
    public editMode: boolean;
    public frmD5: FormGroup;
    public loading: boolean;
    public errorMessages: string;

    /* Collections */
    public data: any;
    public collectionsParentezco: Collection;
    public collectionsReferencias: Collection;

    constructor(
        private fb: FormBuilder,
        private collectionsService: CollectionsService,
        private utilitiesService: UtilitiesService,
        public route: ActivatedRoute,
        private modalErrors: NgbModal,
        private router: Router,
        private D5RevisionAcreditacion: PromotorD5RevisionAcreditacionReferencias,
    ) {
        this.status = 2;
        this.editMode = false;
        this.loading = false;

    }

    ngOnInit(): void {
        this.route.data.subscribe((data: Data) => {
            this.collectionsParentezco =  data['collections'][1].data;
            this.collectionsReferencias =  data['collections'][2].data;
        });

        this.IdSolicitud = this.route.snapshot.params.id;

        this.data = {...this.dataParent}

        this.frmD5 = this.createFormD5();
        this.frmD5.patchValue({
            IdSolicitud: this.IdSolicitud
        })
        this.frmD5.patchValue(this.data);

    }

    createFormD5() {
        return this.fb.group(
            {
                Nombre1: [
                    null,
                    Validators.required
                ],
                ApellidoPaterno1: [
                    null,
                    Validators.required
                ],
                ApellidoMaterno1: [
                    null,
                    Validators.required
                ],
                Telefono1: [
                    null,
                    Validators.required
                ],
                Celular1: [
                    null,
                    Validators.required
                ],
                IdParentesco1: [
                    null,
                    [Validators.required, Validators.min(1)]
                ],
                TiempoConocerlo1: [
                    null,
                    Validators.required
                ],
                HorarioContacto1: [
                    null,
                    Validators.required
                ],
                IdResultadoReferencia1: [
                    null,
                    [Validators.required, Validators.min(1)]
                ],
                Comentario1: [
                    null,
                    Validators.required
                ],
                Nombre2: [
                    null,
                    Validators.required
                ],
                ApellidoPaterno2: [
                    null,
                    Validators.required
                ],
                ApellidoMaterno2: [
                    null,
                    Validators.required
                ],
                Telefono2: [
                    null,
                    Validators.required
                ],
                Celular2: [
                    null,
                    Validators.required
                ],
                IdParentesco2: [
                    null,
                    [Validators.required, Validators.min(1)]
                ],
                TiempoConocerlo2: [
                    null,
                    Validators.required
                ],
                HorarioContacto2: [
                    null,
                    Validators.required
                ],
                IdResultadoReferencia2: [
                    null,
                    [Validators.required, Validators.min(1)]
                ],
                Comentario2: [
                    null,
                    Validators.required
                ],
                IdSolicitud: [

                ],
                IdTelefonoCasa:[
                    null,
                    Validators.required
                ],
                TelefonoCasa:[
                    null,
                    Validators.required
                ],
                ComentarioCasa:[
                    null,
                    Validators.required
                ],
                HorarioContactoCasa: [
                    null,
                    Validators.required
                ],
                IdTelefonoTrabajo:[
                    null,
                    Validators.required
                ],
                TelefonoTrabajo:[
                    null,
                    Validators.required
                ],
                ComentarioTrabajo:[
                    null,
                    Validators.required
                ],
                ExtensionTrabajo: [
                    null,
                ],
            }
        );
    }

    parentEmitCancelEditionMode(): void {
        this.eventoCancelado.emit();
    }

    onSubmit(modalTarget) {
        if (!this.frmD5.valid) {
            Object.keys(this.frmD5.value).forEach(element => {
              if ( this.frmD5.get(element).errors != null ) {
                this.frmD5.get(element).markAsDirty();
              }
            });
            return;
        }
        var sendData = this.frmD5.value;
        this.loading = true;

        this.D5RevisionAcreditacion.sendUpdate(sendData).subscribe(
            data => {
                this.loading = false;
                this.datosGuardados.emit(data)
            }, errors => {
                this.loading = false;
                var msgError = errors.error.error; 
                this.errorMessages = errors.error.message;
                this.modalErrors.open(modalTarget, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        );
    }

}