import { Component, OnInit, AfterContentInit } from "@angular/core";
import { ActivatedRoute, Data, Router } from '@angular/router';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { Collection } from 'src/app/models/collection.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PromotorD5RevisionAcreditacionReferencias } from 'src/app/services/promotor/D5RevisionAcreditacionReferencias.service';

@Component({
    selector: 'app-d5-revision-acreditacion-referencias',
    templateUrl: './d5-revision-acreditacion-referencias.component.html'
})

export class D5RevisionAcreditacionReferenciasComponent implements OnInit, AfterContentInit {
    public status: number;
    public IdSolicitud: number;
    public editMode: boolean;
    public frm: FormGroup;
    public loading : boolean;
    public errorMessages : string;
    public active: boolean;
    public resultadoRef1: string;
    public resultadoRef2: string;
    public parentezco1: string;
    public parentezco2: string;
    public motivoRechazo: Collection;

    /* Collections */
    public data: any;
    public currentState: any;
    public collectionsParentezco: Collection;
    public collectionsReferencias: Collection;
    public canSubmit: boolean;

    public canEdit: boolean;
    public canDecline: boolean;

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private modalErrors: NgbModal,
        private D5Service : PromotorD5RevisionAcreditacionReferencias,
        private utilitiesService: UtilitiesService,
        private router: Router,
    ) {
        this.status = 2;
        this.loading = false;
        this.canEdit = false;
        this.canDecline = false;
        
    }
    ngAfterContentInit (){
        window.scrollTo(0, 0);
        this.editMode = false;
        this.IdSolicitud = this.route.snapshot.params.id;
        this.route.data.subscribe((data: Data) => {
            this.data = data['collections'][0].data.revision_referencias;
            this.active = data['collections'][0].data.has_revision_referencias;
            this.currentState = data['collections'][0].data;
            this.collectionsParentezco =  data['collections'][1].data;
            this.collectionsReferencias =  data['collections'][2].data;
        });

        this.route.data.subscribe((data: Data) => {
            this.motivoRechazo = data['motivoRechazo'][0].data;
        });

        if ( this.data.IdResultadoReferencia1 ) {
            this.resultadoRef1 = this.utilitiesService.getFieldFromCollection(this.collectionsReferencias, this.data.IdResultadoReferencia1, "name");
        }
        else{
            this.resultadoRef1 = '-';
        }

        this.parentezco1 = this.utilitiesService.getFieldFromCollection(this.collectionsParentezco, this.data.IdParentesco1, "name");
        this.parentezco2 = this.utilitiesService.getFieldFromCollection(this.collectionsParentezco, this.data.IdParentesco2, "name");

        if ( this.data.IdResultadoReferencia2 ) {
            this.resultadoRef2= this.utilitiesService.getFieldFromCollection(this.collectionsReferencias, this.data.IdResultadoReferencia2, "name");
        }
        else{
            this.resultadoRef2 = '-';
        }
        if ( this.data.IdResultadoReferencia1 == null || this.data.IdResultadoReferencia2 == null || this.data.ComentarioCasa==null || this.data.ComentarioTrabajo==null || this.data.ComentarioCasa.length<1 || this.data.ComentarioTrabajo.length<1) {
            this.canSubmit = false;
        }
        else{
            this.canSubmit = true;
        }
        if ( ! this.active )
            this.router.navigateByUrl("promotor/revision_solicitud/"+this.IdSolicitud);
    }

    ngOnInit(): void {
        this.IdSolicitud = this.route.snapshot.params.id;
        this.frm = this.createForm();

        this.canEdit = this.utilitiesService.hasPermission('Edicion informacion');
        this.canDecline = this.utilitiesService.hasPermission('Declinar Solicitud');
    }

    declinarSubmit(MotivoRechazo){
        if ( MotivoRechazo.value == '' ) {
            alert('Debe selecciona un motivo de rechazo');
            return;
        }

        let data = {MotivoRechazo: MotivoRechazo.value};
        
        this.utilitiesService.declinarSolicitud(this.IdSolicitud, data).subscribe(
            resp => {
                this.modalErrors.dismissAll();
                this.router.navigateByUrl("promotor/dashboard");
            },
            errors => {
            }
        );
    }

    declinar(modalDeclinar){
        this.modalErrors.open(modalDeclinar, { ariaLabelledBy: 'modal-basic-title', centered: false});
    }

    createForm(){
        return this.fb.group({
            confirmData:[
                null
            ]
        })
    }

    onSubmit(modalTarget){
        this.loading = true;
        this.D5Service.sendCheckedData(this.IdSolicitud).subscribe(
            response =>{
                this.loading = false;
                this.router.navigateByUrl("promotor/revision_acreditacion_documentacion/"+this.IdSolicitud);
            }, 
            errors =>{
                this.loading = false;

                this.errorMessages = errors.error.message;
                this.modalErrors.open(modalTarget, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        );
    }

    triggerEdit(){
        this.editMode = true;
    }

    recibirCancelacion($event){
        this.editMode = false;
    } 

    recibirGuardado($event){
        this.editMode = false;
        this.data = $event.data;
        this.resultadoRef1 = this.utilitiesService.getFieldFromCollection(this.collectionsReferencias, this.data.IdResultadoReferencia1, "name");
        this.resultadoRef2 = this.utilitiesService.getFieldFromCollection(this.collectionsReferencias, this.data.IdResultadoReferencia2, "name");

        this.parentezco1 = this.utilitiesService.getFieldFromCollection(this.collectionsParentezco, this.data.IdParentesco1, "name");
        this.parentezco2 = this.utilitiesService.getFieldFromCollection(this.collectionsParentezco, this.data.IdParentesco2, "name");

        if ( this.data.IdResultadoReferencia1 == null || this.data.IdResultadoReferencia2 == null || this.data.ComentarioCasa==null || this.data.ComentarioTrabajo==null || this.data.ComentarioCasa.length<1 || this.data.ComentarioTrabajo.length<1 ) {
            this.canSubmit = false;
        }
        else{
            this.canSubmit = true;
        }
    }
}