import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import { Data, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-temp-messages-error',
    templateUrl: './temp-messages-error.component.html',
})
export class TempMessagesErrorComponent implements OnInit, AfterViewInit {
    @Input('messages') messages: any;
    public status: number;
    public proceso: any;
    public docs: string;
    constructor(
        private route: ActivatedRoute,
    ) { 
        this.status = 7;
        this.docs = '';
    }

    ngAfterViewInit(): void{
        if ( this.messages.revision_docs && this.proceso.E5 ) {
            this.proceso.E5.forEach(documento => {
                if ( ! parseInt(documento.estatus) ) {
                    this.docs += '<strong>' + documento.nombre + '</strong><br>';
                }
            });
            // document.getElementsByClassName('DocumentosRevision')[0].innerHTML = this.docs;
        }
        else{
            if ( this.messages.revision_docs && this.proceso.revision_documentacion_legales ) {
                this.proceso.revision_documentacion_legales.forEach(documento => {
                    if ( ! parseInt(documento.estatus) ) {
                        this.docs += '<strong>' + documento.nombre + '</strong><br>';
                    }
                });
                // document.getElementsByClassName('DocumentosRevision')[0].innerHTML = this.docs;
            }
        }
    }

    ngOnInit() { 
        this.route.data.subscribe((data: Data) => {
            this.proceso = data['proceso'].data;
        });
    }
}