import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute, Data } from '@angular/router';
import { Collection } from 'src/app/models/collection';
import { E1InformacionComplementaria } from '../../../models/E1InformacionComplementaria.model';
import { E1InformacionComplementariaService } from "../../../services/E1InformacionComplementariaService.service";
import { CollectionsService } from 'src/app/services/colecctions.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { CustomValidators } from 'src/app/validators/validators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-e1-informacion-personal',
  templateUrl: './e1-informacion-personal.component.html',
  styleUrls: ['./e1-informacion-personal.component.scss']
})
export class E1InformacionPersonalComponent implements OnInit,AfterViewInit {
  public collectionsNaconalidad: Collection;
  public collectionsPaisNacimiento: Collection;
  public collectionsEstadoNacimiento: any;
  public collectionsRegimenFisical: Collection;
  public data: E1InformacionComplementaria;

  /* Colections for data direction */
  public estadosDireccion : any;
  public ciudadesDireccion : any;
  public municipiosDireccion : any;
  public coloniasDireccion : any;
  /* End collections for data direction */

  public errorMessages: string;
  public valid_country: boolean;
  public frmE1: FormGroup;
  public IdSolicitud: number;
  public personalData: any;
  public loading: boolean;
  public status: number;
  public credit: {};
  public isMarried: boolean;
  public previous_data: any;
  public checkFiel: boolean;
  public dataFormat = {
    IdPaisNacionalidad: null,
    IdPaisNacimiento: null,
    IdEstadoNacimiento: null,
    EstadoNacimiento: null,
    IdRegimenFiscal: null,
    NombreConyuge: null,
    Telefono: null,
    HorarioContacto: null,
    NoSerieFiel: null,
    TieneNoSerieFiel: null,
    IdSolicitudParticipante: null,
  };
  public requieredConyuge: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private E1Service: E1InformacionComplementariaService,
    private colectionsService: CollectionsService,
    private utilitiesService: UtilitiesService,
    private fb: FormBuilder,
    private modalError: NgbModal,
  ) {
    this.status = 3;
    this.data = new E1InformacionComplementaria();
    this.valid_country = true;
    this.loading = false;
    this.requieredConyuge = false;
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.IdSolicitud = this.route.snapshot.params.id;
    this.route.data.subscribe((data: Data) => {
      this.collectionsNaconalidad = data['collections'][0].data;
      this.collectionsPaisNacimiento = data['collections'][1].data;
      this.collectionsRegimenFisical = data['collections'][2].data;
      this.personalData = data['collections'][3].data;
    });


    if (this.route.snapshot.data.IdSolicitud.data) {
      this.previous_data = this.route.snapshot.data.IdSolicitud.data.E1;
    }

    this.frmE1 = this.createE1Form();

    if (this.previous_data) {
      if (this.previous_data.persona.IdPaisNacimiento.toString() === "1") {
        this.valid_country = true;
        this.colectionsService.getStateByCountryId(this.previous_data.persona.IdPaisNacimiento).subscribe(
          data => {
            this.collectionsEstadoNacimiento = data.data;
          }
        )
      } else {
        this.valid_country = false;
      }
      if (this.previous_data.persona.TieneNoSerieFiel === 0) {
        this.frmE1.controls["NoSerieFiel"].clearValidators();
        this.frmE1.controls["NoSerieFiel"].setValidators([
          Validators.compose([
            Validators.required,
            CustomValidators.patternValidator(
              /^[0-9]{20}$/,
              {
                validFiel: true
              }
            )
          ])
        ]);
        this.checkFiel = false;
        this.frmE1.get("NoSerieFiel").updateValueAndValidity();
      } else {
        this.checkFiel = true
        this.frmE1.controls["NoSerieFiel"].clearValidators();
        this.frmE1.get("NoSerieFiel").updateValueAndValidity();
      }

      this.dataFormat.TieneNoSerieFiel = false;
      this.dataFormat.IdPaisNacionalidad = this.previous_data.persona.IdPaisNacionalidad;
      this.dataFormat.IdEstadoNacimiento = this.previous_data.persona.IdEstadoNacimiento;
      this.dataFormat.EstadoNacimiento = this.previous_data.persona.EstadoNacimiento;
      this.dataFormat.IdPaisNacimiento = this.previous_data.persona.IdPaisNacimiento;
      this.dataFormat.IdRegimenFiscal = this.previous_data.solicitud.IdRegimenFiscal;
      this.dataFormat.NombreConyuge = this.previous_data.solicitud.NombreConyuge;
      this.dataFormat.Telefono = this.previous_data.telefono.Telefono;
      this.dataFormat.HorarioContacto = this.previous_data.telefono.HorarioContacto;
      this.dataFormat.NoSerieFiel = this.previous_data.persona.NoSerieFiel;
      this.dataFormat.IdSolicitudParticipante = this.IdSolicitud;

      this.frmE1.patchValue(this.dataFormat)
    } else {
      this.colectionsService.getStateByCountryId(1).subscribe(
        data => {
          this.collectionsEstadoNacimiento = data.data;
        }
      )
      this.frmE1.patchValue({
        IdPaisNacionalidad: 1,
        IdPaisNacimiento: 1,
        IdEstadoNacimiento: "",
        IdRegimenFiscal: "",
        NombreConyuge: "",
        Telefono: "",
        HorarioContacto: "",
        NoSerieFiel: null,
        TieneNoSerieFiel: false,
        IdSolicitudParticipante: this.IdSolicitud
      });
      this.checkFiel = false;
    }

    this.frmE1.controls["NoSerieFiel"].clearValidators();
      this.frmE1.controls["NoSerieFiel"].setValidators([
        Validators.compose([
          Validators.required,
          CustomValidators.patternValidator(
            /^[0-9]{20}$/,
            {
              validFiel: true
            }
          )
        ])
      ]);
      this.frmE1.get("NoSerieFiel").updateValueAndValidity();

    if (this.personalData) {
      if (this.personalData.datosPersonales.estadoCivil === "CASADO") {
        this.isMarried = true;
        if ( this.personalData.datosPersonales.regimenConyugal == 2 ) {
          this.requieredConyuge = true;
        }
      } else {
        this.isMarried = false;
      }
    }
  }

  onSubmit(modalError) {
    if (!this.frmE1.valid) {
      Object.keys(this.frmE1.value).forEach(element => {
        if ( this.frmE1.get(element).errors != null ) {
          this.frmE1.get(element).markAsDirty();
        }
      });
      return;
    }

    this.loading = true;
    let tempTieneFiel = this.frmE1.controls["TieneNoSerieFiel"].value === false ? 0 : 1;
    let sendData = this.frmE1.value;
    sendData.TieneNoSerieFiel = tempTieneFiel;
    let fiel = <HTMLInputElement>document.getElementById('NoSerieFiel');
    sendData.NoSerieFiel = sendData.TieneNoSerieFiel === 0 ? fiel.value : null;

    sendData.IdRegimenFiscal = parseInt(sendData.IdRegimenFiscal);

    this.E1Service.sendInformacionPersonalExtra(sendData).subscribe(
      resp => {
        this.loading = false;
        this.router.navigateByUrl("client/informacion_laboral_extra/" + this.IdSolicitud);
      },
      errors => {
        this.loading = false;
        this.errorMessages = errors.error.message;
        this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
      },
    )
  }

  handleFiel(event) {
    if (!event.target.checked) {
      this.frmE1.controls["NoSerieFiel"].clearValidators();
      this.frmE1.controls["NoSerieFiel"].setValidators([
        Validators.compose([
          Validators.required,
          CustomValidators.patternValidator(
            /^[0-9]{20}$/,
            {
              validFiel: true
            }
          )
        ])
      ]);
      this.frmE1.get("NoSerieFiel").updateValueAndValidity();
    } else {
      this.frmE1.controls["NoSerieFiel"].clearValidators();
      this.frmE1.get("NoSerieFiel").updateValueAndValidity();
    }
  }

  handleCountry(evt) {
    var index = evt.target.selectedIndex;
    var textCountry = evt.target[index].text;
    var indexCountry = evt.target.value;
    if (indexCountry === "1" && textCountry === "MEXICO") {
      this.colectionsService.getStateByCountryId(indexCountry).subscribe(
        data => {
          this.valid_country = true;
          this.collectionsEstadoNacimiento = data.data;
          this.frmE1.controls["EstadoNacimiento"].clearValidators();
          this.frmE1.get("EstadoNacimiento").updateValueAndValidity();
          this.frmE1.controls["IdEstadoNacimiento"].setValidators([
            Validators.required,
            Validators.min(1)
          ]);

        }, error => {
          this.valid_country = false;
          this.frmE1.controls["IdEstadoNacimiento"].clearValidators();
          this.frmE1.get("IdEstadoNacimiento").updateValueAndValidity();
          this.frmE1.controls["EstadoNacimiento"].setValidators([
            Validators.required
          ]);
        }
      )

    } else {
      this.valid_country = false;
      this.frmE1.controls["IdEstadoNacimiento"].clearValidators();
      this.frmE1.get("IdEstadoNacimiento").updateValueAndValidity();
      this.frmE1.controls["EstadoNacimiento"].setValidators([
        Validators.required
      ]);
    }
  }

  createE1Form() {
    return this.fb.group({
      IdPaisNacionalidad: [
        Validators.required,
        Validators.min(1)
      ],
      IdPaisNacimiento: [
        Validators.required,
        Validators.min(1)
      ],
      IdEstadoNacimiento: [
        Validators.required,
        Validators.min(1)
      ],
      EstadoNacimiento: [
        null
      ],
      IdRegimenFiscal: [
        Validators.required,
        Validators.min(1)
      ],
      NombreConyuge: [
        null,
      ],
      Telefono: [
        null,
      ],
      HorarioContacto: [
        null,
      ],
      NoSerieFiel: [
      ],
      TieneNoSerieFiel: [
        Validators.required
      ],
      IdSolicitudParticipante: [
        Validators.required
      ]
    })
  }
}