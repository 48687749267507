import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Collection } from 'src/app/models/collection';
import { CollectionsService } from 'src/app/services/colecctions.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { M5PromotorInformacionEconomicaService } from 'src/app/services/promotor/M5PromotorInformacionEconomicaService.service';

@Component({
    selector: 'app-m5-promotor-aval-informacion-economica',
    templateUrl: './m5-promotor-aval-informacion-economica.component.html',
})

export class M5PromotorAvalInformacionEconomicaComponent implements OnInit, AfterViewInit {
    public status: number;
    public IdSolicitud: number;
    public IdPersona: number;
    public tipoAval: number;
    public editMode:boolean;
    public errorMessages: string;
    public loading: boolean;
    
    /* Collections for component data */
    public data: any;
    public dataPersonSolicitud:any;

    public canEdit: boolean;
    public canDecline: boolean;

    constructor(
        private route: ActivatedRoute,
        private collectionsService: CollectionsService,
        private utilities: UtilitiesService,
        private M5Service: M5PromotorInformacionEconomicaService,
        private router: Router
    ) {
        this.status = 2;
        this.canEdit = false;
        this.canDecline = false;
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    ngOnInit() {
        this.IdSolicitud = this.route.snapshot.params.id_solicitud;
        this.IdPersona = this.route.snapshot.params.id_persona;
        this.tipoAval = this.route.snapshot.params.tipo_aval;
        this.editMode = false;
        this.route.data.subscribe((data: Data) => {
            this.data = data['data'][0].data.informacion_economica;
            this.dataPersonSolicitud = data['data'][0].data.informacion_propietario;
        });

        this.canEdit = this.utilities.hasPermission('Edicion informacion');
        this.canDecline = this.utilities.hasPermission('Declinar Solicitud');
    }

    triggerEdit(){
        this.editMode = true;
    }

    recibirCancelacion($event){
        this.editMode = false;
    }

    onSubmit(modalError){
        this.loading = true;
        this.M5Service.setDataComplete(this.IdSolicitud, this.IdPersona).subscribe(
            resp => {
                this.loading = false;
                this.router.navigateByUrl('promotor/revision/aval/documentacion/'+this.IdSolicitud+'/'+this.IdPersona+'/'+this.tipoAval);
            }
        );
        
    }
    
    recibirGuardado($event){
        this.editMode = false;
        this.data = $event.data;
    }
}