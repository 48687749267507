import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Collection } from 'src/app/models/collection';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { C111InformacionFamiliarRecursos } from '../../../models/C111InformacionFamiliarRecursos.model';
import { C111InformacionFamiliarRecursosService } from "../../../services/C111InformacionFamiliarRecursosService.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/validators/validators';
import { UtilitiesService } from 'src/app/services/utilities_services.service';

@Component({
  selector: 'app-c11-informacion-familiar',
  templateUrl: './c11-informacion-familiar.component.html',
  styleUrls: ['./c11-informacion-familiar.component.scss']
})
export class C11InformacionFamiliarComponent implements OnInit, AfterViewInit {
  public _prevSelectedPersonCredit: boolean;
  public requiredParentezco: any;
  public status: number;
  public collectionsEstadoCivil: Collection;
  public collectionsRegimenConyugal: Collection;
  public collectionsParentezco: Collection;
  public collectionsTipoVivienda: Collection;
  public data: C111InformacionFamiliarRecursos;
  public errorMessages: string;
  public showRegimenConyugal: boolean;
  public IdSolicitud: number;
  public frmC11: FormGroup;
  public previous_data: any;
  public errorFecha: boolean;
  public controls = ['PorcentajeOtraPersona', 'Nombre', 'ApellidoPaterno', 'ApellidoMaterno', 'FechaNacimiento', 'Email', 'Celular'];
  public controlsReset = [
    {
      name: "PorcentajeOtraPersona",
      rules: [
        Validators.compose([
          Validators.required,
          CustomValidators.patternValidator(
            /\b([1-9]|[1-8][0-9]|9[0-9]|100)\b/,
            {
              invalidPercentage: true
            }
          )
        ])
      ] 
    },
    {
      name: "Nombre",
      rules: [
        Validators.required
      ] 
    },
    {
      name: "ApellidoPaterno",
      rules: [
        Validators.required
      ] 
    },
    {
      name: "ApellidoMaterno",
      rules: [
        Validators.required
      ] 
    },
    {
      name: "FechaNacimiento",
      rules: [
        Validators.required
      ] 
    },
    {
      name: "Email",
      rules: [
        Validators.required,
        Validators.email
      ] 
    },
    {
      name: "Celular",
      rules: [
        Validators.compose([
          Validators.required,
          CustomValidators.patternValidator(
            /[0-9]{10}/,
            {
              invalidCellphone: true
            }
          )
        ])
      ] 
    }
  ];
  public loading: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private C1Service: C111InformacionFamiliarRecursosService,
    private modalErrors: NgbModal,
    private fb: FormBuilder,
    private utilitiesService: UtilitiesService

  ) {
    this.status = 2;
    this.data = new C111InformacionFamiliarRecursos();
    this.loading = false;
    this.errorMessages = "";

    this.errorFecha = false;
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this._prevSelectedPersonCredit = false;
    this.IdSolicitud = this.route.snapshot.params.id;

    
    this.route.data.subscribe((data: Data) => {
      this.collectionsEstadoCivil = data['collections'][0].data;
      this.collectionsRegimenConyugal = data['collections'][1].data;
      this.collectionsParentezco = data['collections'][2].data;
      this.collectionsTipoVivienda = data['collections'][3].data;
      this.requiredParentezco = data['collections'][4].data.has_parentezco;
    });


    this.frmC11 = this.createC11Form();

    if (this.route.snapshot.data.IdSolicitud.data) {
      this.previous_data = this.route.snapshot.data.IdSolicitud.data.C1;
      if (this.previous_data != undefined){
        if  (this.previous_data.FechaAntiguedadVivienda){
          var formatFechaAntiguedad = this.utilitiesService.formatDateObject(this.previous_data.FechaAntiguedadVivienda);
          this.previous_data.FechaAntiguedadVivienda = formatFechaAntiguedad;
        }
        if  (this.previous_data.FechaNacimiento){
          var formatFechaNacimiento = this.utilitiesService.formatDateObject(this.previous_data.FechaNacimiento);
          this.previous_data.FechaNacimiento = formatFechaNacimiento;
        }
        this.previous_data.IdSolicitud = this.IdSolicitud;
        if ( this.previous_data.UnicaPersona == 0 ) {
          this._prevSelectedPersonCredit = true;
        }
        else{
          this._prevSelectedPersonCredit = false;
        }
        this.previous_data.UnicaPersona = this.previous_data.UnicaPersona == 1 ? "true" : "false";
        if (this.previous_data.IdEstadoCivil == "1"){
          this.showRegimenConyugal = true;
          this.frmC11.controls["IdRegimenConyugal"].setValidators([
            Validators.required
          ]);
        }else{
          this.showRegimenConyugal = false;
          this.frmC11.controls["IdRegimenConyugal"].clearValidators();
          this.frmC11.get("IdRegimenConyugal").updateValueAndValidity();
        }
        this.previous_data.IdSolicitudParticipante = this.IdSolicitud;
        this.frmC11.patchValue(this.previous_data);
      }
    }else{
      this.showRegimenConyugal = false;
      this.frmC11.patchValue({
        IdEstadoCivil: "",
        IdRegimenConyugal: "",
        IdParentescoConEstudiante: "",
        IdTipoVivienda: "",
        UnicaPersona: "true", 
        IdSolicitudParticipante: this.IdSolicitud
      });
    }
  }

  onSubmit(modalError) {
    if ( this.frmC11.controls["FechaAntiguedadVivienda"].value.year == undefined ) {
      this.errorFecha = true;
      return;
    }

    if (!this.frmC11.valid) {
      Object.keys(this.frmC11.value).forEach(element => {
        if ( this.frmC11.get(element).errors != null ) {
          this.frmC11.get(element).markAsDirty();
        }
      });
      return;
    }

    

    this.loading = true;
    let FechaFinalAntiguedad = this.utilitiesService.parseDate(this.frmC11.controls["FechaAntiguedadVivienda"].value);
    let FechaFinalNacimiento = "";
    
    if (this.frmC11.controls["FechaNacimiento"].value != null) {
      FechaFinalNacimiento = this.utilitiesService.parseDate(this.frmC11.controls["FechaNacimiento"].value);
    }

    let sendData = this.frmC11.value;
    
    sendData.FechaAntiguedadVivienda = FechaFinalAntiguedad;
    sendData.FechaNacimiento = FechaFinalNacimiento;
    sendData.UnicaPersona = this.frmC11.controls["UnicaPersona"].value == "true";

    this.C1Service.sendInformacionFamiliarRecursos(sendData).subscribe(
      resp => {
        
        let redirect = this.utilitiesService.handleRedirect(resp['proceso']);
        
        this.router.navigateByUrl("client/informacion_laboral/"+this.IdSolicitud);
        // this.router.navigateByUrl(redirect+"/"+this.IdSolicitud);
      },
      errors => {
        this.loading = false;
        this.errorMessages = errors.error.message;
        this.modalErrors.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
      },
    );
  }

  handleChangeBeneficent(evt) {
    var target = evt.target;
    if (target.value == "true") {
      this._prevSelectedPersonCredit = false;
      this.utilitiesService.removeControlsValidity(this.frmC11, this.controls);
      this.frmC11.patchValue({
        PorcentajeOtraPersona: null,
        Nombre: null,
        ApellidoPaterno: null,
        FechaNacimiento: null,
        Email:null,
        Celular: null
      });
    } else {

      this._prevSelectedPersonCredit = true;
      this.nuevaValidator(this.frmC11, this.controlsReset);
    }
  }

  handleChangeEstadoCivil(evt){
    var target = evt.target;
    if (target.value == "1"){
      this.showRegimenConyugal = true;
      this.frmC11.controls["IdRegimenConyugal"].setValidators([
        Validators.required
      ]);
    }else{
      this.showRegimenConyugal = false;
      this.frmC11.controls["IdRegimenConyugal"].clearValidators();
      this.frmC11.get("IdRegimenConyugal").updateValueAndValidity();
    }
  }

  createC11Form() {
    return this.fb.group({
      IdEstadoCivil: [
        Validators.required,
      ],
      IdRegimenConyugal: [
        Validators.required
      ],
      NumeroDependientes: [
        null,
        Validators.compose([
          Validators.required,
          CustomValidators.patternValidator(
            /^[0-9][0-9]*$/,
            {
              atLeastOne: true
            }
          )
        ])
      ],
      IdParentescoConEstudiante: [
        null,
      ],
      IdTipoVivienda: [
        Validators.required,
      ],
      FechaAntiguedadVivienda: [
        Validators.required,
      ],
      UnicaPersona: [
        Validators.required,
      ],
      IdSolicitudParticipante: [
        null,
        null
      ],
      PorcentajeOtraPersona: [
        null,
        null
      ],
      Nombre: [
        null,
        null
      ],
      ApellidoPaterno: [
        null,
        null
      ],
      ApellidoMaterno: [
        null,
        null
      ],
      FechaNacimiento: [
        null,
        null
      ],
      Celular: [
        null,
        null
      ],
      Email: [
        null,
        null
      ],
      IdSolicitud:[
        null
      ]
    })
  }

  nuevaValidator(frm, arrayRules) {
    arrayRules.forEach(element => {
      frm.controls[element.name].setValidators(element.rules);
    });
  }
}