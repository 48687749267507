import { Component, OnInit } from '@angular/core';
import { RegisterService } from '../../../services/register.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-a21-olvide-contrasenia',
    templateUrl: './a21-olvide-contrasenia.component.html',
})
export class A21OlvideContraseniaComponent implements OnInit {
    email: string;
    public errorMessages: string;

    constructor(private register: RegisterService, private modalService: NgbModal) {}

    ngOnInit() {
        
    }

    onSubmit(content,errors) {
        const data = {
            email: this.email,
        };

        this.register.recoverPassword(data).subscribe(
            response => {
                this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
            errorService => {
                this.errorMessages = errorService.error.message
                this.modalService.open(errors, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        );
    }
}
