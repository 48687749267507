import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin,of,throwError } from 'rxjs';
import { CollectionsService } from "../services/colecctions.service";
import { Collection } from '../models/collection.model';
import { Injectable } from '@angular/core';

@Injectable()
export class D8PromotorResolver implements Resolve<Collection>{
    private Object = {};
    constructor (private CollectionsServive: CollectionsService){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<any>{   
        let test = forkJoin(
            this.CollectionsServive.getCollectionsTipoInmueble(),
            this.CollectionsServive.getCollectionsPaises()
        );

        return test;
    }
}