import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { CollectionsService } from "../../services/colecctions.service";
import { Collection } from '../../models/collection.model';
import { Injectable } from '@angular/core';
import { UtilitiesAvalService } from 'src/app/services/aval/UtilitiesAvalService.service';

@Injectable()
export class SolicitudAvalPrivilegesResolver implements Resolve<Collection>{
    public IdSolicitud: number;
    constructor (private avalServices: UtilitiesAvalService){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<any>{   
        this.IdSolicitud = route.params.id
        let test = forkJoin(
            this.avalServices.getRoleAval(this.IdSolicitud),
        );

        return test;
    }
}