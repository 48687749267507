import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Data, Router } from '@angular/router';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { Subject } from 'rxjs';
import { CollectionsService } from 'src/app/services/colecctions.service';
import { Collection } from 'src/app/models/collection.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-reasignar-solicitudes',
    templateUrl: './reasignar-solicitudes.component.html'
})

export class ReasignarSolicitudes implements OnInit {
    dtOptions: any = {};
    dtTrigger = new Subject();
    public tablePromotor: any;
    public agencias: Collection;
    public motivosRechazo: Collection;
    public promotores: Collection;
    public etapas: Collection;
    public frm: FormGroup;
    public loading: boolean;
    public errorMessages: string;
    public dtElement: DataTableDirective;
    public avialableSolicitudes: any;
    public collectionsPromotores: Collection;

    public API_URL: string;
    
    public user_role: number;

    constructor(
        private collections: CollectionsService,
        private utilities: UtilitiesService,
        private router: Router,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        public modalHandler: NgbModal,
        private http: HttpClient
    ) {
        this.loading = false;
        this.API_URL = environment.API_URL;
    }

    ngOnInit() {
        this.route.data.subscribe((data: Data) => {
            this.agencias = data['collections'][0].data;
            this.motivosRechazo = data['collections'][1].data;
            this.promotores = data['collections'][2].data;
            this.etapas = data['collections'][3].data;
        });

        this.route.data.subscribe((data: Data) => {
            this.collectionsPromotores = data['promotores'][0].data;
        });

        this.frm = this.createFrm();
        this.resetForm();

        this.dtOptions = {
            pagingType: 'full_numbers',
            searching: true,
            paging: true,
            info: false,
            columns: [
                { title: 'No. de solicitud', data: 'IdSolicitud', type: 'number' },
                { title: 'Cliente', data: 'Cliente' },
                { title: 'Monto financiado', data: 'MontoSolicitado' },
                { title: 'Alianza', data: 'RazonSocial' },
                { title: 'Promotor', data: 'Promotor' },
                { title: 'Etapa', data: 'Etapa' },
                { title: 'Etapa - Promotor'},
                { title: 'Acciones' },

            ],
            dom: 'Bfrtip',
            // Configure the buttons
            buttons: [
                'excel',
            ],
            responsive: true
        };
        this.user_role = parseInt(localStorage.getItem('userRole'));

        this.collections.obtenerSolicitudesSuperPromotor().subscribe(
            data => {
                var temp = [];
                data.data.forEach(element => {
                    element.IdSolicitud = parseInt(element.IdSolicitud);
                    temp.push(element);
                });
                this.tablePromotor = temp;
                this.dtTrigger.next();
            }
        );
    }

    asignarSolicitud(promotor, IdSolicitudAsignar){
        let IdPromotor = parseInt(promotor.value);
        let IdSolicitud = parseInt(IdSolicitudAsignar.value);

        if ( IdPromotor < 1 ) {
            alert('Debe seleccionar un promotor');
            return;
        }
        else{
            const headerStruct = {
                'Authorization': 'Bearer ' + localStorage.getItem("temp_tokentoken")
            }
            
            const requestOptions = {
                headers: new HttpHeaders(headerStruct),
            };

            var NamePromotor = <HTMLInputElement>document.getElementById('NamePromotor' + IdSolicitud);

            this.http.post(this.API_URL + 'super/promotor/reasignar/solicitud', {IdPromotor: IdPromotor, IdSolicitud: IdSolicitud}, requestOptions).subscribe(
                response => {
                    this.modalHandler.dismissAll();
                    alert('Se ha reasignado la solicitud correctamente');

                    NamePromotor.textContent = response['data'].promotor;
                },
                errors => {
                    alert('Algo salió mal, recargue e intente de nuevo');
                }
            );
        }
    }

    resetSearch(){
        this.collections.obtenerSolicitudesSuperPromotor().subscribe(
            data => {
                var temp = [];
                data.data.forEach(element => {
                    element.IdSolicitud = parseInt(element.IdSolicitud);
                    temp.push(element);
                });
                this.tablePromotor = temp;
                this.dtTrigger.next();
            }
        );
        this.resetForm();
    }

    apartar(IdSolicitud, modalError, modalSuccess){
        this.loading = true;
        this.utilities.getQuery('get', 'promotor/apartar/solicitud/' + IdSolicitud, '').subscribe(
            data => {
                this.modalHandler.open(modalSuccess, { ariaLabelledBy: 'modal-basic-title', centered: true });
                window.location.reload();
            },
            errors => {
                this.errorMessages = errors.error.message;
                this.modalHandler.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
                window.location.reload();
            }
        );
    }

    resetForm(){
        this.frm.patchValue({
            To: null,
            From: null,
            IdEtapa: '',
            MotivoRechazo: '',
            IdEmpleadoPromotor: '',
            NombreCliente: null,
            CorreoCliente: null,
            CelularCliente: null,
            IdAlianza: '',
        })
    }

    filterData(modalTarget) {
        this.loading = true;
        var test = this.formatQuery();
        if ( this.user_role < 12 ) {
            this.collections.getFilteredPromotor(test.slice(0, -1)).subscribe(
                data => {
                    this.loading = false;
                    this.tablePromotor = data.data;
                }
            )
        }
        else{
            this.collections.getFilteredDirectiva(test.slice(0, -1)).subscribe(
                data => {
                    this.loading = false;
                    this.tablePromotor = data.data;
                }
            )
        }
    }


    createFrm() {
        return this.fb.group({
            To: [
                null
            ],
            From: [
                null
            ],
            IdEtapa: [
                null
            ],
            MotivoRechazo: [
                null
            ],
            IdEmpleadoPromotor: [
                null
            ],
            NombreCliente: [
                null
            ],
            CorreoCliente: [
                null
            ],
            CelularCliente: [
                null
            ],
            IdAlianza: [
                null
            ],
        })
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
    }

    rerender(): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            // Destroy the table first
            dtInstance.destroy();
            // Call the dtTrigger to rerender again
            this.dtTrigger.next();
        });
    }

    formatQuery() {
        var stringQuery: string = "";
        if (this.frm.controls['From'].value != null && this.frm.controls['From'].value !='') {
            stringQuery += 'From=' + this.utilities.parseDate(this.frm.controls['From'].value) + '&'
        }
        if (this.frm.controls['To'].value != null && this.frm.controls['To'].value !='') {
            stringQuery += 'To=' + this.utilities.parseDate(this.frm.controls['To'].value) + '&'
        }
        if (this.frm.controls['IdEtapa'].value != null && this.frm.controls['IdEtapa'].value !='') {
            stringQuery += 'IdEtapa=' + this.frm.controls['IdEtapa'].value + '&'
        }
        if (this.frm.controls['MotivoRechazo'].value != null && this.frm.controls['MotivoRechazo'].value !='') {
            stringQuery += 'MotivoRechazo=' + this.frm.controls['MotivoRechazo'].value + '&'
        }
        if (this.frm.controls['IdEmpleadoPromotor'].value != null && this.frm.controls['IdEmpleadoPromotor'].value !='') {
            stringQuery += 'IdEmpleadoPromotor=' + this.frm.controls['IdEmpleadoPromotor'].value + '&'
        }
        if (this.frm.controls['NombreCliente'].value != null && this.frm.controls['NombreCliente'].value !='') {
            stringQuery += 'NombreCliente=' + encodeURIComponent(this.frm.controls['NombreCliente'].value) + '&'
        }
        if (this.frm.controls['CorreoCliente'].value != null && this.frm.controls['CorreoCliente'].value !='') {
            stringQuery += 'CorreoCliente=' + encodeURIComponent(this.frm.controls['CorreoCliente'].value) + '&'
        }
        if (this.frm.controls['CelularCliente'].value != null && this.frm.controls['CelularCliente'].value !='') {
            stringQuery += 'CelularCliente=' + this.frm.controls['CelularCliente'].value + '&'
        }
        if (this.frm.controls['IdAlianza'].value != null && this.frm.controls['IdAlianza'].value !='') {
            stringQuery += 'IdAlianza=' + this.frm.controls['IdAlianza'].value + '&'
        }

        return stringQuery;
    }

    reasignarSolicitud(IdSolicitud, modalAsignar, IdSolicitudAsignar) {
        IdSolicitud = parseInt(IdSolicitud);
        IdSolicitudAsignar.value = IdSolicitud;
        this.modalHandler.open(modalAsignar, { ariaLabelledBy: 'modal-basic-title', centered: true });
    }
}