import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router, ActivatedRoute, Data } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorsService {
    private s:boolean;
    constructor(private modal: NgbModal, private route: Router){

    }

    handleErrors (error: HttpErrorResponse,modal?:NgbModal){
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
              `Backend returned code ${error.status}, ` +
              `body was: ${error.error}`);
              
        }
          // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    }
}