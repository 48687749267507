export class E2InformacionEmpleoComplemento{
    public Puesto: string;
    public Telefono:number;
    public Extension: number;
    public IdPais: number;
    public IdCiudad: number;
    public IdEstado: number;
    public IdMunicipio: number;
    public IdColonia: number;
    public IdCodigoPostal: number;
    public Calle: string;
    public NoExterior: number;
    public NoInterior: string;
    public FuncionesPublicas: boolean;
    public PuestoFuncionesPublicas: string;
    public PeriodoFuncionesPublicas: string;
    public FamiliarFuncionesPublicas: boolean;
    public PuestoFamiliarFuncionesPublicas: string;
    public PeriodoFamiliarFuncionesPublicas: string;
    public IdUsuario : number;
    public IdPersona: number;
    public IdSolicitud: number;
    
    constructor(){}
}