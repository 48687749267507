import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { K2ContratosService } from 'src/app/services/K2ContratosService.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesService } from 'src/app/services/utilities_services.service';

@Component({
    selector: 'app-i1-envio-contratos',
    templateUrl: './i1-envio-contratos.component.html'
})

export class I1EnvioContratosComponent implements OnInit {
    public status: number;
    public IdSolicitud: number;
    public loading: boolean;
    public errorMessages: string;
    public persona: any;
    public contrato: any;
    public garantes:any;

    constructor(
        private route: ActivatedRoute,
        private K2Service: K2ContratosService,
        private modalError: NgbModal,
        private router: Router,
        private utilities: UtilitiesService
    ) {
        this.status = 7;
    }

    ngOnInit(): void {
        this.IdSolicitud = this.route.snapshot.params.id;
        this.persona = this.route.snapshot.data.solicitudPromotor.data.persona;
        this.contrato = this.route.snapshot.data.solicitudPromotor.data.Contrato;
        this.loading = false;
        this.garantes=this.route.snapshot.data.solicitudPromotor.data.garanteAuto;
    }

    downloadCarta(){
        this.loading = true;
        this.K2Service.downloadCarta(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Carta de Bienvenida.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
            //   this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
    }

    //se integra solicitud documento legal
    downloadLegal(){
        this.loading = true;
        this.utilities.imprimirSolicitud2(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Solicitud de crédito.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
            }
        );
    }

    downloadPagare(){
        this.loading = true;
        this.K2Service.downloadPagare(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Pagare.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
              this.modalError.open('modalError', { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
    }

    downloadContrato(){
        this.loading = true;
        this.K2Service.downloadContrato(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Contrato.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
              this.modalError.open('modalError', { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
    }

    downloadAnexo(){
        this.loading = true;
        this.K2Service.downloadAnexo(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Anexo.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
              this.modalError.open('modalError', { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
    }

    downloadDesembolso(){
        this.loading = true;
        this.K2Service.downloadDesembolso(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Instrucciones de Desembolso.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
              this.modalError.open('modalError', { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
    }

    downloadSeguro(){
        var a = <HTMLInputElement>document.getElementById('self_file');
        a.setAttribute('download', 'Seguro.pdf');
        a.setAttribute('target', '_blank');
        a.setAttribute('href', 'https://apifinanciamiento.edupass.mx/Seguro.pdf');
        a.click();
    }

    downloadPrenda(IdGarantia){
        this.loading = true;
        this.K2Service.downloadPrenda(IdGarantia).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Prenda_'+IdGarantia+'.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
            //   this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
    }

    sendDocumentos(modalTarget){
        this.loading = true;
        var sendData = {
            IdSolicitud: this.IdSolicitud,
        }
        this.K2Service.sendEnvioContratos(sendData).subscribe(
            response =>{
                this.loading = false;
                this.router.navigateByUrl("promotor/revision_contratos/"+this.IdSolicitud);
            },
            errors =>{
                this.loading = false;

                this.errorMessages = errors.error.message;
                this.modalError.open(modalTarget, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        )
    }
}
