import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { Collection } from 'src/app/models/collection.model';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesAvalService } from 'src/app/services/aval/UtilitiesAvalService.service';
import { CustomValidators } from 'src/app/validators/validators';
import { M3PromotorInformacionFamiliarService } from 'src/app/services/promotor/M3PromotorInformacionFamiliarService.service';

@Component({
    selector: 'app-m3-promotor-aval-informacion-familiar-edit',
    templateUrl: './m3-promotor-aval-informacion-familiar-edit.component.html',
})

export class M3PromotorAvalInformacionFamiliarEditComponent implements OnInit, AfterViewInit {
    @Input('dataParent') dataParent: any;
    @Output() eventoCancelado = new EventEmitter<any>();
    @Output() datosGuardados = new EventEmitter<any>();
    public loading: boolean;
    public frmM3: FormGroup;
    public IdSolicitud: number;
    public IdPersona: number;
    public tipoAval: number;
    public showRegimenConyugal: boolean;
    public errorMessages: string;
    public data: any;

    /* collections from resolver */
    public collectionsEstadoCivil: Collection;
    public collectionsRegimenConyugal: Collection;
    public collectionsTipoVivienda: Collection;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private M3Service: M3PromotorInformacionFamiliarService,
        private modalErrors: NgbModal,
        private fb: FormBuilder,
        private utilitiesService: UtilitiesService,
        private modalError: NgbModal

    ) {


    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    ngOnInit() {
        this.IdSolicitud = this.route.snapshot.params.id_solicitud;
        this.IdPersona = this.route.snapshot.params.id_persona;
        this.tipoAval = this.route.snapshot.params.tipo_aval;
        this.route.data.subscribe((data: Data) => {
            this.collectionsEstadoCivil = data['collections'][0].data;
            this.collectionsRegimenConyugal = data['collections'][1].data;
            this.collectionsTipoVivienda = data['collections'][2].data;
        });
        this.data = {...this.dataParent}
        this.showRegimenConyugal = this.data.IdEstadoCivil == 1 ? true : false;
        this.frmM3 = this.createM3Form();
        this.frmM3.patchValue({
            IdSolicitud: this.IdSolicitud,
            IdPersona: this.IdPersona
        });
        if (typeof this.data.FechaAntiguedadVivienda === "string") {
            var formatFechaAntiguedadVivienda = this.utilitiesService.formatDateObject(this.data.FechaAntiguedadVivienda);
            this.data.FechaAntiguedadVivienda = formatFechaAntiguedadVivienda;
        }
        
        this.frmM3.patchValue(this.data);


    }

    handleChangeEstadoCivil(evt) {
        var target = evt.target;
        if (target.value === "1") {
            this.showRegimenConyugal = true;
            this.frmM3.controls["IdRegimenConyugal"].setValidators([
                Validators.required, Validators.min(1)
            ]);
        } else {
            this.showRegimenConyugal = false;
            this.frmM3.controls["IdRegimenConyugal"].clearValidators();
            this.frmM3.get("IdRegimenConyugal").updateValueAndValidity();
        }
    }


    createM3Form() {
        return this.fb.group({
            IdEstadoCivil: [
                null,
                [Validators.required, Validators.min(1)],
            ],
            IdRegimenConyugal: [
                null
            ],
            NumeroDependientes: [
                null,
                Validators.compose([
                    Validators.required,
                    CustomValidators.patternValidator(
                        /^[0-9][0-9]*$/,
                        {
                            atLeastOne: true
                        }
                    )
                ])
            ],
            IdParentescoConEstudiante: [
                null
            ],
            IdTipoVivienda: [
                Validators.required,
            ],
            FechaAntiguedadVivienda: [
                Validators.required,
            ],
            IdSolicitud: [
                null
            ],
            IdPersona:[
                null
            ]
        })
    }

    onSubmit(modalError) {
        if (!this.frmM3.valid) {
            Object.keys(this.frmM3.value).forEach(element => {
              if ( this.frmM3.get(element).errors != null ) {
                this.frmM3.get(element).markAsDirty();
              }
            });
            return;
        }

        this.loading = true;
        var sendData = this.frmM3.value;
        sendData.FechaAntiguedadVivienda = this.utilitiesService.parseDate(sendData.FechaAntiguedadVivienda);
        this.M3Service.sendInformacionFamiliar(sendData).subscribe(
            resp => {
                this.datosGuardados.emit(resp);
                this.loading = false;
                
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
                this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
        )
    }

    parentEmitCancelEditionMode(): void {
        
        this.eventoCancelado.emit();
    }

}