import { Component, OnInit, Input } from '@angular/core';
import { UtilitiesAvalService } from 'src/app/services/aval/UtilitiesAvalService.service';

@Component({
    selector: 'app-navegacion-revision-aval',
    templateUrl: './navegacion-revision-aval.component.html',
    
})
export class NavegacionRevisionAvalComponent implements OnInit {
    @Input('step') step: number;
    @Input('IdSolicitud') IdSolicitud: number;
    @Input('IdPersona') IdPersona: number;
    @Input('avalType') avalType: number;
    public permissions: any;

    constructor(
        private avalService: UtilitiesAvalService
    ) { }

    ngOnInit(){
        this.permissions = this.avalService.getPermissions(this.avalType);
    }
}
