import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
    providedIn: 'root',
})
export class RegisterService {

    API_URL: string;

    constructor(private http: HttpClient) {
        this.API_URL = environment.API_URL;
    }

    getQuery(query: string, parameters: string) {
        const url = `${this.API_URL}${query}`;

        return this.http.post(url, parameters);
    }

    registerClient(parameters: any) {
        return this.getQuery('register', parameters);
    }

    recoverPassword(parameters: any) {
        return this.getQuery('password/email', parameters);
    }

    sendNewPassword(data){
        return this.getQuery('password/recover', data);
    }

    changePassword(data){
        return this.getQuery('cambiar/contrasena', data);
    }

    verifyEmail(token){
        const url = `${this.API_URL}verificar/correo/`;
        return this.http.get(url + token);
    }
}
