import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Data, Router } from '@angular/router';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { map } from 'rxjs/operators';
import { PromotorD6RevisionAcreditacionDocumentacion } from 'src/app/services/promotor/D6RevisionAcreditacionDocumentacion.service';
import { Collection } from 'src/app/models/collection.model';

@Component({
    selector: 'app-d6-revision-acreditacion-documentacion',
    templateUrl: './d6-revision-acreditacion-documentacion.component.html'
})

export class D6RevisionAcreditacionDocumentacionComponent implements OnInit, AfterViewInit {
    public status: number;
    public IdSolicitud: number;
    public loading : boolean;
    public editMode: boolean;
    public data:any;
    public currentState: any;
    public formatData: any;
    public frmD6: FormGroup;
    public errorMessages: string;
    public active: boolean;
    public has_rfc: boolean;
    public has_garantia_auto: boolean;
    public has_garantia_rpp: boolean;
    public motivoRechazo: Collection;
    public noidentificacion: string;

    public noComprobantes: number;
    public ingresos:boolean;
    public index:any;
    /* Booleans Comentarios */

    public showCommentsIdentificacion : boolean;
    public showCommentsIdentificacion2 : boolean;
    public showCommentsComprobante : boolean;
    public showCommentsComprobanteMes : any;
    public showCommentsCedulaFiscal : boolean;
    public showCommentsGarantiaAuto : boolean;
    public showCommentsEvidenciaInmueble : boolean;

    public canEdit: boolean;
    public canDecline: boolean;

    /* strings nombres categorias */

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private modalErrors: NgbModal,
        private utilitiesService: UtilitiesService,
        private router: Router,
        private D6service: PromotorD6RevisionAcreditacionDocumentacion
    ) {
        this.status = 2;
        this.loading = false;
        this.canEdit = false;
        this.canDecline = false;
    }

    ngOnInit(): void {
        this.editMode = false;
        this.IdSolicitud = this.route.snapshot.params.id;
        this.frmD6 = this.createFormD6();
        this.route.data.subscribe((data: Data) => {
            this.data = data['collections'][0].data.revision_documentacion;
            this.active = data['collections'][0].data.has_revision_referencias;
            this.currentState = data['collections'][0].data;
            this.noidentificacion= data['collections'][0].data.NoIdentificacion;
            this.noComprobantes=data['collections'][0].data.NoComprobantes;
        });

        this.canEdit = this.utilitiesService.hasPermission('Edicion informacion');
        this.canDecline = this.utilitiesService.hasPermission('Declinar Solicitud');

        this.route.data.subscribe((data: Data) => {
            this.motivoRechazo = data['motivoRechazo'][0].data;
        });

        if ( ! this.active)
            this.router.navigateByUrl("promotor/revision_solicitud/"+this.IdSolicitud)
        if ( this.currentState.E5.Identificacion=='')
            this.router.navigateByUrl("promotor/revision_solicitud/"+this.IdSolicitud)
        if (this.currentState.comprobante_identidad=='')
            this.router.navigateByUrl("promotor/revision_solicitud/"+this.IdSolicitud)

        this.formatData = this.formatFileObject(this.data,this.noComprobantes);

        
        this.ingresos=false;
        this.index=[];
        this.showCommentsComprobanteMes=[];
        for (let i=1;i<=this.noComprobantes;i++){
            this.index.push({mes:i,estatus:'EstatusComprobanteMes'+i,comentario:'ComentarioComprobanteMes'+i,comprobante:'comprobante_mes_'+i});
            this.frmD6.addControl('EstatusComprobanteMes'+i, new FormControl(null, [Validators.required]));
            this.frmD6.addControl('ComentarioComprobanteMes'+i, new FormControl(null));
            this.frmD6.addControl('_IdComprobanteMes'+i, new FormControl(null));
            this.frmD6.controls['_IdComprobanteMes'+i].patchValue(this.formatData['comprobante_mes_'+i]['_IdComprobanteMes'+i]);
            this.frmD6.controls['EstatusComprobanteMes'+i].patchValue(parseInt(this.formatData['comprobante_mes_'+i]['EstatusComprobanteMes'+i]) ? '1' : '0');
            this.frmD6.controls['ComentarioComprobanteMes'+i].patchValue(this.formatData['comprobante_mes_'+i]['ComentarioComprobanteMes'+i]);
            if ( ! parseInt(this.formatData['comprobante_mes_'+i]['EstatusComprobanteMes'+i]) ) {
                this.frmD6.controls['ComentarioComprobanteMes'+i].setValidators([Validators.required]);
            }
            this.showCommentsComprobanteMes.push(parseInt(this.formatData['comprobante_mes_'+i]['EstatusComprobanteMes'+i]) == 1 ? false : true);
        
        }
        this.ingresos=true;

        this.showCommentsIdentificacion = parseInt(this.formatData.identificacion.EstatusIdentificacion) == 1 ? false : true;
        this.showCommentsIdentificacion2 = this.formatData.identificacion2 && parseInt(this.formatData.identificacion2.EstatusIdentificacion2) == 1 ? false : true;
        this.showCommentsComprobante = parseInt(this.formatData.comprobante_domicilio.EstatusComprobante) == 1 ? false : true;
        
        this.has_rfc = this.formatData.cedula_fiscal ? true : false;

        this.has_garantia_auto = this.formatData.garantia_auto ? true : false;
        this.has_garantia_rpp = this.formatData.evidencia ? true : false;


        this.frmD6.patchValue({
            IdSolicitud: this.IdSolicitud,
            _IdIdentificacion: this.formatData.identificacion._IdIdentificacion,
            _IdComprobante: this.formatData.comprobante_domicilio._IdComprobante,
            EstatusIdentificacion: parseInt(this.formatData.identificacion.EstatusIdentificacion) ? '1' : '0',
            EstatusIdentificacion2:'1',
            EstatusComprobante: parseInt(this.formatData.comprobante_domicilio.EstatusComprobante) ? '1' : '0',
            ComentarioIdentificacion:  this.formatData.identificacion.ComentarioIdentificacion,
            ComentarioComprobante:  this.formatData.comprobante_domicilio.ComentarioComprobante,
            NoIdentificacion:this.noidentificacion,
            NoComprobantes:this.noComprobantes,
        });
        if (this.formatData.identificacion2) {
            this.frmD6.patchValue({
                _IdIdentificacion2: this.formatData.identificacion2._IdIdentificacion2,
                EstatusIdentificacion2: parseInt(this.formatData.identificacion2.EstatusIdentificacion2) ? '1' : '0',
                ComentarioIdentificacion2:  this.formatData.identificacion2.ComentarioIdentificacion2
            });
        }

        

        if ( ! parseInt(this.formatData.identificacion.EstatusIdentificacion) ) {
            this.frmD6.controls['ComentarioIdentificacion'].setValidators([Validators.required]);
        }

        if (this.formatData.identificacion2 && ! parseInt(this.formatData.identificacion2.EstatusIdentificacion2) ) {
            this.frmD6.controls['ComentarioIdentificacion2'].setValidators([Validators.required]);
        }

        if ( ! parseInt(this.formatData.comprobante_domicilio.EstatusComprobante) ) {
            this.frmD6.controls['ComentarioComprobante'].setValidators([Validators.required]);
        }



        if ( this.has_rfc ) {
            this.showCommentsCedulaFiscal = parseInt(this.formatData.cedula_fiscal.EstatusCedulaFiscal) == 1 ? false : true;
            this.frmD6.patchValue({
                _IdCedulaFiscal: this.formatData.cedula_fiscal._IdCedulaFiscal,
                EstatusCedulaFiscal: this.formatData.cedula_fiscal.EstatusCedulaFiscal ? '1' : '0',
                ComentarioCedulaFiscal:  this.formatData.cedula_fiscal.ComentarioCedulaFiscal,
            });
        }

        if ( this.has_garantia_auto ) {
            this.showCommentsGarantiaAuto = parseInt(this.formatData.garantia_auto.EstatusGarantiaAuto) == 1 ? false : true;

            this.frmD6.patchValue({
                _IdGarantiaAuto: this.formatData.garantia_auto ? this.formatData.garantia_auto._IdGarantiaAuto : null,
                EstatusGarantiaAuto: this.formatData.garantia_auto.EstatusGarantiaAuto ? '1' : '0',
                ComentarioGarantiaAuto: this.formatData.garantia_auto ? this.formatData.garantia_auto.ComentarioGarantiaAuto : null,
            });
        }

        if ( this.has_garantia_rpp ) {
            this.showCommentsEvidenciaInmueble = parseInt(this.formatData.evidencia.EstatusEvidenciaInmueble) == 1 ? false : true;

            this.frmD6.patchValue({
                _IdEvidenciaInmueble: this.formatData.evidencia ? this.formatData.evidencia._IdEvidenciaInmueble : null,
                EstatusEvidenciaInmueble: this.formatData.evidencia.EstatusEvidenciaInmueble ? '1' : '0',
                ComentarioEvidenciaInmueble: this.formatData.evidencia ? this.formatData.evidencia.ComentarioEvidenciaInmueble : null,
            });
        }
    }

    declinarSubmit(MotivoRechazo){
        if ( MotivoRechazo.value == '' ) {
            alert('Debe selecciona un motivo de rechazo');
            return;
        }

        let data = {MotivoRechazo: MotivoRechazo.value};

        this.utilitiesService.declinarSolicitud(this.IdSolicitud, data).subscribe(
            resp => {
                this.modalErrors.dismissAll();
                this.router.navigateByUrl("promotor/dashboard");
            },
            errors => {
            }
        );
    }

    declinar(modalDeclinar){
        this.modalErrors.open(modalDeclinar, { ariaLabelledBy: 'modal-basic-title', centered: false});
    }

    changeCommentsIdentificacion(evt){
        var target = evt.target;
        if (target.value == "1") {
            this.showCommentsIdentificacion = false;
            this.utilitiesService.removeControlsValidity(this.frmD6, ['ComentarioIdentificacion']);
        }else{
            this.showCommentsIdentificacion = true;
            this.frmD6.controls['ComentarioIdentificacion'].setValidators([Validators.required]);
        }
    }

    changeCommentsIdentificacion2(evt){
        var target = evt.target;
        if (target.value == "1") {
            this.showCommentsIdentificacion2 = false;
            this.utilitiesService.removeControlsValidity(this.frmD6, ['ComentarioIdentificacion2']);
        }else{
            this.showCommentsIdentificacion2 = true;
            this.frmD6.controls['ComentarioIdentificacion2'].setValidators([Validators.required]);
        }
    }

    changeCommentsComprobante($event){
        var target = $event.target;
        if (target.value == "1") {
            this.showCommentsComprobante = false;
            this.utilitiesService.removeControlsValidity(this.frmD6, ['ComentarioComprobante']);
        }else{
            this.showCommentsComprobante = true;
            this.frmD6.controls['ComentarioComprobante'].setValidators([Validators.required]);
        }
    }

    changeCommentsComprobanteMes($event,mes){
        var target = $event.target;
        if (target.value == "1") {
            this.showCommentsComprobanteMes[mes-1] = false;
            this.utilitiesService.removeControlsValidity(this.frmD6, ['ComentarioComprobanteMes'+mes]);
        }else{
            this.showCommentsComprobanteMes[mes-1]  = true;
            this.frmD6.controls['ComentarioComprobanteMes'+mes].setValidators([Validators.required]);
        }
    }

    changeCommentsCedula($event){
        var target = $event.target;
        if (target.value == "1") {
            this.showCommentsCedulaFiscal = false;
            this.utilitiesService.removeControlsValidity(this.frmD6, ['ComentarioCedulaFiscal']);
        }else{
            this.showCommentsCedulaFiscal = true;
            this.frmD6.controls['ComentarioCedulaFiscal'].setValidators([Validators.required]);
        }
    }

    changeCommentsGarantiaAuto($event){
        var target = $event.target;
        if (target.value == "1") {
            this.showCommentsGarantiaAuto = false;
            this.utilitiesService.removeControlsValidity(this.frmD6, ['ComentarioGarantiaAuto']);
        }else{
            this.showCommentsGarantiaAuto = true;
            this.frmD6.controls['ComentarioGarantiaAuto'].setValidators([Validators.required]);
        }
    }

    changeCommentsEvidenciaInmueble($event){
        var target = $event.target;
        if (target.value == "1") {
            this.showCommentsEvidenciaInmueble = false;
            this.utilitiesService.removeControlsValidity(this.frmD6, ['ComentarioEvidenciaInmueble']);
        }else{
            this.showCommentsEvidenciaInmueble = true;
            this.frmD6.controls['ComentarioEvidenciaInmueble'].setValidators([Validators.required]);
        }
    }

    createFormD6(){
        return this.fb.group({
            IdSolicitud:[
                null
            ],
            _IdIdentificacion:[
                null
            ],
            _IdIdentificacion2:[
                null
            ],
            _IdComprobante:[
                null
            ],
            _IdCedulaFiscal:[
                null
            ],
            _IdGarantiaAuto:[
                null
            ],
            _IdEvidenciaInmueble:[
                null
            ],
            EstatusIdentificacion:[
                null,
                Validators.required
            ],
            EstatusIdentificacion2:[
                null,
                Validators.required
            ],
            EstatusComprobante:[
                null,
                Validators.required
            ],
            EstatusCedulaFiscal:[
                null
            ],
            EstatusGarantiaAuto:[
                null
            ],
            EstatusEvidenciaInmueble:[
                null
            ],
            ComentarioIdentificacion:[
                null
            ],
            ComentarioIdentificacion2:[
                null
            ],
            ComentarioComprobante:[
                null
            ],
            ComentarioCedulaFiscal:[
                null
            ],
            ComentarioGarantiaAuto:[
                null
            ],
            ComentarioEvidenciaInmueble:[
                null
            ],
            NoIdentificacion:[
                null
            ],
            NoComprobantes:[
                null
            ],
        });
    }

    formatFileObject(obj,noComprobantes){
        var formatDataSkelleton = {
            identificacion:null,
            identificacion2:null,
            comprobante_domicilio:null,
            cedula_fiscal:null,
            garantia_auto:null,
            evidencia:null,
        }
        for (let i=1;i<=noComprobantes;i++){
            formatDataSkelleton['comprobante_mes_'+i]=null
        }
        obj.map(function(item){
            if(item.hasOwnProperty("_IdIdentificacion")){
                formatDataSkelleton.identificacion = item;
            }
            if(item.hasOwnProperty("_IdIdentificacion2")){
                formatDataSkelleton.identificacion2 = item;
            }
            if(item.hasOwnProperty("_IdComprobante")){
                formatDataSkelleton.comprobante_domicilio = item;
            }
            for (let i=1;i<=noComprobantes;i++){
                if(item.hasOwnProperty("_IdComprobanteMes"+i)){
                    formatDataSkelleton['comprobante_mes_'+i] = item;
                }
            }
            if(item.hasOwnProperty("_IdCedulaFiscal")){
                formatDataSkelleton.cedula_fiscal = item;
            }
            if(item.hasOwnProperty("_IdGarantiaAuto")){
                formatDataSkelleton.garantia_auto = item;
            }
            if(item.hasOwnProperty("_IdEvidenciaInmueble")){
                formatDataSkelleton.evidencia = item;
            }
        });
        return formatDataSkelleton;
    }

    onSubmit(modalTarget) {
        if (!this.frmD6.valid) {
            Object.keys(this.frmD6.value).forEach(element => {
              if ( this.frmD6.get(element).errors != null ) {
                this.frmD6.get(element).markAsDirty();
              }
            });
            return;
        }

        var sendData = this.frmD6.value;
        this.loading = true;

        this.D6service.sendUpdate(sendData).subscribe(
            data=>{
                if( sendData.EstatusCedulaFiscal == "1" && sendData.EstatusComprobante == "1" && sendData.EstatusComprobanteMes1 == "1" && sendData.EstatusComprobanteMes2 == "1" && sendData.EstatusComprobanteMes3 == "1" && sendData.EstatusEvidenciaInmueble == "1" && sendData.EstatusGarantiaAuto == "1" && sendData.EstatusIdentificacion == "1" && sendData.EstatusIdentificacion2 == "1" ){
                    this.D6service.sendCheckedData(this.IdSolicitud).subscribe(
                        response =>{
                            this.loading = false;
                            this.router.navigateByUrl("promotor/revision_solicitud/"+this.IdSolicitud);
                        },
                        errors =>{
                            this.loading = false;
                            var msgError = errors.error.error;
                            if (msgError.indexOf("token") > -1) {
                                localStorage.clear();
                                this.router.navigateByUrl("/login");
                            } else {
                                this.errorMessages = errors.error.message;
                                this.modalErrors.open(modalTarget, { ariaLabelledBy: 'modal-basic-title', centered: true });
                            }
                        }
                    );
                }else{
                    this.loading = false;
                    this.router.navigateByUrl("promotor/revision_solicitud/"+this.IdSolicitud);
                }
            },errors =>{
                this.loading = false;
                var msgError = errors.error.error;
                if (msgError && msgError.indexOf("token") > -1) {
                    localStorage.clear();
                    this.router.navigateByUrl("/login");
                } else {
                    this.errorMessages = errors.error.message;
                    this.modalErrors.open(modalTarget, { ariaLabelledBy: 'modal-basic-title', centered: true });
                }
            }
        );

    }
}
