import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { NgxCurrencyModule } from "ngx-currency";
import { NgxLoadingModule } from 'ngx-loading';
import { FileUploadComponent  } from "./components/shared/file-upload/file-upload.component";
import { ProgressComponent } from "./components/shared/progress/progress.component";
export const customCurrencyMaskConfig = {
  align: "left",
  allowNegative: false,
  allowZero: true,
  decimal: ".",
  precision: 2,
  prefix: "$",
  suffix: "",
  thousands: ",",
  nullable: false
};

import { DataTablesModule } from 'angular-datatables';

/* Client components import */
import { TestComponent } from './components/test/test.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { TablePromotorComponent } from "./components/shared/table/table_promotor.component";
import { ARegistroComponent } from './components/client/a-registro/a-registro.component';
import { A2InicioSesionComponent } from './components/client/a2-inicio-sesion/a2-inicio-sesion.component';
import { A01CorreoUsadoComponent } from './components/client/a01-correo-usado/a01-correo-usado.component';
import { A12DatosPersonalesComponent } from './components/client/a12-datos-personales/a12-datos-personales.component';
import { A21OlvideContraseniaComponent } from './components/client/a21-olvide-contrasenia/a21-olvide-contrasenia.component';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { B1DestinoCreditoComponent } from './components/client/b1-destino-credito/b1-destino-credito.component';
import { B2ConfirmacionRequisitosComponent } from './components/client/b2-confirmacion-requisitos/b2-confirmacion-requisitos.component';
import { B3InformacionPersonalComponent } from "./components/client/b3-informacion-personal/b3-informacion-personal.component";
import { B4AutorizarBuroComponent } from "./components/client/b4-autorizar-buro/b4-autorizar-buro.component";
import { C11InformacionFamiliarComponent } from "./components/client/c11-informacion-familiar/c11-informacion-familiar.component";
import { C2InformacionLaboralComponent } from "./components/client/c2-informacion-laboral/c2-informacion-laboral.component";
import { C13InformacionEconomicaComponent } from "./components/client/c13-informacion-economica/c13-informacion-economica.component";
import { E1InformacionPersonalComponent } from "./components/client/e1-informacion-personal/e1-informacion-personal.component";
import { E2InformacionLaboralComponent } from "./components/client/e2-informacion-laboral/e2-informacion-laboral.component";
import { E3ReferenciasComponent } from "./components/client/e3-referencias/e3-referencias.component";
import { E4ConfirmacionIdentidadComponent } from "./components/client/e4-confirmacion-identidad/e4-confirmacion-identidad.component";
import { E5DocumentacionComponent } from "./components/client/e5-documentacion/e5-documentacion.component";
import { F1InformacionAvalComponent } from './components/client/f1-informacion-aval/f1-informacion-aval.component';
import { I1CargaDocumentosComponent } from "./components/client/i1-carga-documentos/i1-carga-documentos.component";
import { K1InstruccionDepositoComponent } from "./components/client/k1-instruccion-deposito/k1-instruccion-deposito.component";
import { K2ContratosComponent } from "./components/client/k2-contratos/k2-contratos.component";
import { LDashboardComponent } from "./components/client/l-dashboard/l-dashboard.component";

/* Resolvers import */
import { FormHandlerResolver } from "./resolvers/form-handler-resolver.service";
import { AuthInterceptorService } from './interceptors/auth_interceptor.service';
import { B3Resolver } from './resolvers/B3_resolver.service';
import { ARegistro_resolver } from './resolvers/ARegistro_resolver.service';
import { C1Resolver } from './resolvers/C1_resolver.service';
import { C2Resolver } from './resolvers/C2Resolver.service';
import { E1Resolver } from './resolvers/E1Resolver.service';
import { E2Resolver } from "./resolvers/E2Resolver.service";
import { E3Resolver } from "./resolvers/E3Resolver.service";
import { E5Resolver } from "./resolvers/E5Resolver.service";
import { K1Resolver } from "./resolvers/K1Resolver.service";
import { D8PromotorResolver } from "./resolvers/D8PromotorResolver.service";
import { SolicitudResolver } from "./resolvers/SolicitudResolver.service";
import { IdSolicitudResolver } from "./resolvers/IdSolicitudResolver.service";
import { TelephoneNumberDirective } from "./directives/telephone_number";
import { LDashboardResolver } from './resolvers/LDashboardResolver.service';
import { MessagesResolver } from './resolvers/MessagesResolver.service';
import { B1SolicitudesResolver } from './resolvers/promotor/B1SolicitudesResolver.service';
import { C3RevisionSolicitudResolver } from './resolvers/promotor/C3RevisionSolicitudResolver.service';
import { D2RevisionResolver } from "./resolvers/promotor/D2RevisionResolver.service";
import { D3RevisionAcreditacionResolver } from "./resolvers/promotor/D3RevisionAcreditacionResolver.service";
import { D4AcreditacionRevisionEmpleoResolver } from './resolvers/promotor/D4RevisionAcreditacionEmpleoResolver.service';
import { D5AcreditacionRevisionAcreditacionResolver } from './resolvers/promotor/D5RevisionAcreditacionPreanalisisResolver.service';
import { D5AcreditacionRevisionReferenciasResolver } from './resolvers/promotor/D5RevisionAcreditacionReferenciasResolver.service';
import { D6RevisionAcreditacionDocumentacionResolver } from './resolvers/promotor/D6RevisionAcreditacionDocumentacionResolver.service';
import { F1CotizacionResolver } from './resolvers/promotor/F1CotizacionResolver.service';
import { H1RevisionComiteResolver } from './resolvers/promotor/H1RevisionComiteResolver.service';
import { H2CambioCondicionesResolver } from './resolvers/promotor/H2CambioCondicionesResolver.service';
import { I2RevisionContratosResolver } from './resolvers/promotor/I2RevisionContratosResolver.service';
import { J1DesembolsoResolver } from './resolvers/promotor/J1DesembolsoResolver.service';
import { K1MesaControlResolver } from './resolvers/promotor/K1MesaControlResolver.service';
import { L1TesoreriaResolver } from './resolvers/promotor/L1TesoreriaResolver.service';
import { B1CapturaInformacionResolver } from './resolvers/aval/B1CapturaInformacionResolver.service';
import { B2CapturaInformacionFiscalResolver } from './resolvers/aval/B2CapturaInformacionFiscalResolver.service';
import { SolicitudAvalPrivilegesResolver } from './resolvers/aval/SolicitudAvalPrivilegesResolver';
import { AvalPreviousDataResolver } from './resolvers/aval/AvalPreviousDataResolver.service';
import { PromotorAvalInformacionResolver } from './resolvers/promotor/PromotorAvalInformationResolver.service';
import { M1PromotorInformacionPersonalResolver } from './resolvers/promotor/M1PromotorInformacionPersonalResolver.service';
import { M2PromotorInformacionFiscalResolver } from './resolvers/promotor/M2PromotorInformacionFiscalResolver.service';
import { M3PromotorInformacionFamiliarResolver } from './resolvers/promotor/M3PromotorInformacionFamiliarResolver.service';
import { M4PromotorInformacionLaboralResolver } from './resolvers/promotor/M4PromotorInformacionLaboralResolver.service';
import { N1Resolver } from './resolvers/promotor/N1_resolver.service';
import { InfoInmuebleResolver } from './resolvers/promotor/InfoInmuebleResolver.service';
import { InfoAutoResolver } from './resolvers/promotor/InfoAutoResolver.service';

/* Handle message error import */
import { TempMessagesComponent } from "./components/client/temp-messages/temp-messages.component";
import { MessagesComponent } from './components/promotor/messages/messages.component';
import { TempMessagesErrorComponent } from "./components/client/temp-messages/temp-messages-error.component";
import { TempMessagesSuccessComponent } from "./components/client/temp-messages/temp-messages-success.component";
import { TempMessagesWaitComponent } from "./components/client/temp-messages/temp-messages-wait.component";
import { DetalleComponent } from './components/promotor/messages/detalle.component';

/* Promotor components import */
import { B1SolicitudesComponent } from "./components/promotor/b1-solicitudes/b1-solicitudes.component";
import { C1PreanalisisComponent } from "./components/promotor/c1-preanalisis/c1-preanalisis.component";
import { C2AvalesComponent } from "./components/promotor/c2-avales/c2-avales.component";
import { C3RevisionSolicitudComponent } from "./components/promotor/c3-revision-solicitud/c3-revision-solicitud.component";
import { NavegacionRevisionComponent } from "./components/shared/promotor/navegacion-revision/navegacion-revision.component";
import { NavegacionRevisionAvalComponent } from './components/shared/promotor/navegacion-revision-aval/navegacion-revision-aval.component';
import { NavegacionRevisionAvalExtraComponent } from './components/shared/promotor/navegacion-revision-aval-extra/navegacion-revision-aval-extra.component';
import { D2RevisionSolicitudComponent } from "./components/promotor/d2-revision-destino/d2-revision-destino.component";
import { D2RevisionSolicitudEditComponent } from "./components/promotor/d2-revision-destino/d2-revision-destino-edit.component";
import { D3RevisionAcreditacionComponent } from "./components/promotor/d3-revision-acreditacion/d3-revision-acreditacion.component";
import { D3RevisionAcreditacionEditComponent } from "./components/promotor/d3-revision-acreditacion/d3-revision-acreditacion-edit.component";
import { D4RevisionAcreditacionEmpleoComponent } from "./components/promotor/d4-revision-acreditacion-empleo/d4-revision-acreditacion-empleo.component";
import { D4RevisionAcreditacionEmpleoEditComponent } from "./components/promotor/d4-revision-acreditacion-empleo/d4-revision-acreditacion-empleo-edit.component";
import { D5RevisionAcreditacionPreanalisisComponent } from "./components/promotor/d5-revision-acreditacion-preanalisis/d5-revision-acreditacion-preanalisis.component";
import { D5RevisionAcreditacionPreanalisisEditComponent } from './components/promotor/d5-revision-acreditacion-preanalisis/d5-revision-acreditacion-preanalisis-edit.component';
import { D5RevisionAcreditacionReferenciasComponent } from "./components/promotor/d5-revision-acreditacion-referencias/d5-revision-acreditacion-referencias.component";
import { D5RevisionAcreditacionReferenciasEditComponent } from './components/promotor/d5-revision-acreditacion-referencias/d5-revision-acreditacion-referencias-edit.component';
import { D6RevisionAcreditacionDocumentacionComponent } from "./components/promotor/d6-revision-acreditacion-documentacion/d6-revision-acreditacion-documentacion.component";
import { D8GarantiaAutoComponent } from "./components/promotor/d8-garantia-auto/d8-garantia-auto.component";
import { D9EvidenciaInmueble } from "./components/promotor/d9-evidencia-inmueble/d9-evidencia-inmueble.component";
import { E1EntrevistaComponent } from "./components/promotor/e1-entrevista/e1-entrevista.component";
import { F1CotizacionComponent } from "./components/promotor/f1-cotizacion/f1-cotizacion.component";
import { G1LineaCreditoComponent } from "./components/promotor/g1-linea-credito/g1-linea-credito.component";
import { G2EnviarComiteComponent } from "./components/promotor/g2-enviar-comite/g2-enviar-comite.component";
import { H1RevisionComiteComponent } from "./components/promotor/h1-revision-comite/h1-revision-comite.component";
import { H2CambioCondicionesComponent } from "./components/promotor/h2-cambio-condiciones/h2-cambio-condiciones.component";
import { I1EnvioContratosComponent } from "./components/promotor/i1-envio-contratos/i1-envio-contratos.component";
import { I2RevisionContratosComponent } from "./components/promotor/i2-revision-contratos/i2-revision-contratos.component";
import { I3SubirContratosComponent } from "./components/promotor/i3-subir-contratos/i3-subir-contratos.component";
import { I4InstruccionDepositoComponent } from "./components/promotor/i4-instruccion-deposito/i4-instruccion-deposito.component";
import { J1DesembolsoComponent } from "./components/promotor/j1-desembolso/j1-desembolso.component";
import { J1DesembolsoEditComponent } from './components/promotor/j1-desembolso/j1-desembolso-edit.component';
import { K1MesaControlComponent } from './components/promotor/k1-mesa-control/k1-mesa-control.component';
import { L1TesoreriaComponent } from './components/promotor/l1-tesoreria/l1-tesoreria.component';
import { M1PromotorAvalInformacionPersonalComponent } from './components/promotor/m1-promotor-aval-informacion-personal/m1-promotor-aval-informacion-personal.component';
import { M1PromotorAvalInformacionPersonalEditComponent } from './components/promotor/m1-promotor-aval-informacion-personal/m1-promotor-aval-informacion-personal-edit.component';
import { M2PromotorAvalInformacionFiscal } from './components/promotor/m2-promotor-aval-informacion-fiscal/m2-promotor-aval-informacion-fiscal.component';
import { M2PromotorAvalInformacionFiscalEditComponent } from './components/promotor/m2-promotor-aval-informacion-fiscal/m2-promotor-aval-informacion-fiscal-edit.component';
import { M3PromotorAvalInformacionFamiliar } from './components/promotor/m3-promotor-aval-informacion-familiar/m3-promotor-aval-informacion-familiar.component';
import { M3PromotorAvalInformacionFamiliarEditComponent } from './components/promotor/m3-promotor-aval-informacion-familiar/m3-promotor-aval-informacion-familiar-edit.component';
import { M4PromotorAvalInformacionLaboralComponent } from './components/promotor/m4-promotor-aval-informacion-laboral/m4-promotor-aval-informacion-laboral.component';
import { M4PromotorAvalInformacionLaboralEditComponent } from './components/promotor/m4-promotor-aval-informacion-laboral/m4-promotor-aval-informacion-laboral-edit.component';
import { N1CambioEstructura } from './components/promotor/n1-cambio-estructura/n1-cambio-estructura.component';

/* Special promotor imports */
import { NgbdSortableHeader } from './components/shared/table/sortable.directive';
import { PromotorSidebarComponent } from "./components/promotor/sidebar/sidebar.component";
import { TempViewsComponent } from "./components/promotor/temp-views/temp-views.component";

/* Admin components import  */
import { Admin2CatalogosComponent } from "./components/admin/2-catalogos/admin-2-catalogos.component";
import { AdminParametrosGeneralesComponent } from "./components/admin/1.4-parametros-generales/admin-parametros-generales.component";
import { CatalogosRowComponent } from "./components/admin/2-catalogos/admin-2-catalogos-row.component";
import { AdminSidebarComponent } from "./components/admin/sidebar/admin-sidebar.component"

/* Aval components import */
import { B1CapturaInformacionComponent } from './components/aval/b1-captura-informacion/b1-captura-informacion.component';
import { B2CapturaInformacionFiscalComponent } from './components/aval/b2-captura-informacion-fiscal/b2-captura-informacion-fiscal.component';
import { B3CapturaInformacionBuro } from './components/aval/b3-autorizar-buro/b3-autorizar-buro.component';
import { C1InformacionPersonal } from './components/aval/c1-informacion-personal/c1-informacion-personal.component';
import { C1InformacionPersonalResolver } from './resolvers/aval/C1InformacionPersonalResolver.service';
import { C2InformacionLaboralAval } from './components/aval/c2-informacion-laboral/c2-informacion-laboral-aval.component';
import { C2InformacionLaboralResolver } from './resolvers/aval/C2InformacionLaboralResolver.service';
import { C3InformacionEconomicaComponent } from './components/aval/c3-informacion-economica/c3-informacion-economica-aval.component';
import { C4ConfirmacionIdentidadComponent } from './components/aval/c4-confirmacion-identidad/c4-confirmacion-identidad-aval.component';
import { C5DocumentacionAvalComponent } from './components/aval/c5-documentacion/c5-documentacion.component';
import { M5PromotorAvalInformacionEconomicaComponent } from './components/promotor/m5-promotor-aval-informacion-economica/m5-promotor-aval-informacion-economica.component';
import { M5PromotorAvalInformacionEconomicaEditComponent } from './components/promotor/m5-promotor-aval-informacion-economica/m5-promotor-aval-informacion-economica-edit.component';
import { TempMessagesPromotorComponent } from './components/promotor/temp-messages/temp-messages.component';
import { TempMessagesErrorPromotorComponent } from './components/promotor/temp-messages/temp-messages-error.component';
import { TempMessagesSuccessPromotorComponent } from './components/promotor/temp-messages/temp-messages-success.component';
import { TempMessagesWaitPromotorComponent } from './components/promotor/temp-messages/temp-messages-wait.component';
import { TempMessagesAvalComponent } from './components/aval/temp-messages/temp-messages.component';
import { TempMessagesSuccessAvalComponent } from './components/aval/temp-messages/temp-messages-success.component';
import { TempMessagesWaitAvalComponent } from './components/aval/temp-messages/temp-messages-wait.component';
import { TempMessagesErrorAvalComponent } from './components/aval/temp-messages/temp-messages-error.component';
import { A22RestablecerContraseniaComponent } from './components/client/a22-restablecer-contrasenia/a22-restablecer-contrasenia.component';
import { M6PromotorAvalDocumentacionComponent } from './components/promotor/m6-promotor-aval-documentacion/m6-promotor-aval-documentacion.component';
import { M6SubirDocumentacionAvalComponent } from './components/promotor/m6-promotor-aval-subir-documentacion/m6-promotor-aval-subir-documentacion.component';
import { M6PromotorDocumentacionResolver } from './resolvers/promotor/M6PromotorDocumentacionResolver.service';
import { F1InformacionClientResolver } from './resolvers/F1InformacionClientResolver.service';
import { APreguntasFrecuentes } from './components/client/a-preguntas-frecuentes/a-preguntas-frecuentes.component';
import { A23VerificarCorreoComponent } from './components/client/a23-verificar-correo/a23-verificar-correo.component';
import { A24CambioContrasenaComponent } from './components/client/a24-cambio-contrasena/a24-cambio-contrasena.component';
import { B1MotivoRechazoResolver } from './resolvers/promotor/B1MotivoRechazoResolver.service';
import { ReasignarSolicitudes } from './components/super_promotor/reasignar-solicitudes/reasignar-solicitudes.component';
import { PromotoresResolver } from './resolvers/promotor/PromotoresResolver.service';
import { D5RevisionCotizacionComponent } from './components/promotor/d5-revision-cotizacion/d5-revision-cotizacion.component';
import { PermissionsComponent } from './components/promotor/permissions/permissions.component'
import { D6SubirDocumentacionComponent } from './components/promotor/d6-subir-documentacion/d6-subir-documentacion';
import { MantenimientoComponent } from './components/mantenimiento/mantenimiento.component';


@NgModule({
    declarations: [
        AppComponent,
        TestComponent,
        TablePromotorComponent,
        ARegistroComponent,
        ProgressComponent,
        NavbarComponent,
        SidebarComponent,
        PromotorSidebarComponent,
        FileUploadComponent,
        A2InicioSesionComponent,
        A01CorreoUsadoComponent,
        A12DatosPersonalesComponent,
        A21OlvideContraseniaComponent,
        A22RestablecerContraseniaComponent,
        B1DestinoCreditoComponent,
        B2ConfirmacionRequisitosComponent,
        B3InformacionPersonalComponent,
        B4AutorizarBuroComponent,
        C11InformacionFamiliarComponent,
        C2InformacionLaboralComponent,
        C13InformacionEconomicaComponent,
        E1InformacionPersonalComponent,
        E2InformacionLaboralComponent,
        E3ReferenciasComponent,
        E4ConfirmacionIdentidadComponent,
        E5DocumentacionComponent,
        F1InformacionAvalComponent,
        I1CargaDocumentosComponent,
        K1InstruccionDepositoComponent,
        K2ContratosComponent,
        TempMessagesComponent,
        MessagesComponent,
        TempMessagesErrorComponent,
        TempMessagesSuccessComponent,
        TempMessagesWaitComponent,
        LDashboardComponent,
        TelephoneNumberDirective,
        B1SolicitudesComponent,
        NgbdSortableHeader,
        C1PreanalisisComponent,
        C2AvalesComponent,
        C3RevisionSolicitudComponent,
        NavegacionRevisionComponent,
        NavegacionRevisionAvalComponent,
        NavegacionRevisionAvalExtraComponent,
        D2RevisionSolicitudComponent,
        D2RevisionSolicitudEditComponent,
        D3RevisionAcreditacionComponent,
        D3RevisionAcreditacionEditComponent,
        D4RevisionAcreditacionEmpleoComponent,
        D4RevisionAcreditacionEmpleoEditComponent,
        D5RevisionAcreditacionPreanalisisComponent,
        D5RevisionAcreditacionPreanalisisEditComponent,
        D5RevisionAcreditacionReferenciasComponent,
        D5RevisionAcreditacionReferenciasEditComponent,
        D6RevisionAcreditacionDocumentacionComponent,
        D6SubirDocumentacionComponent,
        D8GarantiaAutoComponent,
        D9EvidenciaInmueble,
        E1EntrevistaComponent,
        F1CotizacionComponent,
        G1LineaCreditoComponent,
        G2EnviarComiteComponent,
        H1RevisionComiteComponent,
        H2CambioCondicionesComponent,
        I1EnvioContratosComponent,
        I2RevisionContratosComponent,
        I3SubirContratosComponent,
        I4InstruccionDepositoComponent,
        J1DesembolsoComponent,
        J1DesembolsoEditComponent,
        K1MesaControlComponent,
        L1TesoreriaComponent,
        TempMessagesPromotorComponent,
        TempMessagesWaitPromotorComponent,
        TempMessagesErrorPromotorComponent,
        TempMessagesSuccessPromotorComponent,
        M1PromotorAvalInformacionPersonalComponent,
        M1PromotorAvalInformacionPersonalEditComponent,
        M2PromotorAvalInformacionFiscal,
        M2PromotorAvalInformacionFiscalEditComponent,
        M3PromotorAvalInformacionFamiliar,
        M3PromotorAvalInformacionFamiliarEditComponent,
        M4PromotorAvalInformacionLaboralComponent,
        M4PromotorAvalInformacionLaboralEditComponent,
        M5PromotorAvalInformacionEconomicaComponent,
        M5PromotorAvalInformacionEconomicaEditComponent,
        M6PromotorAvalDocumentacionComponent,
        M6SubirDocumentacionAvalComponent,
        N1CambioEstructura,
        TempViewsComponent,
        AdminSidebarComponent,
        Admin2CatalogosComponent,
        CatalogosRowComponent,
        AdminParametrosGeneralesComponent,
        B1CapturaInformacionComponent,
        B2CapturaInformacionFiscalComponent,
        B3CapturaInformacionBuro,
        C1InformacionPersonal,
        C2InformacionLaboralAval,
        C3InformacionEconomicaComponent,
        C4ConfirmacionIdentidadComponent,
        C5DocumentacionAvalComponent,
        TempMessagesAvalComponent,
        TempMessagesSuccessAvalComponent,
        TempMessagesWaitAvalComponent,
        TempMessagesErrorAvalComponent,
        APreguntasFrecuentes,
        A23VerificarCorreoComponent,
        A24CambioContrasenaComponent,
        ReasignarSolicitudes,
        D5RevisionCotizacionComponent,
        PermissionsComponent,
        MantenimientoComponent,
        DetalleComponent,
    ],
    imports: [BrowserModule, HttpClientModule, AppRoutingModule, FormsModule, ReactiveFormsModule, DataTablesModule ,NgbModule, 
      StoreModule.forRoot(reducers, {
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true
        }
      }),
      NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
      NgxLoadingModule.forRoot({})
    ],providers: [
      FormHandlerResolver,
      B3Resolver,
      C1Resolver,
      ARegistro_resolver,
      C2Resolver,
      E1Resolver,
      E2Resolver,
      F1InformacionClientResolver,
      IdSolicitudResolver,
      E3Resolver,
      LDashboardResolver,
      MessagesResolver,
      E5Resolver,
      K1Resolver,
      SolicitudResolver,
      B1SolicitudesResolver,
      B1MotivoRechazoResolver,
      C3RevisionSolicitudResolver,
      D8PromotorResolver,
      D2RevisionResolver,
      D3RevisionAcreditacionResolver,
      D4AcreditacionRevisionEmpleoResolver,
      D5AcreditacionRevisionAcreditacionResolver,
      D5AcreditacionRevisionReferenciasResolver,
      D6RevisionAcreditacionDocumentacionResolver,
      F1CotizacionResolver,
      H1RevisionComiteResolver,
      H2CambioCondicionesResolver,
      I2RevisionContratosResolver,
      J1DesembolsoResolver,
      K1MesaControlResolver,
      L1TesoreriaResolver,
      B1CapturaInformacionResolver,
      B2CapturaInformacionFiscalResolver,
      C1InformacionPersonalResolver,
      C2InformacionLaboralResolver,
      SolicitudAvalPrivilegesResolver,
      AvalPreviousDataResolver,
      PromotorAvalInformacionResolver,
      M1PromotorInformacionPersonalResolver,
      M2PromotorInformacionFiscalResolver,
      M3PromotorInformacionFamiliarResolver,
      M4PromotorInformacionLaboralResolver,
      M6PromotorDocumentacionResolver,
      N1Resolver,
      InfoInmuebleResolver,
      InfoAutoResolver,
      PromotoresResolver,
      { 
        provide: LocationStrategy, 
        useClass: HashLocationStrategy 
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptorService,
        multi: true
      } 
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}