const COMPLEMENTO_INGRESOS = 5;
const PROVEEDOR_RECURSOS = 4;
const CONYUGE = 9;
const ESTUDIANTE_CON_BURO = 3;
const ESTUDIANTE_SIN_BURO = 2;
const GARANTE_AUTO = 7;
const GARANTE_RPP = 8;
const REFORZAR_BURO = 6;

export const avalFilePermissions = {
    Identificacion:[COMPLEMENTO_INGRESOS,CONYUGE,ESTUDIANTE_CON_BURO,ESTUDIANTE_SIN_BURO,PROVEEDOR_RECURSOS,REFORZAR_BURO,GARANTE_AUTO,GARANTE_RPP],
    Identificacion2:[COMPLEMENTO_INGRESOS,CONYUGE,ESTUDIANTE_CON_BURO,ESTUDIANTE_SIN_BURO,PROVEEDOR_RECURSOS,REFORZAR_BURO,GARANTE_AUTO,GARANTE_RPP],
    ComprobanteDomicilio:[COMPLEMENTO_INGRESOS,CONYUGE,ESTUDIANTE_CON_BURO,ESTUDIANTE_SIN_BURO,PROVEEDOR_RECURSOS,REFORZAR_BURO,GARANTE_AUTO,GARANTE_RPP],
    ComprobanteIngresos:[COMPLEMENTO_INGRESOS,PROVEEDOR_RECURSOS],
    GarantiaAuto:[GARANTE_AUTO],
    GarantiaRPP:[GARANTE_RPP],
    comprobacion_identidad:[COMPLEMENTO_INGRESOS,PROVEEDOR_RECURSOS,ESTUDIANTE_CON_BURO]//se agrega roles para que se muestre el comprobante de identidad para el val al momento de subir documentos por el Promotor
}
