import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Data } from '@angular/router';
import { K2ContratosService } from 'src/app/services/K2ContratosService.service';

@Component({
    selector: 'app-temp-messages',
    templateUrl: './messages.component.html'
})
export class MessagesComponent implements OnInit {
    public dataContrato: any;
    public Contratos: any;
    public data: any;
    public totalScore: number;
    public score: any;
    public burosc: number;
    public proceso: any;
    public proceso_cliente: any;
    public rejected: boolean;
    public IdSolicitud: number;

    constructor(
        private route: ActivatedRoute,
        private K2Service: K2ContratosService,
    ) {
        this.rejected = false;
    }

    ngOnInit() {
        this.route.data.subscribe((data: Data) => {
            this.IdSolicitud = data['solicitudPromotor'].data.IdSolicitud;
            this.dataContrato = data['solicitudPromotor'].data.Contrato;
            this.Contratos = data['solicitudPromotor'].data.revision_documentacion_legales;
            this.proceso = data['solicitudPromotor'].proceso;
            this.proceso_cliente = data['solicitudPromotor'].data.proceso_cliente;
            this.data = data['collections'][0].data; 
        });


        if ( this.proceso.flag == 0 && this.proceso_cliente.flag == 0) {
            this.rejected = true;
        }
        else{
            this.rejected = false;
        }


        this.data.variablesScore.forEach(score => {
            this.totalScore = this.totalScore + parseInt(score.Puntos);
        });

        this.score = this.route.snapshot.data.solicitudPromotor.data.variable;

        this.burosc = this.route.snapshot.data.solicitudPromotor.data.burosc;
    }

    getDocumentAvalBuro(IdPersona){
        this.K2Service.downloadBuroAval(this.IdSolicitud, IdPersona).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Buro Aval.pdf');
                a.click();
            },
            errors => {
            },
          )
    }

    downloadBuro(){
        this.K2Service.downloadBuro(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Documento de buro.pdf');
                a.click();
            },
            errors => {
            },
          )
    }
}
