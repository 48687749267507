import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Collection } from 'src/app/models/collection';
import { CollectionsService } from 'src/app/services/colecctions.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { M2PromotorInformacionFiscalService } from 'src/app/services/promotor/M2PromotorInformacionFiscalService.service';
import { UtilitiesAvalService } from 'src/app/services/aval/UtilitiesAvalService.service';

@Component({
    selector: 'app-m2-promotor-aval-informacion-fiscal',
    templateUrl: './m2-promotor-aval-informacion-fiscal.component.html',
})

export class M2PromotorAvalInformacionFiscal implements OnInit, AfterViewInit {
    public status: number;
    public IdSolicitud: number;
    public IdPersona: number;
    public tipoAval: number;
    public editMode:boolean;
    public errorMessages: string;
    public loading: boolean;

    /* COllections for  data*/
    public dataPersonSolicitud: any;
    public collectionsRegimenFiscal: any;

    /* Collections for component data */
    public data: any;

    /* Strings */

    public RegimenFiscal: string;

    public permissionsAval: any;
    public canEdit: boolean;
    public canDecline: boolean;

    constructor(
        private route: ActivatedRoute,
        private collectionsService: CollectionsService,
        private utilities: UtilitiesService,
        private M2Service: M2PromotorInformacionFiscalService,
        private router: Router,
        private avalService: UtilitiesAvalService,
    ) {
        this.status = 2;
        this.canEdit = false;
        this.canDecline = false;
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    ngOnInit() {
        this.IdSolicitud = this.route.snapshot.params.id_solicitud;
        this.IdPersona = this.route.snapshot.params.id_persona;
        this.tipoAval = this.route.snapshot.params.tipo_aval;
        this.editMode = false;
        this.route.data.subscribe((data: Data) => {
            this.collectionsRegimenFiscal = data['collections'][0].data;
            this.data = data['data'][0].data.informacion_fiscal;
            this.dataPersonSolicitud = data['data'][0].data.informacion_propietario;
        });
        this.canEdit = this.utilities.hasPermission('Edicion informacion');
        this.canDecline = this.utilities.hasPermission('Declinar Solicitud');
        
        this.RegimenFiscal = this.utilities.getFieldFromCollection(this.collectionsRegimenFiscal, this.data.IdRegimenFiscal, "name");
        
        this.permissionsAval = this.avalService.getPermissions(this.tipoAval);
    }

    triggerEdit(){
        this.editMode = true;
    }

    recibirCancelacion($event){
        this.editMode = false;
    }

    onSubmit(modalError){
        this.loading = true;
        this.M2Service.setDataComplete(this.IdSolicitud,this.IdPersona).subscribe(
            resp=>{
                if ( this.permissionsAval.indexOf('InformacionFamiliar') > 0 ) {
                    this.router.navigateByUrl('promotor/revision/aval/familiar/'+this.IdSolicitud+'/'+this.IdPersona+'/'+this.tipoAval);
                }
                else{
                    if ( this.permissionsAval.indexOf('InformacionLaboral') >  0 ) {
                        this.router.navigateByUrl('promotor/revision/aval/laboral/'+this.IdSolicitud+'/'+this.IdPersona+'/'+this.tipoAval);
                    }
                    else{
                        if ( this.permissionsAval.indexOf('InformacionEconomica') > 0 ) {
                            this.router.navigateByUrl('promotor/revision/aval/economica/'+this.IdSolicitud+'/'+this.IdPersona+'/'+this.tipoAval);
                        }
                        else{
                            if ( this.permissionsAval.indexOf('Documentacion') > 0 ) {
                                this.router.navigateByUrl('promotor/revision/aval/documentacion/'+this.IdSolicitud+'/'+this.IdPersona+'/'+this.tipoAval);
                            }
                            else{
                                this.router.navigateByUrl('promotor/revision/aval/documentacion/'+this.IdSolicitud+'/'+this.IdPersona+'/'+this.tipoAval);
                            }
                        }
                    }
                }
                this.loading = false;
            }
        );
    }
    
    recibirGuardado($event){
        this.editMode = false;
        this.data = $event.data;
        this.data.TieneNoSerieFiel = ! this.data.TieneNoSerieFiel;
        this.RegimenFiscal = this.utilities.getFieldFromCollection(this.collectionsRegimenFiscal, $event.data.IdRegimenFiscal, "name");
    }
}