import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { CollectionsService } from "../../services/colecctions.service";
import { Collection } from '../../models/collection.model';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilitiesAvalService } from 'src/app/services/aval/UtilitiesAvalService.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AvalPreviousDataResolver implements Resolve<Collection>{
    public IdSolicitud: number;
    constructor (private avalServices: UtilitiesAvalService, private http:HttpClient, private router: Router){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<any>{
        this.IdSolicitud = route.params.id
        let test = forkJoin(
            this.avalServices.getPreviousData(this.IdSolicitud),
        );

       let temp = test;

        temp.subscribe(
            res => {
                if ( res[0].data.avales_id.indexOf(localStorage['IdPerson']) == -1 ) {
                    //if ( res[0].data.avales_id.indexOf(parseInt(localStorage['IdPerson'])) == -1 ) {
                        this.router.navigateByUrl("client/dashboard");
                    //}
                }
            },
            error => {
                this.router.navigateByUrl("client/dashboard");
            }
        );

        return test;
    }
}
