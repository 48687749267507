import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { Collection } from '../models/collection.model';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { B1Model } from '../models/B1.model';
import { environment } from '../../environments/environment';
import { delay } from 'q';
import { tap, catchError, mergeMap } from 'rxjs/operators';
import { UtilitiesService } from '../services/utilities_services.service';

const ERROR_STATUS = "0";
const PENDING_STATUS = "1";
const APPROVE_STATUS = "2";

@Injectable()
export class IdSolicitudResolver implements Resolve<number>{
  public IdSolicitud: number;
  public can_access: number;
  public principal_stage: number;
  public sub_stage: number;
  public flag: number;
  public dataB1: any;
  public dataB2: any;
  public dataB3: any;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private utilities: UtilitiesService
  ) {
    this.dataB1 = new Object();
    this.dataB2 = new Object();
    this.dataB3 = new Object();
  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, ): Observable<any> | Promise<any> | any {
    this.IdSolicitud = parseInt(route.paramMap.get('id'));
    return this.http.get<Collection>(environment.API_URL + 'solicitud/' + this.IdSolicitud).pipe(
      catchError(error => {
        var msgError = error.error.error;
        if (msgError) {
          if (msgError.indexOf("token") > -1) {
            localStorage.clear();
            this.router.navigateByUrl("/login");
          }
        }
        return [];
      }
      ), mergeMap(resp => {
        if (resp) {
          if (resp['proceso'] === null) {
            this.router.navigate(['client/destino_credito'])
          }
          this.can_access = resp['data']; // Hace refenecia a que pueda ver/editar la solicitud, osea sea suya
          this.principal_stage = Number(resp['proceso']['etapa']);
          this.sub_stage = Number(resp['proceso']['paso']);
          this.flag = Number(resp['proceso']['flag']);

          if ( this.utilities.isClient() ) {
            if ( resp['data'].dueno != parseInt(localStorage['IdPerson'])  ) {
              this.router.navigateByUrl("client/dashboard");
            }
            else{
              delete resp['data'].dueno;
              delete resp['data'].avales_id;
              let is_empty = true
              for(var key in resp['data']) {
                if(resp['data'].hasOwnProperty(key))
                    is_empty = false;
              }

              if ( is_empty ) {
                resp['data'] = null;
              }
            }
          }


          // TODO: Switch para saber el juego de status y saber a que vista va a 
          switch (this.principal_stage) {
            case 2:
              if (this.can_access) {
                if (this.flag === 3 && this.sub_stage === 4) {
                  this.router.navigate(['client/messages/' + this.IdSolicitud])
                } else {
                  if (this.flag === 0) {
                    this.router.navigate(['client/messages/' + this.IdSolicitud])
                  } else {
                    if (state.url.includes('client/destino_credito')) { // peso 1
                    }
                    else {
                      if (state.url.includes('client/requisitos_credito')) {
                        if (2 > this.sub_stage) {
                          this.router.navigate(['client/destino_credito/' + this.IdSolicitud])
                        }
                      }
                      else {
                        if (state.url.includes('client/informacion_personal')) {
                          if (3 > this.sub_stage) {
                            switch (this.sub_stage) {
                              case 1:
                                this.router.navigate(['client/destino_credito/' + this.IdSolicitud])
                                break;

                              default:
                                this.router.navigate(['client/requisitos_credito/' + this.IdSolicitud])
                                break;
                            }
                          }
                        }
                        else {
                          if (state.url.includes('client/autorizar_buro')) {
                            if (4 > this.sub_stage) {
                              switch (this.sub_stage) {
                                case 1:
                                  this.router.navigate(['client/destino_credito/' + this.IdSolicitud])
                                  break;

                                case 2:
                                  this.router.navigate(['client/requisitos_credito/' + this.IdSolicitud])
                                  break;

                                default:
                                  this.router.navigate(['client/informacion_personal/' + this.IdSolicitud])
                                  break;
                              }
                            }
                          }
                        }
                      }
                    }
                  }

                }
              } else {
                this.router.navigate(['client/dashboard/']);
              }
              break;
            case 3:
              if (!this.can_access) {
                if (state.url.includes('client/informacion_familiar')) {
                } else {
                  if (state.url.includes('client/informacion_laboral')) {
                    if (2 > this.sub_stage) {
                      this.router.navigate(['client/informacion_familiar/' + this.IdSolicitud])
                    }
                  } else {
                    if (state.url.includes('client/informacion_economica')) {
                      if (3 > this.sub_stage) {
                        switch (this.sub_stage) {
                          case 1:
                            this.router.navigate(['client/informacion_familiar/' + this.IdSolicitud])
                            break;
                          default:
                            this.router.navigate(['client/informacion_laboral/' + this.IdSolicitud])
                            break;
                        }
                      }
                    }
                  }
                }
              } else {
                if (state.url.includes('client/informacion_laboral')) {
                  if (2 > this.sub_stage) {
                    this.router.navigate(['client/informacion_familiar/' + this.IdSolicitud])
                  }
                } else {
                  if (state.url.includes('client/informacion_economica')) {
                    if (3 > this.sub_stage) {
                      switch (this.sub_stage) {
                        case 1:
                          this.router.navigate(['client/informacion_familiar/' + this.IdSolicitud])
                          break;
                        default:
                          this.router.navigate(['client/informacion_laboral/' + this.IdSolicitud])
                          break;
                      }
                    }
                  }
                }

              }
              break;

            case 4:
              if (!this.can_access) {
                if (state.url.includes('client/informacion_personal_extra')) {
                }
              } else {
                if (state.url.includes('client/informacion_laboral_extra')) {
                  if (2 > this.sub_stage) {
                    this.router.navigate(['client/informacion_personal_extra/' + this.IdSolicitud])
                  }
                }
                else{
                  if (state.url.includes('client/referencias')) {
                    if (3 > this.sub_stage) {
                      this.router.navigate(['client/informacion_laboral_extra/' + this.IdSolicitud])
                    }
                  }
                  else{
                    if (state.url.includes('client/confirmacion_identidad')) {
                      if (4 > this.sub_stage) {
                        this.router.navigate(['client/referencias/' + this.IdSolicitud])
                      }
                    }
                    else{
                      if (state.url.includes('client/documentacion')) {
                        if (5 > this.sub_stage) {
                          this.router.navigate(['client/confirmacion_identidad/' + this.IdSolicitud])
                        }
                      }
                    }
                  }
                }
              }

            default:
              // TODO: Redirigir a lo que devuelva el servicio de redirect o routing
              break;
          }


          return of(resp)
        } else {
          return [];
        }
      })
    )
  }

  handleRedirect(process): string {
    let redirect: string = "";
    switch (process.etapa) {
      /* Paso 1 Consulta a buró*/
      case "2":
        switch (process.paso) {
          case "1":
            redirect = "client/destino_credito";
            break;
          case "2":
            redirect = "client/requisitos_credito";
            break;
          case "3":
            redirect = "client/informacion_personal";
            break;
          case "4":
            if (process.flag !== null) {
              /* Falta preguntar como van a venir los tipos de flag (si vamos a   tener un diccionario de constantes o alguna otra cosa) */
              switch (process.flag) {
                case ERROR_STATUS:
                  redirect = "client/messages/rechazado";
                  break;
                case PENDING_STATUS:
                  redirect = "client/messages/pendiente";
                  break;
                case APPROVE_STATUS:
                  redirect = "client/messages/aprobado";
                  break;
                default:
                  break;
              }
            } else {
              redirect = "client/autorizar_buro";
            }
            break;
          default:
            break;
        }
        break;
      /* Paso 2 Preaprobación*/
      case "3":
        switch (process.paso) {
          case "1":
            redirect = "client/informacion_familiar";
            break;
          case "2":
            if (process.flag !== null) {
              /* Falta preguntar como van a venir los tipos de flag (si vamos a tener un diccionario de constantes o alguna otra cosa) */
              switch (process.flag) {
                case ERROR_STATUS:
                  redirect = "client/messages";
                  break;
                default:
                  break;
              }
            } else {
              redirect = "client/informacion_laboral";
            }
            break;
          case "3":
            if (process.flag !== null) {
              /* Falta preguntar como van a venir los tipos de flag (si vamos a tener un diccionario de constantes o alguna otra cosa) */
              switch (process.flag) {
                case ERROR_STATUS:
                  redirect = "client/messages";
                  break;
                case PENDING_STATUS:
                  redirect = "client/messages";
                  break;
                case APPROVE_STATUS:
                  redirect = "client/messages";
                  break;
                default:
                  break;
              }
            } else {
              redirect = "client/informacion_economica";
            }
            break;
          default:
            break;
        }
        break;
      case "4":
        switch (process.paso) {
          case "1":
            redirect = "client/informacion_personal_extra";
            break;
          case "2":
            redirect = "client/informacion_laboral_extra";
            break;
          case "3":
            redirect = "client/referencias";
            break;
          case "4":
            redirect = "client/confirmacion_identidad";
            break;
          case "5":
            if (process.flag !== null) {
              /* Falta preguntar como van a venir los tipos de flag (si vamos a   tener un diccionario de constantes o alguna otra cosa) */
              switch (process.flag) {
                case ERROR_STATUS:
                  redirect = "client/messages";
                  break;
                case PENDING_STATUS:
                  redirect = "client/messages";
                  break;
                case APPROVE_STATUS:
                  redirect = "client/messages";
                  break;
                default:
                  break;
              }
            } else {
              redirect = "client/documentacion";
            }
            break;

          default:
            break;
        }
        break;
      case "5":
        switch (process.paso) {
          case "1":
            if (process.flag !== null) {
              /* Falta preguntar como van a venir los tipos de flag (si vamos a tener un diccionario de constantes o alguna otra cosa) */
              switch (process.flag) {
                case ERROR_STATUS:
                  redirect = "client/carga_documentos";
                  break;
                case APPROVE_STATUS:
                  redirect = "client/messages";
                  break;
                default:
                  break;
              }
            }
            break;
          default:
            break;
        }
      case "6":
        switch (process.paso) {
          case "1":
            redirect = "client/instruccion_deposito";
            break;
          case "2":
            switch (process.flag) {
              case ERROR_STATUS:
                redirect = "client/messages";
                break;
              case PENDING_STATUS:
                redirect = "client/messages";
                break;
              case APPROVE_STATUS:
                redirect = "client/messages";
                break;
              default:
                break;
            }
          default:
            break;
        }
        break;
      default:
        redirect = "client/dashboard"
        break;
    }

    return redirect;
  }
}