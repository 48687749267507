import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from "@angular/core";
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Collection } from 'src/app/models/collection';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { PromotorD2RevisionDestino } from 'src/app/services/promotor/D2DestinoCredito.service';
import { ActivatedRoute, Router, Data } from '@angular/router';

@Component({
    selector: 'app-d2-revision-destino-edit',
    templateUrl: './d2-revision-destino-edit.component.html',
})

export class D2RevisionSolicitudEditComponent implements OnInit, OnDestroy {
    @Output() eventoCancelado = new EventEmitter<any>();
    @Output() datosGuardados = new EventEmitter<any>();
    @Input('collectionsDestinoCredito') collectionsDestinoCredito: any;
    @Input('collectionsGeneros') collectionsGeneros: any;
    @Input('collectionsPaises') collectionsPaises: any;
    @Input('collectionsPeriodos') collectionsPeriodos: any;
    @Input('previousData') previousData: any;
    public tempCollections: any = [];
    public data: any;
    public selected_one_value: boolean;
    public validSchool = [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
    public requiredSchool: boolean;
    public frmD2: FormGroup;
    public changed: boolean;
    public _prevSelected: boolean;
    public errorMessages: string = "";
    public loading: boolean;
    public IdSolicitud: number;
    //public collectionsAlianza: Collection;
    //public nombreAlianza: string;


    constructor(
        private fb: FormBuilder,
        private utilitiesService: UtilitiesService,
        private modalErrors: NgbModal,
        private D2Service: PromotorD2RevisionDestino,
        private route: ActivatedRoute,
        private router: Router,
    ) {
        this.requiredSchool = false;
        this.loading = false;
    }

    ngOnInit(): void {
        this.frmD2 = this.createD2Form();
        this.IdSolicitud = this.route.snapshot.params.id;
        this.frmD2.patchValue({
            IdSolicitud: this.IdSolicitud
        })
        this.data = { ...this.previousData };

        /*this.route.data.subscribe((data: Data) => {
         this.collectionsAlianza = data['collections'][5].data;
        });
        this.nombreAlianza = this.utilitiesService.getNameAlianzaFromId(this.collectionsAlianza,this.previousData.IdAlianza);
        if (typeof (this.data.IdAlianza) == 'undefined') {
            this.data.IdAlianza = 0;
            this._prevSelected = false;
            this.frmD2.patchValue(this.data);
          }*/
        this.selected_one_value = true;
        if (this.data.IdDestinoCredito) {
            var dest = [];
            if (typeof this.data.IdDestinoCredito != "object") {
                this.tempCollections = this.data.IdDestinoCredito.split(',');
                this.tempCollections.forEach(d => {
                    dest.push(Number(d));
                });
            } else {
                this.tempCollections = this.data.IdDestinoCredito;
                this.tempCollections.map(function (element) {
                    dest.push(Number(element));
                })
            }

            this.tempCollections = dest;

            this.data.IdDestinoCredito = dest;

            this.data.IdDestinoCredito.forEach(element => {
                if (this.validSchool.indexOf(element) > -1) {
                    this.requiredSchool = true;
                }
            });

        }

        if (this.data.FechaNacimientoEstudiante != null && typeof this.data.FechaNacimientoEstudiante != "object") {
            const [year, month, day] = this.data.FechaNacimientoEstudiante.split('-');
            const obj = {
                year: parseInt(year), month: parseInt(month), day:
                    parseInt(day.split(' ')[0].trim())
            };
            this.data.FechaNacimientoEstudiante = obj;
        }

        if (this.data.InicioPrograma != null && typeof this.data.InicioPrograma != "object") {
            const [year, month, day] = this.data.InicioPrograma.split('-');
            const obj = {
                year: parseInt(year), month: parseInt(month), day:
                    parseInt(day.split(' ')[0].trim())
            };
            this.data.InicioPrograma = obj;
        }

        if (this.data.CreditoOtraPersona == null || this.data.CreditoOtraPersona == "1") {
            this.data.CreditoOtraPersona = "1";
            this._prevSelected = true;
        } else {
            this.data.CreditoOtraPersona = "0";
            this._prevSelected = false;
        }

        this.frmD2.patchValue(this.data)
        
    }

    ngOnDestroy() {
        this.data = null;
    }

    onSubmit(modalTarget) {
        
        let FormatFechaInicio = "";
        let FormatFechaEstudiante = "";
        if (this.requiredSchool) {
            FormatFechaInicio = this.utilitiesService.parseDate(this.frmD2.controls['InicioPrograma'].value);
        }

        if (this.frmD2.controls['FechaNacimientoEstudiante'].value != null) {
            FormatFechaEstudiante = this.utilitiesService.parseDate(this.frmD2.controls['FechaNacimientoEstudiante'].value);
        }

        this.frmD2.patchValue({
            IdDestinoCredito: this.tempCollections.join(),
        });

        if (!this.frmD2.valid) {
            Object.keys(this.frmD2.value).forEach(element => {
              if ( this.frmD2.get(element).errors != null ) {
                this.frmD2.get(element).markAsDirty();
              }
            });
            return;
          }

        var sendData: any = this.frmD2.value;

        sendData.InicioPrograma = FormatFechaInicio;
        sendData.FechaNacimientoEstudiante = FormatFechaEstudiante;

        this.loading = true;
        this.D2Service.sendUpdate(sendData).subscribe(
            response => {
                this.loading = false;
                this.datosGuardados.emit(response)
            },
            errors => {
                this.loading = false;
                var msgError = errors.error.error;
                if (msgError.indexOf("token") > -1) {
                    localStorage.clear();
                    this.router.navigateByUrl("/login");
                } else {
                    this.errorMessages = errors.error.message;
                    this.modalErrors.open(modalTarget, { ariaLabelledBy: 'modal-basic-title', centered: true });
                }
            }
        );

    }

    handleChangeBeneficent(evt) {
        this.changed = true;
        var target = evt.target;
        if (parseInt(target.value) == 0) {
            this._prevSelected = false;
            let controls = ['NombreEstudiante', 'IdGeneroEstudiante', 'FechaNacimientoEstudiante', 'EmailEstudiante'];
            this.utilitiesService.removeControlsValidity(this.frmD2, controls);
        } else {
            this._prevSelected = true;
            let controls = {
                NombreEstudiante: [
                    null,
                    Validators.required
                ],
                IdGeneroEstudiante: [
                    null,
                    [Validators.required, Validators.min(1)]
                ],
                FechaNacimientoEstudiante: [
                    null,
                    Validators.required
                ],
                EmailEstudiante: [
                    null,
                    Validators.required
                ],
            }
            //this.frmD2.get('IdAlianza').setValidators([Validators.required, Validators.min(1)]);
            this.frmD2.get('IdGeneroEstudiante').setValidators([Validators.required, Validators.min(1)]);
            this.utilitiesService.addControlsValidity(this.fb, controls);

        }
    }


    checkedDestinoCredito(id) {
        if (this.data && this.data.IdDestinoCredito && this.data.IdDestinoCredito.indexOf(Number(id)) != -1) {
            return "checked";
        }
    }

    onChangeCategory(evt, cat: Collection) {
        this.changed = true;
        var target = evt.target;

        if (target.checked) {
            this.tempCollections.push(Number(cat.id));
        } else {
            var index = this.tempCollections.indexOf(Number(cat.id));

            if (index > -1) {
                this.tempCollections.splice(index, 1);
            }
        }

        if (this.tempCollections.length > 0) {
            this.selected_one_value = true;
        }
        else {
            this.selected_one_value = false;
        }

        if (this.tempCollections.length > 3) {
            if (target.checked) {
                this.tempCollections.pop(Number(cat.id));

                target.checked = false;
            }
        }

        this.requiredSchool = false;

        this.tempCollections.forEach(element => {
            if (this.validSchool.indexOf(element) > -1) {
                this.requiredSchool = true;
            }
        });

        if (!this.requiredSchool) {
            let controls = ['Escuela', 'InicioPrograma', 'Duracion', 'IdPeriodicidad', 'PaisEstudios', 'CiudadEstudios'];
            this.utilitiesService.removeControlsValidity(this.frmD2, controls);
        }
        else {
            let controls = {
                Escuela: [
                    null,
                    Validators.required
                ],
                InicioPrograma: [
                    null,
                    Validators.required
                ],
                Duracion: [
                    null,
                    Validators.required
                ],
                IdPeriodicidad: [
                    null,
                    [Validators.required, Validators.min(1)]
                ],
                PaisEstudios: [
                    null,
                    [Validators.required, Validators.min(1)]
                ],
                CiudadEstudios: [
                    null,
                    Validators.required
                ],
            }
            //this.nombreAlianza = this.utilitiesService.getNameAlianzaFromId(this.collectionsAlianza,evt.data.IdAlianza);

            this.frmD2.get('IdPeriodicidad').setValidators([Validators.required, Validators.min(1)]);
            this.frmD2.get('PaisEstudios').setValidators([Validators.required, Validators.min(1)]);
            this.utilitiesService.addControlsValidity(this.fb, controls);
        }

    }


    createD2Form(): FormGroup {
        return this.fb.group(
            {
                IdEstatusSolicitud: [
                    null
                ],
                MontoSolicitado: [
                    null
                ],
                Plazo: [
                    null
                ],
                IdDestinoCredito: [
                    null,
                    Validators.required
                ],
                IdAlianza: [
                    null
                ],
                Agencia: [
                    null
                ],
                Escuela: [
                    null
                ],
                InicioPrograma: [
                    null
                ],
                Duracion: [
                    null
                ],
                IdPeriodicidad: [
                    null
                ],
                PaisEstudios: [
                    null
                ],
                CiudadEstudios: [
                    null
                ],
                CreditoOtraPersona: [
                    null
                ],
                NombreEstudiante: [
                    null
                ],
                IdGeneroEstudiante: [
                    null
                ],
                FechaNacimientoEstudiante: [
                    null
                ],
                EmailEstudiante: [
                    null
                ],
                IdPlan: [
                    null
                ],
                MontoMinimo: [
                    null
                ],
                MontoMaximo: [
                    null
                ],
                PlazoMin: [
                    null
                ],
                PlazoMax: [
                    null
                ],
                AplicaDisposiciones: [
                    null
                ],
                IdCotizacion: [
                    null
                ],
                MontoPago: [
                    null
                ],
                NumeroDisposiciones: [
                    null
                ],
                IdPeriodicidadDisposiciones: [
                    null
                ],
                nextStep: [
                    null
                ],
                IdSolicitud: [
                    null
                ]
            }
        );
    }

    parentEmitCancelEditionMode(): void {
        this.eventoCancelado.emit();
    }


}