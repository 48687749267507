import { Component, OnInit, Input } from '@angular/core';
import { UtilitiesService } from 'src/app/services/utilities_services.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  @Input('status') status: any;
  @Input('proceso') proceso: any;
  @Input('IdSolicitud') IdSolicitud: any;
  @Input('solicitudAval') solicitudAval?: any;
  public userRole: number;
  public showEntrevista: boolean;
  public showCotizacion: boolean;
  public showLineaCredito: boolean;

  public isPromotor: boolean;
  public isClient: boolean;

  constructor(private utilities: UtilitiesService) { 
    this.userRole = parseInt(localStorage.getItem("userRole"));

    this.showEntrevista = false;
    this.showCotizacion = false;
    this.showLineaCredito = false;
    this.isPromotor = false;
  }

  ngOnInit() {
    this.isPromotor = this.utilities.isPromotor() || this.utilities.isDirective();
    this.isClient = this.utilities.isClient();
    if ( this.proceso ) {
      if ( this.proceso.etapa == 4 ) {
        this.showEntrevista = true;
        this.showCotizacion = true;
        if ( this.proceso.paso == 2 ) {
          this.showLineaCredito = true;
        }
      }
    }
  }

}
