import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ADatosPersonales } from '../models/ADatosPersonales.model';
import { environment } from '../../environments/environment';

import { map } from "rxjs/operators";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ADatosPersonalesService {
    API_URL: string;

    constructor(private http: HttpClient) {
        this.API_URL =  environment.API_URL;
    }

    getQuery(type:string , query: string, parameters: string) {
        const url = `${this.API_URL}${query}`;

        switch (type) {
            case 'post':
                return this.http.post(url, parameters);
            case 'get':
                return this.http.get(url);
            case 'put':
                return this.http.put(url, parameters);
            case 'delete':
                return this.http.delete(url);
            case 'patch':
                return this.http.patch(url, parameters);
            default:
                return new Observable();
                
        }
    }


    sendRegistroPersona(data){
        return this.getQuery('post','register_person',data);
    }
}