import { Component, OnInit } from "@angular/core";
import { Data, ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { requiredMultipleFileType } from 'src/app/directives/upload-file-multiple-validator';
import { requiredFileSize } from 'src/app/directives/upload-file-size-validator';
import { I1RevisionDocumentosService } from 'src/app/services/promotor/I1RevisionDocumentosService.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-i1-carga-documentos',
    templateUrl: './i1-carga-documentos.component.html',
    styleUrls: ['./i1-carga-documentos.component.scss']
})
export class I1CargaDocumentosComponent implements OnInit {
    public status: number;
    public docs: any;
    public IdSolicitud: number;
    public loading: boolean;
    public errorMessages: string;
    public identificacion2:boolean;
    public NoComprobantes:number;

    public frmI1 = new FormGroup({
        _IdIdentificacion: new FormControl(null),
        IdentificacionVigente: new FormControl(null, [requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]),

        //_IdIdentificacion2: new FormControl(null),
        IdentificacionVigente2: new FormControl(null, [requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]),

        _IdComprobante: new FormControl(null),
        ComprobanteDomicilio: new FormControl(null, [requiredMultipleFileType(['jpg','pdf', , 'png']), requiredFileSize()]),

        _IdCedulaFiscal: new FormControl(null),
        CedulaFiscal: new FormControl(null, [requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]),

        _IdGarantiaAuto: new FormControl(null),
        GrantiaAuto: new FormControl(null, [requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]),

        _IdEvidenciaInmueble: new FormControl(null),
        EvidenciaInmueble: new FormControl(null, [requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]),

        _Contrato: new FormControl(null),
        Contrato: new FormControl(null, [requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]),

        _IdAnexo: new FormControl(null),
        Anexo: new FormControl(null, [requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]),

        _IdPagare: new FormControl(null),
        Pagare: new FormControl(null, [requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]),

        _IdDesembolso: new FormControl(null),
        Desembolso: new FormControl(null, [requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]),

        _IdSeguro: new FormControl(null),
        Seguro: new FormControl(null, [requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]),

        _IdSolicitudCredito: new FormControl(null),
        SolicitudCredito: new FormControl(null, [requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]),

        IdSolicitud: new FormControl(null)
      });

    constructor(
        private route: ActivatedRoute,
        private I1Service: I1RevisionDocumentosService,
        private modalError: NgbModal,
        private router: Router,
    ) {
        this.status = 4;
    }

    ngOnInit() {
        this.IdSolicitud = this.route.snapshot.params.id;
        this.identificacion2=false;
        this.route.data.subscribe((data: Data) => {
            this.docs = data['solicitud'].data.E5 ? data['solicitud'].data.E5 : data['solicitud'].data.revision_documentacion_legales;
            this.NoComprobantes = data['solicitud'].data.NoComprobantes;
        });
        this.frmI1.patchValue({IdSolicitud: this.IdSolicitud});

        for (let i=1;i<=this.NoComprobantes;i++){
            this.frmI1.addControl('ComprobanteIngresosMes'+i, new FormControl(null, [requiredMultipleFileType(['jpg','pdf', , 'png']), requiredFileSize()]));
            this.frmI1.addControl('_IdComprobanteMes'+i, new FormControl(null));
        }

        this.docs.forEach(documento => {
            if ( ! parseInt(documento.estatus) ){
                switch ( documento.nombreform ) {
                    case 'IdentificacionVigente':
                        documento.tipo='Identificación vigente : '
                        this.frmI1.patchValue({_IdIdentificacion: documento._IdIdentificacion});
                        this.frmI1.controls["IdentificacionVigente"].setValidators([
                            Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()
                        ]);
                        if (documento.nombre=='LICENCIA' || documento.nombre=='CREDENCIAL PARA VOTAR'){
                            this.identificacion2=true
                            this.frmI1.controls["IdentificacionVigente2"].setValidators([
                                Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()
                            ]);
                        }
                        break;

                    case 'ComprobanteDomicilio':
                        documento.tipo='Comprobante de domicilio  : '
                        this.frmI1.patchValue({_IdComprobante: documento._IdComprobante});
                        this.frmI1.controls["ComprobanteDomicilio"].setValidators([
                            Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()
                        ]);
                        break;

                    case 'CedulaFiscal':
                        this.frmI1.patchValue({_IdCedulaFiscal: documento._IdCedulaFiscal});
                        this.frmI1.controls["CedulaFiscal"].setValidators([
                            Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()
                        ]);
                        break;

                    case 'GrantiaAuto':
                        this.frmI1.patchValue({_IdGarantiaAuto: documento._IdGarantiaAuto});
                        this.frmI1.controls["GrantiaAuto"].setValidators([
                            Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()
                        ]);
                        break;

                    case 'EvidenciaInmueble':
                        this.frmI1.patchValue({_IdEvidenciaInmueble: documento._IdEvidenciaInmueble});
                        this.frmI1.controls["EvidenciaInmueble"].setValidators([
                            Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()
                        ]);
                        break;

                    case 'Contrato':
                        this.frmI1.patchValue({_Contrato: documento._Contrato});
                        this.frmI1.controls["Contrato"].setValidators([
                            Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()
                        ]);
                        break;

                    case 'Anexo':
                        this.frmI1.patchValue({_IdAnexo: documento._IdAnexo});
                        this.frmI1.controls["Anexo"].setValidators([
                            Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()
                        ]);
                        break;

                    case 'Pagare':
                        this.frmI1.patchValue({_IdPagare: documento._IdPagare});
                        this.frmI1.controls["Pagare"].setValidators([
                            Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()
                        ]);
                        break;

                    case 'Desembolso':
                        this.frmI1.patchValue({_IdDesembolso: documento._IdDesembolso});
                        this.frmI1.controls["Desembolso"].setValidators([
                            Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()
                        ]);
                        break;

                    case 'Seguro':
                        this.frmI1.patchValue({_IdSeguro: documento._IdSeguro});
                        this.frmI1.controls["Seguro"].setValidators([
                            Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()
                        ]);
                        break;

                    case 'SolicitudCredito':
                        this.frmI1.patchValue({_IdSolicitudCredito: documento._IdSolicitudCredito});
                        this.frmI1.controls["SolicitudCredito"].setValidators([
                            Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()
                        ]);
                        break;
                    default:
                        if (documento.nombreform.startsWith('ComprobanteIngresosMes')){
                            let mes=documento.nombreform.charAt(documento.nombreform.length-1);
                            documento.tipo="Comprobante de ingresos - Mes "+mes+' : '
                            this.frmI1.controls['_IdComprobanteMes'+mes].patchValue(documento['_IdComprobanteMes'+ mes]);
                            this.frmI1.controls["ComprobanteIngresosMes"+ mes].setValidators([
                                Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()
                            ]);
                        }else if(documento.nombreform.startsWith('Prenda')){
                            let i=documento.nombreform.charAt(documento.nombreform.length-1);
                            this.frmI1.addControl('_IdPrenda'+i, new FormControl(null, [Validators.required, Validators.min(1)]));
                            this.frmI1.addControl('IdGarantia'+i, new FormControl(null, [Validators.required, Validators.min(1)]));
                            this.frmI1.addControl('Prenda'+i, new FormControl(null, [Validators.required,requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]));
                            this.frmI1.controls['_IdPrenda'+i].patchValue(documento['_IdPrenda'+i]);
                            this.frmI1.controls['IdGarantia'+i].patchValue(documento.IdGarantia);
                        }

                        break;
                }
            }
        });
        
    }

    onSubmit(modalError){
        if (!this.frmI1.valid) {
            Object.keys(this.frmI1.value).forEach(element => {
                if ( this.frmI1.get(element).errors != null ) {
                    this.frmI1.get(element).markAsDirty();
                }
              });
              return;
        }
        let sendData = toFormData(this.frmI1.value);
        this.loading = true;
        this.I1Service.sendDocuments( sendData ).subscribe(
            resp => {
                this.router.navigateByUrl('client/dashboard');
            },
            errors => {
                this.loading = false;

                if ( errors.status == 0 ) {
                    this.errorMessages = 'Error al cargar los documentos. La solicitud es muy pesada. Favor de subir documentos de menor tamaño.';
                }
                else{
                    this.errorMessages = errors.error.message;
                }
                this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        );
    }

    resetValue(id) {
        this.frmI1.controls[id].patchValue(null);
        document.querySelector("#" + id).querySelectorAll('input')[0]
        document.querySelector("#" + id).querySelectorAll('input')[0].value = null;
      }
}

export function markAllAsDirty(form: FormGroup) {
    for (const control of Object.keys(form.controls)) {
      form.controls[control].markAsDirty();
    }
  }

  export function toFormData<T>(formValue: T) {
    const formData = new FormData();
    let total_mb = 0;
    for (const key of Object.keys(formValue)) {
      const value = formValue[key];

      if ( value !== null ) {
        if ( value instanceof File ) {
          total_mb = total_mb + (value.size/1000000);
        }
        formData.append(key, value);
      }
    }

    return formData;
  }
