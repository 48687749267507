import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Router, Data } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { D8GarantiaAutoService } from 'src/app/services/D8GarantiaAutoService.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesService } from 'src/app/services/utilities_services.service';

@Component({
    selector: 'app-d8-garantia-auto',
    templateUrl: './d8-garantia-auto.component.html'
})

export class D8GarantiaAutoComponent implements OnInit,AfterViewInit {
    public status: number;
    public IdSolicitud: number;
    public IdPersona: number;
    public persona: any;
    public SeguroAuto: string;
    public NombreArchivo: string;
    public FechaArchivo: string;
    public frmD8: FormGroup;
    public loading: boolean;
    public errorMessages: string;
    public SeguroRpp: string;
    public previous_data: any;

    public canEdit: boolean;
    public canDecline: boolean;

    constructor(
        public route: ActivatedRoute,
        private fb: FormBuilder,
        private D8Service: D8GarantiaAutoService,
        private modalError: NgbModal,
        private router: Router,
        private utilitiesService: UtilitiesService,
    ) {
        this.status = 2;
        this.canEdit = false;
        this.canDecline = false;
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    ngOnInit(): void {
        this.route.params.forEach(param=>this.IdPersona=param['idPersona'])
     
        this.IdSolicitud = this.route.snapshot.data.infoGarantia.data.IdSolicitud;
        this.persona = this.route.snapshot.data.infoGarantia.data.persona;
        this.SeguroAuto = this.route.snapshot.data.infoGarantia.data.E5.SeguroAuto;

        this.SeguroRpp = this.route.snapshot.data.infoGarantia.data.E5.SeguroRpp;

        this.canEdit = this.utilitiesService.hasPermission('Edicion informacion');
        this.canDecline = this.utilitiesService.hasPermission('Declinar Solicitud');

        this.previous_data = this.route.snapshot.data.infoGarantia.data;

        if ( this.SeguroAuto === '' ) {
            this.router.navigateByUrl("promotor/garantia_auto/"+this.IdSolicitud);
        }

        this.NombreArchivo = this.route.snapshot.data.infoGarantia.data.E5.NombreSeguroAuto;
        this.FechaArchivo = this.route.snapshot.data.infoGarantia.data.E5.fechaSeguroAuto;

        this.frmD8 = this.createD8Form();
        this.loading = false;

        if ( this.previous_data.data_garantia_auto != null ) {
            let garantia = this.previous_data.data_garantia_auto;
            var FormatFechaFactura = this.utilitiesService.formatDateObject(garantia.FechaFactura);
            this.frmD8.patchValue({
                NumeroFactura: garantia.NumeroFactura,
                FechaFactura: FormatFechaFactura,
                ValorFactura: garantia.ValorFactura,
                ValorEstimado: garantia.ValorEstimado,
                NumeroSerie: garantia.NumeroSerie,
                NumeroMotor: garantia.NumeroMotor,
                Marca: garantia.Marca,
                Modelo: garantia.Modelo,
                Anio: garantia.Anio
            });
        }
        else{
            this.frmD8.patchValue({
                NumeroFactura: "",
                FechaFactura: "",
                ValorFactura: "",
                ValorEstimado: "",
                NumeroSerie: "",
                NumeroMotor: "",
                Marca: "",
                Modelo: '',
                Anio: ""
            });
        }
    }

    onSubmit(modalError){
        if (!this.frmD8.valid) {
            Object.keys(this.frmD8.value).forEach(element => {
              if ( this.frmD8.get(element).errors != null ) {
                this.frmD8.get(element).markAsDirty();
              }
            });
            return;
        }
        this.loading = true;
        this.frmD8.patchValue({IdSolicitud: this.IdSolicitud,IdPersona: this.IdPersona});
        let sendData = this.frmD8.value;

        this.D8Service.sendGarantiaAuto( sendData ).subscribe(
            resp => {
                this.loading = false;
                if ( this.SeguroRpp === '' ) {
                    this.router.navigateByUrl("promotor/revision_solicitud/"+this.IdSolicitud);
                }
                else{
                    this.router.navigateByUrl("promotor/evidencia_inmueble/"+this.IdSolicitud+'/'+this.IdPersona);
                }
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
                this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        );
    }

    onlyDigits(event){
        if (event.which != 8 && event.which != 0 && event.which < 48 || event.which > 57)
        {
            event.preventDefault();
        }
    }

    createD8Form(){
        return this.fb.group({
            IdSolicitud: [
                null
            ],
            IdPersona: [
                null
            ],
            NumeroFactura: [
                Validators.required,
            ],
            FechaFactura: [
                Validators.required,
            ],
            ValorFactura: [
                Validators.required,
            ],
            ValorEstimado: [
                Validators.required,
            ],
            NumeroSerie: [
                Validators.required,
            ],
            NumeroMotor: [
                Validators.required,
            ],
            Marca: [
                Validators.required,
            ],
            Modelo: [
                Validators.required,
            ],
            Anio: [
                Validators.required,
            ]
        })
    }
}