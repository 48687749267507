import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, AfterViewInit } from "@angular/core";
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { Collection } from 'src/app/models/collection';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { ActivatedRoute, Router, Data } from '@angular/router';
import { CollectionsService } from 'src/app/services/colecctions.service';
import { CustomValidators } from 'src/app/validators/validators';
import { PromotorD4RevisionAcreditacionEmpleo } from 'src/app/services/promotor/D4RevisionAcreditacionEmpleo.service';

@Component({
    selector: 'app-d4-revision-acreditacion-empleo-edit',
    templateUrl: './d4-revision-acreditacion-empleo-edit.component.html',
})

export class D4RevisionAcreditacionEmpleoEditComponent implements OnInit, OnDestroy {

    @Input('dataParent') dataParent: any;
    @Output() eventoCancelado = new EventEmitter<any>();
    @Output() datosGuardados = new EventEmitter<any>();
    public IdSolicitud: number;

    public frmD4: FormGroup;
    public loading: boolean;
    public errorMessages: string;
    public valid_country: boolean;
    public _prevFunctionsExternal:boolean;
    public _prevFunctionsFamiliarExternal: boolean;
    public address_id = ['IdCiudad', 'IdEstado', 'IdMunicipio', 'IdColonia'];
    public address = ['Ciudad', 'Estado', 'Municipio', 'Colonia'];
    public dataLaboral = ['NombreEmpresa', 'IdOcupacion', 'FechaAntiguedad', 'Puesto'];

    /* Collecciones */
    public data: any;
    public collectionsOcupaciones: Collection;
    public collectionsSituacionLaboral: Collection;
    public collectionsPaises: Collection;
    public collectionsEstado:Collection;
    public collectionsCiudad:Collection;
    public collectionsMunicipio:Collection;
    public collectionsColonia: Collection;

    public validSituacionLaboral: boolean;

    constructor(private fb: FormBuilder,
        private collectionsService: CollectionsService,
        private utilitiesService: UtilitiesService,
        public route: ActivatedRoute,
        private modalErrors: NgbModal,
        private router: Router,
        private D4RevisionEmpleoService: PromotorD4RevisionAcreditacionEmpleo
    ) {
        this.IdSolicitud = this.route.snapshot.params.id;
        this.loading = false;
        this.validSituacionLaboral = true;
    }

    ngOnInit() {
        this.route.data.subscribe((data: Data) => {
            this.collectionsOcupaciones = data['collections'][1].data;
            this.collectionsSituacionLaboral = data['collections'][2].data;
            this.collectionsPaises = data['collections'][3].data;
        });
        this.frmD4 = this.createFormD4();
        this.data = { ...this.dataParent }


        if ( this.data.IdSituacionLaboral == 2 || this.data.IdSituacionLaboral == 5 || this.data.IdSituacionLaboral == 6 ) {
            this.validSituacionLaboral = false;
        }
        else{
            this.validSituacionLaboral = true;

            var formatFechaAntiguedad = this.utilitiesService.formatDateObject(this.data.FechaAntiguedad.split(" ")[0]);
            this.data.FechaAntiguedad = formatFechaAntiguedad;
        }
        
        parseInt(this.data.IdPais) === 1 ? this.valid_country = true : this.valid_country = false;

        if(parseInt(this.data.IdPais) !== null){
            this.collectionsService.getStateByCountryId(this.data.IdPais).subscribe(
                data=>{
                    if (data.data.length !== 0){
                        this.collectionsEstado = data.data;
                        this.valid_country = true;
                    }else{
                        this.valid_country = false;
                    }
                },
                errors =>{
                    this.valid_country = false;
                }
            );
            this.collectionsService.getCitiesById(this.data.IdPais, this.data.IdEstado).subscribe(
                data=>{
                    if (data.data.length !== 0){
                        this.collectionsCiudad = data.data;
                        this.valid_country = true;
                    }else{
                        this.valid_country = false;
                    }
                },
                errors =>{
                    this.valid_country = false;
                }
            );
            this.collectionsService.getMunicipalitiesById(this.data.IdPais,this.data.IdEstado).subscribe(
                data=>{
                    if (data.data.length !== 0){
                        this.collectionsMunicipio = data.data;
                        this.valid_country = true;
                    }else{
                        this.valid_country = false;
                    }
                },
                errors =>{
                    this.valid_country = false;
                }
            );
            this.collectionsService.getCollectionsColoniasByPais(this.data.IdPais, this.data.IdEstado,this.data.IdMunicipio).subscribe(
                data=>{
                    if (data.data.length !== 0){
                        this.collectionsColonia = data.data;
                        this.valid_country = true;
                    }else{
                        this.valid_country = false;
                    }
                    
                },
                errors =>{
                    this.valid_country = false;
                }
            );
        }

        this.data.PoliticaExpuesta === "1" ? this._prevFunctionsExternal = false : this._prevFunctionsExternal = true;
        this.data.FamiliarPoliticaExpuesta === "1" ? this._prevFunctionsFamiliarExternal = false : this._prevFunctionsFamiliarExternal = true;
        this.frmD4.patchValue(this.data);
        
    }

    ngOnDestroy() {

    }

    handleSituacionLaboral(evt){
        var valueSituacionLaboral = evt.target.value;
        if ( valueSituacionLaboral == 2 || valueSituacionLaboral == 5 || valueSituacionLaboral == 6 ) {
          this.validSituacionLaboral = false;
          this.utilitiesService.removeControlsValidity(this.frmD4, this.dataLaboral);
          if ( valueSituacionLaboral == 2 ) {
            this.frmD4.patchValue({
              IdOcupacion: 13
            });
          }
          else{
            if ( valueSituacionLaboral == 5 ) {
              this.frmD4.patchValue({
                IdOcupacion: 3
              });
            }
            else{
              if ( valueSituacionLaboral == 6 ) {
                this.frmD4.patchValue({
                  IdOcupacion: 16
                });
              }
            }
          }
        }
        else{
          this.dataLaboral.forEach(element => {
            this.frmD4.get(element).setValidators([Validators.required]);
          });
          this.validSituacionLaboral = true;
  
          if ( valueSituacionLaboral == 4 ) {
            this.frmD4.patchValue({
              IdOcupacion: 23
            });
          }
          else{
            this.frmD4.patchValue({
              IdOcupacion: 0
            });
          }
        }
      }

    onlyDigits(event){
        if (event.which != 8 && event.which != 0 && event.which < 48 || event.which > 57)
        {
            event.preventDefault();
        }
    }

    
  handleChangeFunctionsExternal(evt) {
    var target = evt.target;
    if (target.value === "1") {
      this._prevFunctionsExternal = false;
      let controls = {
        PuestoPoliticaExpuesta: [
          Validators.required,
          null
        ],
        PeriodoPoliticaExpuesta: [
          Validators.required,
          null
        ],
      }
      this.utilitiesService.addControlsValidity(this.fb, controls);
    } else {
      this._prevFunctionsExternal = true;
      let controls = ['PuestoPoliticaExpuesta', 'PeriodoPoliticaExpuesta'];
      this.utilitiesService.removeControlsValidity(this.frmD4, controls);
      this.frmD4.controls["PuestoPoliticaExpuesta"].setValue(null);
      this.frmD4.controls["PeriodoPoliticaExpuesta"].setValue(null);
    }
  }

  handleChangeFunctionsFamiliarExternal(evt) {
    var target = evt.target;
    if (target.value === "1") {
      this._prevFunctionsFamiliarExternal = false;
      let controls = {
        PuestoFamiliarPoliticaExpuesta: [
          Validators.required,
          null
        ],
        PeriodoFamiliarPoliticaExpuesta: [
          Validators.required,
          null
        ],
      }
      this.utilitiesService.addControlsValidity(this.fb, controls);
    } else {
      this._prevFunctionsFamiliarExternal = true;
      let controls = ['PuestoFamiliarPoliticaExpuesta', 'PeriodoFamiliarPoliticaExpuesta'];
      this.utilitiesService.removeControlsValidity(this.frmD4, controls);
      this.frmD4.controls["PeriodoFamiliarPoliticaExpuesta"].setValue(null);
      this.frmD4.controls["PuestoFamiliarPoliticaExpuesta"].setValue(null);
    }
  }

    handleCountry(evt) {
        var index = evt.target.selectedIndex;
        var textCountry = evt.target[index].text;
        var indexCountry = evt.target.value;
        if (indexCountry === "1" && textCountry === "MEXICO") {
            this.address_id.forEach(element => {
                this.frmD4.get(element).setValidators(Validators.min(1));
            });
            this.frmD4.patchValue({CodigoPostal: ''});

            this.collectionsService.getStateByCountryId(indexCountry).subscribe(
                data => {
                    this.valid_country = true;
                    this.collectionsEstado = data.data;
                    this.utilitiesService.removeControlsValidity(this.frmD4, this.address);
                }, error => {
                    this.valid_country = false;
                    this.utilitiesService.removeControlsValidity(this.frmD4, this.address_id);
                }
            )

        } else {
            this.valid_country = false;
            this.utilitiesService.removeControlsValidity(this.frmD4, this.address_id);
        }
    }

    handleState(evt) {
        var indexState = evt.target.value;
        var indexCountry = this.frmD4.controls['IdPais'].value;
        this.frmD4.patchValue({IdCiudad: 0, IdMunicipio: 0, IdColonia: "", CodigoPostal: ''});
        if (indexState !== "0") {
            this.collectionsService.getCitiesById(indexCountry, indexState).subscribe(
                data => {
                    this.valid_country = true;
                    this.collectionsCiudad = data.data;
                }, error => {
                    this.valid_country = false;
                }
            )
            this.collectionsService.getMunicipalitiesById(indexCountry, indexState).subscribe(
                data => {
                    this.valid_country = true;
                    this.collectionsMunicipio = data.data;
                }, error => {
                    this.valid_country = false;
                }
            )

        }

    }

    handleTown() {
        var IdPais = this.frmD4.controls['IdPais'].value;
        var IdEstado = this.frmD4.controls['IdEstado'].value;
        var IdMunicipio = this.frmD4.controls['IdMunicipio'].value;

        if (this.frmD4.controls['IdPais'].value && this.frmD4.controls['IdEstado'].value && this.frmD4.controls['IdMunicipio'].value) {
            this.frmD4.patchValue({IdColonia: "", CodigoPostal: ''});
            this.collectionsService.getCollectionsColoniasByPais(IdPais, IdEstado, IdMunicipio).subscribe(
                data => {
                    this.collectionsColonia = data.data;
                }, error => {
                    // this.valid_country = false;
                }
            )
        }
    }

    changeColonia(colonia){
        var index = colonia.target.selectedIndex;
        if ( colonia.target[index].getAttribute('CodigoPostal') != null ) {
          this.frmD4.patchValue({CodigoPostal: colonia.target[index].getAttribute('CodigoPostal')});
        }
      }

    createFormD4() {
        return this.fb.group(
            {
                IdSolicitud: [
                    null
                ],
                NombreEmpresa: [
                    null
                ],
                IdSituacionLaboral: [
                    null,
                    [Validators.required, Validators.min(1)]
                ],
                FechaAntiguedad: [
                    null
                ],
                IdOcupacion: [
                    null,
                    [Validators.required, Validators.min(1)]
                ],
                Puesto: [
                    null
                ],
                Telefono: [
                    null
                ],
                Extension: [
                    null
                ],
                Calle: [
                    null
                ],
                NoExterior: [
                    null
                ],
                NoInterior: [
                    null
                ],
                IdPais: [
                    null,
                    [Validators.required, Validators.min(1)]
                ],
                IdColonia: [
                    null
                ],
                IdMunicipio: [
                    null
                ],
                IdCiudad: [
                    null
                ],
                IdEstado: [
                    null
                ],
                CodigoPostal: [
                    null
                ],
                Colonia: [
                    null
                ],
                Municipio: [
                    null
                ],
                Ciudad: [
                    null
                ],
                Estado: [
                    null
                ],
                PoliticaExpuesta: [
                    null
                ],
                PuestoPoliticaExpuesta: [
                    null
                ],
                PeriodoPoliticaExpuesta: [
                    null
                ],
                FamiliarPoliticaExpuesta: [
                    null
                ],
                PuestoFamiliarPoliticaExpuesta: [
                    null
                ],
                PeriodoFamiliarPoliticaExpuesta: [
                    null
                ],
            }
        );
    }

    parentEmitCancelEditionMode(): void {
        this.eventoCancelado.emit();
    }

    onSubmit(modalTarget) {
        if (this.valid_country) {
            this.frmD4.patchValue(
                {
                    Estado: null,
                    Ciudad: null,
                    Municipio: null,
                    Colonia: null,
                    IdSolicitud: this.IdSolicitud
                }
            );

        } else {
            this.frmD4.patchValue(
                {
                    IdEstado: null,
                    IdCiudad: null,
                    IdMunicipio: null,
                    IdColonia: null,
                    IdSolicitud: this.IdSolicitud
                }
            );
        }
        if (!this.frmD4.valid) {
            Object.keys(this.frmD4.value).forEach(element => {
              if ( this.frmD4.get(element).errors != null ) {
                this.frmD4.get(element).markAsDirty();
              }
            });
            return;
        }

        var sendData = this.frmD4.value;
        this.loading = true;

        var FormatFecha;

        if (this.frmD4.controls['FechaAntiguedad'].value !== null) {
            FormatFecha = this.utilitiesService.parseDate(this.frmD4.controls['FechaAntiguedad'].value);
        }

        sendData.FechaAntiguedad = FormatFecha;


        this.D4RevisionEmpleoService.sendUpdate(sendData).subscribe(
            data=>{
                this.loading = false;
                this.datosGuardados.emit(data)
            },errors =>{
                this.loading = false;
                var msgError = errors.error.error;
                if (msgError.indexOf("token") > -1) {
                    localStorage.clear();
                    this.router.navigateByUrl("/login");
                } else {
                    this.errorMessages = errors.error.message;
                    this.modalErrors.open(modalTarget, { ariaLabelledBy: 'modal-basic-title', centered: true });
                }
            }
        );

    }


}