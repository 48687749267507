import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { Collection } from 'src/app/models/collection';
import { B3InformacionPersonal } from '../../../models/B3InformacionPersonal.model';
import { B3InformacionPersonalService } from "../../../services/B3InformacionPersonalService.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '../../../validators/validators';
import { CollectionsService } from 'src/app/services/colecctions.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';

@Component({
  selector: 'app-b3-informacion-personal',
  templateUrl: './b3-informacion-personal.component.html',
  styleUrls: ['./b3-informacion-personal.component.scss']
})
export class B3InformacionPersonalComponent implements OnInit, AfterViewInit {
  public collectionsPaises: Collection;
  public collectionsEstados: any;
  public collectionsCiudades: any;
  public collectionsMunicipios: any;
  public collectionsColonias: Collection;
  public errorMessages: string;
  public frmInformacionPersonal: FormGroup;
  public IdSolicitud: number;
  public valid_country: boolean;
  public address_id = ['IdCiudad', 'IdEstado', 'IdMunicipio', 'IdColonia'];
  public address = ['Ciudad', 'Estado', 'Municipio', 'Colonia'];
  public previous_data: any;
  public loading: boolean;

  public data: B3InformacionPersonal;
  public user = {
    name: "",
    birthday: ""
  };
  public status: number;
  public has_rfc: boolean;
  public rfc: string;

  public has_curp: boolean;
  public curp: string;



  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public B3Service: B3InformacionPersonalService,
    public modalErrors: NgbModal,
    private fb: FormBuilder,
    private colectionsService: CollectionsService,
    private utilitiesService: UtilitiesService
  ) {
    this.status = 1;
    this.IdSolicitud = this.route.snapshot.params.id;
    this.loading = false;
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    let person = JSON.parse(localStorage.getItem('person'));
    this.user.name = person.Nombre + " " + person.ApellidoPaterno + " " + person.ApellidoMaterno;
    this.user.birthday = person.FechaNacimiento;


    this.frmInformacionPersonal = this.createPersonalDataForm();

    this.has_rfc = this.route.snapshot.data.IdSolicitud.data.has_rfc;

    if (this.has_rfc) {
      this.rfc = this.route.snapshot.data.IdSolicitud.data.rfc;

      this.frmInformacionPersonal.patchValue({Rfc: this.rfc});
    }

    this.has_curp = this.route.snapshot.data.IdSolicitud.data.has_curp;

    if (this.has_curp) {
      this.curp = this.route.snapshot.data.IdSolicitud.data.curp;

      this.frmInformacionPersonal.patchValue({Curp: this.curp});
    }

    if (this.route.snapshot.data.IdSolicitud) {
      this.previous_data = this.route.snapshot.data.IdSolicitud.data.B3;
      this.data = this.previous_data;
      if (this.data !== undefined) {
        if (this.data.IdPais.toString() === "1") {
          this.colectionsService.getStateByCountryId(this.data.IdPais).subscribe(
            data => {
              this.collectionsEstados = data.data;
            }
          )
          this.colectionsService.getCitiesById(this.data.IdPais, this.data.IdEstado).subscribe(
            data => {
              this.collectionsCiudades = data.data;
            }
          )
          this.colectionsService.getMunicipalitiesById(this.data.IdPais, this.data.IdEstado).subscribe(
            data => {
              this.collectionsMunicipios = data.data;
            }
          )

          this.colectionsService.getCollectionsColoniasByPais(this.data.IdPais, this.data.IdEstado, this.data.IdMunicipio).subscribe(
            data => {
              this.collectionsColonias = data.data;
            }, error => {
              // this.valid_country = false;
            }
          )
          this.valid_country = true;
        } else {
          this.valid_country = false;
        }
        this.frmInformacionPersonal.patchValue(this.data);
      } else {
        this.valid_country = true;
        this.colectionsService.getStateByCountryId(1).subscribe(
          data => {
            this.collectionsEstados = data.data;
          }
        )
        this.frmInformacionPersonal.patchValue({
          IdPais: 1
        })
      }
    }
    this.route.data.subscribe((data: Data) => {
      this.collectionsPaises = data['collections'][1].data;
      this.collectionsColonias = data['collections'][2].data;
    })

  }

  checkForm(){
    if (!this.frmInformacionPersonal.valid) {
      Object.keys(this.frmInformacionPersonal.value).forEach(element => {
        if ( this.frmInformacionPersonal.get(element).errors != null ) {
          this.frmInformacionPersonal.get(element).markAsDirty();
        }
      });
      return;
    }
  }

  onSubmit(modalError) {
    if (!this.frmInformacionPersonal.valid) {
      Object.keys(this.frmInformacionPersonal.value).forEach(element => {
        if ( this.frmInformacionPersonal.get(element).errors != null ) {
          this.frmInformacionPersonal.get(element).markAsDirty();
        }
      });
      return;
    }
    this.loading = true;
    let personal_data = this.frmInformacionPersonal.value;
    let sendData = new B3InformacionPersonal(personal_data);
    sendData.setIdPersona = Number(localStorage.getItem('IdPerson'));
    let b3Data;
    if (this.valid_country) {
      this.frmInformacionPersonal.patchValue(
        {
          Estado: null,
          Ciudad: null,
          Municipio: null,
          Colonia: null,
          IdSolicitud: this.IdSolicitud
        }
      );
      b3Data = this.frmInformacionPersonal.value;
    } else {
      this.frmInformacionPersonal.patchValue(
        {
          IdEstado: null,
          IdCiudad: null,
          IdMunicipio: null,
          IdColonia: null,
          IdSolicitud: this.IdSolicitud
        }
      );
      b3Data = this.frmInformacionPersonal.value;
    }

    this.B3Service.sendInformacionPersonal(b3Data).subscribe(
      resp => {
        this.router.navigateByUrl('client/autorizar_buro/' + this.IdSolicitud);
      },
      errors => {
        this.loading = false;
        this.errorMessages = errors.error.message;
        this.modalErrors.open(modalError, {
          ariaLabelledBy: 'modal-basic-title',
          centered: true,
        });
      },
    )
  }

  onlyDigits(event){
      if (event.which != 8 && event.which != 0 && event.which < 48 || event.which > 57)
      {
          event.preventDefault();
      }
  }

  handleCountry(evt) {
    var index = evt.target.selectedIndex;
    var textCountry = evt.target[index].text;
    var indexCountry = evt.target.value;
    if (indexCountry === "1" && textCountry === "MEXICO") {

      this.colectionsService.getStateByCountryId(indexCountry).subscribe(
        data => {
          this.valid_country = true;
          this.collectionsEstados = data.data;
          this.utilitiesService.removeControlsValidity(this.frmInformacionPersonal, this.address);
          this.address_id.forEach(element => {
            this.frmInformacionPersonal.get(element).setValidators(Validators.min(1));
          });
          this.frmInformacionPersonal.patchValue({CodigoPostal: ''});
        }, error => {
          this.valid_country = false;
          this.utilitiesService.removeControlsValidity(this.frmInformacionPersonal, this.address_id);
        }
      )

    } else {
      this.valid_country = false;
      this.utilitiesService.removeControlsValidity(this.frmInformacionPersonal, this.address_id);
    }
  }

  handleState(evt) {
    var indexState = evt.target.value;
    var indexCountry = this.frmInformacionPersonal.controls['IdPais'].value;
    this.frmInformacionPersonal.patchValue({IdCiudad: 0, IdMunicipio: 0, IdColonia: 0, CodigoPostal: ''});
    if (indexState !== "0") {
      this.colectionsService.getCitiesById(indexCountry, indexState).subscribe(
        data => {
          this.valid_country = true;
          this.collectionsCiudades = data.data;
        }, error => {
          this.valid_country = false;
        }
      )
      this.colectionsService.getMunicipalitiesById(indexCountry, indexState).subscribe(
        data => {
          this.valid_country = true;
          this.collectionsMunicipios = data.data;
        }, error => {
          this.valid_country = false;
        }
      )

    }

  }

  handleTown() {
    var IdPais = this.frmInformacionPersonal.controls['IdPais'].value;
    var IdEstado = this.frmInformacionPersonal.controls['IdEstado'].value;
    var IdMunicipio = this.frmInformacionPersonal.controls['IdMunicipio'].value;

    if (this.frmInformacionPersonal.controls['IdPais'].value && this.frmInformacionPersonal.controls['IdEstado'].value && this.frmInformacionPersonal.controls['IdMunicipio'].value) {
      this.frmInformacionPersonal.patchValue({IdColonia: 0, CodigoPostal: ''});
      this.colectionsService.getCollectionsColoniasByPais(IdPais, IdEstado, IdMunicipio).subscribe(
        data => {
          this.collectionsColonias = data.data;
        }, error => {
          // this.valid_country = false;
        }
      )
    }
  }

  changeColonia(colonia){
    var index = colonia.target.selectedIndex;
    if ( colonia.target[index].getAttribute('CodigoPostal') != null ) {
      this.frmInformacionPersonal.patchValue({CodigoPostal: colonia.target[index].getAttribute('CodigoPostal')});
    }
  }

  createPersonalDataForm(): FormGroup {
    return this.fb.group(
      {
        Rfc: [
          null,
          Validators.compose([
            Validators.required,
            CustomValidators.patternValidator(
              /^[A-Z]{4}[0-9]{6}[A-Z0-9]{3}$/,
              {
                validRFC: true
              }
            )
          ])
        ],
        Curp: [
          null,
          Validators.compose([
            null,
            CustomValidators.patternValidator(
              /^[A-Z]{4}[0-9]{6}[A-Z]{3}[A-Z0-9]{5}$/,
              {
                validCURP: true
              }
            )
          ])
        ],
        IdPais: [
          null,
          Validators.min(1)
        ],
        IdEstado: [
          null,
          Validators.min(1)
        ],
        IdCiudad: [
          null,
          Validators.min(1)
        ],
        IdMunicipio: [
          null,
          Validators.min(1)
        ],
        IdColonia: [
          null,
          Validators.min(1)
        ],
        Calle: [
          null,
          null
        ],
        CodigoPostal: [
          null,
          null
        ],
        NoExterior: [
          null,
          null
        ],
        NoInterior: [
          null,
          null
        ],
        Estado: [
          null,
          null
        ],
        Ciudad: [
          null,
          null
        ],
        Municipio: [
          null,
          null
        ],
        Colonia: [
          null,
          null
        ],
        IdSolicitud: [
          null,
          null
        ]

      },
    );
  }
}
