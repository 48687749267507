import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, Data, Router } from '@angular/router';
import { K2ContratosService } from 'src/app/services/K2ContratosService.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { Collection } from 'src/app/models/collection.model';

@Component({
    selector: 'app-g2-enviar-comite',
    templateUrl: './g2-enviar-comite.component.html'
})

export class G2EnviarComiteComponent implements OnInit {
    @ViewChild('modalCoincidencias1', {static: true}) modalCoincidencias1:ElementRef;
    @ViewChild('modalCoincidencias2', {static: true}) modalCoincidencias2:ElementRef;

    public status: number;
    public data: any;
    public totalScore: number;
    public loading: boolean;
    public IdSolicitud: number;
    public errorMessages: string;
    public score: any;
    public role_user: number;
    public motivoRechazo: Collection;

    public estatus_qeq: number;
    public coincidencias: any;
    public comentariosqeq: string;
    public espep: string;

    public nameAval1: string;
    public nameAval2: string;

    public coincidenciasAval1: any;
    public coincidenciasAval2: string;

    public InfoIngresos: any;
    public CapacidadPago: any;

    public canEdit: boolean;
    public canDecline: boolean;

    constructor(
        public route: ActivatedRoute,
        private K2Service: K2ContratosService,
        private router: Router,
        private modalErrors: NgbModal,
        private utilities: UtilitiesService
    ) {
        this.status = 6;
        this.totalScore = 0;
        this.nameAval1 = '';
        this.nameAval2 = '';
        this.canEdit = false;
        this.canDecline = false;
    }

    ngOnInit(): void {
        this.IdSolicitud = this.route.snapshot.params.id;
        this.route.data.subscribe( (data: Data) => {
            this.data = data['collections'][0].data;
          } );

        this.canEdit = this.utilities.hasPermission('Edicion informacion comite');
        this.canDecline = this.utilities.hasPermission('Declinar Solicitud');

        
        this.InfoIngresos = this.route.snapshot.data.solicitudPromotor.data.revision_preanalisis;
        this.CapacidadPago = this.route.snapshot.data.solicitudPromotor.data.capacidad_pago;
        this.estatus_qeq = this.route.snapshot.data.solicitudPromotor.data.estatus_qeq;

        this.coincidencias = this.route.snapshot.data.solicitudPromotor.data.coincidencias;

        this.comentariosqeq = this.route.snapshot.data.solicitudPromotor.data.ComentariosQeq;
        this.espep = this.route.snapshot.data.solicitudPromotor.data.EsPep;

        this.route.data.subscribe((data: Data) => {
            this.motivoRechazo = data['motivoRechazo'][0].data;
        });

        this.score = this.route.snapshot.data.solicitudPromotor.data.variable;

        this.data.variablesScore.forEach(score => {
            this.totalScore = this.totalScore + parseInt(score.Puntos);
        });

        this.role_user = parseInt( localStorage.getItem('userRole') );

        this.data.avales.forEach((aval, key) => {
            if ( key == 0 ) {
                this.nameAval1 = aval.Nombre;
                this.coincidenciasAval1 = aval.coincidencias;
            }
            else{
                this.nameAval2 = aval.Nombre;
                this.coincidenciasAval2 = aval.coincidencias;
            }
        });
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    detalleCapacidadPago(modalCapacidadPago){
        this.modalErrors.open(modalCapacidadPago, { ariaLabelledBy: 'modal-basic-title', centered: false, size: 'xl', scrollable: true});
    }

    verCoincidenciasAval(index){
        if ( index == 1 ) {
            this.modalErrors.open(this.modalCoincidencias1, { ariaLabelledBy: 'modal-basic-title', centered: false, size: 'xl', scrollable: true });
        }
        else{
            this.modalErrors.open(this.modalCoincidencias2, { ariaLabelledBy: 'modal-basic-title', centered: false, size: 'xl', scrollable: true });
        }
    }

    verCoincidencias(modalCoincidenciasCliente){
        this.modalErrors.open(modalCoincidenciasCliente, { ariaLabelledBy: 'modal-basic-title', centered: false, size: 'xl', scrollable: true });
    }

    downloadBuro(){
        this.loading = true;
        this.K2Service.downloadBuro(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Docuemento de Buro.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
                // this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
    }

    declinarSubmit(MotivoRechazo){
        if ( MotivoRechazo.value == '' ) {
            alert('Debe selecciona un motivo de rechazo');
            return;
        }

        let data = {MotivoRechazo: MotivoRechazo.value};
        
        this.utilities.declinarSolicitud(this.IdSolicitud, data).subscribe(
            resp => {
                this.modalErrors.dismissAll();
                this.router.navigateByUrl("promotor/dashboard");
            },
            errors => {
            }
        );
    }

    declinar(modalDeclinar){
        this.modalErrors.open(modalDeclinar, { ariaLabelledBy: 'modal-basic-title', centered: false});

        // if ( window.confirm('¿Estás seguro que deseas declinar la solicitud?') ) {
        // }
    }

    getDocumentAvalBuro(IdPersona){
        this.loading = true;
        this.K2Service.downloadBuroAval(this.IdSolicitud, IdPersona).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Buro Aval.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
                // this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
    }

    sendRevisionComite(modalTarget){
        var sendData = {
            IdSolicitud: this.IdSolicitud,
        }
        this.K2Service.sendRevisionComite(sendData).subscribe(
            response =>{
                this.loading = false;
                this.router.navigateByUrl('promotor/revision_comite/'+this.IdSolicitud);
            }, 
            errors =>{
                this.loading = false;

                this.errorMessages = errors.error.message;
                this.modalErrors.open(modalTarget, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        )
    }
}