import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { CollectionsService } from 'src/app/services/colecctions.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { Collection } from 'src/app/models/collection.model';
import { FormBuilder, Validators, FormGroup, ValidationErrors } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesAvalService } from 'src/app/services/aval/UtilitiesAvalService.service';
import { CustomValidators } from 'src/app/validators/validators';
import { B2CapturaInformacionFiscalService } from 'src/app/services/aval/B2CapturaInformacionFiscalService.service';
import { M2PromotorInformacionFiscalService } from 'src/app/services/promotor/M2PromotorInformacionFiscalService.service';

@Component({
    selector: 'app-m2-promotor-aval-informacion-fiscal-edit',
    templateUrl: './m2-promotor-aval-informacion-fiscal-edit.component.html',
})

export class M2PromotorAvalInformacionFiscalEditComponent implements OnInit, AfterViewInit {
    @Input('dataParent') dataParent: any;
    @Output() eventoCancelado = new EventEmitter<any>();
    @Output() datosGuardados = new EventEmitter<any>();
    public frmM2: FormGroup;
    public loading: boolean;
    public errorMessages: string;
    public IdSolicitud: number;
    public IdPersona: number;
    public checkFiel: boolean;

    public has_file: boolean;

    public _prevFunctionsExternal:boolean;
    public _prevFunctionsFamiliarExternal: boolean;


    /* Collections from resolver */
    public collectionsRegimenFiscal: Collection;
    public data: any;

    /* End Collections from resolver */

    /* permissions */
    public IdRoleAval: any;
    public formatedPermissions: any;

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private collectionsService: CollectionsService,
        private utilitiesService: UtilitiesService,
        private modalError: NgbModal,
        private M2Service: M2PromotorInformacionFiscalService,
        private avalService: UtilitiesAvalService
    ) {
        this.loading = false;
        
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    ngOnInit() {
        this.IdSolicitud = this.route.snapshot.params.id_solicitud;
        this.IdPersona= this.route.snapshot.params.id_persona;
        this.route.data.subscribe((data: Data) => {
            this.collectionsRegimenFiscal = data['collections'][0].data;
        });

        this.formatedPermissions = this.avalService.getPermissions(this.IdRoleAval);
        this.data = {...this.dataParent}

        this.frmM2 = this.createfrmM2();

        this.frmM2.patchValue(this.data);
        this.frmM2.patchValue({
            IdSolicitud: this.IdSolicitud,
            IdPersona: this.IdPersona
        });
        if (this.data.TieneNoSerieFiel == 0) {
            this.frmM2.controls["NoSerieFiel"].clearValidators();
            this.frmM2.get("NoSerieFiel").updateValueAndValidity();
            this.checkFiel = true;
            this.has_file = false;
        } else {
            this.has_file = true;
            this.frmM2.controls["NoSerieFiel"].clearValidators();
            this.frmM2.controls["NoSerieFiel"].setValidators([
                Validators.compose([
                    Validators.required,
                    CustomValidators.patternValidator(
                    /^[0-9]{20}$/,
                    {
                        validFiel: true
                    }
                    )
                ])
            ]);
            this.checkFiel = false;
            this.frmM2.get("NoSerieFiel").updateValueAndValidity();
        }
        this.data.PoliticaExpuesta === "1" ? this._prevFunctionsExternal = false : this._prevFunctionsExternal = true;
        this.data.FamiliarPoliticaExpuesta === "1" ? this._prevFunctionsFamiliarExternal = false : this._prevFunctionsFamiliarExternal = true;
        this.frmM2.patchValue(this.data);

        // this.getFormValidationErrors()
    }

    createfrmM2() {
        return this.fb.group(
            {
                IdSolicitud: [
                    null
                ],
                IdRegimenFiscal: [
                    null,
                    [Validators.required, Validators.min(1)]
                ],
                Rfc: [
                    null,
                    Validators.compose([
                        Validators.required,
                        CustomValidators.patternValidator(
                            /^[A-Z]{4}[0-9]{6}[A-Z0-9]{3}$/,
                            {
                                validRFC: true
                            }
                        )
                    ])
                ],
                Curp: [
                    null,
                    Validators.compose([
                        null,
                        CustomValidators.patternValidator(
                            /^[A-Z]{4}[0-9]{6}[A-Z]{3}[A-Z0-9]{5}$/,
                            {
                                validCURP: true
                            }
                        )
                    ])
                ],
                NoSerieFiel: [
                    null
                ],
                TieneNoSerieFiel: [
                    Validators.required
                ],
                IdPersona:[
                    null
                ],
                PoliticaExpuesta: [
                    null
                ],
                PuestoPoliticaExpuesta: [
                    null
                ],
                PeriodoPoliticaExpuesta: [
                    null
                ],
                FamiliarPoliticaExpuesta: [
                    null
                ],
                PuestoFamiliarPoliticaExpuesta: [
                    null
                ],
                PeriodoFamiliarPoliticaExpuesta: [
                    null
                ],
            }
        );
    }

    handleFiel(event) {
        if (!event.target.checked) {
            this.frmM2.controls["NoSerieFiel"].clearValidators();
            this.frmM2.controls["NoSerieFiel"].setValidators([
                Validators.compose([
                    Validators.required,
                    CustomValidators.patternValidator(
                        /^[0-9]{20}$/,
                        {
                            validFiel: true
                        }
                    )
                ])
            ]);
            this.frmM2.get("NoSerieFiel").updateValueAndValidity();
            this.has_file = true;
        } else {
            this.frmM2.controls["NoSerieFiel"].clearValidators();
            this.frmM2.get("NoSerieFiel").updateValueAndValidity();
            this.frmM2.patchValue({NoSerieFiel: null});
            this.has_file = false;
        }
    }

    handleChangeFunctionsExternal(evt) {
        var target = evt.target;
        if (target.value === "1") {
        this._prevFunctionsExternal = false;
        let controls = {
            PuestoPoliticaExpuesta: [
            Validators.required,
            null
            ],
            PeriodoPoliticaExpuesta: [
            Validators.required,
            null
            ],
        }
        this.utilitiesService.addControlsValidity(this.fb, controls);
        } else {
        this._prevFunctionsExternal = true;
        let controls = ['PuestoPoliticaExpuesta', 'PeriodoPoliticaExpuesta'];
        this.utilitiesService.removeControlsValidity(this.frmM2, controls);
        this.frmM2.controls["PuestoPoliticaExpuesta"].setValue(null);
        this.frmM2.controls["PeriodoPoliticaExpuesta"].setValue(null);
        }
    }
    
    handleChangeFunctionsFamiliarExternal(evt) {
        var target = evt.target;
        if (target.value === "1") {
        this._prevFunctionsFamiliarExternal = false;
        let controls = {
            PuestoFamiliarPoliticaExpuesta: [
            Validators.required,
            null
            ],
            PeriodoFamiliarPoliticaExpuesta: [
            Validators.required,
            null
            ],
        }
        this.utilitiesService.addControlsValidity(this.fb, controls);
        } else {
        this._prevFunctionsFamiliarExternal = true;
        let controls = ['PuestoFamiliarPoliticaExpuesta', 'PeriodoFamiliarPoliticaExpuesta'];
        this.utilitiesService.removeControlsValidity(this.frmM2, controls);
        this.frmM2.controls["PeriodoFamiliarPoliticaExpuesta"].setValue(null);
        this.frmM2.controls["PuestoFamiliarPoliticaExpuesta"].setValue(null);
        }
    }

    onSubmit(modalError) {
        if (!this.frmM2.valid) {
            Object.keys(this.frmM2.value).forEach(element => {
              if ( this.frmM2.get(element).errors != null ) {
                this.frmM2.get(element).markAsDirty();
              }
            });
            return;
        }

        this.loading = true;
        let sendData = this.frmM2.value;

        let fiel = <HTMLInputElement>document.getElementById('NoSerieFiel');
        sendData.NoSerieFiel = this.has_file ? fiel.value : null;

        sendData.TieneNoSerieFiel = this.has_file ? 0 : 1;
        
        this.M2Service.sendInformacionFiscal(sendData).subscribe(
          resp => {
            this.datosGuardados.emit(resp);
            this.loading = false;
          },
          errors => {
            this.loading = false;
            this.errorMessages = errors.error.message;
            this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
          },
        )
    }

    parentEmitCancelEditionMode(): void {
        this.eventoCancelado.emit();
    }

    getFormValidationErrors() {
        Object.keys(this.frmM2.controls).forEach(key => {
      
        const controlErrors: ValidationErrors = this.frmM2.get(key).errors;
        if (controlErrors != null) {
              Object.keys(controlErrors).forEach(keyError => {
              });
            }
          });
        }

}