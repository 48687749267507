import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { Collection } from 'src/app/models/collection.model';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { M4PromotorInformacionLaboralService } from 'src/app/services/promotor/M4PromotorInformacionLaboralService.service';
import { CollectionsService } from 'src/app/services/colecctions.service';

@Component({
    selector: 'app-m4-promotor-aval-informacion-laboral-edit',
    templateUrl: './m4-promotor-aval-informacion-laboral-edit.component.html',
})

export class M4PromotorAvalInformacionLaboralEditComponent implements OnInit,AfterViewInit {
    @Input('dataParent') dataParent: any;
    @Output() eventoCancelado = new EventEmitter<any>();
    @Output() datosGuardados = new EventEmitter<any>();
    public loading: boolean;
    public frmM4: FormGroup;
    public IdSolicitud: number;
    public IdPersona: number;
    public tipoAval: number;
    public showRegimenConyugal: boolean;
    public errorMessages: string;
    public data:any;
    public dataLaboral = ['NombreEmpresa', 'IdOcupacion', 'FechaAntiguedad'];
    public address_id = ['IdCiudad', 'IdEstado', 'IdMunicipio', 'IdColonia'];
    public address = ['Ciudad', 'Estado', 'Municipio', 'Colonia'];
    public valid_country: boolean;


    /* collections from resolver */
    public collectionsSituacionLaboral: Collection;
    public collectionsOcupaciones: Collection;

    public collectionsPaises: Collection;
    public collectionsEstado:Collection;
    public collectionsCiudad:Collection;
    public collectionsMunicipio:Collection;
    public collectionsColonia: Collection;

    public validSituacionLaboral: boolean;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private M4Service: M4PromotorInformacionLaboralService,
        private modalErrors: NgbModal,
        private fb: FormBuilder,
        private utilitiesService: UtilitiesService,
        private collectionsService: CollectionsService,
    ) {
        this.validSituacionLaboral = true;
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    ngOnInit() {
        this.IdSolicitud = this.route.snapshot.params.id_solicitud;
        this.IdPersona = this.route.snapshot.params.id_persona;
        this.tipoAval = this.route.snapshot.params.tipo_aval;
        this.route.data.subscribe( (data: Data) => {
            this.collectionsSituacionLaboral = data['collections'][0].data;
            this.collectionsOcupaciones = data['collections'][1].data;
            this.collectionsPaises = data['direcciones'][0].data;
            this.collectionsColonia = data['direcciones'][1].data;
        });
        this.data = {...this.dataParent}
        this.frmM4 = this.createM4Form();
        this.frmM4.patchValue({
            IdSolicitud: this.IdSolicitud,
            IdPersona: this.IdPersona
        });
        
        

        

        if ( this.data.IdSituacionLaboral == 2 || this.data.IdSituacionLaboral == 5 || this.data.IdSituacionLaboral == 6 ) {
            this.validSituacionLaboral = false;
        }
        else{
            this.validSituacionLaboral = true;

            var formatFechaAntiguedad = this.utilitiesService.formatDateObject(this.data.FechaAntiguedad.split(" ")[0]);
            this.data.FechaAntiguedad = formatFechaAntiguedad;
        }

        this.frmM4.patchValue(this.data);
        parseInt(this.data.IdPais) === 1 ? this.valid_country = true : this.valid_country = false;

        if(parseInt(this.data.IdPais) !== null){
            
            this.collectionsService.getStateByCountryId(this.data.IdPais).subscribe(
                data=>{
                    if (data.data.length !== 0){
                        this.collectionsEstado = data.data;
                        this.valid_country = true;
                    }else{
                        this.valid_country = false;
                    }
                },
                errors =>{
                    this.valid_country = false;
                }
            );
            this.collectionsService.getCitiesById(this.data.IdPais, this.data.IdEstado).subscribe(
                data=>{
                    if (data.data.length !== 0){
                        this.collectionsCiudad = data.data;
                        this.valid_country = true;
                    }else{
                        this.valid_country = false;
                    }
                },
                errors =>{
                    this.valid_country = false;
                }
            );
            this.collectionsService.getMunicipalitiesById(this.data.IdPais,this.data.IdEstado).subscribe(
                data=>{
                    if (data.data.length !== 0){
                        this.collectionsMunicipio = data.data;
                        this.valid_country = true;
                    }else{
                        this.valid_country = false;
                    }
                },
                errors =>{
                    this.valid_country = false;
                }
            );
            this.collectionsService.getCollectionsColoniasByPais(this.data.IdPais, this.data.IdEstado,this.data.IdMunicipio).subscribe(
                data=>{
                    if (data.data.length !== 0){
                        this.collectionsColonia = data.data;
                        this.valid_country = true;
                    }else{
                        this.valid_country = false;
                    }
                    
                },
                errors =>{
                    this.valid_country = false;
                }
            );
        }
    }

    handleCountry(evt) {
    var index = evt.target.selectedIndex;
    var textCountry = evt.target[index].text;
    var indexCountry = evt.target.value;
    if (indexCountry === "1" && textCountry === "MEXICO") {
        this.address_id.forEach(element => {
            this.frmM4.get(element).setValidators(Validators.min(1));
        });
        this.frmM4.patchValue({CodigoPostal: ''});

        this.collectionsService.getStateByCountryId(indexCountry).subscribe(
            data => {
                this.valid_country = true;
                this.collectionsEstado = data.data;
                this.utilitiesService.removeControlsValidity(this.frmM4, this.address);
            }, error => {
                this.valid_country = false;
                this.utilitiesService.removeControlsValidity(this.frmM4, this.address_id);
            }
        )

    } else {
        this.valid_country = false;
        this.utilitiesService.removeControlsValidity(this.frmM4, this.address_id);
    }
}

handleState(evt) {
    var indexState = evt.target.value;
    var indexCountry = this.frmM4.controls['IdPais'].value;
    this.frmM4.patchValue({IdCiudad: 0, IdMunicipio: 0, IdColonia: "", CodigoPostal: ''});
    if (indexState !== "0") {
        this.collectionsService.getCitiesById(indexCountry, indexState).subscribe(
            data => {
                this.valid_country = true;
                this.collectionsCiudad = data.data;
            }, error => {
                this.valid_country = false;
            }
        )
        this.collectionsService.getMunicipalitiesById(indexCountry, indexState).subscribe(
            data => {
                this.valid_country = true;
                this.collectionsMunicipio = data.data;
            }, error => {
                this.valid_country = false;
            }
        )

    }

}

handleTown() {
    var IdPais = this.frmM4.controls['IdPais'].value;
    var IdEstado = this.frmM4.controls['IdEstado'].value;
    var IdMunicipio = this.frmM4.controls['IdMunicipio'].value;

    if (this.frmM4.controls['IdPais'].value && this.frmM4.controls['IdEstado'].value && this.frmM4.controls['IdMunicipio'].value) {
        this.frmM4.patchValue({IdColonia: "", CodigoPostal: ''});
        this.collectionsService.getCollectionsColoniasByPais(IdPais, IdEstado, IdMunicipio).subscribe(
            data => {
                this.collectionsColonia = data.data;
            }, error => {
                // this.valid_country = false;
            }
        )
    }
}

changeColonia(colonia){
    var index = colonia.target.selectedIndex;
    if ( colonia.target[index].getAttribute('CodigoPostal') != null ) {
    this.frmM4.patchValue({CodigoPostal: colonia.target[index].getAttribute('CodigoPostal')});
    }
    }

    createM4Form() {
        return this.fb.group({
            NombreEmpresa: [
                null
            ],
            IdSituacionLaboral: [
                Validators.required
            ],
            FechaAntiguedad: [
                Validators.required
            ],
            IdOcupacion: [
                Validators.required
            ],
            IdSolicitud: [
                null
            ],
            IdPersona:[
                null
            ],
            Puesto: [
            null
        ],
        Telefono: [
            null
        ],
        Extension: [
            null
        ],
        Calle: [
            null
        ],
        NoExterior: [
            null
        ],
        NoInterior: [
            null
        ],
        IdPais: [
            null,
            [Validators.required, Validators.min(1)]
        ],
        IdColonia: [
            null
        ],
        IdMunicipio: [
            null
        ],
        IdCiudad: [
            null
        ],
        IdEstado: [
            null
        ],
        CodigoPostal: [
            null
        ],
        Colonia: [
            null
        ],
        Municipio: [
            null
        ],
        Ciudad: [
            null
        ],
        Estado: [
            null
        ]
        })
    }

    onSubmit(modalError) {
    if (this.valid_country) {
        this.frmM4.patchValue(
            {
                Estado: null,
                Ciudad: null,
                Municipio: null,
                Colonia: null,
                IdSolicitud: this.IdSolicitud
            }
        );

    } else {
        this.frmM4.patchValue(
            {
                IdEstado: null,
                IdCiudad: null,
                IdMunicipio: null,
                IdColonia: null,
                IdSolicitud: this.IdSolicitud
            }
        );
    }
    if (!this.frmM4.valid) {
        Object.keys(this.frmM4.value).forEach(element => {
        if ( this.frmM4.get(element).errors != null ) {
            this.frmM4.get(element).markAsDirty();
        }
        });
        return;
    }
        this.loading = true;
        var sendData = this.frmM4.value;
        sendData.FechaAntiguedad = this.utilitiesService.parseDate(sendData.FechaAntiguedad);
        this.M4Service.sendInformacionLaboral(sendData).subscribe(
        resp => {
            this.loading = false;
            this.datosGuardados.emit(resp);
        },
        errors => {
            this.loading = false;
            this.errorMessages = errors.error.message;
            this.modalErrors.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
        },
        )
    }

    handleSituacionLaboral(evt){
        var valueSituacionLaboral = evt.target.value;
        if ( valueSituacionLaboral == 2 || valueSituacionLaboral == 5 || valueSituacionLaboral == 6 ) {
        this.validSituacionLaboral = false;
        this.utilitiesService.removeControlsValidity(this.frmM4, this.dataLaboral);
        if ( valueSituacionLaboral == 2 ) {
            this.frmM4.patchValue({
            IdOcupacion: 13
            });
        }
        else{
            if ( valueSituacionLaboral == 5 ) {
            this.frmM4.patchValue({
                IdOcupacion: 3
            });
            }
            else{
            if ( valueSituacionLaboral == 6 ) {
                this.frmM4.patchValue({
                IdOcupacion: 16
                });
            }
            }
        }
        }
        else{
        this.dataLaboral.forEach(element => {
        this.frmM4.get(element).setValidators([Validators.required]);
        });
        this.validSituacionLaboral = true;

        if ( valueSituacionLaboral == 4 ) {
        this.frmM4.patchValue({
            IdOcupacion: 23
        });
        }
        else{
        this.frmM4.patchValue({
            IdOcupacion: 0
        });
        }
    }
    }


    parentEmitCancelEditionMode(): void {
        
        this.eventoCancelado.emit();
    }

}