import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, Data } from '@angular/router';
import { K2ContratosService } from "../../../services/K2ContratosService.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { requiredMultipleFileType } from '../../../directives/upload-file-multiple-validator';
import { requiredFileSize } from '../../../directives/upload-file-size-validator';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { Location } from "@angular/common";

@Component({
    selector: 'app-i3-subir-contratos',
    templateUrl: './i3-subir-contratos.component.html',
})
export class I3SubirContratosComponent implements OnInit {
    public status: number;
    public IdSolicitud: number;
    public errorMessages: string;
    public loading: boolean;
    public previous_documents:any;
    public opcional: boolean;
    public garantes:any;

    public frmK2 = new FormGroup({
        ContratoCaratula: new FormControl(null, [Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]),
        Anexo: new FormControl(null, [Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]),
        Pagare: new FormControl(null, [Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]),
        CartaDesembolso: new FormControl(null, [Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]),
        Seguro: new FormControl(null, [Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]),
        SolicitudCredito: new FormControl(null, [Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]),
        IdSolicitud: new FormControl(null),
        Opcional: new FormControl(null),
        _IdContrato: new FormControl(null),
        _IdAnexo:new FormControl(null),
        _IdPagare:new FormControl(null),
        _IdDesembolso:new FormControl(null),
        _IdSeguro:new FormControl(null),
        _IdSolicitudCredito: new FormControl(null),
        NumGarantes: new FormControl(null),
      });

    public previous_data: any;

    constructor(
        private route: ActivatedRoute,
        private K2Service: K2ContratosService,
        private modalError: NgbModal,
        private utilities: UtilitiesService,
        private router: Router,
        private location:Location,
    ) {
        this.status = 7;
        this.loading = false;
    }

    ngAfterViewInit(): void {
      window.scrollTo(0, 0);
    }

    ngOnInit() {
        this.IdSolicitud = this.route.snapshot.params.id;
        this.opcional=false;
        this.route.data.subscribe((data: Data) => {
          this.previous_data = data['IdSolicitud'].data.K1 ? data['IdSolicitud'].data.K1 : false;
          this.previous_documents=this.formatData(data['IdSolicitud'].data.revision_documentacion_legales)
          this.garantes=data['IdSolicitud'].data.garanteAuto;
      });

        if (Object.entries(this.previous_documents).length >0 && this.previous_documents.contrato){
          this.opcional=true;
          this.utilities.removeControlsValidity(this.frmK2, ['ContratoCaratula']);
          this.utilities.removeControlsValidity(this.frmK2, ['Anexo']);
          this.utilities.removeControlsValidity(this.frmK2, ['Pagare']);
          this.utilities.removeControlsValidity(this.frmK2, ['CartaDesembolso']);
          this.utilities.removeControlsValidity(this.frmK2, ['Seguro']);
          this.utilities.removeControlsValidity(this.frmK2, ['SolicitudCredito']);
          if(this.previous_documents.contrato){
          this.frmK2.patchValue({
            _IdContrato: this.previous_documents.contrato._Contrato,
            _IdAnexo: this.previous_documents.anexo._IdAnexo,
            _IdPagare: this.previous_documents.pagare._IdPagare,
            _IdDesembolso: this.previous_documents.desembolso._IdDesembolso,
            _IdSeguro: this.previous_documents.seguro._IdSeguro,
            _IdSolicitudCredito: this.previous_documents.solicitudcredito?this.previous_documents.solicitudcredito._IdSolicitudCredito:null,
          })
          
          this.frmK2.controls['_IdContrato'].setValidators([Validators.required]);
          this.frmK2.controls['_IdAnexo'].setValidators([Validators.required]);
          this.frmK2.controls['_IdPagare'].setValidators([Validators.required]);
          this.frmK2.controls['_IdDesembolso'].setValidators([Validators.required]);
          this.frmK2.controls['_IdSeguro'].setValidators([Validators.required]);
          this.frmK2.controls['_IdSolicitudCredito'].setValidators([Validators.required]);
          }
        }
        
        this.frmK2.patchValue({
          IdSolicitud: this.IdSolicitud,
          Opcional:this.opcional,
          NumGarantes:this.garantes.length
        })
        if (!this.opcional){
          for (let i=1;i<=this.garantes.length;i++){
            //this.garantes[i-1].formName= 'Prenda'+i
            this.frmK2.addControl('IdGarantia'+i, new FormControl(null, [Validators.required, Validators.min(1)]));
            this.frmK2.addControl('_IdPrenda'+i, new FormControl(null));
            this.frmK2.addControl('Prenda'+i, new FormControl(null, [Validators.required, requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]));
            this.frmK2.controls['IdGarantia'+i].patchValue(this.garantes[i-1].IdGarantiaAuto);
          }
        }else{
          for (let i=1;i<=this.garantes.length;i++){
            //this.garantes[i-1].formName= 'Prenda'+i
            this.frmK2.addControl('IdGarantia'+i, new FormControl(null, [Validators.required, Validators.min(1)]));
            this.frmK2.addControl('_IdPrenda'+i, new FormControl(null , [Validators.required, Validators.min(1)]));
            this.frmK2.addControl('Prenda'+i, new FormControl(null, [requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]));
            this.frmK2.controls['_IdPrenda'+i].patchValue(this.garantes[i-1].documento);
            this.frmK2.controls['IdGarantia'+i].patchValue(this.garantes[i-1].IdGarantiaAuto);
          }
        }
    }

    resetValue(id) {
        this.frmK2.controls[id].patchValue(null);
        document.querySelector("#" + id).querySelectorAll('input')[0]
        document.querySelector("#" + id).querySelectorAll('input')[0].value = null;
      }

      onSubmit(event: any, modalError){
        if (!this.frmK2.valid) {
          Object.keys(this.frmK2.value).forEach(element => {
            if ( this.frmK2.get(element).errors != null ) {
              this.frmK2.get(element).markAsDirty();
            }
          });
          return;
        }

        this.loading = true;
        let sendData = toFormData(this.frmK2.value);

        this.K2Service.sendContratosPromotor(sendData).subscribe(
            resp => {

              let redirect='/promotor/instruccion_deposito/'
              const proceso=resp['proceso'];
          
              if (proceso.etapa==8){
                redirect='/promotor/solicitud_desembolso/'
              }

              this.router.navigateByUrl(redirect+this.IdSolicitud);
            },
            errors => {
                this.loading = false;

                if ( errors.status == 0 ) {
                  this.errorMessages = 'Error al cargar los documentos. La solicitud es muy pesada. Favor de subir documentos de menor tamaño.';
                }
                else{
                    this.errorMessages = errors.error.message;
                }

              this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
      }
      formatData(obj){
        var formatDataSkelleton = {
            contrato:null,
            pagare:null,
            anexo:null,
            desembolso:null,
            seguro:null,
            solicitudcredito:null,
        }
        obj.map(function(item){
            if(item.hasOwnProperty("_Contrato")){
                formatDataSkelleton.contrato = item;
            }
            if(item.hasOwnProperty("_IdAnexo")){
                formatDataSkelleton.anexo = item;
            }
            if(item.hasOwnProperty("_IdPagare")){
                formatDataSkelleton.pagare = item;
            }
            if(item.hasOwnProperty("_IdDesembolso")){
                formatDataSkelleton.desembolso = item;
            }
            if(item.hasOwnProperty("_IdSeguro")){
                formatDataSkelleton.seguro = item;
            }
            if(item.hasOwnProperty("_IdSolicitudCredito")){
                formatDataSkelleton.solicitudcredito = item;
            }
        });
        return formatDataSkelleton;
    }

      downloadCarta(){
        this.loading = true;
        this.K2Service.downloadCarta(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Carta de Bienvenida.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
            //   this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
    }

    downloadPagare(){
        this.loading = true;
        this.K2Service.downloadPagare(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Pagare.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
            //   this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
    }

    downloadContrato(){
        this.loading = true;
        this.K2Service.downloadContrato(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Contrato.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
            //   this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
    }

    downloadAnexo(){
        this.loading = true;
        this.K2Service.downloadAnexo(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Anexo.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
            //   this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
    }

    downloadDesembolso(){
        this.loading = true;
        this.K2Service.downloadDesembolso(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Instrucciones de Desembolso.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
            //   this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
    }

    downloadSeguro(){
        var a = <HTMLInputElement>document.getElementById('self_file');
        a.setAttribute('download', 'Seguro.pdf');
        a.setAttribute('target', '_blank');
        a.setAttribute('href', 'https://apifinanciamiento.edupass.mx/Seguro.pdf');
        a.click();
    }

    downloadPrenda(IdGarantia){
      this.loading = true;
      this.K2Service.downloadPrenda(IdGarantia).subscribe(
          resp => {
              var a = <HTMLInputElement>document.getElementById('self_file');
              this.loading = false;
              const url = window.URL.createObjectURL(resp);
              a.setAttribute('href', url);
              a.setAttribute('download', 'Prenda_'+IdGarantia+'.pdf');
              a.click();
          },
          errors => {
              this.loading = false;
              this.errorMessages = errors.error.message;
          //   this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
          },
        )
  }
     //se integra solicitud documento legal
     downloadLegal(){
        this.loading = true;
        this.utilities.imprimirSolicitud2(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Solicitud de crédito.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
            }
        );
    }
    back(){
      this.location.back();
    }
}

export function toFormData<T>(formValue: T) {
    const formData = new FormData();
    let total_mb = 0;
    for (const key of Object.keys(formValue)) {
      const value = formValue[key];

      if ( value !== null ) {
        if ( value instanceof File ) {
          total_mb = total_mb + (value.size/1000000);
        }
        formData.append(key, value);
      }
    }

    return formData;
  }
