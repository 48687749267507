import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import { Data, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-temp-messages-promotor-error',
    templateUrl: './temp-messages-error.component.html',
})
export class TempMessagesErrorPromotorComponent implements OnInit {
    @Input('messages') messages: any;
    public status: number;
    public proceso: any;
    public docs: string;
    constructor(
        private route: ActivatedRoute,
    ) { 
        this.status = 7;
    }

    ngOnInit() { 
        // this.route.data.subscribe((data: Data) => {
        //     this.proceso = data['proceso'].data;
        // });
    }
}