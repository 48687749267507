import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { CollectionsService } from "../../services/colecctions.service";
import { Collection } from '../../models/collection.model';
import { Injectable } from '@angular/core';

@Injectable()
export class PromotorAvalInformacionResolver implements Resolve<Collection>{
    public idSolicitud: number;
    public idPersona: number;
    constructor (private CollectionsServive: CollectionsService){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<any>{
        this.idSolicitud = parseInt(route.paramMap.get('id_solicitud'));
        this.idPersona = parseInt(route.paramMap.get('id_persona'));
        let test = forkJoin(
            this.CollectionsServive.getInformacionAvalPromotor(this.idSolicitud,this.idPersona),
            this.CollectionsServive.getSolicitudPromotorDetails(this.idSolicitud),
            this.CollectionsServive.getNoIdentificacion(this.idSolicitud,this.idPersona)
        );

        return test;
    }
}
