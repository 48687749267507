import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, Data } from '@angular/router';
import { K2ContratosService } from "../../../services/K2ContratosService.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { requiredMultipleFileType } from '../../../directives/upload-file-multiple-validator';
import { requiredFileSize } from '../../../directives/upload-file-size-validator';
import { UtilitiesService } from 'src/app/services/utilities_services.service';

@Component({
    selector: 'app-k2-contratos',
    templateUrl: './k2-contratos.component.html',
    styleUrls: ['./k2-contratos.component.scss']
})
export class K2ContratosComponent implements OnInit {
    public status: number;
    public IdSolicitud: number;
    public errorMessages: string;
    public loading: boolean;

    public garantes:any;

    public frmK2 = new FormGroup({
        ContratoCaratula: new FormControl(null, [Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]),
        Anexo: new FormControl(null, [Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]),
        Pagare: new FormControl(null, [Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]),
        CartaDesembolso: new FormControl(null, [Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]),
        Seguro: new FormControl(null, [Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]),
        IdSolicitud: new FormControl(null),
        SolicitudCredito: new FormControl(null, [Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]), 
      });

    public previous_data: any;

    constructor(
        private route: ActivatedRoute,
        private K2Service: K2ContratosService,
        private modalError: NgbModal,
        private utilities: UtilitiesService,
        private router: Router,
    ) {
        this.status = 5;
        this.loading = false;
    }

    ngOnInit() {
        this.IdSolicitud = this.route.snapshot.params.id;

        this.route.data.subscribe((data: Data) => {
          this.previous_data = data['IdSolicitud'].data.K1 ? data['IdSolicitud'].data.K1 : false;
          this.garantes=data['IdSolicitud'].data.garanteAuto;
      });

      /*if ( ! this.previous_data ) {
        this.router.navigateByUrl("client/instruccion_deposito/"+this.IdSolicitud);
        this.loading = true;
      }*/

        this.frmK2.patchValue({
            IdSolicitud: this.IdSolicitud,
            NumGarantes: this.garantes.length
          })
        for (let i=1;i<=this.garantes.length;i++){
          //this.garantes[i-1].formName= 'Prenda'+i
          this.frmK2.addControl('IdGarantia'+i, new FormControl(null, [Validators.required, Validators.min(1)]));
          this.frmK2.addControl('Prenda'+i, new FormControl(null, [requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]));
          this.frmK2.controls['IdGarantia'+i].patchValue(this.garantes[i-1].IdGarantiaAuto);
        }
          
    }

    resetValue(id) {
        this.frmK2.controls[id].patchValue(null);
        document.querySelector("#" + id).querySelectorAll('input')[0]
        document.querySelector("#" + id).querySelectorAll('input')[0].value = null;
      }

      onSubmit(event: any, modalError){
        if (!this.frmK2.valid) {
          Object.keys(this.frmK2.value).forEach(element => {
            if ( this.frmK2.get(element).errors != null ) {
              this.frmK2.get(element).markAsDirty();
            }
          });
          return;
        }

        this.loading = true;
        let sendData = toFormData(this.frmK2.value);


        this.K2Service.sendContratos(sendData).subscribe(
            resp => {
              let redirect = this.utilities.handleRedirect(resp['proceso']);
              this.router.navigateByUrl(redirect+"/"+this.IdSolicitud);
            },
            errors => {
                this.loading = false;

                if ( errors.status == 0 ) {
                  this.errorMessages = 'Error al cargar los documentos. La solicitud es muy pesada. Favor de subir documentos de menor tamaño.';
                }
                else{
                    this.errorMessages = errors.error.message;
                }

              this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
      }

      downloadCarta(){
        this.loading = true;
        this.K2Service.downloadCarta(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Carta de Bienvenida.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
            //   this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
    }

    //se integra solicitud documento legal
    downloadLegal(){
        this.loading = true;
        this.utilities.imprimirSolicitud2(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Solicitud de crédito.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
            }
        );
    }

    downloadPagare(){
        this.loading = true;
        this.K2Service.downloadPagare(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Pagare.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
            //   this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
    }

    downloadContrato(){
        this.loading = true;
        this.K2Service.downloadContrato(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Contrato.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
            //   this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
    }

    downloadAnexo(){
        this.loading = true;
        this.K2Service.downloadAnexo(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Anexo.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
            //   this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
    }

    downloadDesembolso(){
        this.loading = true;
        this.K2Service.downloadDesembolso(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Instrucciones de Desembolso.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
            //   this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
    }

    downloadSeguro(){
        var a = <HTMLInputElement>document.getElementById('self_file');
        a.setAttribute('download', 'Seguro.pdf');
        a.setAttribute('target', '_blank');
        a.setAttribute('href', 'https://apifinanciamiento.edupass.mx/Seguro.pdf');
        a.click();
    }

    downloadPrenda(IdGarantia){
        this.loading = true;
        this.K2Service.downloadPrenda(IdGarantia).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Prenda_'+IdGarantia+'.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
            //   this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
    }
}

export function toFormData<T>(formValue: T) {
    const formData = new FormData();
    let total_mb = 0;
    for (const key of Object.keys(formValue)) {
      const value = formValue[key];

      if ( value !== null ) {
        if ( value instanceof File ) {
          total_mb = total_mb + (value.size/1000000);
        }
        formData.append(key, value);
      }
    }

    return formData;
  }
