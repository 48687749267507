import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/validators/validators';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { AvalC1InformacionPersonalService } from 'src/app/services/aval/C1InformacionPersonalService.service';
import { Collection } from 'src/app/models/collection';

@Component({
    selector: 'app-c1-informacion-personal',
    templateUrl: './c1-informacion-personal.component.html'
})
export class C1InformacionPersonal implements OnInit, AfterViewInit {

    public loading: boolean;
    public frmC1: FormGroup;
    public IdSolicitud: number;
    public showRegimenConyugal: boolean;
    public errorMessages: string;
    public data: any;
    public informacionPropietario: any;

    /* collections from resolver */
    public collectionsEstadoCivil: Collection;
    public collectionsRegimenConyugal: Collection;
    public collectionsTipoVivienda: Collection;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private C1Service: AvalC1InformacionPersonalService,
        private modalErrors: NgbModal,
        private fb: FormBuilder,
        private utilitiesService: UtilitiesService,
        private modalError: NgbModal

    ) {


    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    ngOnInit() {
        this.route.data.subscribe((data: Data) => {
            this.collectionsEstadoCivil = data['collections'][0].data;
            this.collectionsRegimenConyugal = data['collections'][1].data;
            this.collectionsTipoVivienda = data['collections'][2].data;
            this.data = data['data'][0].data.informacion_familiar;
            this.informacionPropietario = data['data'][0].data.informacion_propietario;
        });
        this.IdSolicitud = this.route.snapshot.params.id;
        this.showRegimenConyugal = false;
        this.frmC1 = this.createC1Form();
        this.frmC1.patchValue({
            IdSolicitud: this.IdSolicitud,
            etapa: 2,
            paso:2,
            flag:1
        });
        if(this.data.FechaAntiguedadVivienda){
            var formatFechaAntiguedadVivienda = this.utilitiesService.formatDateObject(this.data.FechaAntiguedadVivienda);
            this.data.FechaAntiguedadVivienda = formatFechaAntiguedadVivienda;
        }
        this.frmC1.patchValue(this.data);


    }

    handleChangeEstadoCivil(evt) {
        var target = evt.target;
        if (target.value === "1") {
            this.showRegimenConyugal = true;
            this.frmC1.controls["IdRegimenConyugal"].setValidators([
                Validators.required
            ]);
        } else {
            this.showRegimenConyugal = false;
            this.frmC1.controls["IdRegimenConyugal"].clearValidators();
            this.frmC1.get("IdRegimenConyugal").updateValueAndValidity();
        }
    }


    createC1Form() {
        return this.fb.group({
            IdEstadoCivil: [
                Validators.required,
            ],
            IdRegimenConyugal: [
                null
            ],
            NumeroDependientes: [
                null,
                Validators.compose([
                    Validators.required,
                    CustomValidators.patternValidator(
                        /^[0-9][0-9]*$/,
                        {
                            atLeastOne: true
                        }
                    )
                ])
            ],
            IdParentescoConEstudiante: [
                null
            ],
            IdTipoVivienda: [
                Validators.required,
            ],
            FechaAntiguedadVivienda: [
                Validators.required,
            ],
            IdSolicitud: [
                null
            ],
            etapa:[
                null
            ],
            paso:[
                null
            ],
            flag:[
                null
            ],
        })
    }

    onSubmit(modalError) {
        if (!this.frmC1.valid) {
            Object.keys(this.frmC1.value).forEach(element => {
                if ( this.frmC1.get(element).errors != null ) {
                this.frmC1.get(element).markAsDirty();
                }
            });
            return;
        }

        this.loading = true;
        var sendData = this.frmC1.value;
        sendData.FechaAntiguedadVivienda = this.utilitiesService.parseDate(sendData.FechaAntiguedadVivienda);
        this.C1Service.sendData(sendData).subscribe(
          resp => {
            this.router.navigateByUrl("aval/informacion_laboral/" + this.IdSolicitud);
          },
          errors => {
            this.loading = false;
            this.errorMessages = errors.error.message;
            this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
          },
        )
    }
}