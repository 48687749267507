import { Component, OnInit } from '@angular/core';
import { RegisterService } from '../../../services/register.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '../../../validators/validators';

@Component({
    selector: 'app-a-registro',
    templateUrl: './a-registro.component.html',
})
export class ARegistroComponent implements OnInit {

    email: string;
    password: string;
    passwordConfirmation: string;
    public errorMessages: string;
    public frmSignup: FormGroup;
    public loading: boolean;

    constructor(private register: RegisterService, private router: Router, private modalErrors: NgbModal,private fb: FormBuilder) {
        this.frmSignup = this.createSignupForm();
    }

    ngOnInit() {
        this.loading = false;
    }

    onSubmit(modalError){
        this.loading = true;
        const user = this.frmSignup.value;
        this.register.registerClient(user)
        .subscribe(response => {
            this.router.navigateByUrl('/login?register=user_registred');

            // this.loading = false;
            // localStorage.setItem('token', response['access_token']);
            // localStorage.setItem('user', JSON.stringify(response['user']));
            // localStorage.setItem('userRole', response['user']['id_role'] );
            // localStorage.setItem('IdPerson', '');
            // const today = new Date();
            // const expiresDateToken = new Date(today.getTime() + (response['expires_in'] * 1000))
            // localStorage.setItem('expires_in', expiresDateToken.getTime().toString());
            // this.router.navigateByUrl('client/datos_personales');
        }, 
        errorService => {
            this.loading = false;
            if  ( errorService.error.errors === 401 ){
                this.errorMessages = "Las contraseñas no coinciden";
                this.modalErrors.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
            else{
                if  ( errorService.error.errors === 500 ){
                    this.errorMessages = errorService.error.message;
                    this.modalErrors.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
                }
                this.router.navigateByUrl('correo_usado');
            }
        }); 
    }

    createSignupForm(): FormGroup {
        return this.fb.group(
            {
                email: [
                null,
                Validators.compose([
                    Validators.email, 
                    Validators.required
                ])
                ],
                password: [
                null,
                Validators.compose([
                    Validators.required,
                    // check whether the entered password has a special character

                    CustomValidators.patternValidator(
                    /(?=.*[A-Z].*)(?=.*[0-9].*).{8,}/,
                        {
                            hasSpecialCharacters: true
                        }
                    )
                    
                    
                    
                    ,
                    Validators.minLength(8)
                ])
                ],
                password_confirmation: [null, Validators.compose([Validators.required])],
                email_confirmation: [null, Validators.compose([Validators.required])],
            },

            {
                // check whether our password and confirm password match
                validator: [CustomValidators.passwordMatchValidator, CustomValidators.emailMatchValidator]
            }
        );
    }
}