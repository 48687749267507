import { Component, OnInit, OnDestroy, HostListener, Inject, ViewChild } from "@angular/core";
import { ActivatedRoute, Data, Router } from '@angular/router';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { Observable, Subject } from 'rxjs';
import { TablePromotorModel } from 'src/app/models/TablePromotor.model';
import { CollectionsService } from 'src/app/services/colecctions.service';
import { HttpClient } from '@angular/common/http';
import { Collection } from 'src/app/models/collection.model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-b1-solicitudes',
    templateUrl: './b1-solicitudes.component.html'
})

export class B1SolicitudesComponent implements OnInit {
    @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
    dtElement: DataTableDirective;
    dtOptions: any = {};
    dtInstance: DataTables.Api;
    dtTrigger: Subject<any> = new Subject();

    @HostListener('click', ['$event.target.id'])
    onClick(btn) {
        let td = document.getElementById(btn);
        if ( td != null && td.hasAttribute('idsolicitud') ) {
            this.checkStep(td.getAttribute('idsolicitud'));
        }
        else if ( td != null && td.hasAttribute('idsolicitudimprimir') ) {
            this.imprimirSolicitud(td.getAttribute('idsolicitudimprimir'));
        }
        else if ( td != null && td.hasAttribute('idsolicituddetalle') ) {
            this.detalle(td.getAttribute('idsolicituddetalle'));
        }
    }

    // dtOptions: any = {};
    // dtTrigger = new Subject();
    public tablePromotor: any;
    public agencias: Collection;
    public motivosRechazo: Collection;
    public promotores: Collection;
    public etapas: Collection;
    public frm: FormGroup;
    public loading: boolean;
    public errorMessages: string;
    // public dtElement: DataTableDirective;
    public avialableSolicitudes: any;
    
    public user_role: number;
    public is_directiva: boolean;

    constructor(
        private collections: CollectionsService,
        private utilities: UtilitiesService,
        private router: Router,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        public modalHandler: NgbModal,
        @Inject(DOCUMENT) document
    ) {
        this.loading = false;
    }

    ngOnInit() {
        this.route.data.subscribe((data: Data) => {
            this.agencias = data['collections'][0].data;
            this.motivosRechazo = data['collections'][1].data;
            this.promotores = data['collections'][2].data;
            this.etapas = data['collections'][3].data;
        });

        this.frm = this.createFrm();
        this.resetForm();

        this.dtOptions = {
            pagingType: 'full_numbers',
            searching: true,
            paging: true,
            info: false,
            columns: [
                { title: 'No. de solicitud', data: 'IdSolicitud', type: 'number' },
                { title: 'Cliente', data: 'Cliente' },
                { title: 'Monto financiado', data: 'MontoSolicitado' },
                { title: 'Alianza', data: 'RazonSocial' },
                { title: 'Promotor', data: 'Promotor' },
                { title: 'Etapa', data: 'Etapa' },
                { title: 'Etapa - Promotor'},
                { title: 'Acciones' },
                { title: 'Imprimir' },
                { title: 'Detalle' },

            ],
            dom: 'Bfrtip',
            // Configure the buttons
            buttons: [
                'excel',
            ],
            responsive: true,
        };
        this.user_role = parseInt(localStorage.getItem('userRole'));

        this.is_directiva = this.utilities.isDirective();

        if ( ! this.is_directiva ) {
            this.collections.obtenerSolicitudesPromotor().subscribe(
                data => {
                    var temp = [];
                    data.data.forEach(element => {
                        element.IdSolicitud = parseFloat(element.IdSolicitud).toFixed(2).toString();
                        temp.push(element);
                    });
                    this.tablePromotor = temp;
                    this.dtTrigger.next();
                }
            );
        }
        else{
            this.collections.obtenerSolicitudesDirectiva().subscribe(
                data => {
                    var temp = [];
                    data.data.forEach(element => {
                        element.IdSolicitud = parseFloat(element.IdSolicitud).toFixed(2).toString();
                        temp.push(element);
                    });
                    this.tablePromotor = temp;
                    this.dtTrigger.next();
                }
            );
        }

        this.collections.solicitudesDisponiblesPromotor().subscribe(
            data => {
                if ( data.data.length > 0 ) {
                    this.avialableSolicitudes = data.data;
                }
                else{
                    this.avialableSolicitudes = [];
                }

            },
            errors => {
                this.avialableSolicitudes = [];
            }
        );
    }

    resetSearch(){
        if ( ! this.is_directiva ) {
            this.collections.obtenerSolicitudesPromotor().subscribe(
                data => {
                    var temp = [];
                    data.data.forEach(element => {
                        element.IdSolicitud = parseFloat(element.IdSolicitud).toFixed(2).toString();
                        temp.push(element);
                    });
                    this.tablePromotor = temp;
                    this.rerender();
                }
            );
        }
        else{
            this.collections.obtenerSolicitudesDirectiva().subscribe(
                data => {
                    var temp = [];
                    data.data.forEach(element => {
                        element.IdSolicitud = parseFloat(element.IdSolicitud).toFixed(2).toString();
                        temp.push(element);
                    });
                    this.tablePromotor = temp;
                    this.rerender();
                }
            );
        }
        this.resetForm();
    }

    apartar(IdSolicitud, modalError, modalSuccess){
        this.loading = true;
        this.utilities.getQuery('get', 'promotor/apartar/solicitud/' + IdSolicitud, '').subscribe(
            data => {
                this.modalHandler.open(modalSuccess, { ariaLabelledBy: 'modal-basic-title', centered: true });
                window.location.reload();
            },
            errors => {
                this.errorMessages = errors.error.message;
                this.modalHandler.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
                window.location.reload();
            }
        );
    }

    resetForm(){
        this.frm.patchValue({
            To: null,
            From: null,
            IdEtapa: '',
            MotivoRechazo: '',
            IdEmpleadoPromotor: '',
            NombreCliente: null,
            CorreoCliente: null,
            CelularCliente: null,
            IdAlianza: '',
        })
    }

    filterData(modalTarget) {
        this.loading = true;
        var test = this.formatQuery();
        if ( ! this.is_directiva ) {
            this.collections.getFilteredPromotor(test.slice(0, -1)).subscribe(
                data => {
                    this.loading = false;
                    this.tablePromotor = data.data;
                    this.rerender();
                }
            )
        }
        else{
            this.collections.getFilteredDirectiva(test.slice(0, -1)).subscribe(
                data => {
                    this.loading = false;
                    this.tablePromotor = data.data;
                    this.rerender();
                }
            )
        }
    }


    createFrm() {
        return this.fb.group({
            To: [
                null
            ],
            From: [
                null
            ],
            IdEtapa: [
                null
            ],
            MotivoRechazo: [
                null
            ],
            IdEmpleadoPromotor: [
                null
            ],
            NombreCliente: [
                null
            ],
            CorreoCliente: [
                null
            ],
            CelularCliente: [
                null
            ],
            IdAlianza: [
                null
            ],
        })
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
    }

    rerender(): void {
        //this.datatableElement is important
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
                this.dtTrigger.next();
              });
  
    }

    formatQuery() {
        var stringQuery: string = "";
        if (this.frm.controls['From'].value != null && this.frm.controls['From'].value !='') {
            stringQuery += 'From=' + this.utilities.parseDate(this.frm.controls['From'].value) + '&'
        }
        if (this.frm.controls['To'].value != null && this.frm.controls['To'].value !='') {
            stringQuery += 'To=' + this.utilities.parseDate(this.frm.controls['To'].value) + '&'
        }
        if (this.frm.controls['IdEtapa'].value != null && this.frm.controls['IdEtapa'].value !='') {
            stringQuery += 'IdEtapa=' + this.frm.controls['IdEtapa'].value + '&'
        }
        if (this.frm.controls['MotivoRechazo'].value != null && this.frm.controls['MotivoRechazo'].value !='') {
            stringQuery += 'MotivoRechazo=' + this.frm.controls['MotivoRechazo'].value + '&'
        }
        if (this.frm.controls['IdEmpleadoPromotor'].value != null && this.frm.controls['IdEmpleadoPromotor'].value !='') {
            stringQuery += 'IdEmpleadoPromotor=' + this.frm.controls['IdEmpleadoPromotor'].value + '&'
        }
        if (this.frm.controls['NombreCliente'].value != null && this.frm.controls['NombreCliente'].value !='') {
            stringQuery += 'NombreCliente=' + encodeURIComponent(this.frm.controls['NombreCliente'].value) + '&'
        }
        if (this.frm.controls['CorreoCliente'].value != null && this.frm.controls['CorreoCliente'].value !='') {
            stringQuery += 'CorreoCliente=' + encodeURIComponent(this.frm.controls['CorreoCliente'].value) + '&'
        }
        if (this.frm.controls['CelularCliente'].value != null && this.frm.controls['CelularCliente'].value !='') {
            stringQuery += 'CelularCliente=' + this.frm.controls['CelularCliente'].value + '&'
        }
        if (this.frm.controls['IdAlianza'].value != null && this.frm.controls['IdAlianza'].value !='') {
            stringQuery += 'IdAlianza=' + this.frm.controls['IdAlianza'].value + '&'
        }

        return stringQuery;
    }

    checkStep(IdSolicitud) {
        IdSolicitud = parseInt(IdSolicitud);
        this.utilities.handleStatusSolicitudPromotorDashboard(IdSolicitud).subscribe(
            response => {
                var estatusSolicitud: any;
                estatusSolicitud = response;
                let redirect = this.utilities.handleStatePromotorRedirect(estatusSolicitud.proceso, response['data'].proceso_cliente);
                this.router.navigateByUrl(redirect + "/" + IdSolicitud);
            }
        )

    }
    imprimirSolicitud(IdSolicitud){
        this.utilities.imprimirSolicitud(parseInt(IdSolicitud)).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Detalle de la solicitud '+parseInt(IdSolicitud)+'.pdf');
                a.click();
            },
            errors => {
                console.log('Error al imprimir',errors)
            }
        );
    }
    detalle(IdSolicitud){
        IdSolicitud = parseInt(IdSolicitud);
                this.router.navigateByUrl( "promotor/detalle/documentos/" + IdSolicitud);
    }
}