import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class K2ContratosService {
    API_URL: string;

    constructor(private http: HttpClient) {
        this.API_URL =  environment.API_URL;
    }

    getQuery(type:string , query: string, parameters: string, ) {
        const url = `${this.API_URL}${query}`;

        switch (type) {
            case 'post':
                return this.http.post(url, parameters);
            case 'get':
                return this.http.get(url, { responseType: 'blob' });
            case 'put':
                return this.http.put(url, parameters);
            case 'delete':
                return this.http.delete(url);
            case 'patch':
                return this.http.patch(url, parameters);
            default:
                return new Observable();

        }
    }

    sendContratos(data){
        return this.getQuery('post','contratacion/contratos',data);
    }
    sendContratosPromotor(data){ 
        return this.getQuery('post','promotor/contratacion/contratos',data);
    }

    downloadCarta(IdSolicitud) : any{
        return this.getQuery('get','contratos/carta/' + IdSolicitud, '');
    }

    downloadLegal(IdSolicitud) : any{
        return this.getQuery('get','generar/solicitud/' + IdSolicitud, '');
    }


    downloadPagare(IdSolicitud) : any{
        return this.getQuery('get','contratos/pagare/' + IdSolicitud, '');
    }

    downloadContrato(IdSolicitud): any{
        return this.getQuery('get','contratos/contrato/' + IdSolicitud, '');
    }

    downloadAnexo(IdSolicitud): any{
        return this.getQuery('get','contratos/anexo/' + IdSolicitud, '');
    }

    downloadDesembolso(IdSolicitud): any{
        return this.getQuery('get','contratos/desembolso/' + IdSolicitud, '');
    }

    downloadPrenda(IdGarantia): any{
        return this.getQuery('get','contratos/prenda/' + IdGarantia, '');
    }

    downloadCotizacion(IdSolicitud): any{
        return this.getQuery('get','contratos/cotizacion/' + IdSolicitud, '');
    }

    downloadBuro(IdSolicitud): any{
        return this.getQuery('get','contratos/buro/' + IdSolicitud, '');
    }

    sendRevisionComite(data): any{
        return this.getQuery('post','promotor/mandar/revision/comite', data);
    }

    sendEnvioContratos(data): any{
        return this.getQuery('post','promotor/pasar/revision/documentos', data);
    }

    downloadBuroAval(IdSolicitud, IdPersona){
        return this.getQuery('get','contratos/buro/' + IdSolicitud + '/' + IdPersona, '');
    }

    updateDocumentoSeguro(data): any{ 
        return this.getQuery('post','promotor/actualizar/documento_seguro',data);
    }
    
}
