import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Collection } from 'src/app/models/collection';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PromotorD5RevisionAcreditacionPreanalisis } from 'src/app/services/promotor/D5RevisionAcreditacionPreanalisis.service';
import { K2ContratosService } from 'src/app/services/K2ContratosService.service';
import { PromotorC1PreanalisiService } from 'src/app/services/promotor/C1PreanalisisService.service';

@Component({
    selector: 'app-d5-revision-acreditacion-preanalisis',
    templateUrl: './d5-revision-acreditacion-preanalisis.component.html'
})

export class D5RevisionAcreditacionPreanalisisComponent implements OnInit, AfterViewInit {

    public status: number;
    public IdSolicitud:number;
    public editMode: boolean;
    public frm: FormGroup;
    public loading : boolean;
    public errorMessages : string;
    public active: boolean;

    /* Collections */
    public data: any;
    public currentState: any;
    public collectionsEstadoCivil: Collection;
    public collectionsRegimenConyugal: Collection;
    public collectionsParentezco: Collection;
    public collectionsTipoVivienda: Collection;
    public motivoRechazo: Collection;

    /* strings */

    public EstadoCivil: string;
    public RegimenConyugal: string;
    public Parentezco: string;
    public Vivienda: string;

    public CapacidadPago: any;

    public burosc: string;
    public score: any;

    public estatus_qeq: number;
    public coincidencias: any;
    public ComentariosQeq: string;
    public EsPep: string;

    public showBottomBuro: boolean;

    public canEdit: boolean;
    public canDecline: boolean;
    public canCotizar: boolean;

    constructor(private route: ActivatedRoute,
        private utilities: UtilitiesService,
        private fb: FormBuilder,
        private modalErrors: NgbModal,
        private D5Service : PromotorD5RevisionAcreditacionPreanalisis,
        private router: Router,
        private K2Service: K2ContratosService,
        private modalCoincidencias: NgbModal,
        private C1Service: PromotorC1PreanalisiService,
    ) {
        this.status = 2;
        this.editMode = false;
        this.showBottomBuro = false;
        this.canEdit = false;
        this.canDecline = false;
        this.canCotizar = false;
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    ngOnInit(): void {
        this.IdSolicitud = this.route.snapshot.params.id;
        this.route.data.subscribe((data: Data) => {
            this.data = data['collections'][0].data.revision_preanalisis;
            this.active = data['collections'][0].data.has_revision_preanalisis;
            this.currentState = data['collections'][0].data;
            this.collectionsEstadoCivil = data['collections'][1].data; 
            this.collectionsRegimenConyugal = data['collections'][2].data; 
            this.collectionsParentezco = data['collections'][3].data; 
            this.collectionsTipoVivienda = data['collections'][4].data; 
            this.CapacidadPago = data['collections'][0].data.capacidad_pago;

            this.burosc = data['collections'][0].data.burosc;
            this.score = data['collections'][0].data.variable;

            this.estatus_qeq = data['collections'][0].data.estatus_qeq;

            this.coincidencias = data['collections'][0].data.coincidencias;
            this.ComentariosQeq = data['collections'][0].data.ComentariosQeq;
            this.EsPep = data['collections'][0].data.EsPep;
        });

        this.canEdit = this.utilities.hasPermission('Edicion informacion');
        this.canDecline = this.utilities.hasPermission('Declinar Solicitud');
        this.canCotizar = this.utilities.hasPermission('Cotizacion en revision');

        this.route.data.subscribe((data: Data) => {
            this.motivoRechazo = data['motivoRechazo'][0].data;
        });



        if ( ! this.active )
            this.router.navigateByUrl("promotor/revision_solicitud/"+this.IdSolicitud)

        this.EstadoCivil = this.utilities.getFieldFromCollection(this.collectionsEstadoCivil, this.data.IdEstadoCivil, "name");

        if ( this.data.IdRegimenConyugal !== null){
            this.RegimenConyugal = this.utilities.getFieldFromCollection(this.collectionsRegimenConyugal, this.data.IdRegimenConyugal, "name");
        }

        if(this.data.IdParentescoConEstudiante !== null){
            this.Parentezco = this.utilities.getFieldFromCollection(this.collectionsParentezco, this.data.IdParentescoConEstudiante, "name");
        }
        if(this.data.IdTipoVivienda !== null){
            this.Vivienda = this.utilities.getFieldFromCollection(this.collectionsTipoVivienda, this.data.IdTipoVivienda, "name");
        }


        if ( parseInt(this.burosc) < 1 ) {
            this.showBottomBuro = true;
        }

        this.frm = this.createForm();
    }

    verCoincidencias(modalCoincidencias){
        this.modalCoincidencias.open(modalCoincidencias, { ariaLabelledBy: 'modal-basic-title', centered: false, size: 'xl', scrollable: true });
    }

    downloadBuro(){
        this.loading = true;
        this.K2Service.downloadBuro(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Buro.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
            },
          )
    }

    triggerEdit(){
        this.editMode = true;
    }

    createForm(){
        return this.fb.group({
            confirmData:[
                null
            ]
        })
    }

    consultarBuro(){
        this.C1Service.consultarBuroPromotor(this.IdSolicitud).subscribe(
            response => {
            },
            errors => {
            }
        );
    }

    declinarSubmit(MotivoRechazo){
        if ( MotivoRechazo.value == '' ) {
            alert('Debe selecciona un motivo de rechazo');
            return;
        }

        let data = {MotivoRechazo: MotivoRechazo.value};
        
        this.utilities.declinarSolicitud(this.IdSolicitud, data).subscribe(
            resp => {
                this.modalErrors.dismissAll();
                this.router.navigateByUrl("promotor/dashboard");
            },
            errors => {
            }
        );
    }

    declinar(modalDeclinar){
        this.modalErrors.open(modalDeclinar, { ariaLabelledBy: 'modal-basic-title', centered: false});

        // if ( window.confirm('¿Estás seguro que deseas declinar la solicitud?') ) {
        // }
    }

    onSubmit(modalTarget){
        this.loading = true;
        this.D5Service.sendCheckedData(this.IdSolicitud).subscribe(
            response =>{
                this.loading = false;
                this.router.navigateByUrl("promotor/revision_acreditacion_referencias/"+this.IdSolicitud);
            }, 
            errors =>{
                this.loading = false;

                this.errorMessages = errors.error.message;
                this.modalErrors.open(modalTarget, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        );
    }

    recibirCancelacion($event){
        this.editMode = false;
    }

    cotizacion(){
        this.loading = true;
        this.router.navigateByUrl("promotor/revision_cotizacion/"+this.IdSolicitud);
    }

    recibirGuardado($event){
        this.editMode = false;
        this.data = $event.data;
        this.score = this.data.variable;
        this.EstadoCivil = this.utilities.getFieldFromCollection(this.collectionsEstadoCivil, $event.data.IdEstadoCivil, "name");
        if ( $event.data.IdRegimenConyugal){
            this.RegimenConyugal = this.utilities.getFieldFromCollection(this.collectionsRegimenConyugal, $event.data.IdRegimenConyugal, "name");
        }
        if($event.data.IdParentescoConEstudiante !== null){
            this.Parentezco = this.utilities.getFieldFromCollection(this.collectionsParentezco, $event.data.IdParentescoConEstudiante, "name");
        }
        if($event.data.IdTipoVivienda !== null){
            this.Vivienda = this.utilities.getFieldFromCollection(this.collectionsTipoVivienda, $event.data.IdTipoVivienda, "name");
        }
        window.location.reload()
    }
}