import { Component, OnInit, Input } from "@angular/core";

@Component({
    selector: 'app-temp-messages-promotor-wait',
    templateUrl: './temp-messages-wait.component.html',
})
export class TempMessagesWaitPromotorComponent implements OnInit {
    @Input('messages') messages: any;
    public status: number;
    constructor() { 
        this.status = 7;
    }

    ngOnInit() { 
    }
}