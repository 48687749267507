import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Data, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { J1Desembolso } from 'src/app/services/promotor/J1Desembolso.service';
import { J1DesembolsoEditComponent } from './j1-desembolso-edit.component';
import { requiredMultipleFileType } from '../../../directives/upload-file-multiple-validator';
import { requiredFileSize } from '../../../directives/upload-file-size-validator';
import { toFormData } from '../../client/e4-confirmacion-identidad/e4-confirmacion-identidad.component';

@Component({
    selector: 'app-j1-desembolso',
    templateUrl: './j1-desembolso.component.html'
})

export class J1DesembolsoComponent implements OnInit, AfterViewInit {
    @ViewChild(J1DesembolsoEditComponent, {static: false}) child: J1DesembolsoEditComponent;

    API_URL: string;
    public status: number;
    public editMode: boolean;
    public IdSolicitud: number;
    public loading: boolean;
    public errorMessages: string;

    public currentState: any;
    public currentData: any;
    public has_comment: boolean;
    public comment:any;
    public interesesFinanciados:number;
    public uploadCarta:boolean;

    public tempDesembolso: any;

    public frm: FormGroup;

    /* aditional numbers */

    public subsidio: number;
    public porcentajeSubsidio: number;
    public total: number;
    public intereses: number;
    public saldoContrato: number;
    public montoDesembolsar: number;


    public montosPagados: any;
    
    public role_user: number;

    public canEdit: boolean;
    
    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        public modalError: NgbModal,
        private utilities: UtilitiesService,
        private J1Service: J1Desembolso,
        private modalErrors: NgbModal,
        private router: Router,
        private utilitiesService: UtilitiesService
    ) {
        this.status = 8;
        this.editMode = false;
        this.API_URL = environment.API_URL.replace('api/v1/', '');
        this.canEdit = false;
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    triggerEdit(){
        this.editMode = true;
    }

    recibirCancelacion($event){
        this.editMode = false;
    }

    handlePayments(event){
        
    }

    onSubmit(modalTarget){
        if ( this.child.sum != this.total ) {
            alert('La cantidad a pagar debe de ser igual al importe a desembolsar.');
            return;
        }
        this.loading = true;
        var temp = {...this.frm.value};
        /*Para el desembolso se usan 
            IdSolicitud: this.IdSolicitud,
            SaldoContratoAnterior: temp.SaldoContratoAnterior,
            Subsidio: temp.Subsidio,
            InteresesOrdinarios: temp.InteresesOrdinarios,
            MontoPorDesembolsar: temp.MontoPorDesembolsar,
            FechaPago1: this.utilities.parseDate(temp.FechaPago1),
            FechaPago2: this.utilities.parseDate(temp.FechaPago2),
        */
       
        const sendData=toFormData(this.frm.value)
        sendData.set('FechaPago1',this.utilities.parseDate(temp.FechaPago1));
        sendData.set('FechaPago2',this.utilities.parseDate(temp.FechaPago2));
       
        var pagoInicial = {
            IdSolicitud:this.IdSolicitud,
            ImportePagadoPagoInicial1:temp.ImportePagadoPagoInicial1,
            FechaPagoInicial1:this.utilities.parseDate(temp.FechaPagoInicial1),
            ImportePagadoPagoInicial2:temp.ImportePagadoPagoInicial2,
            FechaPagoInicial2:this.utilities.parseDate(temp.FechaPagoInicial2),
            ImportePagadoPagoInicial3:temp.ImportePagadoPagoInicial3,
            FechaPagoInicial3:this.utilities.parseDate(temp.FechaPagoInicial3),
        }

        if (!this.frm.valid) {
            Object.keys(this.frm.value).forEach(element => {
            if ( this.frm.get(element).errors != null ) {
                this.frm.get(element).markAsDirty();
            }
            });
            return;
        }
        
        this.J1Service.pagoInicial(pagoInicial).subscribe(
            response =>{
                this.J1Service.desembolso(sendData).subscribe(
                    response =>{
                        this.loading = false;
                        this.router.navigateByUrl("promotor/mesa_control/"+this.IdSolicitud);
                    }, 
                    errors =>{
                        this.loading = false;
        
                        this.errorMessages = errors.error.message;
                        this.modalErrors.open(modalTarget, { ariaLabelledBy: 'modal-basic-title', centered: true });
                    }
                )
                
            }, 
            errors =>{
                this.loading = false;
                this.errorMessages = errors.error.message;
                this.modalErrors.open(modalTarget, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        )
    }

    handlePagos(evt) {
        var index = evt.target.selectedIndex;
        var pago = evt.target[index].text;

        if ( pago == 1 ) {
            this.utilitiesService.removeControlsValidity(this.frm, ['ImportePagadoPagoInicial2', 'FechaPagoInicial2']);
            this.frm.patchValue({ImportePagadoPagoInicial2: null, FechaPagoInicial2: null});
        }
        else{
            this.frm.controls['ImportePagadoPagoInicial2'].setValidators([Validators.required, Validators.min(1)]);
            this.frm.controls['FechaPagoInicial2'].setValidators([Validators.required]);
        }
      }

    createForm(){
        return this.fb.group({
            IdSolicitud:[
                null,
            ],
            CartaSubsidio:[
                null,
            ],
            TotalPagos:[
                null,
            ],
            ImportePagadoPagoInicial1:[
                null,
                [Validators.required, Validators.min(1)]
            ],
            FechaPagoInicial1:[
                null,
                Validators.required
            ],
            ImportePagadoPagoInicial2:[
                null,
                null
            ],
            FechaPagoInicial2:[
                null,
                null
            ],
            ImportePagadoPagoInicial3:[
                null
            ],
            FechaPagoInicial3:[
                null
            ],
            SaldoContratoAnterior: [
                null
            ],
            Subsidio: [
                null
            ],
            InteresesOrdinarios: [
                null
            ],
            MontoPorDesembolsar:[
                null
            ],
            FechaPago1:[
                null,
                Validators.required
            ],
            FechaPago2:[
                null
            ],
        })
    }
    

    ngOnInit(): void {
        this.IdSolicitud = this.route.snapshot.params.id;
        this.route.data.subscribe((data: Data) => {
            this.currentState = data['collections'][0].data;
            this.currentData = data['collections'][1].data;
        });
        this.frm = this.createForm();

        this.interesesFinanciados=0;
        this.canEdit = this.utilities.hasPermission('Solicitud desembolso');

        this.has_comment=this.currentData.has_comentario;
        if (this.has_comment) {
            this.comment={
                comentario:this.currentData.Comentario.Comentario,
                owner:this.currentData.Comentario.TipoComentario==3?'Mesa de Control':'Tesorería'
            }    
        }
        this.montoDesembolsar = parseFloat(this.currentData.detallesSolicitud.MontoSolicitado);
        this.porcentajeSubsidio = parseFloat(this.currentData.detallesSolicitud.Subsidio) * 100;
        this.subsidio = this.montoDesembolsar * this.porcentajeSubsidio;
        this.intereses = this.currentData.detallesSolicitud.Desembolso.InteresesOrdinarios !== null ? parseFloat(this.currentData.detallesSolicitud.Desembolso.InteresesOrdinarios) : 0;
        this.saldoContrato = this.currentData.detallesSolicitud.Desembolso.SaldoContratoAnterior !== null ? parseFloat(this.currentData.detallesSolicitud.Desembolso.SaldoContratoAnterior) : 0;
        this.total = this.currentData.detallesSolicitud.Desembolso.MontoPorDesembolsar !== null ? parseFloat(this.currentData.detallesSolicitud.Desembolso.MontoPorDesembolsar) : this.montoDesembolsar - this.subsidio - this.intereses - this.saldoContrato;

        this.montosPagados = "1";
        
        if  (this.currentData.pagoInicial.ImportePagadoPagoInicial2 !== null || this.currentData.pagoInicial.ImportePagadoPagoInicial2 !== undefined){
            this.montosPagados = "2";
        }else{
            if  (this.currentData.pagoInicial.ImportePagadoPagoInicial3 !== null || this.currentData.pagoInicial.ImportePagadoPagoInicial3 !== undefined){
                this.montosPagados = "3";
            }
        }

        this.tempDesembolso = this.currentData.informacionPago;
        this.frm.patchValue({
            TotalPagos: this.montosPagados,
            IdSolicitud: this.IdSolicitud,
            SaldoContratoAnterior: this.saldoContrato,
            Subsidio: this.subsidio,
            InteresesOrdinarios: this.intereses,
            MontoPorDesembolsar:this.total,
            FechaPago1: this.tempDesembolso[0]?this.utilities.formatDateObject(this.tempDesembolso[0].FechaDePago):null,
            FechaPago2:this.tempDesembolso[1]?this.utilities.formatDateObject(this.tempDesembolso[1].FechaDePago):null,
            ImportePagadoPagoInicial1:this.currentData.pagoInicial.ImportePagadoPagoInicial1,
            FechaPagoInicial1: this.utilities.formatDateObject(this.currentData.pagoInicial.FechaPagoInicial1),
            ImportePagadoPagoInicial2:this.currentData.pagoInicial.ImportePagadoPagoInicial2,
            FechaPagoInicial2: this.utilities.formatDateObject(this.currentData.pagoInicial.FechaPagoInicial2),
            ImportePagadoPagoInicial3:null,
            FechaPagoInicial3:null,
        });


        this.role_user = parseInt( localStorage.getItem('userRole') );
    }

    calculateTotal(){
        
        this.total = this.montoDesembolsar - parseFloat(this.frm.controls['SaldoContratoAnterior'].value) - (this.interesesFinanciados*parseFloat(this.frm.controls['InteresesOrdinarios'].value)) - this.subsidio;
        this.total = parseFloat(this.total.toFixed(2));
        this.frm.patchValue({
            MontoPorDesembolsar:this.total,
        })
    }

    recibirGuardado($event){
        this.editMode = false;
        this.tempDesembolso = $event.data['InformacionPago'];
        this.currentData.documentos=[...$event.data['documentos']]
    }

    resetValue(id) {
        this.frm.controls[id].patchValue(null);
        document.querySelector("#" + id).querySelectorAll('input')[0]
        document.querySelector("#" + id).querySelectorAll('input')[0].value = null;
      }

    changeIntereses(event){
        this.interesesFinanciados = event.target.checked ? 1 : 0;
        this.calculateTotal();
    }

    changeCarta(event){
        this.uploadCarta = event.target.checked?true:false;
        if(this.uploadCarta){
            this.frm.controls['CartaSubsidio'].setValidators([Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]);
        }
        this.frm.controls['CartaSubsidio'].clearValidators();
    }
}