import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Collection } from 'src/app/models/collection';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { E5Service } from "../../../services/E5DocumentacionService.service";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { requiredMultipleFileType } from '../../../directives/upload-file-multiple-validator';
import { requiredFileSize } from '../../../directives/upload-file-size-validator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesService } from 'src/app/services/utilities_services.service';

@Component({
  selector: 'app-e5-documentacion',
  templateUrl: './e5-documentacion.component.html',
  styleUrls: ['./e5-documentacion.component.scss']
})
export class E5DocumentacionComponent implements OnInit,AfterViewInit {
  public status: number;
  public collectionsIdentificacion: Collection;
  public collectionsIdentificacion2: Collection;
  public collectionsComprobanteDomicilio: Collection;
  public collectionsComprobanteIngresos: Collection;
  public collectionsCedula: Collection;
  public IdSolicitud: any;
  public loading: boolean;
  public errorMessages: string;
  public GarantiaAuto: boolean;
  public GarantiaRPP: boolean;
  public need_rfc: boolean;
  public TipoIdentificacionVista:any;
  public NoComprobantes:number;
  public ingresos:boolean;
  public index:any;


  public frmE5 = new FormGroup({
    TipoIdentificacion: new FormControl(null, [Validators.required, Validators.min(1)]),
    IdentificacionVigente: new FormControl(null, [Validators.required, requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]),
    IdentificacionVigente2: new FormControl(null, [Validators.required, requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]),
    TipoComprobante: new FormControl(null, [Validators.required, Validators.min(1)]),
    ComprobanteDomicilio: new FormControl(null, [Validators.required, requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]),
    TipoCedulaFiscal: new FormControl(null, [Validators.required, Validators.min(1)]),
    CedulaFiscal: new FormControl(null, [Validators.required, requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]),
    GrantiaAuto: new FormControl(null, [Validators.required, requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]),
    EvidenciaInmueble: new FormControl(null, [Validators.required, requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]),
    IdSolicitud: new FormControl(null)
  });

  constructor(
    private E5Service: E5Service,
    private route: ActivatedRoute,
    private router: Router,
    private modalError: NgbModal,
    private utilities: UtilitiesService
  ) {
    this.status = 3;
    this.loading = false;
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.IdSolicitud = this.route.snapshot.params.id;
    this.frmE5.patchValue({
      IdSolicitud: this.IdSolicitud
    })
    this.route.data.subscribe((data: Data) => {
      this.collectionsIdentificacion = data['collections'][0].data;
      this.collectionsIdentificacion2 = data['collections'][0].data;
      this.collectionsComprobanteDomicilio = data['collections'][1].data;
      this.collectionsComprobanteIngresos = data['collections'][2].data;
      this.collectionsCedula = data['collections'][3].data;
      this.GarantiaAuto = data['collections'][4].data.GarantiaAuto;
      this.GarantiaRPP = data['collections'][4].data.GarantiaRPP;
      this.need_rfc = data['collections'][4].data.rfc;
      this.NoComprobantes=data['IdSolicitud'].data.NoComprobantes;
    });

    this.ingresos=false;
    this.index=[];
    for (let i=1;i<=this.NoComprobantes;i++){
      this.index.push({mes:i,tipo:'TipoComprobanteMes'+i,comprobante:'ComprobanteIngresosMes'+i});
      this.frmE5.addControl('TipoComprobanteMes'+i, new FormControl(null, [Validators.required, Validators.min(1)]));
      this.frmE5.addControl('ComprobanteIngresosMes'+i, new FormControl(null, [Validators.required, requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]));
    }
    this.ingresos=true;

    if ( ! this.GarantiaAuto ) {
      this.utilities.removeControlsValidity(this.frmE5, ['GrantiaAuto']);
    }

    if ( ! this.GarantiaRPP ) {
      this.utilities.removeControlsValidity(this.frmE5, ['EvidenciaInmueble']);
    }

    if ( ! this.need_rfc ) {
      this.utilities.removeControlsValidity(this.frmE5, ['CedulaFiscal']);
      this.utilities.removeControlsValidity(this.frmE5, ['TipoCedulaFiscal']);
      this.frmE5.patchValue({TipoCedulaFiscal: null});
    }
    else{
      this.frmE5.patchValue({TipoCedulaFiscal: 13});
    }
  }

  onSubmit(event: any, modalError) {
    event.preventDefault();
    if (!this.frmE5.valid) {
      Object.keys(this.frmE5.value).forEach(element => {
        if ( this.frmE5.get(element).errors != null ) {
          this.frmE5.get(element).markAsDirty();
        }
      });
      return;
    }

    this.loading = true;
    let sendData = toFormData(this.frmE5.value);
    this.E5Service.sendDocumentacion(sendData).subscribe(
      resp => {
        let redirect = this.utilities.handleRedirect(resp['proceso']);
        this.router.navigateByUrl(redirect+"/"+this.IdSolicitud);
      },
      errors => {
        this.loading = false;

        if ( errors.status == 0 ) {
          this.errorMessages = 'Error al cargar los documentos. La solicitud es muy pesada. Favor de subir documentos de menor tamaño.';
        }
        else{
          this.errorMessages = errors.error.message;
        }
        this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
      },
    )
  }

  resetValue(id) {
    this.frmE5.controls[id].patchValue(null);
    document.querySelector("#" + id).querySelectorAll('input')[0]
    document.querySelector("#" + id).querySelectorAll('input')[0].value = null;
  }
  handleTipo(event){
    this.TipoIdentificacionVista=!(event.target.value=='2' || event.target.value=='0')?true:false;
    if (! this.TipoIdentificacionVista){
      this.utilities.removeControlsValidity(this.frmE5, ['IdentificacionVigente2']);
    }else{
      let controls = {
        IdentificacionVigente2: [
          null,
          [Validators.required, requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]
        ]
      }
      this.frmE5.controls['IdentificacionVigente2'].setValidators([
        Validators.required, requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()
      ])
      this.frmE5.get("IdentificacionVigente2").updateValueAndValidity();
      //this.utilities.addControlsValidity(this.frmE5, controls);
    }
  }
}


export function markAllAsDirty(form: FormGroup) {
  for (const control of Object.keys(form.controls)) {
    form.controls[control].markAsDirty();
  }
}

export function toFormData<T>(formValue: T) {
  const formData = new FormData();
  let total_mb = 0;
  for (const key of Object.keys(formValue)) {
    const value = formValue[key];
    if ( value !== null ) {
      if ( value instanceof File ) {
        total_mb = total_mb + (value.size/1000000);
      }
      formData.append(key, value);
    }
  }

  return formData;
}
