import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute, Data } from '@angular/router';
import { Collection } from 'src/app/models/collection';
import { E3Referencias } from "../../../models/E3Referencias.model";
import { E3ReferenciasService } from "../../../services/E3ReferenciasService.service";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesService } from 'src/app/services/utilities_services.service';

@Component({
  selector: 'app-e3-referencias',
  templateUrl: './e3-referencias.component.html',
  styleUrls: ['./e3-referencias.component.scss']
})
export class E3ReferenciasComponent implements OnInit,AfterViewInit {
  public status: number;
  public collectionsParentezco: Collection;
  public data: E3Referencias;
  public frmE3: FormGroup;
  public IdSolicitud: number;
  public loading: boolean;
  public errorMessages;
  public previous_data: any;
  public dataFormat = {
    Nombre1: null,
    ApellidoPaterno1: null,
    ApellidoMaterno1: null,
    Telefono1: null,
    Celular1: null,
    IdParentesco1: null,
    TiempoConocerlo1: null,
    HorarioContacto1: null,
    Nombre2: null,
    ApellidoPaterno2: null,
    ApellidoMaterno2: null,
    Telefono2: null,
    Celular2: null,
    IdParentesco2: null,
    TiempoConocerlo2: null,
    HorarioContacto2: null,
    IdSolicitud: null,
  };

  constructor(
    private E3Service: E3ReferenciasService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private modalError: NgbModal,
    private router: Router,
    private utilities: UtilitiesService
  ) {
    this.status = 3;
    this.loading = false;
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.IdSolicitud = this.route.snapshot.params.id;
    this.route.data.subscribe((data: Data) => {
      this.collectionsParentezco = data['collections'][0].data;
    });
    if (this.route.snapshot.data.IdSolicitud) {
      this.previous_data = this.route.snapshot.data.IdSolicitud.data.E3;
    }

    this.frmE3 = this.createE3Form();
    if (this.previous_data) {
      this.dataFormat.IdSolicitud = this.IdSolicitud;
      this.dataFormat.Nombre1 = this.previous_data.referencia1.Nombre;
      this.dataFormat.ApellidoPaterno1 = this.previous_data.referencia1.ApellidoPaterno;
      this.dataFormat.ApellidoMaterno1 = this.previous_data.referencia1.ApellidoMaterno;
      this.dataFormat.Telefono1 = this.previous_data.referencia1.Telefono;
      this.dataFormat.Celular1 = this.previous_data.referencia1.Celular;
      this.dataFormat.IdParentesco1 = this.previous_data.referencia1.IdParentesco;
      this.dataFormat.TiempoConocerlo1 = this.previous_data.referencia1.TiempoConocerlo;
      this.dataFormat.HorarioContacto1 = this.previous_data.referencia1.HorarioContacto;

      if ( this.previous_data.referencia2 !== null ) {
        this.dataFormat.Nombre2 = this.previous_data.referencia2.Nombre;
        this.dataFormat.ApellidoPaterno2 = this.previous_data.referencia2.ApellidoPaterno;
        this.dataFormat.ApellidoMaterno2 = this.previous_data.referencia2.ApellidoMaterno;
        this.dataFormat.Telefono2 = this.previous_data.referencia2.Telefono;
        this.dataFormat.Celular2 = this.previous_data.referencia2.Celular;
        this.dataFormat.IdParentesco2 = this.previous_data.referencia2.IdParentesco;
        this.dataFormat.TiempoConocerlo2 = this.previous_data.referencia2.TiempoConocerlo;
        this.dataFormat.HorarioContacto2 = this.previous_data.referencia2.HorarioContacto;
      }
      this.frmE3.patchValue(this.dataFormat);
    } else {
      this.frmE3.patchValue({
        IdSolicitud: this.IdSolicitud
      })
    }

  }

  onSubmit(modalError) {
    if (!this.frmE3.valid) {
      Object.keys(this.frmE3.value).forEach(element => {
        if ( this.frmE3.get(element).errors != null ) {
          this.frmE3.get(element).markAsDirty();
        }
      });
      return;
    }

    this.loading = true;
    this.E3Service.sendReferencias(this.frmE3.value).subscribe(
      resp => {
        let redirect = this.utilities.handleRedirect(resp['proceso']);
        this.router.navigateByUrl(redirect+"/"+this.IdSolicitud);
      },
      errors => {
        this.loading = false;
        this.errorMessages = errors.error.message;
        this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
      },
    )
  }


  createE3Form() {
    return this.fb.group({
      Nombre1: [
        null
      ],
      ApellidoPaterno1: [
        null
      ],
      ApellidoMaterno1: [
        null
      ],
      Telefono1: [
        null
      ],
      Celular1: [
        null
      ],
      IdParentesco1: [
        null,
        Validators.min(1)
      ],
      TiempoConocerlo1: [
        null
      ],
      HorarioContacto1: [
        null
      ],
      Nombre2: [
        null
      ],
      ApellidoPaterno2: [
        null
      ],
      ApellidoMaterno2: [
        null
      ],
      Telefono2: [
        null
      ],
      Celular2: [
        null
      ],
      IdParentesco2: [
        null,
        Validators.min(1)
      ],
      TiempoConocerlo2: [
        null
      ],
      HorarioContacto2: [
        null
      ],
      IdSolicitud: [
        null
      ],
    })
  }
}