import { FormControl } from '@angular/forms';

export function requiredFileSize() {
  return function ( control: FormControl ) {
    const file = control.value;
    
    if ( file ) {
      const size = file.size / 1048576;
      if ( size > 5 ) {
        return {
          maxFileSize: true
        };
      }

      return null;
    }

    return null;
  };
}
