const ESTUDIANTE_SIN_BURO = 2;
const GARANTE_AUTO = 7;
const GARANTE_RPP = 8;
const CONYUGE = 9;

const COMPLEMENTO_INGRESOS = 5;
const PROVEEDOR_RECURSOS = 4;

const ESTUDIANTE_CON_BURO = 3;
const REFORZAR_BURO = 6;

export const avalPrivileges = {
    InformacionPersonal: [COMPLEMENTO_INGRESOS,CONYUGE,ESTUDIANTE_CON_BURO,ESTUDIANTE_SIN_BURO,PROVEEDOR_RECURSOS,REFORZAR_BURO,GARANTE_AUTO,GARANTE_RPP],
    InformacionFiscal:[COMPLEMENTO_INGRESOS,CONYUGE,ESTUDIANTE_CON_BURO,ESTUDIANTE_SIN_BURO,PROVEEDOR_RECURSOS,REFORZAR_BURO,GARANTE_AUTO,GARANTE_RPP],
    Buro: [PROVEEDOR_RECURSOS,REFORZAR_BURO,ESTUDIANTE_CON_BURO,COMPLEMENTO_INGRESOS,CONYUGE,GARANTE_AUTO,GARANTE_RPP],
    InformacionFamiliar: [PROVEEDOR_RECURSOS,COMPLEMENTO_INGRESOS],
    InformacionLaboral:[PROVEEDOR_RECURSOS,COMPLEMENTO_INGRESOS],
    InformacionEconomica:[PROVEEDOR_RECURSOS,COMPLEMENTO_INGRESOS],
    ComprobacionIdentidad:[PROVEEDOR_RECURSOS,REFORZAR_BURO,ESTUDIANTE_CON_BURO,COMPLEMENTO_INGRESOS],
    Documentacion:[COMPLEMENTO_INGRESOS,CONYUGE,ESTUDIANTE_CON_BURO,ESTUDIANTE_SIN_BURO,PROVEEDOR_RECURSOS,REFORZAR_BURO,GARANTE_AUTO,GARANTE_RPP],
}