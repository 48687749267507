import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Collection } from 'src/app/models/collection.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { F1CotizacionService } from 'src/app/services/promotor/F1CotizacionService.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { K2ContratosService } from 'src/app/services/K2ContratosService.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';

@Component({
    selector: 'app-d5-revision-cotizacion',
    templateUrl: './d5-revision-cotizacion.component.html'
})

export class D5RevisionCotizacionComponent implements OnInit {
    public status: number;
    public IdSolicitud: number;
    public persona: any;
    public collectionsPlanes: any;
    public collectionsPeriodos: Collection;
    public diferida: boolean;
    public disposiciones: boolean;
    public loading: boolean;
    public frmF1: FormGroup;
    public dataFormat = {
        MontoSolicitado: null,
        Plazo: null,
        IdPlan: null,
        Agencia: null,
        FechaInicio: null,
        PorcentajeTasa: null,
        PorcentajeComision: null,
        NoDisposiciones: null,
        IdPeriodicidadDisposiciones: null,
        IdSolicitud: null,
        MensualidadesGarantia: null,
        MontoDisposicion: null,
    };
    public motivoRechazo: Collection;
    public clickCotizacion: boolean;
    public errorMessages: string;
    public Mensualidad: number;
    public PagoInicial: number;
    public cotizacion: any;
    public PorcentajeTasa: any;
    public AplicaDiferida: boolean;
    public AplicaDispociones: boolean;
    public Meses: any = [];
    public MaxMeses: number;
    public PlazoMin: number;
    public PlazoMax: number;
    public PlazoArray: any = [];
    public DespositoMin: number;
    public DespositoMax: number;
    public DepositosGarantia: any = [];
    public minDate: any;
    public proceso: any;
    public has_comment: boolean;
    public comment: string;
    public nombreAlianza: number;

    public AplicaTasaEspecial: boolean;

    public TienePeriodosGracia: boolean;
    public AplicaPeriodosGraciaF: boolean;
    public MinPeriodosGracia: number;
    public MaxPeriodosGracia: number;

    constructor(
        public route: ActivatedRoute,
        private fb: FormBuilder,
        private F1Service: F1CotizacionService,
        private modalError: NgbModal,
        private K2Service: K2ContratosService,
        private utilities: UtilitiesService,
        private router: Router,
    ) {
        this.status = 4;
        this.AplicaTasaEspecial = false;
        this.AplicaPeriodosGraciaF = false;
    }

    ngOnInit(): void {
        this.frmF1 = this.createF1Form();

        this.route.data.subscribe((data: Data) => {
            this.motivoRechazo = data['motivoRechazo'][0].data; 
            this.nombreAlianza = data['collections'][3].data.revision_destino.IdAlianza;
            
        });

        this.proceso = this.route.snapshot.data.solicitudPromotor.proceso;

        this.IdSolicitud = this.route.snapshot.data.solicitudPromotor.data.IdSolicitud;
        this.persona = this.route.snapshot.data.solicitudPromotor.data.persona;
        this.Mensualidad = this.route.snapshot.data.solicitudPromotor.data.detallesSolicitud.Mensualidades;
        this.PagoInicial = this.route.snapshot.data.solicitudPromotor.data.detallesSolicitud.PagoInicial;
        this.cotizacion = this.route.snapshot.data.solicitudPromotor.data.cotizacion;

        this.has_comment = this.route.snapshot.data.solicitudPromotor.data.has_comentarios;


        if ( this.has_comment ) {
            this.comment = this.route.snapshot.data.solicitudPromotor.data.comentarios_comite.Comentario;
        }

        this.collectionsPlanes = this.cotizacion.Planes;

        this.PorcentajeTasa = this.cotizacion.PorcentajeTasa;

        this.clickCotizacion = false;

        this.diferida = this.cotizacion.EsDiferida;

        this.disposiciones = false;

        if ( this.cotizacion.CambiosComite == 1 ) {
            if ( this.cotizacion.AplicaTasaEspecial == 1 ) {
                this.AplicaTasaEspecial = true;
                this.frmF1.patchValue({TieneTasaEspecial: true, TasaEspecial: this.cotizacion.TasaEspecial});
            }
            else{
                this.frmF1.patchValue({TieneTasaEspecial: false, TasaEspecial: null});
            }
        }
        else{
            this.frmF1.patchValue({TieneTasaEspecial: false, TasaEspecial: null});
        }

        this.minDate = {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate()
        };

        this.collectionsPlanes.forEach(plan => {
            if ( plan.id == this.cotizacion.PlanSeleccionado ) {
                this.AplicaDiferida = !!parseInt(plan.AplicaDiferida);
                this.AplicaDispociones = !!parseInt(plan.AplicaDisposiciones);
                this.MaxMeses = parseInt(plan.MaxMeses);
                this.PlazoMin = parseInt(plan.PlazoMin);
                this.PlazoMax = parseInt(plan.PlazoMax);

                this.DespositoMin = parseInt(plan.DepositosMin);
                this.DespositoMax = parseInt(plan.DepositosMax);

                this.frmF1.controls["MontoSolicitado"].clearValidators();
                this.frmF1.controls['MontoSolicitado'].setValidators([
                    Validators.compose(
                        [
                        Validators.required,
                        Validators.min(plan.MontoMinimo),
                        Validators.max(plan.MontoMaximo),

                        ]
                    )
                ]);
                this.frmF1.get("MontoSolicitado").updateValueAndValidity();

                this.frmF1.controls["PorcentajeComision"].clearValidators();
                this.frmF1.controls['PorcentajeComision'].setValidators([
                    Validators.compose(
                        [
                        Validators.required,
                        Validators.min(plan.PorcComisionMin),
                        Validators.max(plan.PorcComisionMax),

                        ]
                    )
                ]);
                this.frmF1.get("PorcentajeComision").updateValueAndValidity();

                if ( plan.TienePeriodosGracia ) {
                    this.TienePeriodosGracia = true;

                    this.MinPeriodosGracia = plan.MinGraciaCapital;
                    this.MaxPeriodosGracia = plan.MaxGraciaCapital;

                    if ( this.cotizacion.AplicaPeriodosGracia ) {
                        this.AplicaPeriodosGraciaF = true;
                        this.frmF1.patchValue({AplicaPeriodosGracia: true, PeriodosGracia: this.cotizacion.PeriodosGraciaCapital, InicioGraciaCapital: this.cotizacion.InicioGraciaCapital});

                        this.frmF1.controls["PeriodosGracia"].clearValidators();
                        this.frmF1.controls['PeriodosGracia'].setValidators([
                            Validators.compose(
                                [
                                Validators.required,
                                Validators.min( this.MinPeriodosGracia ),
                                Validators.max( this.MaxPeriodosGracia ),

                                ]
                            )
                        ]);
                    }
                    else{
                        this.frmF1.controls["PeriodosGracia"].clearValidators();
                        this.frmF1.controls['PeriodosGracia'].setValidators([
                            Validators.compose(
                                [
                                null,
                                Validators.min( this.MinPeriodosGracia ),
                                Validators.max( this.MaxPeriodosGracia ),

                                ]
                            )
                        ]);
                        // this.utilities.removeControlsValidity(this.frmF1, ['InicioGraciaCapital']);
                        this.AplicaPeriodosGraciaF = false;
                        this.frmF1.patchValue({AplicaPeriodosGracia: false});
                    }
                }
                else{
                    this.TienePeriodosGracia = false;
                    this.frmF1.patchValue({AplicaPeriodosGracia: false});
                }
            }
        });

        if ( this.AplicaDiferida ) {

            for (let index = 1; index <= this.MaxMeses; index++) {
                this.Meses.push( index );
            }
        }

        for (let index = this.PlazoMin; index <= this.PlazoMax; index++) {
            this.PlazoArray.push( index );
        }

        for (let index = this.DespositoMin; index <= this.DespositoMax; index++) {
            this.DepositosGarantia.push( index );
        }

        this.loading = false;

        this.route.data.subscribe((data: Data) => {
            this.collectionsPeriodos = data['collections'][1].data;
        });

        var date = this.utilities.formatDateObject( this.cotizacion.FechaPrimerPago );

        if ( this.cotizacion.EsDiferida ) {
            this.frmF1.patchValue({EsDiferida: true, MesesDiferidos: this.cotizacion.DiferidaMeses});
            this.diferida = true;
        }

        if ( this.cotizacion.TipoCotizacion == 1) {
            this.disposiciones = false;
            this.frmF1.patchValue({EsDiferida: false});
            this.diferida = false;
            this.frmF1.patchValue({radio: "0"});
        }
        else{
            if ( this.cotizacion.TieneDisposiciones ) {
                this.disposiciones = true;
                this.frmF1.patchValue({NoDisposiciones: this.cotizacion.NumeroDisposiciones, MontoDisposicion: this.cotizacion.MontoDisposiciones, IdPeriodicidadDisposiciones: this.cotizacion.IdPeriodicidadDisposiciones});
                this.frmF1.patchValue({radio: "1"});
            }
            else{
                this.disposiciones = false;
                this.frmF1.patchValue({radio: "0"});
            }
        }

        this.frmF1.patchValue({MontoSolicitado: this.cotizacion.MontoFinanciar, IdPlan: this.cotizacion.PlanSeleccionado, Plazo: this.cotizacion.Plazo, PorcentajeComision: this.cotizacion.PorcentajeComision, MensualidadesGarantia: this.cotizacion.NumeroRentasDeposito, FechaInicio: date});
    }

    voler(){
        this.loading = true;
        this.router.navigateByUrl('/promotor/revision_acreditacion_preanalisis/' + this.IdSolicitud);
    }

    handlePlan(event){
        this.Meses = [];
        this.collectionsPlanes.forEach(plan => {
            if ( plan.id == event.target.value ) {
                this.AplicaDiferida = !!parseInt(plan.AplicaDiferida);
                this.AplicaDispociones = !!parseInt(plan.AplicaDisposiciones);
                this.MaxMeses = parseInt(plan.MaxMeses);

                this.PlazoMin = parseInt(plan.PlazoMin);
                this.PlazoMax = parseInt(plan.PlazoMax);
                this.DespositoMin = parseInt(plan.DepositosMin);
                this.DespositoMax = parseInt(plan.DepositosMax);
                
                this.frmF1.controls["MontoSolicitado"].clearValidators();
                this.frmF1.controls['MontoSolicitado'].setValidators([
                Validators.compose(
                    [
                    Validators.required,
                    Validators.min(plan.MontoMinimo),
                    Validators.max(plan.MontoMaximo),

                    ]
                )
                ]);
                this.frmF1.get("MontoSolicitado").updateValueAndValidity();

                this.frmF1.controls["PorcentajeComision"].clearValidators();
                this.frmF1.controls['PorcentajeComision'].setValidators([
                Validators.compose(
                    [
                    Validators.required,
                    Validators.min(plan.PorcComisionMin),
                    Validators.max(plan.PorcComisionMax),

                    ]
                )
                ]);
                this.frmF1.get("PorcentajeComision").updateValueAndValidity();

                if ( ! plan.AplicaDispociones ) {
                    this.disposiciones = false;
                }

                if ( ! plan.AplicaDiferida ) {
                    this.diferida = false;
                }

                if ( plan.TienePeriodosGracia ) {
                    this.TienePeriodosGracia = true;

                    this.MinPeriodosGracia = plan.MinGraciaCapital;
                    this.MaxPeriodosGracia = plan.MaxGraciaCapital;
                }
                else{
                    this.TienePeriodosGracia = false;
                    this.frmF1.patchValue({AplicaPeriodosGracia: false});
                }
            }
        });

        this.PlazoArray = [];

        for (let index = this.PlazoMin; index <= this.PlazoMax; index++) {
            this.PlazoArray.push( index );
        }

        this.DepositosGarantia = [];

        for (let index = this.DespositoMin; index <= this.DespositoMax; index++) {
            this.DepositosGarantia.push( index );
        }

        if ( this.AplicaDiferida ) {
            for (let index = 1; index <= this.MaxMeses; index++) {
                this.Meses.push( index );
            }
        }
    }

    changeTasaEspecial(event){
        var target = event.target;

        if (target.checked) {
            this.AplicaTasaEspecial = true;
            this.frmF1.patchValue({TieneTasaEspecial: true});
        } else {
            this.AplicaTasaEspecial = false;
            this.frmF1.patchValue({TieneTasaEspecial: false});
        }

        this.utilities.removeControlsValidity(this.frmF1, ['TasaEspecial']);
    }

    changePeriodoGracia(event){
        var target = event.target;

        this.frmF1.controls["PeriodosGracia"].clearValidators();

        if ( target.checked ) {
            this.AplicaPeriodosGraciaF = true;
            this.frmF1.patchValue({AplicaPeriodosGracia: true});

            this.frmF1.controls['PeriodosGracia'].setValidators([
                Validators.compose(
                    [
                        Validators.required,
                        Validators.min( this.MinPeriodosGracia ),
                        Validators.max( this.MaxPeriodosGracia ),
                    ]
                )
            ]);
        }
        else{
            this.utilities.removeControlsValidity(this.frmF1, ['PeriodosGracia', 'InicioGraciaCapital']);

            this.AplicaPeriodosGraciaF = false;
            this.frmF1.patchValue({AplicaPeriodosGracia: false});
        }
    }

    changeDiferida(event){
        var target = event.target;

        if (target.checked) {
            this.diferida = true;
            this.frmF1.controls['MesesDiferidos'].setValidators([Validators.required, Validators.min(1)]);
        } else {
            this.diferida = false;
            this.utilities.removeControlsValidity(this.frmF1, ['MesesDiferidos']);
        }
    }

    changeDisposiciones(event){
        var target = event.target;
        if (parseInt(target.value) === 1) {
            this.frmF1.patchValue({AplicaDispociones: true});
            this.disposiciones = true;
        }
        else{
            this.frmF1.patchValue({AplicaDispociones: false});
            this.disposiciones = false;
        }
    }

    declinarSubmit(MotivoRechazo){
        if ( MotivoRechazo.value == '' ) {
            alert('Debe selecciona un motivo de rechazo');
            return;
        }

        let data = {MotivoRechazo: MotivoRechazo.value};
        
        this.utilities.declinarSolicitud(this.IdSolicitud, data).subscribe(
            resp => {
                this.modalError.dismissAll();
                this.router.navigateByUrl("promotor/dashboard");
            },
            errors => {
            }
        );
    }

    calcularCotizacion(modalError){
        if (!this.frmF1.valid) {
            Object.keys(this.frmF1.value).forEach(element => {
              if ( this.frmF1.get(element).errors != null ) {
                this.frmF1.get(element).markAsDirty();
              }
            });
            return;
        }
        this.loading = true;
        this.frmF1.patchValue({IdSolicitud: this.IdSolicitud, IdAlianza: this.nombreAlianza});
        this.frmF1.patchValue({FechaInicio: this.utilities.parseDate( this.frmF1.value.FechaInicio )});
        let sendData = this.frmF1.value;


        this.F1Service.sendCotizacion( sendData ).subscribe(
            resp => {
                
                let date = this.utilities.formatDateObject( resp['data']['FechaInicio'] );

                this.frmF1.patchValue({FechaInicio: date})
                this.Mensualidad = resp['data']['MontoPago'];
                this.PagoInicial = resp['data']['MontoPagoInicial'];
                this.PorcentajeTasa = resp['data']['PorcentajeTasa'];
                this.clickCotizacion = true;
                this.loading = false;
            },
            errors => {
                this.errorMessages = errors.error.message;
                this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
                this.loading = false;
            }
        );
    }

    onSubmit(modalError){
        this.loading = true;

        this.router.navigateByUrl('/promotor/revision_acreditacion_preanalisis/' + this.IdSolicitud);
    }

    downloadCotizacion(){
        this.loading = true;
        this.K2Service.downloadCotizacion(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Formato de cotización.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
                // this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
    }

    createF1Form() {
        return this.fb.group({
            MontoSolicitado: [
                null
            ],
            Plazo: [
                null
            ],
            IdPlan: [
                null
            ],
            FechaInicio: [
                null
            ],
            PorcentajeTasa: [
                null
            ],
            PorcentajeComision: [
                null
            ],
            NoDisposiciones: [
                null
            ],
            IdPeriodicidadDisposiciones: [
                null
            ],
            IdSolicitud: [
                null
            ],
            MensualidadesGarantia: [
                null
            ],
            MontoDisposicion: [
                null
            ],
            MesesDiferidos: [
                null
            ],
            AplicaDispociones: [
                null
            ],
            EsDiferida: [
                null
            ],
            radio: [
                null
            ],
            TieneTasaEspecial: [
                null
            ],
            TasaEspecial: [
                null
            ],
            AplicaPeriodosGracia: [
                null
            ],
            PeriodosGracia: [
                null
            ],
            InicioGraciaCapital: [
                null
            ],
            IdAlianza: [
                null
            ]
        })
    }
}