import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Data, Router } from '@angular/router';
import { UtilitiesService } from 'src/app/services/utilities_services.service';

@Component({
    selector: 'app-temp-messages-aval',
    templateUrl: './temp-messages.component.html'
})
export class TempMessagesAvalComponent implements OnInit, AfterViewInit {
    public IdSolicitud: number;
    public status: number;
    public proceso: any;
    public rechazados: any;
    public messageObject = {
        message: "",
        submessage: "",
        status: "",
        solicitud: 0,
        redirect:""
      };
    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public utilities: UtilitiesService
    ) {
        this.IdSolicitud = this.route.snapshot.params.id;
        this.status = 7;
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    ngOnInit() {
        this.route.data.subscribe((data: Data) => {
            this.proceso = data['data'][0].proceso;
            this.rechazados = data['data'][0].data.documentos_rechazados;
        });
        this.processMessage(this.proceso);
    }

    processMessage(proceso){
        if (proceso.flag == 2){
            this.messageObject.message = "¡Muchas gracias por tu información!";
            this.messageObject.submessage = `Le notificaremos a la persona que ya has completado toda tu información y que puede continuar con su proceso.
            <br>
            <br>
            Si quieres tramitar tu propio crédito, haz clic <a href="#/client/destino_credito">aquí</a>`;
            this.messageObject.status = "success";
            this.messageObject.solicitud = this.IdSolicitud;
        }else if(proceso.flag == 0){
            this.messageObject.message = "Hay que corregir algunos datos para continuar.";
            let documentsReject = "";
            if (this.rechazados != null){
                this.rechazados.forEach(element => {
                    documentsReject += element.nombre + "<br>";
                });
            }
            this.messageObject.submessage = `Tu solicitud presentó algunos problemas en los siguientes documentos:
            <br>
            <br>
            `+ documentsReject + 
            `<br>Por favor, vuelve a subir los documentos procurando que no sean fotografías, se vean claros todos los datos y tengan la vigencia correspondiente.`;
            this.messageObject.status = "error";
            this.messageObject.redirect = "aval/documentacion/"+ this.IdSolicitud;
            this.messageObject.solicitud = this.IdSolicitud;
        }
    }


    obtainProcesoKey(solicitudes){
        let proceso: any;
        let solicitud = this.IdSolicitud;
        solicitudes.forEach(element => {
            if (element.IdTipoRelacion != "1"){
                if (element.IdSolicitud == solicitud){
                    proceso = element.proceso;
                }
            }
        });
        return proceso;
    }
}
