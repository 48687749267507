import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router, Data } from '@angular/router';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { B4AutorizarBuro } from 'src/app/models/B4AutorizarBuro.model';
import { B3CapturaInformacionBuroService } from 'src/app/services/aval/B3CapturaInformacionBuroService.service';
import { UtilitiesAvalService } from 'src/app/services/aval/UtilitiesAvalService.service';

@Component({
  selector: 'app-b3-autorizar-buro',
  templateUrl: './b3-autorizar-buro.component.html',
})
export class B3CapturaInformacionBuro implements OnInit, AfterViewInit {
  public data: B4AutorizarBuro;
  public errorMessages: string;
  public IdSolicitud: number;
  public loading: boolean;

  /* permissions */
  public IdRoleAval: any;
  public formatedPermissions: any;
  public informacionPropietario: any;

  constructor(
    private router: Router,
    private modalErrors: NgbModal,
    public route: ActivatedRoute,
    private utilities: UtilitiesService,
    private B3Service: B3CapturaInformacionBuroService,
    private modalError: NgbModal,
    private avalService: UtilitiesAvalService
  ) {
    this.data = new B4AutorizarBuro();
    this.IdSolicitud = this.route.snapshot.params.id;
    this.data.IdSolicitud = this.route.snapshot.params.id;
    this.loading = false;
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
}

  ngOnInit() {
    /* AQUI NO GABOSH  */
    this.route.data.subscribe((data: Data) => {
      this.IdRoleAval = data['permissions'][0].data.IdTipoRelacion;
      this.informacionPropietario = data['data'][0].data.informacion_propietario;
    });
    this.formatedPermissions = this.avalService.getPermissions(this.IdRoleAval);
    if (this.formatedPermissions.indexOf("InformacionFamiliar") > -1) {
      this.data.etapa = 2;
      this.data.paso = 1;
      this.data.flag = 1;
    } else {
      if (this.formatedPermissions.indexOf("ComprobacionIdentidad") > -1) {
        this.data.etapa = 2;
        this.data.paso = 4;
        this.data.flag = 1;
      }
      else {
        if (this.formatedPermissions.indexOf("Documentacion") > -1) {
          this.data.etapa = 2;
          this.data.paso = 5;
          this.data.flag = 1;
        }
      }
    }
  }

  onSubmit(modalError) {
    this.loading = true;

    this.B3Service.sendData(this.data).subscribe(
      resp => {
        if (this.formatedPermissions.indexOf("InformacionFamiliar") > -1) {
          this.router.navigateByUrl("aval/informacion_personal/" + this.IdSolicitud);
        } else {
          if (this.formatedPermissions.indexOf("ComprobacionIdentidad") > -1) {
            this.router.navigateByUrl("aval/confirmacion_identidad/" + this.IdSolicitud);
          }
          else{
            if(this.formatedPermissions.indexOf("Documentacion") > -1){
                this.router.navigateByUrl("aval/documentacion/" + this.IdSolicitud);
            }
        }
        }
      },
      errors => {
        this.loading = false;
        this.errorMessages = errors.error.message;
        this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
      },
    )
  }
}