import { Component, OnInit, ɵConsole, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Data, Router } from '@angular/router';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { Observable } from 'rxjs';
import { TablePromotorModel } from 'src/app/models/TablePromotor.model';
import { CollectionsService } from 'src/app/services/colecctions.service';
import { FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PromotorC3RevisionSolicitudService } from 'src/app/services/promotor/C3RevisionSolicitudService.service';
import { UtilitiesAvalService } from 'src/app/services/aval/UtilitiesAvalService.service';

@Component({
    selector: 'app-c3-revision-solicitud',
    templateUrl: './c3-revision-solicitud.component.html',
    styleUrls: ['./c3-revision-solicitud.component.scss']
})

export class C3RevisionSolicitudComponent implements OnInit, AfterViewInit {
    public status: number;
    public IdSolicitud: number;
    public loading: boolean;
    public errorMessages: string;
    public showDestino: boolean;
    public showPersonal: boolean;
    public showLaboral: boolean;
    public showPreanalisis: boolean;
    public showReferencias: boolean;
    public showDocumentacion: boolean;
    public showIdentificacion: boolean;
    public comprobante_identidad:string;
    public fullAval: number;

    public permissionsAval = [];
    public dataStatusInfoAval = []; // Dice si el aval ya lleno la info
    public dataCompleteInfoAval = []; // Saber si ya se reviso y aprobo por el promotor
    public canEdit: boolean;
    public canDecline: boolean;
    public canContinue:boolean;


    /* Collections */
    public currentData: any;
    public currentStatus: any;
    public proceso: any;

    constructor(
        private route: ActivatedRoute,
        private modalErrors: NgbModal,
        private C3Service: PromotorC3RevisionSolicitudService,
        private avalService: UtilitiesAvalService,
        private utilitiesService: UtilitiesService,
        private router: Router
    ) {
        this.status = 2;
        this.canEdit = false;
        this.canDecline = false;
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    onSubmit(modalTarget){
        this.loading = true;
        var sendData = {
            IdSolicitud: this.IdSolicitud
        }
        this.C3Service.sendUpdate(sendData).subscribe(
            response =>{
                this.loading = false;
                this.router.navigateByUrl("promotor/entrevista/"+this.IdSolicitud)
            }, 
            errors =>{
                this.loading = false;

                this.errorMessages = errors.error.message;
                this.modalErrors.open(modalTarget, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        );
    }


    ngOnInit(): void {
        this.IdSolicitud = this.route.snapshot.params.id;
        this.route.data.subscribe((data: Data) => {
            this.currentStatus = data['collections'][0].data;
            this.currentData = data['collections'][1].data;
            this.proceso = data['collections'][1].data.procesoCliente;
        });

        this.canContinue=parseInt(this.proceso.etapa)===4 && parseInt(this.proceso.paso)===5 && parseInt(this.proceso.flag)===3;
        
        this.canEdit = this.utilitiesService.hasPermission('Edicion informacion');
        this.canDecline = this.utilitiesService.hasPermission('Declinar Solicitud');

        this.showDestino = true;
        this.showPersonal = this.currentData.has_revision_personal;
        this.showLaboral = this.currentData.has_revision_laboral;
        this.showPreanalisis = this.currentData.has_revision_preanalisis;
        this.showReferencias = this.currentData.has_revision_referencias && !(parseInt(this.proceso.etapa)===4 && parseInt(this.proceso.paso)===3) ;
        this.showDocumentacion = this.currentData.has_revision_documentacion;
        this.showIdentificacion= this.currentData.has_comprobante_identificacion;
        this.comprobante_identidad=this.currentData.comprobante_identidad.comprobacion_identidad;
        if ( this.currentStatus.garantiaRPP == 0 ) {
            this.currentStatus.garantiaRPPAprobado = 1;
        }

        if ( this.currentStatus.garantiaAuto == 0 ) {
            this.currentStatus.garantiaAutoAprobado = 1;
        }
        if (this.currentStatus.avales.length > 0) {
            for (let i=0;i<this.currentStatus.avales.length;i++){
                this.permissionsAval.push (this.getAvalPermissions(this.currentStatus.avales[i].TipoAval,null)) ;
                this.dataStatusInfoAval.push(this.getStatusInfoAval(this.currentStatus.avales[i].TipoAval,i, this.currentData, this.permissionsAval[i] ));
                this.dataCompleteInfoAval.push(this.getStatusCompleteAval(this.currentStatus.avales[i].TipoAval,i, this.currentStatus, this.permissionsAval[i] ));
                this.currentData.avales[i].done=false;
                switch(this.currentStatus.avales[i].TipoAval){
                    case 2:
                    case 3:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                        if (this.currentData.avales[i].has_informacion_fiscal && this.currentData.avales[i].has_informacion_personal) {
                            this.currentData.avales[i].done = true;
                        }
                    break;
    
                    case 4:
                    case 5:
                        if (this.currentData.avales[i].has_informacion_economica && this.currentData.avales[i].has_informacion_familiar && this.currentData.avales[i].has_informacion_fiscal && this.currentData.avales[i].has_informacion_laboral && this.currentData.avales[i].has_informacion_personal ) {
                            this.currentData.avales[i].done = true;
                        }
                    break;
                }
                this.currentData.avales[i].showDocumentacion=this.currentData.avales[i].has_revision_documentacion;
            }

            if ( this.dataCompleteInfoAval.length > 0 ) {
                this.fullAval = 1;

                this.dataCompleteInfoAval.forEach(element => {
                    if ( ! element ) {
                        this.fullAval = 0;
                    }
                });
            }
            else{
                this.fullAval = 1;
            }
        }
        else{
            this.fullAval = 1;
        }

    }

    nameAval(tipoAval){
        return this.avalService.getNameTipoAval(tipoAval);
    }

    getAvalPermissions(TipoAval,permission){
        return this.avalService.getPermissions(TipoAval);
    }

    getStatusInfoAval(tipoAval,index,currentData, permissions){
        var currentInfo: boolean = false;

        if(currentData.avales[index]){
            switch(tipoAval){
                case 2:
                case 3:
                case 6:
                case 7:
                case 8:
                case 9:
                    if (currentData.avales[index].has_informacion_fiscal && currentData.avales[index].has_informacion_personal && currentData.avales[index].has_revision_documentacion) {
                        currentInfo = true;
                    }
                break;

                case 4:
                case 5:
                    if (currentData.avales[index].has_informacion_economica && currentData.avales[index].has_informacion_familiar && currentData.avales[index].has_informacion_fiscal && currentData.avales[index].has_informacion_laboral && currentData.avales[index].has_informacion_personal && currentData.avales[index].has_revision_documentacion) {
                        currentInfo = true;
                    }
                break;
            }
        }
        return currentInfo;
    }

    getStatusCompleteAval(tipoAval,index,dataComplete,permissions){
        var currentInfo: boolean = false;

        if(dataComplete.avales[index]){
            switch(tipoAval){
                case 2:
                case 3:
                case 6:
                case 7:
                case 8:
                case 9:
                    if (dataComplete.avales[index].Fiscal == "1" && dataComplete.avales[index].Personal =="1" && dataComplete.avales[index].Documentacion =="1") {
                        currentInfo = true;
                    }
                break;

                case 4:
                case 5:
                    if ( dataComplete.avales[index].Economica =="1" && dataComplete.avales[index].Familiar =="1" && dataComplete.avales[index].Fiscal =="1" &&  dataComplete.avales[index].Laboral =="1" && dataComplete.avales[index].Personal =="1" && dataComplete.avales[index].Documentacion =="1") {
                        currentInfo = true;
                    }
                break;
            }
        }
        return currentInfo;
    }

}
