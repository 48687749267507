import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { avalPrivileges } from '../../services/aval/AvalPermissions';
import { avalFilePermissions } from '../../services/aval/AvalFilePermissions';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class UtilitiesAvalService {
    API_URL: string;
    privileges: any;
    filesPermission: any;
    public test: any;

    constructor(private http: HttpClient) {
        this.API_URL = environment.API_URL;
        this.privileges = avalPrivileges;
        this.filesPermission = avalFilePermissions;
    }

    getQuery(type: string, query: string, parameters: string, ) {
        const url = `${this.API_URL}${query}`;

        switch (type) {
            case 'post':
                return this.http.post(url, parameters);
            case 'get':
                return this.http.get<any>(url);
            case 'put':
                return this.http.put(url, parameters);
            case 'delete':
                return this.http.delete(url);
            case 'patch':
                return this.http.patch(url, parameters);
            default:
                return new Observable();

        }
    }

    getRoleAval(IdSolicitud) {
        return this.getQuery('get', 'getTipoRelacion/' + IdSolicitud, null);
    }

    getPreviousData(IdSolicitud) {
        return this.getQuery('get', 'aval/solicitud/' + IdSolicitud, null);
    }

    checkAccessRoleAval(role, url) {
        role = parseInt(role.data.IdTipoRelacion);

        if (url.indexOf('capturar_informacion_buro') > -1) {
            return this.privileges.Buro.indexOf(role) > -1 ? true : false;
        }
        if (url.indexOf('informacion_personal') > -1) {
            return this.privileges.InformacionFamiliar.indexOf(role) > -1 ? true : false;
        }
        if (url.indexOf('informacion_laboral') > -1) {
            return this.privileges.InformacionLaboral.indexOf(role) > -1 ? true : false;
        }
        if (url.indexOf('informacion_economica') > -1) {
            return this.privileges.InformacionEconomica.indexOf(role) > -1 ? true : false;
        }
        if (url.indexOf('confirmacion_identidad') > -1) {
            return this.privileges.ComprobacionIdentidad.indexOf(role) > -1 ? true : false;
        }
        if (url.indexOf('documentacion') > -1) {
            return this.privileges.Documentacion.indexOf(role) > -1 ? true : false;
        }

        return false;
    }

    getPermissions(IdRole) {
        var permissions = new Array();
        for (let key in this.privileges) {
            let value = this.privileges[key];
            if (value.indexOf(parseInt(IdRole)) > -1) {
                permissions.push(key);
            }
        }
        return permissions;
    }

    getFiles(IdRole) {
        var permissions = new Array();
        for (let key in this.filesPermission) {
            let value = this.filesPermission[key];
            if (value.indexOf(parseInt(IdRole)) > -1) {
                permissions.push(key);
            }
        }
        return permissions;
    }

    getNameTipoAval(tipoAval){
        var name;
        switch (parseInt(tipoAval)){
            case 2:
                return "Estudiante sin buró";
            break;
            case 3:
                return "Estudiante con buró";
            break;
            case 4:
                return "Proveedor de recursos";
            break;
            case 5:
                return "Complemento de ingresos";
            break;
            case 6:
                return "Reforzar buró";
            break;
            case 7:
                return "Garante auto";
            break;
            case 8:
                return "Garante RPP";
            case 9:
                return 'Conyugue';
            break;
        }
        return name;
    }
}