import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, forkJoin,of,throwError, EMPTY } from 'rxjs';
import { CollectionsService } from "../services/colecctions.service";
import { Collection } from '../models/collection.model';
import { Injectable } from '@angular/core';
import { catchError, tap, mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LDashboardResolver implements Resolve<Collection>{
    private Object = {};
    constructor (private CollectionsServive: CollectionsService, private router: Router,private http: HttpClient,){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<any>{   
        return this.http.get<Collection>(environment.API_URL + 'solicitudes').pipe(catchError(error => {
            return [];

        }), mergeMap(resp => {
            return of(resp)
        })
        );
    }
}