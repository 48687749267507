import { Component, OnInit, ViewChild, TemplateRef, Input } from '@angular/core';
import { RegisterService } from '../../../services/register.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
    selector: 'app-23-verificar-correo',
    templateUrl: './a23-verificar-correo.component.html',
})
export class A23VerificarCorreoComponent implements OnInit {
    public token: string;
    public message: string;

    constructor(
        private register: RegisterService,
        private modalService: NgbModal,
        private route: ActivatedRoute,
        private router: Router,
    ){
        this.message = '';
    }

    ngOnInit() {
        // this.modalService.open(this.modal, { ariaLabelledBy: 'modal-basic-title', centered: true });
        this.token = this.route.snapshot.params.token;

        this.register.verifyEmail(this.token).subscribe(
            response => {
                this.router.navigateByUrl('/login?success=mail_verified');
            },
            errors => {
                this.message = errors.error.message;
            }
        );
    }
}