import { Component, OnInit } from '@angular/core';
import { RegisterService } from '../../../services/register.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CustomValidators } from 'src/app/validators/validators';

@Component({
    selector: 'app-22-restablecer-contrasenia',
    templateUrl: './a22-restablecer-contrasenia.component.html',
})
export class A22RestablecerContraseniaComponent implements OnInit {
    public password_token: string;
    public frm: FormGroup;
    public errorMessages: string;

    constructor(
        private register: RegisterService, 
        private modalService: NgbModal, 
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private router: Router
    ) {}

    ngOnInit() {
        this.password_token = this.route.snapshot.params.token;
        this.frm = this.createResetPassword();
        this.frm.patchValue({
            passwordToken: this.password_token
        });
    }

    createResetPassword(): FormGroup {
        return this.fb.group(
            {
                password: [
                null,
                Validators.compose([
                    Validators.required,
                    // check whether the entered password has a special character

                    CustomValidators.patternValidator(
                    /(?=.*[A-Z].*)(?=.*[0-9].*).{8,}/,
                        {
                            hasSpecialCharacters: true
                        }
                    )
                    
                    ,
                    Validators.minLength(8)
                ])
                ],
                password_confirmation: [null, Validators.compose([Validators.required])],
                passwordToken:[
                    null
                ]
            },

            {
                // check whether our password and confirm password match
                validator: CustomValidators.passwordMatchValidator
            }
        );
    }

    toLogin(){
        this.router.navigateByUrl('client/login');
        this.modalService.dismissAll();
    }

    onSubmit(modalError, modalSuccess) {
        this.register.sendNewPassword(this.frm.value).subscribe(
            response => {
                this.modalService.open(modalSuccess, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
            errorService => {
                this.errorMessages = errorService.error.message
                this.modalService.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        );
    }
}
