import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { CollectionsService } from "../../services/colecctions.service";
import { Collection } from '../../models/collection.model';
import { Injectable } from '@angular/core';

@Injectable()
export class M6PromotorDocumentacionResolver implements Resolve<Collection>{
    public idSolicitud: number;
    public idPersona: number;
    constructor (private CollectionsServive: CollectionsService){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<any>{ 
        let test = forkJoin(
            this.CollectionsServive.getIdentificacion(),
            this.CollectionsServive.getComprobanteDomicilio(),
            this.CollectionsServive.getComprobanteIngresos(),
            this.CollectionsServive.getCedula(),
        );

        return test;
    }
}