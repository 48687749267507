import { Component, OnInit, AfterViewInit } from '@angular/core';
import { B4AutorizarBuro } from '../../../models/B4AutorizarBuro.model';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { B4AutorizarBuroService } from "../../../services/B4AutorizarBuroService.service";
import { NgbDateStruct, NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesService } from 'src/app/services/utilities_services.service';

@Component({
  selector: 'app-b4-autorizar-buro',
  templateUrl: './b4-autorizar-buro.component.html',
  styleUrls: ['./b4-autorizar-buro.component.scss']
})
export class B4AutorizarBuroComponent implements AfterViewInit {
  status = 1;
  public data: B4AutorizarBuro;
  public errorMessages: string;
  public IdSolicitud: number;
  public loading: boolean;

  constructor(
    private router: Router,
    private B3Service: B4AutorizarBuroService,
    private modalErrors: NgbModal,
    public route: ActivatedRoute,
    private utilities: UtilitiesService
  ) {
    this.data = new B4AutorizarBuro();
    this.IdSolicitud = this.route.snapshot.params.id;
    this.data.IdSolicitud = this.route.snapshot.params.id;
    this.loading = false;
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

  onSubmit(modalError){
    this.loading = true;
    this.B3Service.sendDestinoCredito( this.data ).subscribe(
      resp => {
        let redirect = this.utilities.handleRedirect(resp['proceso']);
        this.router.navigateByUrl(redirect+"/"+this.data.IdSolicitud);
      },
      errors => {
        this.loading = false;
        if  (errors.error.message.indexOf("Password") > -1){
          this.errorMessages = "Password incorrecto";
        }else{
          this.errorMessages = errors.error.message;
        }
        this.modalErrors.open(modalError, { ariaLabelledBy: 'modal-basic-title',centered:true });
      }
    )
  }
}
