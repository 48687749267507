import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor( private auth: AuthService,
    private router: Router ) {

  }

  canActivate(): boolean {
    if (this.auth.isLogged()) {
      return true;
    } else {
      this.auth.logout().subscribe(
        response => {
          return false;
          localStorage.clear();
        },
        errorService => {
          localStorage.clear();
          return false;
        }
      );
    }
  }

}
