import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Data, Router } from '@angular/router';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { C2AvalesService } from "../../../services/C2AvalesService.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Collection } from 'src/app/models/collection.model';

@Component({
    selector: 'app-c2-avales',
    templateUrl: './c2-avales.component.html',
    styleUrls: ['./c2-avales.component.scss']
})

export class C2AvalesComponent implements OnInit {
    public status: number;
    public persona: any;
    public NeedAval: boolean;
    public NeedSegundoAval: boolean;
    public frmC1: FormGroup;
    public dataFormat = {
        NeedAval: null,
        TipoAval: null,
        Nombre: null,
        ApellidoPaterno: null,
        ApellidoMaterno: null,
        Email: null,
        NeedSegundoAval: null,
        TipoAval2: null,
        Nombre2: null,
        ApellidoPaterno2: null,
        ApellidoMaterno2: null,
        Email2: null,
        GarantiaAuto: null,
        GarantiaRpp: null,
        IdGenero: null,
        FechaNacimiento: null,
        Celular: null,
        IdEnteradoPor: null,
        IdGenero2: null,
        FechaNacimiento2: null,
        Celular2: null,
        IdEnteradoPor2: null,
    };
    public loading: boolean;
    public collectionsGenero: Collection;
    public collectionsEnteradoPor: Collection;
    public collectionsTipoAvales: Collection;

    public data_other_person: any;
    public has_other_person: boolean;
    public other_person: string;
    public has_avales: boolean;
    public avales: any;
    public other_aval: boolean;

    public previous_data: any;
    public IdSolicitud: number;
    public errorMessages: string;
    public errorPersonReady: string;
    public IdPersonaRegistrada1: number;
    public IdPersonaRegistrada2: number;
    public NeedAuto: boolean;
    public NeedRPP: boolean;
    public edadMin:number;
    public edadMax:number;

    public minDate: any;
    public maxDate: any;

    constructor(
        public route: ActivatedRoute,
        private fb: FormBuilder,
        private utilitiesService: UtilitiesService,
        private C2Service: C2AvalesService,
        private modalError: NgbModal,
        private router: Router,
    ) {
        this.status = 1;
        this.NeedAval = false;
        this.NeedSegundoAval = false;
        this.loading = false;
        this.other_aval = true;
        this.NeedAuto = false;
        this.NeedRPP = false;
    }

    ngOnInit(): void {
        this.IdSolicitud = this.route.snapshot.data.solicitudPromotor.data.IdSolicitud;
        this.persona = this.route.snapshot.data.solicitudPromotor.data.persona;
        this.has_avales = this.route.snapshot.data.solicitudPromotor.data.has_avales;
        this.avales = this.route.snapshot.data.solicitudPromotor.data.avales;

        this.route.data.subscribe( (data: Data) => {
            this.collectionsGenero = data['collections'][0].data;
            this.collectionsEnteradoPor = data['collections'][1].data;
            this.collectionsTipoAvales = data['collections'][2].data;
            let edades=data['collections'][3].data;
            this.edadMin=Number(edades.find(element => element.variable == 'Edad Minima Aval').valor);
            this.edadMax=Number(edades.find(element => element.variable == 'Edad Maxima Aval').valor);
            
            if ( data['solicitudPromotor'].data.revision_preanalisis.IdOtraPersona > 0 ) {
                this.has_other_person = true;
            }
            else{
                this.has_other_person = false;
            }

            if ( this.has_other_person ) {
                this.data_other_person = data['solicitudPromotor'].data.revision_preanalisis;
            }
          } );

        if ( this.has_avales ) {
            this.other_aval = this.avales.length == 1 ? true : false;
            this.NeedAuto = this.route.snapshot.data.solicitudPromotor.data.detallesSolicitud.NeedAuto;
            this.NeedRPP = this.route.snapshot.data.solicitudPromotor.data.detallesSolicitud.NeedRPP;
        }

        this.route.data.subscribe((data: Data) => {
            // this.previous_data = data['solicitudPromotor'].data.C1 ? data['solicitudPromotor'].data.C1 : false;
            this.previous_data = false;
        });

        if ( this.has_other_person ) {
            this.other_person = `La solicitud cuenta con una persona que lo ayudará a saldar la cuenta: <br>
            Nombre: ` + this.data_other_person.Nombre + `<br>
            Apellido Paterno: ` + this.data_other_person.ApellidoPaterno + `<br>
            Apellido Materno: ` + this.data_other_person.ApellidoMaterno + `<br>
            Correo electrónico: ` + this.data_other_person.Email + `<br>
            Fecha Nacimiento: ` + this.data_other_person.FechaNacimiento + `<br>
            Celular: ` + this.data_other_person.Celular + `<br>
            `;
        }

        this.frmC1 = this.createKC1Form();

        if ( this.previous_data ) {
            // TODO: Guardar la info previa
        }
        else{
            this.frmC1.patchValue({
                NecesitaAval: "false",
                NeedAval: "",
                TipoAval: "",
                Nombre: "",
                ApellidoPaterno: "",
                ApellidoMaterno: "",
                Email: "",
                SegundoAval: "false",
                NeedSegundoAval: "",
                TipoAval2: "",
                Nombre2: "",
                ApellidoPaterno2: "",
                ApellidoMaterno2: "",
                Email2: "",
                GarantiaAuto: "",
                GarantiaRpp: "",
                IdGenero: '',
                FechaNacimiento: '',
                Celular: '',
                IdEnteradoPor: '10',
                IdGenero2: '',
                FechaNacimiento2: '',
                Celular2: '',
                IdEnteradoPor2: '10',
            });

            if ( this.NeedAuto ) {
                this.frmC1.patchValue({GarantiaAuto: 'true'});
            }
            else{
                this.frmC1.patchValue({GarantiaAuto: 'false'});
            }

            if ( this.NeedRPP ) {
                this.frmC1.patchValue({GarantiaRpp: 'true'});
            }
            else{
                this.frmC1.patchValue({GarantiaRpp: 'false'});
            }
        }

        this.minDate = {
            year: new Date().getFullYear() - this.edadMax-1,
            month: new Date().getMonth()+1,
            day: new Date().getDate()+1
        };
        this.maxDate = {
            year: new Date().getFullYear() - this.edadMin,
            month: new Date().getMonth()+1,
            day: new Date().getDate()
        };
    }

    handleSegundoAval(event){
        var target = event.target;
        if (target.value === 'true') {
            this.NeedSegundoAval = true;
            let controls = {
                TipoAval2: [
                  null,
                  [Validators.required, Validators.min(1)]
                ],
                Nombre2: [
                  null,
                  Validators.required
                ],
                ApellidoPaterno2: [
                  null,
                  Validators.required
                ],
                ApellidoMaterno2: [
                  null,
                  Validators.required
                ],
                Email2: [
                    null,
                    Validators.email,
                    Validators.required
                ],
                IdGenero2: [
                    null,
                    [Validators.required, Validators.min(1)]
                ],
                FechaNacimiento2: [
                    null,
                    Validators.required
                ],
                Celular2: [
                    null,
                    Validators.required
                ],
                IdEnteradoPor2: [
                    null,
                    [Validators.required, Validators.min(1)]
                ],
              }
              this.utilitiesService.addControlsValidity(this.fb, controls);
        } else {
            this.NeedSegundoAval = false;
            let controls = ['TipoAval2', 'Nombre2', 'ApellidoPaterno2', 'ApellidoMaterno2', 'Email2', 'IdGenero2', 'FechaNacimiento2', 'Celular2', 'IdEnteradoPor2'];
            this.utilitiesService.removeControlsValidity(this.frmC1, controls);
        }
    }

    handleAval(event){
        var target = event.target;
        if (target.value === 'true') {
            this.NeedAval = true;
            let controls = {
                TipoAval: [
                  null,
                  [Validators.required, Validators.min(1)]
                ],
                Nombre: [
                  null,
                  Validators.required
                ],
                ApellidoPaterno: [
                  null,
                  Validators.required
                ],
                ApellidoMaterno: [
                  null,
                  Validators.required
                ],
                Email: [
                    null,
                    Validators.email,
                    Validators.required
                ],
                IdGenero: [
                    null,
                    [Validators.required, Validators.min(1)]
                ],
                FechaNacimiento: [
                    null,
                    Validators.required
                ],
                Celular: [
                    null,
                    Validators.required
                ],
                IdEnteradoPor: [
                    null,
                    [Validators.required, Validators.min(1)]
                ],
            }
            this.utilitiesService.addControlsValidity(this.fb, controls);
        } else {
            this.NeedAval = false;
            this.NeedSegundoAval = false;
            let controls = ['TipoAval', 'Nombre', 'ApellidoPaterno', 'ApellidoMaterno', 'Email', 'IdGenero', 'FechaNacimiento', 'Celular', 'IdEnteradoPor', 'TipoAval2', 'Nombre2', 'ApellidoPaterno2', 'ApellidoMaterno2', 'Email2', 'IdGenero2', 'FechaNacimiento2', 'Celular2', 'IdEnteradoPor2'];
            this.utilitiesService.removeControlsValidity(this.frmC1, controls);
        }
    }

    onSubmit(modalError, modalPersonReady){
        if (!this.frmC1.valid) {
            Object.keys(this.frmC1.value).forEach(element => {
              if ( this.frmC1.get(element).errors != null ) {
                this.frmC1.get(element).markAsDirty();
              }
            });
            return;
          }
        this.loading = true;
        this.frmC1.patchValue({IdSolicitud: this.IdSolicitud, NeedAval: this.NeedAval, NeedSegundoAval: this.NeedSegundoAval});
        let sendData = this.frmC1.value;

        this.C2Service.sendInfoAvales( sendData ).subscribe(
            resp => {
                // Redirect
                this.router.navigateByUrl("promotor/revision_solicitud/"+this.IdSolicitud);
            },
            errors => {
                this.loading = false;
                console.log('hay error',errors.error.status)
                if ( errors.error.status === 405 ) {
                    this.errorPersonReady = '';
                    this.IdPersonaRegistrada1 = 0;
                    this.IdPersonaRegistrada2 = 0;
                    if ( errors.error.errors.tiene_persona_1 ) {
                        this.errorPersonReady += `Los datos del Aval ` + errors.error.errors.Nombre1 + ` ya se encuentran registrados de la siguiente manera:
                        <br>
                        <ul>
                            <li>Nombre: ` + errors.error.errors.Nombre1 + `</li>
                            <li>Apellido Paterno: ` + errors.error.errors.ApellidoPaterno1 + `</li>
                            <li>Apellido Materno: ` + errors.error.errors.ApellidoMaterno1 + `</li>
                            <li>Fecha de Nacimiento: ` + errors.error.errors.FechaNacimiento1 + `</li>
                            <li>Correo: ` + errors.error.errors.email1 + `</li>
                        </ul>`;

                        this.IdPersonaRegistrada1 = errors.error.errors.IdPersona1;
                    }

                    if ( errors.error.errors.tiene_persona_2 ) {
                        this.errorPersonReady += `<br>Los datos del Aval ` + errors.error.errors.Nombre2 + ` ya se encuentran registrados de la siguiente manera:
                        <br>
                        <ul>
                            <li>Nombre: ` + errors.error.errors.Nombre2 + `</li>
                            <li>Apellido Paterno: ` + errors.error.errors.ApellidoPaterno2 + `</li>
                            <li>Apellido Materno: ` + errors.error.errors.ApellidoMaterno2 + `</li>
                            <li>Fecha de Nacimiento: ` + errors.error.errors.FechaNacimiento2 + `</li>
                            <li>Correo: ` + errors.error.errors.email2 + `</li>
                        </ul>`;

                        this.IdPersonaRegistrada2 = errors.error.errors.IdPersona2;
                    }
                    this.modalError.open(modalPersonReady, { ariaLabelledBy: 'modal-basic-title', centered: true });
                }
                else{
                    this.errorMessages = errors.error.message;
                    this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
                }
            },
        );
    }

    savePersonId(modalError,modalPersonReady){
        this.loading = true;
        let sendData = this.frmC1.value;
        sendData.IdPersonaRegistrada1 = this.IdPersonaRegistrada1;
        sendData.IdPersonaRegistrada2 = this.IdPersonaRegistrada2;

        this.C2Service.sendInfoAvales( sendData ).subscribe(
            resp => {
                this.modalError.dismissAll();
                this.loading = false;
                this.router.navigateByUrl("promotor/revision_solicitud/"+this.IdSolicitud);
            },
            errors => {
                
                this.loading = false;
                if ( errors.error.status === 405 ) {
                    this.errorPersonReady = '';
                    this.IdPersonaRegistrada1 = 0;
                    this.IdPersonaRegistrada2 = 0;
                    if ( errors.error.errors.tiene_persona_1 ) {
                        this.errorPersonReady += `Los datos del Aval ` + errors.error.errors.Nombre1 + ` ya se encuentran registrados de la siguiente manera:
                        <br>
                        <ul>
                            <li>Nombre: ` + errors.error.errors.Nombre1 + `</li>
                            <li>Apellido Paterno: ` + errors.error.errors.ApellidoPaterno1 + `</li>
                            <li>Apellido Materno: ` + errors.error.errors.ApellidoMaterno1 + `</li>
                            <li>Fecha de Nacimiento: ` + errors.error.errors.FechaNacimiento1 + `</li>
                            <li>Correo: ` + errors.error.errors.email1 + `</li>
                        </ul>`;

                        this.IdPersonaRegistrada1 = errors.error.errors.IdPersona1;
                    }

                    if ( errors.error.errors.tiene_persona_2 ) {
                        this.errorPersonReady += `<br>Los datos del Aval ` + errors.error.errors.Nombre2 + ` ya se encuentran registrados de la siguiente manera:
                        <br>
                        <ul>
                            <li>Nombre: ` + errors.error.errors.Nombre2 + `</li>
                            <li>Apellido Paterno: ` + errors.error.errors.ApellidoPaterno2 + `</li>
                            <li>Apellido Materno: ` + errors.error.errors.ApellidoMaterno2 + `</li>
                            <li>Fecha de Nacimiento: ` + errors.error.errors.FechaNacimiento2 + `</li>
                            <li>Correo: ` + errors.error.errors.email2 + `</li>
                        </ul>`;

                        this.IdPersonaRegistrada2 = errors.error.errors.IdPersona2;
                    }
                    this.modalError.open(modalPersonReady, { ariaLabelledBy: 'modal-basic-title', centered: true });
                }
                else{
                    this.errorMessages = errors.error.message;
                    this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
                
                }

            }
        );
    }

    createKC1Form() {
        return this.fb.group({
            IdSolicitud: [
                null
            ],
            NecesitaAval: [
                null
            ],
            NeedAval: [
                null
            ],
            TipoAval: [
                null,
            ],
            Nombre: [
                null,
            ],
            ApellidoPaterno: [
                null,
            ],
            ApellidoMaterno: [
                null,
            ],
            Email: [
                null,
            ],
            SegundoAval: [
                null
            ],
            NeedSegundoAval: [
                null,
            ],
            TipoAval2: [
                null,
            ],
            Nombre2: [
                null,
            ],
            ApellidoPaterno2: [
                null,
            ],
            ApellidoMaterno2: [
                null,
            ],
            Email2: [
                null,
            ],
            GarantiaAuto: [
                null,
            ],
            GarantiaRpp: [
                null,
            ],
            IdGenero: [
                null,
            ],
            FechaNacimiento: [
                null,
            ],
            Celular: [
                null,
            ],
            IdEnteradoPor: [
                null,
            ],
            IdGenero2: [
                null,
            ],
            FechaNacimiento2: [
                null,
            ],
            Celular2: [
                null,
            ],
            IdEnteradoPor2: [
                null,
            ],
        })
    }
}