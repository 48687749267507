import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Collection } from 'src/app/models/collection';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { FormGroup, FormBuilder, Validators,FormControl } from '@angular/forms';
import { PromotorD6RevisionAcreditacionDocumentacion } from 'src/app/services/promotor/D6RevisionAcreditacionDocumentacion.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { each } from 'jquery';

@Component({
    selector: 'app-m6-promotor-aval-documentacion',
    templateUrl: './m6-promotor-aval-documentacion.component.html',
})

export class M6PromotorAvalDocumentacionComponent implements OnInit, AfterViewInit {
    public status: number;
    public IdSolicitud: number;
    public IdPersona: number;
    public tipoAval: number;
    public editMode:boolean;
    public errorMessages: string;
    public loading: boolean;
    public noidentificacion: string;
    public NoComprobantes:number;
    public index:any;
    public infosolicitud:any;

    /* Collections documents types */
    public collectionsIdentificacion: Collection;
    //public collectionsIdentificacion2: Collection;
    public collectionsComprobanteDomicilio: Collection;
    public collectionsComprobanteIngresos: Collection;
    public collectionsCedula: Collection;

    /* Collections for component data */
    public data: any;
    public dataPersonSolicitud:any;
    public files: any;
    public frm: FormGroup;

    public canEdit: boolean;
    public canDecline: boolean;


    constructor(
        private route: ActivatedRoute,
        private modalError: NgbModal,
        private utilities: UtilitiesService,
        private fb: FormBuilder,
        private D6service: PromotorD6RevisionAcreditacionDocumentacion,
        private router: Router,
        private utilitiesService: UtilitiesService,
    ) {
        this.status = 2;
        this.canEdit = false;
        this.canDecline = false;
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    ngOnInit() {
        this.IdSolicitud = this.route.snapshot.params.id_solicitud;
        this.IdPersona = this.route.snapshot.params.id_persona;
        this.tipoAval = this.route.snapshot.params.tipo_aval;
        this.editMode = false;
        this.route.data.subscribe((data: Data) => {
            this.data = data['data'][0].data.informacion_economica;
            this.dataPersonSolicitud = data['data'][0].data.informacion_propietario;
            this.files = data['data'][1].data.archivos_aval;
            this.collectionsIdentificacion = data['collections'][0].data;
            this.collectionsComprobanteDomicilio = data['collections'][1].data;
            this.collectionsComprobanteIngresos = data['collections'][2].data;
            this.collectionsCedula = data['collections'][3].data;
            this.noidentificacion= data['data'][2].data.NoIdentificacion;
            this.infosolicitud=data['data'][1].data;
        });
        this.canEdit = this.utilities.hasPermission('Edicion informacion');
        this.canDecline = this.utilities.hasPermission('Declinar Solicitud');
        if (this.files != undefined || this.files != null){
            this.files = this.searchUserFiles(this.files, this.IdPersona);
            this.getNameCategory(this.files.Identificacion);
        }
        this.frm = this.createForm();
        this.index=[];

        for (let aval of this.infosolicitud.avales){
            if(aval.IdPersona==this.IdPersona){
                this.NoComprobantes=aval.informacion_aval.NoComprobantes
            }

        };

        for (let i=1;i<=this.NoComprobantes;i++){
            this.index.push({mes:i,estatus:'EstatusComprobanteMes'+i,comentario:'ComentarioComprobanteMes'+i,comprobante:'comprobante_mes_'+i});
            this.frm.addControl('EstatusComprobanteMes'+i, new FormControl(null, [Validators.required]));
            this.frm.addControl('ComentarioComprobanteMes'+i, new FormControl(null));
            this.frm.addControl('_IdComprobanteMes'+i, new FormControl(null));
        }

        this.frm.patchValue({
            IdSolicitud: this.IdSolicitud,
            IdPersona: this.IdPersona,
            NoIdentificacion:this.noidentificacion
        })
        this.frm.patchValue(this.formUpdateValues(this.files.Identificacion));
        this.files.Identificacion.forEach(file => {
            if ( file.estatus == 0 ) {
                this.frm.controls[file.formComentario].setValidators([Validators.required]);
            }
        });
    }

    changeComments(evt, nameForm){
        var target = evt.target;
        if (target.value == "1") {
            this.utilitiesService.removeControlsValidity(this.frm, [nameForm]);
        }else{
            this.frm.controls[nameForm].setValidators([Validators.required]);
        }
    }

    searchUserFiles(files, idPersona){
        let ret = null;
        files.forEach(function(element){
            if(element.IdPersona == idPersona){
                ret = element;
            }
        });
        return ret;
    }

    formUpdateValues(files){
        let objectPatch = {
            _IdIdentificacion:null,
            _IdIdentificacion2:null,
            _IdComprobante:null,
            _IdGarantiaAuto:null,
            _IdEvidenciaInmueble:null,
            _IdCedulafiscal:null,
            EstatusIdentificacion:null,
            EstatusIdentificacion2:null,
            EstatusComprobante:null,
            EstatusGarantiaAuto:null,
            EstatusEvidenciaInmueble:null,
            EstatusCedulaFiscal:null,
            ComentarioIdentificacion:null,
            ComentarioIdentificacion2:null,
            ComentarioComprobante:null,
            ComentarioGarantiaAuto:null,
            ComentarioEvidenciaInmueble:null,
            ComentarioCedulaFiscal:null,
        }
        for (let i=1;i<=this.NoComprobantes;i++){
            objectPatch['_IdComprobanteMes'+i]=null
            objectPatch['EstatusComprobanteMes'+i]=null
            objectPatch['ComentarioComprobanteMes'+i]=null
        }
        let NoComprobantes=this.NoComprobantes;
        files.forEach(function (currentValue, index, array) {
            currentValue._IdIdentificacion != undefined ? objectPatch._IdIdentificacion = currentValue._IdIdentificacion : null;
            currentValue._IdIdentificacion2 != undefined ? objectPatch._IdIdentificacion2 = currentValue._IdIdentificacion2 : null;
            currentValue._IdComprobante != undefined ? objectPatch._IdComprobante = currentValue._IdComprobante : null;
            currentValue._IdGarantiaAuto != undefined ? objectPatch._IdGarantiaAuto = currentValue._IdGarantiaAuto : null;
            currentValue._IdEvidenciaInmueble != undefined ? objectPatch._IdEvidenciaInmueble = currentValue._IdEvidenciaInmueble : null;
            currentValue.EstatusIdentificacion != undefined ? objectPatch.EstatusIdentificacion = '' + currentValue.EstatusIdentificacion : null;
            currentValue.EstatusIdentificacion2 != undefined ? objectPatch.EstatusIdentificacion2 = '' + currentValue.EstatusIdentificacion2 : null;
            currentValue.EstatusComprobante != undefined ? objectPatch.EstatusComprobante = '' + currentValue.EstatusComprobante : null;
            currentValue.EstatusGarantiaAuto != undefined ? objectPatch.EstatusGarantiaAuto = '' + currentValue.EstatusGarantiaAuto : null;
            currentValue.EstatusEvidenciaInmueble != undefined ? objectPatch.EstatusEvidenciaInmueble = '' + currentValue.EstatusEvidenciaInmueble : null;
            currentValue.ComentarioIdentificacion != undefined ? objectPatch.ComentarioIdentificacion = currentValue.ComentarioIdentificacion : null;
            currentValue.ComentarioIdentificacion2 != undefined ? objectPatch.ComentarioIdentificacion2 = currentValue.ComentarioIdentificacion2 : null;
            currentValue.ComentarioComprobante != undefined ? objectPatch.ComentarioComprobante = currentValue.ComentarioComprobante : null;
            currentValue.ComentarioGarantiaAuto != undefined ? objectPatch.ComentarioGarantiaAuto = currentValue.ComentarioGarantiaAuto : null;
            currentValue.ComentarioEvidenciaInmueble != undefined ? objectPatch.ComentarioEvidenciaInmueble = currentValue.ComentarioEvidenciaInmueble : null;
            
            for (let i=1;i<=NoComprobantes;i++){
                currentValue['_IdComprobanteMes'+i] != undefined ? objectPatch['_IdComprobanteMes'+i] = currentValue['_IdComprobanteMes'+i] : null;
                currentValue['EstatusComprobanteMes'+i] != undefined ? objectPatch['EstatusComprobanteMes'+i] = '' + currentValue['EstatusComprobanteMes'+i] : null;
                currentValue['ComentarioComprobanteMes'+i] != undefined ? objectPatch['ComentarioComprobanteMes'+i] = currentValue['ComentarioComprobanteMes'+i] : null;
            }
        });
        return objectPatch;
    }

    getNameCategory(files){
        for (let index = 0; index < files.length; index++) {
            switch (files[index].nombreform){
                case "IdentificacionVigente":
                    files[index].IdTipoDocumento = this.utilities.getFieldFromCollection(this.collectionsIdentificacion, files[index].IdTipoDocumento, "name");
                break;
                case "IdentificacionVigente2":
                    files[index].IdTipoDocumento = "IDENTIFICACION PARTE TRASERA";
                break;
                case "ComprobanteDomicilio":
                    files[index].IdTipoDocumento = this.utilities.getFieldFromCollection(this.collectionsComprobanteDomicilio, files[index].IdTipoDocumento, "name");
                break;
                case "EvidenciaInmueble":
                    files[index].IdTipoDocumento = "Escritura";
                break;
                case "GrantiaAuto":
                    files[index].IdTipoDocumento = "Factura";
                break;
                case "CedulaFiscal":
                    files[index].IdTipoDocumento = "RFC";
                break;
                default:
                    if(files[index].nombreform.includes("ComprobanteIngresosMes")) 
                        files[index].IdTipoDocumento = this.utilities.getFieldFromCollection(this.collectionsComprobanteIngresos, files[index].IdTipoDocumento, "name");
                break;
                
            }
            if (files[index].formComentario == "ComentarioCombrobante") {
                files[index].formComentario = "ComentarioComprobante";
            }
        }
    }

    createForm(){
        return this.fb.group({
            IdSolicitud:[
                null
            ],
            IdPersona: [
                null
            ],
            _IdIdentificacion:[
                null
            ],
            _IdIdentificacion2:[
                null
            ],
            _IdComprobante:[
                null
            ],
            _IdGarantiaAuto:[
                null
            ],
            _IdEvidenciaInmueble:[
                null
            ],
            EstatusIdentificacion:[
                null
            ],
            EstatusIdentificacion2:[
                null
            ],
            EstatusComprobante:[
                null
            ],
            EstatusGarantiaAuto:[
                null
            ],
            EstatusEvidenciaInmueble:[
                null
            ],
            ComentarioIdentificacion:[
                null
            ],
            ComentarioIdentificacion2:[
                null
            ],
            ComentarioComprobante:[
                null
            ],
            ComentarioGarantiaAuto:[
                null
            ],
            ComentarioEvidenciaInmueble:[
                null
            ],
            NoIdentificacion:[
                null
            ],
            ComentarioCedulaFiscal:[
                null
            ],
            EstatusCedulaFiscal:[
                null
            ],
            _IdCedulafiscal:[
                null
            ],
        });
    }

    onSubmit(modalError){
        if (!this.frm.valid) {
            Object.keys(this.frm.value).forEach(element => {
              if ( this.frm.get(element).errors != null ) {
                this.frm.get(element).markAsDirty();
              }
            });
            return;
        }

        let sendData = this.frm.value;
        this.loading = true;
        this.D6service.sendUpdate(sendData).subscribe(
            response => {
                this.loading = false;
                this.router.navigateByUrl('promotor/revision_solicitud/' + this.IdSolicitud);
            },
            errors =>{
                this.loading = false;
                this.errorMessages = errors.error.message;
                this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        );
    }
}
