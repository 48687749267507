import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin} from 'rxjs';
import { CollectionsService } from "../../services/colecctions.service";
import { Collection } from '../../models/collection.model';
import { Injectable } from '@angular/core';

@Injectable()
export class B1SolicitudesResolver implements Resolve<Collection>{
    private Object = {};
    public idSolicitud: number;
    constructor (private CollectionsService: CollectionsService){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<any>{
        this.idSolicitud = parseInt(route.paramMap.get('id'));
        let test = forkJoin(
            this.CollectionsService.getCollectionsAgencia(),
            this.CollectionsService.getMotivosRechazo(),
            this.CollectionsService.getPromotoresAsignados(),
            this.CollectionsService.getEtapas(),
        );

        return test;
    }
}