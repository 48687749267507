import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Router, ActivatedRoute, Data } from '@angular/router';
import { Collection } from 'src/app/models/collection.model';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PromotorD2RevisionDestino } from 'src/app/services/promotor/D2DestinoCredito.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-d2-revision-destino',
    templateUrl: './d2-revision-destino.component.html',
    styleUrls: ['./d2-revision-destino.component.scss']
})

export class D2RevisionSolicitudComponent implements OnInit, AfterViewInit {
    public status: number;
    public editMode: boolean;
    public IdSolicitud: number;
    public frm : FormGroup;
    public loading: boolean;
    public errorMessages: string;

    /* Collections */
    public collectionsDestinoCredito: Collection;
    public collectionsPaises: Collection;
    public collectionsGeneros: Collection;
    public collectionsPeriodos: Collection;
    public collectionsAlianza: Collection;
    public previousData: any;
    public currentState: any;
    public validSchool: any;

    public motivoRechazo: Collection;

    /* Strings */
    public nombreAlianza: string;
    public nombresIdDestinoCredito : string;
    public nombrePeriodicidad : string;
    public nombrePeriodos: string;
    public nombrePais:string;
    public nombreGenero:string;
    public OtraPersona: boolean;
    public requiredSchool: boolean;
    public tempCollections: any;
    public canEdit: boolean;
    public canDecline: boolean;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private utilities: UtilitiesService,
        private fb: FormBuilder,
        private D3Service: PromotorD2RevisionDestino,
        private modalErrors: NgbModal,
    ) {
        this.status = 2;
        this.editMode = false;
        this.loading = false;
        this.validSchool = [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
        this.canEdit = false;
        this.canDecline = false;
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    ngOnInit(): void {
        this.IdSolicitud = this.route.snapshot.params.id;
        this.route.data.subscribe((data: Data) => {
            this.collectionsDestinoCredito = data['collections'][0].data;
            this.collectionsPaises = data['collections'][1].data;
            this.collectionsGeneros = data['collections'][2].data;
            this.collectionsPeriodos = data['collections'][3].data;
            this.previousData = data['collections'][4].data.revision_destino;
            this.currentState = data['collections'][4].data;
            this.collectionsAlianza = data['collections'][5].data;
        });

        this.canEdit = this.utilities.hasPermission('Edicion informacion');
        this.canDecline = this.utilities.hasPermission('Declinar Solicitud');

        this.route.data.subscribe((data: Data) => {
            this.motivoRechazo = data['motivoRechazo'][0].data;
        });

        this.nombreAlianza = this.utilities.getNameAlianzaFromId(this.collectionsAlianza,this.previousData.IdAlianza);
        this.nombresIdDestinoCredito = this.utilities.getNameIdDestinoCredito(this.collectionsDestinoCredito, this.previousData.IdDestinoCredito);
        this.nombrePeriodicidad = this.utilities.getPeriodicidadName(this.collectionsPeriodos, this.previousData.IdPeriodicidad);
        this.nombrePeriodos = this.utilities.getPeriodosName(this.collectionsPeriodos, this.previousData.IdPeriodicidad);
        this.nombrePais = this.utilities.getCountryName(this.collectionsPaises, this.previousData.PaisEstudios);
        this.nombreGenero = this.utilities.getGeneroName(this.collectionsGeneros, this.previousData.IdGeneroEstudiante);

        this.OtraPersona = !! parseInt(this.previousData.CreditoOtraPersona);

        if (this.previousData.IdDestinoCredito) {
            this.previousData.IdDestinoCredito = this.previousData.IdDestinoCredito.replace(/\[/, '');
            this.previousData.IdDestinoCredito = this.previousData.IdDestinoCredito.replace(/\]/, '');
            this.tempCollections = this.previousData.IdDestinoCredito.split(',');
            var dest = [];
    
            this.tempCollections.forEach(d => {
              dest.push(Number(d));
            });
    
            this.tempCollections = dest;
    
            this.previousData.IdDestinoCredito = dest;
    
            this.previousData.IdDestinoCredito = this.previousData.IdDestinoCredito;
    
            this.previousData.IdDestinoCredito.forEach(element => {
              if (this.validSchool.indexOf(element) > -1) {
                this.requiredSchool = true;
              }
            });
        }

        

        this.frm = this.createForm();
    }

    declinarSubmit(MotivoRechazo){
        if ( MotivoRechazo.value == '' ) {
            alert('Debe selecciona un motivo de rechazo');
            return;
        }

        let data = {MotivoRechazo: MotivoRechazo.value};
        
        this.utilities.declinarSolicitud(this.IdSolicitud, data).subscribe(
            resp => {
                this.modalErrors.dismissAll();
                this.router.navigateByUrl("promotor/dashboard");
            },
            errors => {
            }
        );
    }

    declinar(modalDeclinar){
        this.modalErrors.open(modalDeclinar, { ariaLabelledBy: 'modal-basic-title', centered: false});
    }

    triggerEdit(){
        this.editMode = true;
    }

    recibirCancelacion($event){
        this.editMode = false;
    }

    onSubmit(modalTarget){
        this.loading = true;
        this.D3Service.sendCheckedData(this.IdSolicitud).subscribe(
            response =>{
                this.loading = false;
                this.router.navigateByUrl("promotor/revision_acreditacion/"+this.IdSolicitud)
            }, 
            errors =>{
                this.loading = false;

                this.errorMessages = errors.error.message;
                this.modalErrors.open(modalTarget, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        );
    }

    createForm(){
        return this.fb.group({
            confirmData:[
                null
            ]
        })
    }

    recibirGuardado($event){
        this.editMode = false;
        const montoPago=this.previousData.MontoPago
        this.previousData = $event.data;
        this.previousData.MontoPago=montoPago;
        this.nombreAlianza = this.utilities.getNameAlianzaFromId(this.collectionsAlianza,$event.data.IdAlianza);
        this.nombresIdDestinoCredito = this.utilities.getNameIdDestinoCredito(this.collectionsDestinoCredito, $event.data.IdDestinoCredito);
        this.nombrePeriodicidad = this.utilities.getPeriodicidadName(this.collectionsPeriodos, $event.data.IdPeriodicidad);
        this.nombrePeriodos = this.utilities.getPeriodosName(this.collectionsPeriodos, $event.data.IdPeriodicidad);
        this.nombrePais = this.utilities.getCountryName(this.collectionsPaises, $event.data.PaisEstudios);
        this.nombreGenero = this.utilities.getGeneroName(this.collectionsGeneros, $event.data.IdGeneroEstudiante);
        this.OtraPersona = !! parseInt($event.data.CreditoOtraPersona);
        if ($event.data.IdDestinoCredito) {
            $event.data.IdDestinoCredito = $event.data.IdDestinoCredito.replace(/\[/, '');
            $event.data.IdDestinoCredito = $event.data.IdDestinoCredito.replace(/\]/, '');
            this.tempCollections = $event.data.IdDestinoCredito.split(',');
            var dest = [];
    
            this.tempCollections.forEach(d => {
              dest.push(Number(d));
            });
    
            this.tempCollections = dest;
    
            $event.data.IdDestinoCredito = dest;
    
            $event.data.IdDestinoCredito = $event.data.IdDestinoCredito;
    
            $event.data.IdDestinoCredito.forEach(element => {
              if (this.validSchool.indexOf(element) > -1) {
                this.requiredSchool = true;
              }
            });
        }
        
    }
}