import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Collection } from '../../../models/collection.model';
import { B1Service } from "../../../services/B1.service";
import { B1Model } from '../../../models/B1.model';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { CheckLoginService } from 'src/app/services/check_login.service';
import { AuthService } from 'src/app/services/auth.service';
import { Subscription, interval, empty } from 'rxjs';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CollectionsService } from 'src/app/services/colecctions.service';
import { CustomValidators } from 'src/app/validators/validators';


@Component({
  selector: 'app-b1-destino-credito',
  templateUrl: './b1-destino-credito.component.html',
  styleUrls: ['./b1-destino-credito.component.scss']
})
export class B1DestinoCreditoComponent implements OnInit,AfterViewInit {
  public _prevSelected: boolean;
  public errorMessages: string;
  public selected_one_value: boolean;
  public status: number;
  public variables: object;
  public data: B1Model;
  public collectionsDestinoCredito: Collection;
  public collectionsAgencia: Collection;
  public collectionsPlazosMensualidades: Collection;
  public collectionsPeriodos: Collection;
  public collectionsPaises: Collection;
  public collectionsGeneros: Collection;
  public tempCollections: any = [];
  public model: NgbDateStruct;
  public date: { year: number, month: number };
  public subscription: Subscription;
  public items: number;
  public user: {
    name: string,
    last_name: string
  };
  public changed: boolean;
  public principal_stage: number;
  public sub_stage: number;
  public IdSolicitud;
  public previous_data: any = [];
  public frmB1: FormGroup;
  public validSchool: any = [];
  public otra_alianza: boolean;
  public loading: boolean;
  public requiredSchool: boolean;
  public collectionsPlazo: any;
  public hasDisposiciones: boolean;
  public calcularCotizacion: boolean;
  public isCalculatedCotizacion:boolean;
  public montoCotizacion = {
    mensualidadCotizacion:"-",
    montoMensualidadCotizacion:0

  }

  public minDate: any;
  public minDateBirthdate: any;

  public plazosFijos: any;

  constructor(
    private B1Service: B1Service,
    private router: Router,
    private route: ActivatedRoute,
    private modalErrors: NgbModal,
    private checkLogin: CheckLoginService,
    private authService: AuthService,
    private utilitiesService: UtilitiesService,
    private fb: FormBuilder,
    private collections: CollectionsService
  ) {

    this.selected_one_value = false;
    this.status = 1;
    this.errorMessages = "";
    this.data = new B1Model();
    this.changed = false;
    this.frmB1 = this.createB1Form();
    this.validSchool = [1, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
    this.requiredSchool = false;
    this.otra_alianza = false;
    this.loading = false;
    this.calcularCotizacion = true;

    this.minDate = {
      year: new Date().getFullYear() - 5,
      month: 1,
      day: 1
    };

    this.minDateBirthdate = {
      year: new Date().getFullYear() - 69,
      month: 1,
      day: 1
    };

  }

  ngOnInit() {
    this.IdSolicitud = this.route.snapshot.params.id;
    if (this.route.snapshot.data.IdSolicitud) {
      this.previous_data = this.route.snapshot.data.IdSolicitud.data.B1;
    }
    /* El orden de los datos tiene que ser el mismo que esta en el resolver
        -Destino Credito
        -Agencia
        -Plazos de Mensualidad
        -Periodos
        -Paises
        Generos
    */

    this.route.data.subscribe((data: Data) => {
      this.collectionsDestinoCredito = data['collections'][0].data;
      this.collectionsAgencia = data['collections'][1].data;
      this.collectionsPlazosMensualidades = data['collections'][2].data;
      this.collectionsPeriodos = data['collections'][3].data;
      this.collectionsPaises = data['collections'][4].data;
      this.collectionsGeneros = data['collections'][5].data;
      this.plazosFijos = data['collections'][6].data;
    });
    if (this.previous_data.length != 0) {
      this.data = this.previous_data;
    }
    if (typeof (this.data.IdAlianza) == 'undefined') {
      this.hasDisposiciones = false;
      this.isCalculatedCotizacion = false;
      this.data.IdAlianza = 0;
      this.data.Plazo = "";
      this.data.IdPeriodicidadDisposiciones = 0;
      this.data.IdGeneroEstudiante = 0;
      this.data.PaisEstudios = 0;
      this.data.CreditoOtraPersona = "0";
      this.data.NoDisposiciones = 1;
      this._prevSelected = false;
      this.frmB1.patchValue(this.data);
    }
    else {

      if(this.data.MontoPago != undefined){
        this.montoCotizacion.montoMensualidadCotizacion = this.data.MontoPago;
      }

      if(this.data.Plazo != undefined){
        this.montoCotizacion.mensualidadCotizacion = this.data.Plazo;
      }

      if(this.data.IdCotizacion != null || this.data.IdCotizacion != undefined){
        this.isCalculatedCotizacion = true;
      }


      if (this.previous_data.CreditoOtraPersona == null || this.previous_data.CreditoOtraPersona == "0" || this.previous_data.CreditoOtraPersona == 0){
        this.data.CreditoOtraPersona = "0";
        this._prevSelected = false;
      }else{
        this._prevSelected = true;
      }

      if ( this.previous_data.NumeroDisposiciones > 0 ) {
        this.data.NoDisposiciones = this.previous_data.NumeroDisposiciones;
      }

      if (this.previous_data.InicioPrograma != null) {
        const [year, month, day] = this.previous_data.InicioPrograma.split('-');
        const obj = {
          year: parseInt(year), month: parseInt(month), day:
            parseInt(day.split(' ')[0].trim())
        };
        this.data.FechaInicio = obj;
      }

      if (this.previous_data.FechaNacimientoEstudiante != null) {
        const [year, month, day] = this.previous_data.FechaNacimientoEstudiante.split('-');
        const obj = {
          year: parseInt(year), month: parseInt(month), day:
            parseInt(day.split(' ')[0].trim())
        };
        this.data.FechaNacimientoEstudiante = obj;
      }



      if (this.previous_data.IdDestinoCredito) {
        this.selected_one_value = true;
        this.previous_data.IdDestinoCredito = this.previous_data.IdDestinoCredito.replace(/\[/, '');
        this.previous_data.IdDestinoCredito = this.previous_data.IdDestinoCredito.replace(/\]/, '');
        this.tempCollections = this.previous_data.IdDestinoCredito.split(',');
        var dest = [];

        this.tempCollections.forEach(d => {
          dest.push(Number(d));
        });

        this.tempCollections = dest;

        this.previous_data.IdDestinoCredito = dest;

        this.data.IdDestinoCredito = this.previous_data.IdDestinoCredito;

        this.data.IdDestinoCredito.forEach(element => {
          if (this.validSchool.indexOf(element) > -1) {
            this.requiredSchool = true;
          }
        });
      }


      if (this.data.MontoMaximo != undefined && this.data.MontoMinimo != undefined) {
        this.frmB1.controls["MontoSolicitado"].clearValidators();
        this.frmB1.controls['MontoSolicitado'].setValidators([
          Validators.compose(
            [
              Validators.required,
              Validators.min(this.data.MontoMinimo),
              Validators.max(this.data.MontoMaximo),

            ]
          )
        ]);
        this.frmB1.get("MontoSolicitado").updateValueAndValidity();
      }

      if (this.data.PlazoMin != undefined && this.data.PlazoMax != undefined){
        var tempArray = [];
        this.plazosFijos.forEach(element => {
          if ( parseInt(element.Plazo) >= parseInt(this.data.PlazoMin) && parseInt(element.Plazo) <= parseInt(this.data.PlazoMax) ) {
            tempArray.push(element.Plazo);
          }
        });
        // for (let index = parseInt(this.data.PlazoMin); index <= parseInt(this.data.PlazoMax); index++) {
        //   tempArray.push(index);
        // }
        this.collectionsPlazo = tempArray;
      }

      if (this.data.AplicaDisposiciones == 0){
        this.hasDisposiciones = false;
      }else{
        this.hasDisposiciones = true;
      }



      let IdPersona = Number(localStorage.getItem('IdPerson'));
      this.data.IdPersona = IdPersona;

      this.frmB1.patchValue(this.data);
    }

    this.checkLogin.validateExpiracyToken().subscribe(
      response => {

        let source = interval(response);
        this.subscription = source.subscribe(val => this.opensnack());
      },
      errors => {
        this.authService.logout()
      }
    );
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

  checkedDestinoCredito(id) {
    if (this.previous_data && this.previous_data.IdDestinoCredito && this.previous_data.IdDestinoCredito.indexOf(Number(id)) != -1) {
      return "checked";
    }
  }

  checkedOtraPersona(value) {
    if (this.previous_data && this.previous_data.CreditoOtraPersona && this.previous_data.CreditoOtraPersona == value) {
      return "checked";
    }
  }

  opensnack() {
    if (confirm("La sesión está apunto de expirar, quieres que sea renovada?")) {
      this.authService.refreshToekn().subscribe(
        resp => {
          localStorage.setItem("token", resp['access_token']);
          const today = new Date();
          const expiresDateToken = new Date(today.getTime() + (resp['expires_in'] * 1000))
          localStorage.setItem('expires_in', expiresDateToken.getTime().toString());
          this.subscription && this.subscription.unsubscribe();
          this.checkLogin.validateExpiracyToken().subscribe(
            response => {
              let source = interval(response);
              this.subscription = source.subscribe(val => this.opensnack());
            },
            errors => {
              this.authService.logout()
            }
          );
        }
      );
    } else {
      this.authService.logout().subscribe(
        response => {
          localStorage.clear();
          this.router.navigateByUrl('/login');
        }
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }

  elementChange() {
    this.changed = true;
  }

  submitB1(modalTarget, route) {

    if (this.IdSolicitud != undefined) {
      this.frmB1.patchValue({
        IdSolicitud: this.IdSolicitud,
        CalcularCotizacion: false
      })

      this.previous_data.IdSolicitud = this.IdSolicitud;
      let orderedPrevious = JSON.stringify(this.utilitiesService.sortObject(this.previous_data)).toLowerCase();
      let orderedCurrent = JSON.stringify(this.utilitiesService.sortObject(this.frmB1.value)).toLowerCase();

      if (orderedPrevious != orderedCurrent) {
        this.onSubmit(modalTarget);
        this.router.navigateByUrl(route + this.IdSolicitud);
      } else {
        this.router.navigateByUrl(route + this.IdSolicitud);
      }
    } else {
      alert("Debes de llenar el formulario para navegar a la siguiente sección");
    }

  }

  handleAlianza(evt) {
    var index = evt.target.selectedIndex;
    var alianza = evt.target[index].text;
    if (alianza.toLowerCase() == "sin alianza" || alianza.toLowerCase() == "otra") {
      this.otra_alianza = true;
      this.loading = true;
      this.collections.getAlianzaDetails(evt.target[index].value).subscribe(
        response => {
          this.loading = false;
          if (response.data) {
            if (parseInt(response.data.AplicaDisposiciones) != 0) {
              this.hasDisposiciones = true;
              this.frmB1.controls["NoDisposiciones"].clearValidators();
              this.frmB1.controls['NoDisposiciones'].setValidators([
                Validators.compose(
                  [
                    Validators.required,
                    CustomValidators.patternValidator(
                      /^[1-9]\d*$/,
                      {
                        validDisposiciones: true
                      }
                    )
                  ]
                )
              ]);
              this.frmB1.get("NoDisposiciones").updateValueAndValidity();
            } else {
              this.hasDisposiciones = false;
            }

            var tempArray = [];
            this.plazosFijos.forEach(element => {
              if ( parseInt(element.Plazo) >= parseInt(response.data.PlazoMin) && parseInt(element.Plazo) <= parseInt(response.data.PlazoMax) ) {
                tempArray.push(element.Plazo);
              }
            });
            // for (let index = parseInt(response.data.PlazoMin); index <= parseInt(response.data.PlazoMax); index++) {
            //   tempArray.push(index);
            // }
            this.collectionsPlazo = tempArray;
            this.frmB1.controls["MontoSolicitado"].clearValidators();
            this.frmB1.controls['MontoSolicitado'].setValidators([
              Validators.compose(
                [
                  Validators.required,
                  Validators.min(response.data.MontoMinimo),
                  Validators.max(response.data.MontoMaximo),

                ]
              )
            ]);
            this.frmB1.get("MontoSolicitado").updateValueAndValidity();

          } else {
            this.frmB1.controls["MontoSolicitado"].clearValidators();
            this.frmB1.controls['MontoSolicitado'].setValidators([
              Validators.compose(
                [
                  Validators.required,
                ]
              )
            ]);
            this.frmB1.get("MontoSolicitado").updateValueAndValidity();
          }
        },
        errors => {
          this.loading = false;
          this.frmB1.controls["MontoSolicitado"].clearValidators();
          this.frmB1.controls['MontoSolicitado'].setValidators([
            Validators.compose(
              [
                Validators.required,
              ]
            )
          ]);
          this.frmB1.get("MontoSolicitado").updateValueAndValidity();
        }
      )
    } else {
      this.otra_alianza = false;
      this.utilitiesService.removeControlsValidity(this.frmB1, ['Agencia']);
      this.loading = true;
      this.collections.getAlianzaDetails(evt.target[index].value).subscribe(
        response => {
          this.loading = false;
          if (response.data) {
            if (parseInt(response.data.AplicaDisposiciones) != 0) {
              this.hasDisposiciones = true;
              this.frmB1.controls["NoDisposiciones"].clearValidators();
              this.frmB1.controls['NoDisposiciones'].setValidators([
                Validators.compose(
                  [
                    Validators.required,
                    CustomValidators.patternValidator(
                      /^[1-9]\d*$/,
                      {
                        validDisposiciones: true
                      }
                    )
                  ]
                )
              ]);
              this.frmB1.get("NoDisposiciones").updateValueAndValidity();
            } else {
              this.hasDisposiciones = false;
            }

            var tempArray = [];

            this.plazosFijos.forEach(element => {
              if ( parseInt(element.Plazo) >= parseInt(response.data.PlazoMin) && parseInt(element.Plazo) <= parseInt(response.data.PlazoMax) ) {
                tempArray.push(element.Plazo);
              }
            });
            // for (let index = parseInt(response.data.PlazoMin); index <= parseInt(response.data.PlazoMax); index++) {
            //   tempArray.push(index);
            // }
            this.collectionsPlazo = tempArray;
            this.frmB1.controls["MontoSolicitado"].clearValidators();
            this.frmB1.controls['MontoSolicitado'].setValidators([
              Validators.compose(
                [
                  Validators.required,
                  Validators.min(response.data.MontoMinimo),
                  Validators.max(response.data.MontoMaximo),

                ]
              )
            ]);
            this.frmB1.get("MontoSolicitado").updateValueAndValidity();

          } else {
            this.frmB1.controls["MontoSolicitado"].clearValidators();
            this.frmB1.controls['MontoSolicitado'].setValidators([
              Validators.compose(
                [
                  Validators.required,
                ]
              )
            ]);
            this.frmB1.get("MontoSolicitado").updateValueAndValidity();
          }
        },
        errors => {
          this.loading = false;
          this.frmB1.controls["MontoSolicitado"].clearValidators();
          this.frmB1.controls['MontoSolicitado'].setValidators([
            Validators.compose(
              [
                Validators.required,
              ]
            )
          ]);
          this.frmB1.get("MontoSolicitado").updateValueAndValidity();
        }
      )

    }
  }

  calculateCotizacion(modalTarget, typeCotizacion) {
    let fields = ['IdAlianza', 'MontoSolicitado', 'Plazo'];
    let puede_cotizar = true;
    fields.forEach(element => {
      if ( this.frmB1.get(element).errors != null ) {
        this.frmB1.get(element).markAsDirty();
        puede_cotizar = false;
      }
    });

    if ( ! puede_cotizar ) {
      return;
    }

    var data: any;
    if (typeCotizacion == 0) {
      data = {
        IdAlianza: this.frmB1.controls['IdAlianza'].value,
        MontoSolicitado: this.frmB1.controls['MontoSolicitado'].value,
        Plazo: this.frmB1.controls['Plazo'].value,
        IdSolicitud: this.IdSolicitud,
        CalcularCotizacion: true,
      }

    } else {
      if (this.frmB1.controls['IdPeriodicidadDisposiciones'].value != 'unico'){
        data = {
          IdAlianza: this.frmB1.controls['IdAlianza'].value,
          MontoSolicitado: this.frmB1.controls['MontoSolicitado'].value,
          NoDisposiciones: this.frmB1.controls['NoDisposiciones'].value,
          Plazo:0,
          IdPeriodicidadDisposiciones: this.frmB1.controls['IdPeriodicidadDisposiciones'].value,
          IdSolicitud: this.IdSolicitud,
          CalcularCotizacion: true,
        }
      }else{
        data = {
          IdAlianza: this.frmB1.controls['IdAlianza'].value,
          MontoSolicitado: this.frmB1.controls['MontoSolicitado'].value,
          Plazo: this.frmB1.controls['Plazo'].value,
          IdSolicitud: this.IdSolicitud,
          CalcularCotizacion: true,
        }
      }
    }
    this.loading = true
    this.B1Service.sendDestinoCredito(data).subscribe(
      response => {
        this.loading = false;
        if (response.data.id != undefined) {
          this.IdSolicitud = response.data.id;
          this.isCalculatedCotizacion = true;
          this.montoCotizacion.mensualidadCotizacion = response.data.Plazo;
          this.montoCotizacion.montoMensualidadCotizacion = response.data.MontoPago;
        }
      },
      errorService => {
        this.loading = false;
        this.errorMessages = errorService.error.message;
        this.modalErrors.open(modalTarget, { ariaLabelledBy: 'modal-basic-title', centered: true });
      }
    );
  }

  checkForm(){
    if (!this.frmB1.valid) {
      Object.keys(this.frmB1.value).forEach(element => {
        if ( this.frmB1.get(element).errors != null ) {
          this.frmB1.get(element).markAsDirty();
        }
      });
      return;
    }
  }

  onSubmit(modalTarget) {
    if (this.tempCollections.length > 0) {
      this.frmB1.patchValue({
        IdDestinoCredito: this.tempCollections.join(),
      });
    }

    if (!this.frmB1.valid) {
      Object.keys(this.frmB1.value).forEach(element => {
        if ( this.frmB1.get(element).errors != null ) {
          this.frmB1.get(element).markAsDirty();
        }
      });
      return;
    }

    if ( ! this.isCalculatedCotizacion ) {
      this.errorMessages = 'Pirmero debes calcular una cotización para poder avanzar';
      this.modalErrors.open(modalTarget, { ariaLabelledBy: 'modal-basic-title', centered: true });
      return;
    }

    this.loading = true;

    let user = JSON.parse(localStorage.getItem("user"));
    let IdPersona = Number(localStorage.getItem('IdPerson'));
    let IdUsuario = user.id;
    let FormatFechaInicio = "";
    let FormatFechaEstudiante = "";
    if (this.requiredSchool) {
      FormatFechaInicio = this.utilitiesService.parseDate(this.frmB1.controls['FechaInicio'].value);
    }

    if (this.frmB1.controls['FechaNacimientoEstudiante'].value != null) {
      FormatFechaEstudiante = this.utilitiesService.parseDate(this.frmB1.controls['FechaNacimientoEstudiante'].value);
    }
    if (this.frmB1.controls['Plazo'].value == null || this.frmB1.controls['Plazo'].value == undefined || this.frmB1.controls['Plazo'].value == "") {
      this.frmB1.patchValue({
        Plazo: 0
      })
    }

    if (this.IdSolicitud != undefined) {
      this.frmB1.patchValue({
        IdSolicitud: this.IdSolicitud
      })
    }

    this.frmB1.patchValue({
      IdPersona: IdPersona,
      IdUsuario: IdUsuario,
      IdDestinoCredito: this.tempCollections.join(),
      FechaInicio: FormatFechaInicio,
      FechaNacimientoEstudiante: FormatFechaEstudiante,
      nextStep: 1,
      CalcularCotizacion: false
    });

    this.B1Service.sendDestinoCredito(this.frmB1.value).subscribe(
      response => {
        if (this.previous_data.length != 0) {
          this.router.navigateByUrl('client/requisitos_credito/' + this.IdSolicitud);
        }
        else {
          this.router.navigateByUrl('client/requisitos_credito/' + response['data']['id']);
        }
      },
      errorService => {
        this.loading = false;
        var msgError = errorService.error.error;
        if (msgError && msgError.indexOf("token") > -1) {
          localStorage.clear();
          this.router.navigateByUrl("/login");
        } else {
          this.errorMessages = errorService.error.message;
          this.modalErrors.open(modalTarget, { ariaLabelledBy: 'modal-basic-title', centered: true });
        }

      }
    );

  }

  onChangeCategory(evt, cat: Collection) {
    this.changed = true;
    var target = evt.target;

    if (target.checked) {
      this.tempCollections.push(Number(cat.id));
    } else {
      var index = this.tempCollections.indexOf(Number(cat.id));

      if (index > -1) {
        this.tempCollections.splice(index, 1);
      }
    }

    if (this.tempCollections.length > 0) {
      this.selected_one_value = true;
    }
    else {
      this.selected_one_value = false;
    }

    if (this.tempCollections.length > 3) {
      if (target.checked) {
        this.tempCollections.pop(Number(cat.id));

        target.checked = false;
      }
    }

    this.requiredSchool = false;

    this.tempCollections.forEach(element => {
      if (this.validSchool.indexOf(element) > -1) {
        this.requiredSchool = true;
      }
    });

    let controls = ['Escuela', 'FechaInicio', 'Duracion', 'IdPeriodicidad', 'PaisEstudios', 'CiudadEstudios'];
    if (!this.requiredSchool) {
      this.utilitiesService.removeControlsValidity(this.frmB1, controls);
    }
    else {
      controls.forEach(element => {
        if ( element == 'IdPeriodicidad' || element == 'PaisEstudios') {
          this.frmB1.get(element).setValidators([Validators.required, Validators.min(1)]);
        }
        else{
          this.frmB1.get(element).setValidators(Validators.required);
        }
      });
    }

  }

  onlyDigits(event){
    if (event.which != 8 && event.which != 0 && event.which < 48 || event.which > 57)
    {
        event.preventDefault();
    }
}

  handleChangeBeneficent(evt) {
    this.changed = true;
    var target = evt.target;
    if (parseInt(target.value) == 0) {
      this._prevSelected = false;
      let controls = ['NombreEstudiante', 'IdGeneroEstudiante', 'FechaNacimientoEstudiante', 'EmailEstudiante'];
      this.utilitiesService.removeControlsValidity(this.frmB1, controls);
    } else {
      this._prevSelected = true;
      let controls = {
        NombreEstudiante: [
          null,
          Validators.required
        ],
        IdGeneroEstudiante: [
          null,
          Validators.min(1)
        ],
        FechaNacimientoEstudiante: [
          null,
          Validators.required
        ],
        EmailEstudiante: [
          null,
          Validators.required
        ],
      }
      this.utilitiesService.addControlsValidity(this.fb, controls);
      this.frmB1.controls['IdGeneroEstudiante'].setValidators([
        Validators.compose(
          [
          Validators.required,
          Validators.min(1),

          ]
        )
      ]);
    }
  }

  createB1Form(): FormGroup {
    return this.fb.group(
      {
        MontoSolicitado: [
          null,
          Validators.required
        ],
        IdDestinoCredito: [
          null,
          Validators.required
        ],
        Plazo: [
          null,
          Validators.required
        ],
        IdAlianza: [
          null,
          [Validators.required, Validators.min(1)]
        ],
        Agencia: [
          null,
          null
        ],
        Escuela: [
          null,
          null
        ],
        Duracion: [
          null,
          null
        ],
        PaisEstudios: [
          null,
          null
        ],
        IdPeriodicidadDisposiciones: [
          null,
          null
        ],
        IdPeriodicidad:[
          null
        ],
        CiudadEstudios: [
          null,
          null
        ],
        CreditoOtraPersona: [
          Validators.required,
          null
        ],
        NombreEstudiante: [
          null,
          null
        ],
        IdGeneroEstudiante: [
          null,
          null
        ],
        FechaNacimientoEstudiante: [
          null,
          null
        ],
        EmailEstudiante: [
          null,
          null
        ],
        FechaInicio: [
          null,
          null
        ],
        IdUsuario: [
          null,
          null
        ],
        IdPersona: [
          null,
          null
        ],
        IdSolicitud: [
          null,
          null
        ],
        IdEstatusSolicitud: [
          null
        ],
        nextStep: [
          null
        ],
        CalcularCotizacion: [
          null
        ],
        NoDisposiciones: [
          CustomValidators.patternValidator(
            /^[1-9]\d*$/,
            {
              validDisposiciones: true
            }
          )
        ],

      },
    );
  }
}
