import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Collection } from 'src/app/models/collection';
import { CollectionsService } from 'src/app/services/colecctions.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { M4PromotorInformacionLaboralService } from 'src/app/services/promotor/M4PromotorInformacionLaboralService.service';

@Component({
    selector: 'app-m4-promotor-aval-informacion-laboral',
    templateUrl: './m4-promotor-aval-informacion-laboral.component.html',
})

export class M4PromotorAvalInformacionLaboralComponent implements OnInit, AfterViewInit {
    public status: number;
    public IdSolicitud: number;
    public IdPersona: number;
    public tipoAval: number;
    public editMode:boolean;
    public errorMessages: string;
    public loading: boolean;

    /* collections from resolver */
    public collectionsSituacionLaboral: Collection;
    public collectionsOcupaciones: Collection;
    
    /* Collections for component data */
    public data: any;
    public dataPersonSolicitud:any;

    /* Strings */
    public SituacionLaboral:string;
    public Ocupacion:string;

    public validSituacionLaboral: boolean;

    public canEdit: boolean;
    public canDecline: boolean;

    constructor(
        private route: ActivatedRoute,
        private collectionsService: CollectionsService,
        private utilities: UtilitiesService,
        private M4Service: M4PromotorInformacionLaboralService,
        private router: Router
    ) {
        this.status = 2;
        this.validSituacionLaboral = true;
        this.canEdit = false;
        this.canDecline = false;
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    ngOnInit() {
        this.IdSolicitud = this.route.snapshot.params.id_solicitud;
        this.IdPersona = this.route.snapshot.params.id_persona;
        this.tipoAval = this.route.snapshot.params.tipo_aval;
        this.editMode = false;
        this.route.data.subscribe((data: Data) => {
            this.collectionsSituacionLaboral = data['collections'][0].data;
            this.collectionsOcupaciones = data['collections'][1].data;
            this.data = data['data'][0].data.informacion_laboral;
            this.dataPersonSolicitud = data['data'][0].data.informacion_propietario;
        });

        this.canEdit = this.utilities.hasPermission('Edicion informacion');
        this.canDecline = this.utilities.hasPermission('Declinar Solicitud');

        if ( this.data.IdSituacionLaboral == 2 || this.data.IdSituacionLaboral == 5 || this.data.IdSituacionLaboral == 6 ) {
            this.validSituacionLaboral = false;
        }
        else{
            this.validSituacionLaboral = true;
        }

        this.SituacionLaboral = this.utilities.getFieldFromCollection(this.collectionsSituacionLaboral, this.data.IdSituacionLaboral, "name");
        this.Ocupacion = this.utilities.getFieldFromCollection(this.collectionsOcupaciones, this.data.IdOcupacion, "name");
        if ( this.validSituacionLaboral ) {
            this.data.FechaAntiguedad = this.data.FechaAntiguedad.split(" ")[0];
        }
        else{
            this.data.FechaAntiguedad = '';
        }
    }

    triggerEdit(){
        this.editMode = true;
    }

    recibirCancelacion($event){
        this.editMode = false;
    }

    onSubmit(modalError){
        this.loading = true;
        this.M4Service.setDataComplete(this.IdSolicitud, this.IdPersona).subscribe(
            resp => {
                this.loading = false;
                this.router.navigateByUrl('promotor/revision/aval/economica/'+this.IdSolicitud+'/'+this.IdPersona+'/'+this.tipoAval);
            }
        )
    }
    
    recibirGuardado($event){
        this.editMode = false;
        this.data = $event.data;
        this.SituacionLaboral = this.utilities.getFieldFromCollection(this.collectionsSituacionLaboral, $event.data.IdSituacionLaboral, "name");
        this.Ocupacion = this.utilities.getFieldFromCollection(this.collectionsOcupaciones, $event.data.IdOcupacion, "name");
        this.data.FechaAntiguedad = $event.data.FechaAntiguedad.split(" ")[0];
    }
}