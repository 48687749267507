import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router, Data } from '@angular/router';
import { E5Service } from "../../../services/E5DocumentacionService.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesAvalService } from 'src/app/services/aval/UtilitiesAvalService.service';

@Component({
  selector: 'app-f1-informacion-aval',
  templateUrl: './f1-informacion-aval.component.html',
  styleUrls: ['./f1-informacion-aval.component.scss']
})
export class F1InformacionAvalComponent implements OnInit {
  public status: number;
  public IdSolicitud: number;
  public personalData: any;
  public data = { Confirmacion: null, IdSolicitud: null };
  public loading: boolean;
  public errorMessages: string;
  private modalErrors: NgbModal;
  public avalData: any;
  public fullAval: number;
  public permissionsAval = [];
  public dataStatusInfoAval = []; // Dice si el aval ya lleno la info
  public dataCompleteInfoAval = []; // Saber si ya se reviso y aprobo por el promotor
  
  constructor(
    private route: ActivatedRoute,
    private E5Service: E5Service,
    private router: Router,
    private avalService: UtilitiesAvalService
  ) {
    this.status = 3;
  }

  ngOnInit() {
    this.personalData = JSON.parse(localStorage.getItem('person'));
    this.IdSolicitud = this.route.snapshot.params.id;
    this.data.Confirmacion = false;
    this.route.data.subscribe((data: Data) => {
      this.avalData = data['data'][0].data.avales;
    });
    if (this.avalData.length > 0) {
      if (this.avalData.length == 1) {
        this.permissionsAval.push(this.getAvalPermissions(this.avalData[0].IdTipoAval));
        this.dataStatusInfoAval.push(this.getStatusInfoAval(this.avalData[0].IdTipoAval, 0, this.avalData));
        this.dataCompleteInfoAval.push(this.getStatusCompleteAval(this.avalData[0].IdTipoAval, 0, this.avalData, this.permissionsAval[0]));
      } else {
        if (this.avalData.length == 2) {
          this.permissionsAval.push(this.getAvalPermissions(this.avalData[0].IdTipoAval));
          this.permissionsAval.push(this.getAvalPermissions(this.avalData[1].IdTipoAval));
          this.dataStatusInfoAval.push(this.getStatusInfoAval(this.avalData[0].IdTipoAval, 0, this.avalData));
          this.dataStatusInfoAval.push(this.getStatusInfoAval(this.avalData[1].IdTipoAval, 1, this.avalData));
          this.dataCompleteInfoAval.push(this.getStatusCompleteAval(this.avalData[0].IdTipoAval, 0, this.avalData, this.permissionsAval[0]));
          this.dataCompleteInfoAval.push(this.getStatusCompleteAval(this.avalData[1].IdTipoAval, 1, this.avalData, this.permissionsAval[1]));
        }
      }
      if (this.dataCompleteInfoAval.length > 0) {
        let temp_dataStatusInfoAval = true;
        let temp_dataCompleteInfoAval = true;
        this.dataCompleteInfoAval.forEach((element, key) => {
          temp_dataStatusInfoAval = this.dataStatusInfoAval[key];
          temp_dataCompleteInfoAval = this.dataCompleteInfoAval[key];
        });

          if ( temp_dataStatusInfoAval ) {
            this.fullAval = 1;
          }
          else{
            this.fullAval = 0;
          }
      }
      else {
        this.fullAval = 1;
      }
    }
    else {
      this.fullAval = 1;
    }
  }

  nameAval(tipoAval) {
    return this.avalService.getNameTipoAval(tipoAval);
  }

  getAvalPermissions(TipoAval) {
    return this.avalService.getPermissions(TipoAval);
  }

  getStatusInfoAval(tipoAval, index, currentData) {
    var currentInfo: boolean = false;
    if (currentData[index]) {      
      switch (parseInt(tipoAval)) {
        case 2:
        case 3:
        case 6:
        case 7:
        case 8:
        case 9:
          if (currentData[index].has_informacion_fiscal && currentData[index].has_informacion_personal && currentData[index].has_revision_documentacion) {
            currentInfo = true;
          }
          break;
        case 4:
        case 5:
          if (currentData[index].has_informacion_economica && currentData[index].has_informacion_familiar && currentData[index].has_informacion_fiscal && currentData[index].has_informacion_laboral && currentData[index].has_informacion_personal && currentData[index].has_revision_documentacion) {
            currentInfo = true;
          }
          break;
      }
    }
    return currentInfo;
  }

  getStatusCompleteAval(tipoAval, index, dataComplete, permissions) {
    var currentInfo: boolean = false;

    if (dataComplete[index]) {
      switch (parseInt(tipoAval)) {
        case 2:
        case 3:
        case 6:
        case 7:
        case 8:
        case 9:
          if (dataComplete[index].Fiscal == "1" && dataComplete[index].Personal == "1" && dataComplete[index].Documentacion == "1") {
            currentInfo = true;
          }
          break;

        case 4:
        case 5:
          if (dataComplete[index].Economica == "1" && dataComplete[index].Familiar == "1" && dataComplete[index].Fiscal == "1" && dataComplete[index].Laboral == "1" && dataComplete[index].Personal == "1" && dataComplete[index].Documentacion == "1") {
            currentInfo = true;
          }
          break;
      }
    }
    return currentInfo;
  }

  onSubmit(modalError) {
    this.loading = true;
    this.data.IdSolicitud = Number(this.IdSolicitud);
    this.E5Service.sendAprobarProceso(this.data).subscribe(
      resp => {
        this.router.navigateByUrl('client/messages/' + this.IdSolicitud);
      },
      errors => {
        var msgError = errors.error.error;
        if (msgError.indexOf("token") > -1) {
          localStorage.clear();
          this.router.navigateByUrl("/login");
        } else {
          this.loading = false;
          this.errorMessages = errors.error.message;
          this.modalErrors.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
        }
      }
    )
  }
}