import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { interval, Subscription } from 'rxjs';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'emisha-design';
  env = environment;

  constructor() {

  }
  ngOnInit() {
  }

  ngOnDestroy(): void {
  }
}


