import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Collection } from 'src/app/models/collection';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { E5Service } from "../../../services/E5DocumentacionService.service";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { requiredMultipleFileType } from '../../../directives/upload-file-multiple-validator';
import { requiredFileSize } from '../../../directives/upload-file-size-validator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { Location } from "@angular/common";

@Component({
  selector: 'app-d6-subir-documentacion',
  templateUrl: './d6-subir-documentacion.html'
})
export class D6SubirDocumentacionComponent implements OnInit,AfterViewInit {
  public status: number;
  public collectionsIdentificacion: Collection;
  public collectionsIdentificacion2: Collection;
  public collectionsComprobanteDomicilio: Collection;
  public collectionsComprobanteIngresos: Collection;
  public collectionsCedula: Collection;
  public IdSolicitud: any;
  public loading: boolean;
  public errorMessages: string;
  public GarantiaAuto: boolean;
  public GarantiaRPP: boolean;
  public need_rfc: boolean;
  public TipoIdentificacionVista:any;
  public noComprobantes: number;
  public ingresos:boolean;
  public index:any;
  public persona:any;
  public opcional:boolean;
  public collectionsConfirmacionIdentidad: Collection;


  public frmE5 = new FormGroup({
    TipoIdentificacion: new FormControl(null, [Validators.min(1)]),
    IdentificacionVigente: new FormControl(null, [requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]),
    IdentificacionVigente2: new FormControl(null, [requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]),
    TipoComprobante: new FormControl(null, [ Validators.min(1)]),
    ComprobanteDomicilio: new FormControl(null, [ requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]),
    TipoCedulaFiscal: new FormControl(null, [ Validators.min(1)]),
    CedulaFiscal: new FormControl(null, [ requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]),
    GrantiaAuto: new FormControl(null, [ requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]),
    EvidenciaInmueble: new FormControl(null, [ requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]),
    IdSolicitud: new FormControl(null),
    NoComprobantes:new FormControl(null),
    IdPersona:new FormControl(null),
    Opcional:new FormControl(null),
    ConfirmacionIdentidad: new FormControl(null, [ requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]),
  });

  constructor(
    private E5Service: E5Service,
    private route: ActivatedRoute,
    private router: Router,
    private modalError: NgbModal,
    private utilities: UtilitiesService,
    private location:Location,
  ) {
    this.status = 2;
    this.loading = false;
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.IdSolicitud = this.route.snapshot.params.id;
    this.route.data.subscribe((data: Data) => {
      this.collectionsIdentificacion = data['collections'][1].data;
      this.collectionsIdentificacion2 = data['collections'][1].data;
      this.collectionsComprobanteDomicilio = data['collections'][2].data;
      this.collectionsComprobanteIngresos = data['collections'][3].data;
      this.collectionsCedula = data['collections'][4].data;
      this.GarantiaAuto = data['collections'][0].data.GarantiaAuto;
      this.GarantiaRPP = data['collections'][0].data.GarantiaRPP;
      this.need_rfc = data['collections'][0].data.rfc;
      this.noComprobantes=data['collections'][0].data.NoComprobantes;
      this.persona=data['collections'][0].data.persona;
      this.opcional= data['collections'][0].data.has_revision_documentacion;
      this.collectionsConfirmacionIdentidad = data['collections'][0].data;
      
    });
    this.frmE5.patchValue({
      IdSolicitud: this.IdSolicitud,
      NoComprobantes:this.noComprobantes,
      IdPersona:this.persona.IdPersona,
      Opcional:this.opcional
    })
    this.ingresos=false;
    this.index=[];
    for (let i=1;i<=this.noComprobantes;i++){
      this.index.push({mes:i,tipo:'TipoComprobanteMes'+i,comprobante:'ComprobanteIngresosMes'+i});
      if (!this.opcional){
        this.frmE5.addControl('TipoComprobanteMes'+i, new FormControl(null, [Validators.required, Validators.min(1)]));
        this.frmE5.addControl('ComprobanteIngresosMes'+i, new FormControl(null, [Validators.required, requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]));
      }
      else{
        this.frmE5.addControl('TipoComprobanteMes'+i, new FormControl(null, [Validators.min(1)]));
        this.frmE5.addControl('ComprobanteIngresosMes'+i, new FormControl(null, [requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]));  
      }
    }

    if (!this.opcional){
      this.frmE5.get('TipoIdentificacion').setValidators([Validators.required]);
      this.frmE5.get('IdentificacionVigente').setValidators([Validators.required,requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]);
      this.frmE5.get('TipoComprobante').setValidators([Validators.required]);
      this.frmE5.get('ComprobanteDomicilio').setValidators([Validators.required,requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]);
      //this.frmE5.get('ConfirmacionIdentidad').setValidators([Validators.required,requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]);
    }
    this.ingresos=true;
    if ( this.GarantiaAuto && !this.opcional) {
      this.frmE5.get('GrantiaAuto').setValidators([Validators.required,requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]);
    }

    if ( this.GarantiaRPP && !this.opcional ) {
      this.frmE5.get('EvidenciaInmueble').setValidators([Validators.required,requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]);
    }

    if ( ! this.need_rfc ) {
      this.utilities.removeControlsValidity(this.frmE5, ['TipoCedulaFiscal']);
      this.frmE5.patchValue({TipoCedulaFiscal: null});
    }
    else{
      if( ! this.opcional) {
      this.frmE5.get('TipoCedulaFiscal').setValidators([Validators.required]);
      this.frmE5.get('CedulaFiscal').setValidators([Validators.required,requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]);
      }
      this.frmE5.patchValue({TipoCedulaFiscal: 13});
    }
  }

  onSubmit(event: any, modalError) {
    event.preventDefault();
    if (!this.frmE5.valid) {
      Object.keys(this.frmE5.value).forEach(element => {
        if ( this.frmE5.get(element).errors != null ) {
          this.frmE5.get(element).markAsDirty();
        }
      });
      return;
    }
    this.loading = true;
    let sendData = toFormData(this.frmE5.value);
    this.E5Service.sendDocumentacionPromotor(sendData).subscribe(
      resp => {
        let redirect='/promotor/revision_solicitud/'
        const proceso=resp['proceso'];
        if (proceso.etapa==4){
          redirect='/promotor/cotizacion/'
        }
    
        if (proceso.etapa==8){
          redirect='/promotor/solicitud_desembolso/'
        }

        this.router.navigateByUrl(redirect+this.IdSolicitud);
      },
      errors => {
        this.loading = false;

        if ( errors.status == 0 ) {
          this.errorMessages = 'Error al cargar los documentos. La solicitud es muy pesada. Favor de subir documentos de menor tamaño.';
        }
        else{
          this.errorMessages = errors.error.message;
        }
        this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
      },
    )
  }

  resetValue(id) {
    this.frmE5.controls[id].patchValue(null);
    document.querySelector("#" + id).querySelectorAll('input')[0]
    document.querySelector("#" + id).querySelectorAll('input')[0].value = null;
  }
  handleTipo(event){
    this.TipoIdentificacionVista=!(event.target.value=='2' || event.target.value=='0')?true:false;
    if (! this.TipoIdentificacionVista){
      this.utilities.removeControlsValidity(this.frmE5, ['IdentificacionVigente2']);
    }else{
      this.frmE5.get('IdentificacionVigente2').setValidators([Validators.required,requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]);
    }
  }
  back(){
    this.location.back();
  }
}


export function markAllAsDirty(form: FormGroup) {
  for (const control of Object.keys(form.controls)) {
    form.controls[control].markAsDirty();
  }
}

export function toFormData<T>(formValue: T) {
  const formData = new FormData();
  let total_mb = 0;
  for (const key of Object.keys(formValue)) {
    const value = formValue[key];
    if ( value !== null ) {
      if ( value instanceof File ) {
        total_mb = total_mb + (value.size/1000000);
      }
      formData.append(key, value);
    }
  }

  return formData;
}
