import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from "@angular/core";
import { HttpClient, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { pipe } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { requiredFileSize } from '../../../directives/upload-file-size-validator';
import { ActivatedRoute, Router } from '@angular/router';
import { E4ConfirmarIdentidad } from 'src/app/services/E4ConfirmarIdentidad.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { requiredMultipleFileType } from 'src/app/directives/upload-file-multiple-validator';
import { UtilitiesService } from 'src/app/services/utilities_services.service';


export function uploadProgress<T>(cb: (progress: number) => void) {
  return tap((event: HttpEvent<T>) => {
    if (event.type === HttpEventType.UploadProgress) {
      cb(Math.round((100 * event.loaded) / event.total));
    }
  });
}

export function toResponseBody<T>() {
  return pipe(
    filter((event: HttpEvent<T>) => event.type === HttpEventType.Response),
    map((res: HttpResponse<T>) => res.body)
  );
}

@Component({
  selector: 'app-e4-confirmacion-identidad',
  templateUrl: './e4-confirmacion-identidad.component.html',
  styleUrls: ['./e4-confirmacion-identidad.component.scss']
})
export class E4ConfirmacionIdentidadComponent implements OnInit,AfterViewInit {
  public status: number;
  public IdSolicitud: any;
  public loading: boolean;
  public errorMessages: any;
  public previous_data:any;
  frmE4 = new FormGroup({
    ConfirmacionIdentidad: new FormControl(null, [Validators.required, requiredMultipleFileType(['jpg','png','pdf']), requiredFileSize()]),
    IdSolicitud: new FormControl(null)
  });



  constructor(
    private route: ActivatedRoute,
    private E4Service: E4ConfirmarIdentidad, 
    private router: Router,
    private modalError: NgbModal,
    private utilities: UtilitiesService
  ) {
    this.status = 3;
    this.loading = false;
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.IdSolicitud = this.route.snapshot.params.id;
    if (this.route.snapshot.data.IdSolicitud) {
      this.previous_data = this.route.snapshot.data.IdSolicitud.data.E4;
    }
    this.frmE4.patchValue({
      IdSolicitud:this.IdSolicitud
    })
  }

  resetValue(id) {
    this.frmE4.controls[id].patchValue(null);
    document.querySelector("#" + id).querySelectorAll('input')[0]
    document.querySelector("#" + id).querySelectorAll('input')[0].value = null;
  }

  onSubmit(event: any, modalError) {
    event.preventDefault();
    this.loading = true;
    let sendData = toFormData(this.frmE4.value);
    this.E4Service.sendIdentificacion(sendData).subscribe(
      resp => {
        let redirect = this.utilities.handleRedirect(resp['proceso']);
        this.router.navigateByUrl(redirect+"/"+this.IdSolicitud);
      },
      errors => {
        this.loading = false;
        
        if ( errors.status == 0 ) {
          this.errorMessages = 'Error al cargar los documentos. La solicitud es muy pesada. Favor de subir documentos de menor tamaño.';
        }
        else{
          this.errorMessages = errors.error.message;
        }

        this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
      },
    )
  }

}

export function markAllAsDirty(form: FormGroup) {
  for (const control of Object.keys(form.controls)) {
    form.controls[control].markAsDirty();
  }
}

export function toFormData<T>(formValue: T) {
  const formData = new FormData();
  let total_mb = 0;
  for (const key of Object.keys(formValue)) {
    const value = formValue[key];
    if ( value !== null ) {
      if ( value instanceof File ) {
        total_mb = total_mb + (value.size/1000000);
      }
      formData.append(key, value);
    }
  }

  return formData;
}