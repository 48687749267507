import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,  } from '@angular/common/http';
import { UsuarioModel } from '../models/usuario.model';
import { environment } from '../../environments/environment';
import { map } from "rxjs/operators";
import { ErrorsService } from './errors.service';
import { Router } from '@angular/router';
import { UtilitiesService } from './utilities_services.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userToken: string;
  API_URL: string;

  constructor(private http: HttpClient, private errorService: ErrorsService, private router: Router, private utilities: UtilitiesService) {
    this.API_URL = environment.API_URL;
    this.getToken();
  }

  getQuery(query: string) {
    const url = `${this.API_URL}${query}`;


    const headers = new HttpHeaders({
      //   'Authorization': 'Bearer ' + this.userToken
    });
    return this.http.post(url, { token: this.userToken });
  }

  getUser() {
    return this.getQuery('auth/me');
  }

  login(usuario: UsuarioModel) {
    return this.http.post(this.API_URL + 'auth/login', usuario)
      .pipe(
        map(resp => {
          if( resp.hasOwnProperty('person') ){
            if ( resp['person'] === null ) {
              this.saveToken(resp['access_token'], resp['expires_in'], JSON.stringify(resp['user']), '');
            }
            else{
              this.saveToken(resp['access_token'], resp['expires_in'], JSON.stringify(resp['user']), resp['person']['IdPersona'],JSON.stringify (resp['person']) );
            }
          }
          else{
            this.saveToken(resp['access_token'], resp['expires_in'], JSON.stringify(resp['user']), '');
          }
          
          if(resp.hasOwnProperty('numSolicitudes')){
            if (resp['numSolicitudes'] > 0 || resp['numSolicitudes'] !== null )
            localStorage.setItem('solicitud',resp['numSolicitudes']);
            this.router.navigateByUrl('client/dashboard')
          }
          return resp;
        })
      );
  }

  saveToken(idToken: string, expiresIn: number, user: string, id_person: string, person_data? :string)  {
    this.userToken = idToken;
    localStorage.clear();
    localStorage.setItem('token', idToken);
    localStorage.setItem('user', user);
    localStorage.setItem('IdPerson', id_person);
    var tempUser = JSON.parse(user);
    localStorage.setItem('userRole', tempUser.id_role);
    if(person_data){
      localStorage.setItem('person',person_data);
    }

    const today = new Date();
    const expiresDateToken = new Date(today.getTime() + (expiresIn * 1000))

    localStorage.setItem('expires_in', expiresDateToken.getTime().toString());

  }

  getToken() {
    if (localStorage.getItem('token')) {
      this.userToken = localStorage.getItem('token');
    } else {
      this.userToken = '';
    }
  }

  isLogged(): boolean {
    if (this.userToken.length < 2) {
      return false;
    }

    const expiresIn = Number(localStorage.getItem('expires_in'));
    const expireDate = new Date();
    expireDate.setTime(expiresIn);

    if (expireDate > new Date()) {
      const headerStruct = {
        'Authorization': 'Bearer ' + localStorage.getItem("token")
      }
  
      const requestOptions = {
        headers: new HttpHeaders(headerStruct),
      };

      let logged = true;
      this.http.get(this.API_URL + 'auth/me', requestOptions). subscribe(
        resp => {
          if ( resp['error'] ) {
            return false;
          }
        }
      );

      return logged;
    } else {
      return false;
    }
  }

  logout() {
    const headerStruct = {
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    }

    const requestOptions = {
      headers: new HttpHeaders(headerStruct),
    };

    return this.http.get(this.API_URL + 'auth/logout', requestOptions);
  }

  refreshToekn(){
    const headerStruct = {
      'Authorization': 'Bearer ' + localStorage.getItem("token")
    }

    const requestOptions = {
      headers: new HttpHeaders(headerStruct),
    };

    return this.http.get(this.API_URL + 'auth/refresh', requestOptions);
  }

  isClient(){
    let is_cliente = this.utilities.isClient();
    return is_cliente;
    // var user_role = localStorage.getItem("userRole");
    // if  (user_role !== undefined){
    //   if (parseInt(user_role) === 2){
    //     return true;
    //   }else{
    //     if( parseInt(user_role) > 3 && parseInt(user_role) < 12 ){
    //       return true;
    //     }
    //     return false;
    //   }
    // }
  }

  isPromotor(){
    let is_promotor = this.utilities.isPromotor();
    return is_promotor;
    var user_role = localStorage.getItem("userRole");
    if  (user_role !== undefined){
      if (parseInt(user_role) === 3){
        return true;
      }else{
        return false;
      }
    }
  }
}
