import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { Collection } from '../../models/collection.model';
import { HttpClient } from '@angular/common/http';
import { catchError, mergeMap } from 'rxjs/operators';
import { UtilitiesService } from '../../services/utilities_services.service';

@Injectable()
export class InfoAutoResolver implements Resolve<number>{
    public IdSolicitud: number;
    public IdPersona: number;

    constructor(
        private http: HttpClient,
        private router: Router,
        private utilities: UtilitiesService
      ) {
      }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, ): Observable<any> | Promise<any> | any {
        this.IdSolicitud = parseInt(route.paramMap.get('id'));
        this.IdPersona = parseInt(route.paramMap.get('idPersona'));
        return this.http.get<Collection>(environment.API_URL + 'promotor/garantia/auto/' + this.IdSolicitud+'/'+this.IdPersona).pipe(
            catchError(error => {
                var msgError = error.error.error;
                if (msgError) {
                  if (msgError.indexOf("token") > -1) {
                    localStorage.clear();
                    this.router.navigateByUrl("/login");
                  }
                }
                return [];
              }
            ),
            mergeMap(response => {
                if (response) {
                    if (response['proceso'] === null) {
                        this.router.navigate(['promotor/dashboard'])
                    }

                    if ( response['data'] === null ) {
                        let proceso = response['proceso'];

                        if ( parseInt(proceso.etapa) == 1 && parseInt(proceso.paso) == 1 && parseInt(proceso.flag) == 1 ) {
                            return of(response);
                        }
                        else{
                            this.router.navigate(['promotor/dashboard'])
                        }
                    }
                    let user_temp = JSON.parse(localStorage['user']);
                    if ( !  this.utilities.isDirective() ) {
                        if ( response['data'].promotor_id != parseInt(user_temp.id)  ) {
                            this.router.navigateByUrl("promotor/dashboard");
                        }
                    }

                    delete response['data'].dueno;
                    delete response['data'].avales_id;
                    delete response['data'].promotor_id;

                    return of(response);
                }
                else{
                    return [];
                }
            })
        );
        // return this.IdSolicitud;
    };
}