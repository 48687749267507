
import { Validator, AbstractControl, NG_VALIDATORS } from "@angular/forms";
import { Directive, Input } from "@angular/core";
@Directive({
    selector: "[appEqualValidator]",
    providers: [{
        provide: NG_VALIDATORS,
        useExisting: TelephoneNumberDirective,
        multi: true
    }]
})
export class TelephoneNumberDirective implements Validator {
    validate(c: AbstractControl): { [key: string]: any; } {
        throw new Error("Method not implemented.");
    }
    registerOnValidatorChange?(fn: () => void): void {
        throw new Error("Method not implemented.");
    }
}