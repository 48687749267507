export class C13InformacionEconomica{
    public IngresosNetos: number;
    public OtrosIngresos: string;
    public TarjetasBancarias: number;
    public TarjetasComerciales: number;
    public CreditoHipotecario: number;
    public CreditoPersonal: number;
    public CreditoAutomotriz: number;
    public IdSolicitudParticipante: number;
    public IdPersona: number;
    
    constructor(){}
}