import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { UtilitiesAvalService } from '../services/aval/UtilitiesAvalService.service';
import { map, catchError, } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class AvalGuard implements CanActivate {
    
    public IdSolicitud: number;
    public accessRoute: any;
    constructor(
        private auth: AuthService,
        private router: Router,
        private avalService: UtilitiesAvalService,
        private http: HttpClient,
    ) {

    }

    canActivate(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean> {
        this.IdSolicitud= route.params.id;
        this.accessRoute = state.url;
        //return this.http.get('https://api.emishaorigin.com/api/v1/getTipoRelacion/'+this.IdSolicitud).pipe( //url servidor anterior
        //return this.http.get('http://127.0.0.1:8000/api/v1/getTipoRelacion/'+this.IdSolicitud).pipe(
        return this.http.get('https://apifinanciamiento.edupass.mx/api/v1/getTipoRelacion/'+this.IdSolicitud).pipe(
            map(res => {
                var canAccess = this.avalService.checkAccessRoleAval(res,this.accessRoute);
                if  (canAccess){
                    return canAccess;
                }else{
                    alert("Sin permisos");
                    this.router.navigateByUrl('client/dashboard');
                }
            }),
            catchError((err) => {
                return of(false);
            })
        );

    }
}