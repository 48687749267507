
import { Component, QueryList, ViewChildren, OnInit, Input, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { TablePromotorModel } from 'src/app/models/TablePromotor.model';
import { NgbdSortableHeader, SortEvent } from './sortable.directive';
import { TableService } from './table.service';
import { CollectionsService } from 'src/app/services/colecctions.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-promotor-table',
  templateUrl: './table_promotor.component.html'
})


export class TablePromotorComponent implements OnInit {
  solicitudes$: Observable<TablePromotorModel[]>;
  total$: Observable<number>;
  @Input('solicitudesInput') solicitudesInput: Observable<TablePromotorModel[]>;
  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;


  constructor(public service: TableService, public collections: CollectionsService, public utilities: UtilitiesService, private router: Router) {
    this.solicitudes$ = service.solicitudes$;
    this.total$ = service.total$;
    this.service.pageSize = 10;
    this.service.page = 1;
  }

  ngOnInit() {
  }

  checkStep(IdSolicitud){
    this.utilities.handleStatusSolicitudPromotorDashboard(IdSolicitud).subscribe(
      response => {
        var estatusSolicitud: any;
        estatusSolicitud = response;
        let redirect = this.utilities.handleStatePromotorRedirect(estatusSolicitud.proceso);
        this.router.navigateByUrl(redirect+"/"+IdSolicitud);
      }
    )
    
  }

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
      // only run when property "data" changed
      if (propName === 'solicitudesInput') {
        //  this line will update posts values
        changes.solicitudesInput.currentValue.subscribe(
          data =>{
            this.service.setListData(data);
            this.service.pageSize = 10;
            this.service.page = 1;
          }
        ) 
      }
    }
  }


  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });

    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }


  setNewSolicitudes() {
    
    this.collections.obtenerSolicitudesPromotor().subscribe(
      data => {
        this.service.setListData(data)
      }
    )

  }
}