import { Resolve } from '@angular/router';
import { Observable, forkJoin} from 'rxjs';
import { CollectionsService } from "../../services/colecctions.service";
import { Collection } from '../../models/collection.model';
import { Injectable } from '@angular/core';

@Injectable()
export class B1MotivoRechazoResolver implements Resolve<Collection>{
    constructor (private CollectionsService: CollectionsService){}
    resolve():Observable<any>{
        let test = forkJoin(
            this.CollectionsService.getMotivosRechazo(),
        );

        return test;
    }
}