import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-a01-correo-usado',
  templateUrl: './a01-correo-usado.component.html'
})
export class A01CorreoUsadoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
