import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Data, Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { K1DesembolsoService } from 'src/app/services/K1DesembolsoService.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { send } from 'q';
import { Collection } from 'src/app/models/collection';

@Component({
    selector: 'app-k1-instruccion-deposito',
    templateUrl: './k1-instruccion-deposito.component.html',
    styleUrls: ['./k1-instruccion-deposito.component.scss']
})
export class K1InstruccionDepositoComponent implements OnInit {
    public status: number;
    public IdSolicitud: number;
    public extranjero: boolean;
    public dos_partes: boolean;
    public universidad: string;
    public programa: string;
    public previous_data: any;
    public frmK1: FormGroup;
    public MontoSolicitado: string;
    public dataFormat = {
        Beneficiario1: null,
        Banco1: null,
        NumeroCuenta1: null,
        Clabe_Swift: null,
        Referencia1: null,
        DireccionBanco: null,
        Importe1: null,
        Beneficiario2: null,
        Banco2: null,
        NumeroCuenta2: null,
        Clabe2: null,
        Referencia2: null,
        Importe2: null,
      };
      public loading: boolean;
      public errorMessages: string;
      public collectionsBancos: Collection;
      public hasSchool: boolean;

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private K1Service: K1DesembolsoService,
        private utilities: UtilitiesService,
        private router: Router,
        private modalError: NgbModal,
    ) { 
        this.status = 5;
    }

    ngOnInit() { 
        this.IdSolicitud = this.route.snapshot.params.id;
        this.route.data.subscribe((data: Data) => {
            this.collectionsBancos = data['collections'][0].data;
            this.extranjero = data['IdSolicitud'].data.desembolso.extranjero;
            this.dos_partes = data['IdSolicitud'].data.desembolso.dos_partes;
            this.universidad = data['IdSolicitud'].data.desembolso.escuela;
            this.programa = data['IdSolicitud'].data.desembolso.programa;
            this.MontoSolicitado = data['IdSolicitud'].data.desembolso.MontoSolicitado;
            this.previous_data = data['IdSolicitud'].data.K1 ? data['IdSolicitud'].data.K1 : false;
        });
        this.frmK1 = this.createK1Form();

        if ( this.universidad != null ) {
          this.hasSchool = true;
        }
        else{
          this.hasSchool = false;
        }

        if ( this.previous_data ) {
            this.frmK1.patchValue(this.previous_data);
        }
        else{
            if ( this.extranjero ) {
                if ( this.dos_partes ) {
                    this.frmK1.patchValue({
                        Beneficiario1: "",
                        Banco1: "",
                        NumeroCuenta1: "",
                        Clabe_Swift: "",
                        Referencia1: "",
                        DireccionBanco: "",
                        Importe1: '',
                        Beneficiario2: "",
                        Banco2: "",
                        NumeroCuenta2: "",
                        Clabe2: "",
                        Referencia2: "",
                        Telefono: "",
                        Importe2: '',
                      });
                    
                      this.frmK1.controls['Banco2'].setValidators([Validators.required, Validators.min(1)]);
                }
                else{
                    this.frmK1.patchValue({
                        Beneficiario1: "",
                        Banco1: "",
                        NumeroCuenta1: "",
                        Clabe_Swift: "",
                        Referencia1: "",
                        DireccionBanco: "",
                      });
                }
            }
            else{
                if ( this.dos_partes ) {
                    this.frmK1.patchValue({
                        Beneficiario1: "",
                        Banco1: "",
                        NumeroCuenta1: "",
                        Clabe_Swift: "",
                        Referencia1: "",
                        Importe1: '',
                        Beneficiario2: "",
                        Banco2: "",
                        NumeroCuenta2: "",
                        Clabe2: "",
                        Referencia2: "",
                        Telefono: "",
                        Importe2: '',
                      });
                      this.frmK1.controls['Banco1'].setValidators([Validators.required, Validators.min(1)]);
                      this.frmK1.controls['Banco2'].setValidators([Validators.required, Validators.min(1)]);
                }
                else{
                    this.frmK1.patchValue({
                        Beneficiario1: "",
                        Banco1: "",
                        NumeroCuenta1: "",
                        Clabe_Swift: "",
                        Referencia1: "",
                      });
                    
                      this.frmK1.controls['Banco1'].setValidators([Validators.required, Validators.min(1)]);
                }
            }
        }

        this.loading = false;
    }

    onSubmit(modalError){
        event.preventDefault();
        if (!this.frmK1.valid) {
          Object.keys(this.frmK1.value).forEach(element => {
            if ( this.frmK1.get(element).errors != null ) {
              this.frmK1.get(element).markAsDirty();
            }
          });
          return;
        }

        this.loading = true;
        this.frmK1.patchValue({IdSolicitud: this.IdSolicitud});
        let sendData = this.frmK1.value;

        this.K1Service.sendDetallesDesembolso( sendData ).subscribe(
            resp => {
                let redirect = this.utilities.handleRedirect(resp['proceso']);
                this.router.navigateByUrl(redirect+"/"+this.IdSolicitud);
              },
              errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
                this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
              },
        );

    }

    createK1Form() {
        return this.fb.group({
            IdSolicitud: [
                null
            ],
          Beneficiario1: [
            Validators.required,
          ],
          Banco1: [
            Validators.required,
          ],
          NumeroCuenta1: [
            Validators.required,
          ],
          Clabe_Swift: [
            Validators.required,
          ],
          Referencia1: [
            Validators.required,
          ],
          DireccionBanco: [
            null,
          ],
          Importe1: [
            null,
          ],
          Beneficiario2: [
            null,
          ],
          Banco2: [
            null,
          ],
          NumeroCuenta2: [
            null,
          ],
          Clabe2: [
            null
          ],
          Referencia2: [
            null,
          ],
          Importe2: [
            null,
          ]
        })
      }
}