import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ErrorsService } from './errors.service';


@Injectable({
    providedIn: 'root',
})
export class MessagesService {

    API_URL: string;

    constructor(private http: HttpClient) {
        this.API_URL =  environment.API_URL;
    }

    getQuery(type:string , query: string, parameters: string) {
        const url = `${this.API_URL}${query}`;

        switch (type) {
            case 'post':
                return this.http.post(url, parameters);
            case 'get':
                return this.http.get(url);
            case 'put':
                return this.http.put(url, parameters);
            case 'delete':
                return this.http.delete(url);
            case 'patch':
                return this.http.patch(url, parameters);
            default:
                return new Observable();
                
        }
    }


    setEtapaC1(data){
        return this.getQuery('post','consulta_buro/setEtapaC1',data);
    }

    setEtapaE1(data){
        return this.getQuery('post','preaprobacion/setEtapaE1',data);
    }

    setEtapaK1(data){
        return this.getQuery('post','preaprobacion/setEtapaK1',data);
    }
}
