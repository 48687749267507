import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-navegacion-revision',
    templateUrl: './navegacion-revision.component.html',
    
})
export class NavegacionRevisionComponent implements OnInit {
    @Input('step') step: number;
    @Input('IdSolicitud') IdSolicitud: number;
    public nextLink: any;
    public previousLink: any;
    constructor() { }

    ngOnInit(): void {
        this.handleLinks(this.step);
    }

    handleLinks(step){
        switch(step){
            case 1:
                this.previousLink ="#";
                this.nextLink = "/promotor/revision_acreditacion/" + this.IdSolicitud ;
            break;
            case 2:
                this.previousLink ="/promotor/revision_destino/" + this.IdSolicitud;
                this.nextLink = "/promotor/revision_acreditacion_empleo/" + this.IdSolicitud ;
            break;
            case 3:
                this.previousLink ="/promotor/revision_acreditacion/" + this.IdSolicitud;
                this.nextLink = "/promotor/revision_acreditacion_preanalisis/" + this.IdSolicitud ;
            break;
            case 4:
                this.previousLink ="/promotor/revision_acreditacion_empleo/" + this.IdSolicitud;
                this.nextLink = "/promotor/revision_acreditacion_referencias" + this.IdSolicitud ;
            break;
            case 5:
                this.previousLink ="/promotor/revision_acreditacion_preanalisis/" + this.IdSolicitud;
                this.nextLink = "/promotor/revision_acreditacion_documentacion/" + this.IdSolicitud ;
            break;
            case 6:
                this.previousLink ="/promotor/revision_acreditacion_referencias/" + this.IdSolicitud;
                this.nextLink = "#";
            break;
        }
    }
}
