import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { J1Desembolso } from 'src/app/services/promotor/J1Desembolso.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Data } from '@angular/router';
import { requiredMultipleFileType } from '../../../directives/upload-file-multiple-validator';
import { requiredFileSize } from '../../../directives/upload-file-size-validator';
import { toFormData } from '../../client/e4-confirmacion-identidad/e4-confirmacion-identidad.component';

@Component({
    selector: 'app-j1-desembolso-edit',
    templateUrl: './j1-desembolso-edit.component.html'
})

export class J1DesembolsoEditComponent implements OnInit {
    @Input('dataParent') dataParent: any;
    @Input('montoDesembolsoParent') montoDesembolsoParent: number;
    @Output() total_hijo = new EventEmitter<any>();
    @Output() eventoCancelado = new EventEmitter<any>();
    @Output() datosGuardados = new EventEmitter<any>();
    
    public status: number;
    public editMode: boolean;
    public loading: boolean;
    public frmJ1: FormGroup;
    public errorMessages: string;
    public IdSolicitud: number;
    public secondMember: boolean;
    public nacional:boolean;
    public secondNacional:boolean;
    public edoCuenta:boolean;
    public bancos: any;
    public sum: number;
    public dataSecond = ['Beneficiario2', 'Banco2', 'Clabe2', 'Referencia2', 'Importe2'];

    public data: any;

    constructor(
        private fb: FormBuilder,
        private utilities: UtilitiesService,
        private J1Service: J1Desembolso,
        private modalErrors: NgbModal,
        private route: ActivatedRoute,
    ) {
        this.status = 8;
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: Data) => {
            this.bancos = data['collections'][2].data;
        });

        this.IdSolicitud = this.route.snapshot.params.id;
        this.data = {...this.dataParent}
        this.frmJ1 = this.createFormJ1();
        if (this.data[1] !== undefined === true){
            if ( this.data[1].banco_nacional ) {
                this.secondNacional=true;
            }
            else{
                this.secondNacional=false;
            }
            this.secondMember = true;
            this.dataSecond.forEach(element => {
                if ( element == 'Banco2' ) {
                    this.frmJ1.controls[element].setValidators([Validators.required, Validators.min(1)]);
                }
                else if ( element == 'Importe2' ) {
                    this.frmJ1.controls[element].setValidators([Validators.required, Validators.min(0)]);
                }
                else{
                    this.frmJ1.controls[element].setValidators([Validators.required]);
                }
            });
        }else{
            this.secondMember = false;
        }

        if ( this.data[0].banco_nacional ) {
            this.nacional=true;
            this.frmJ1.controls['Banco1'].setValidators([Validators.required, Validators.min(1)]);
        }
        else{
            this.nacional=false;
            this.frmJ1.controls['DireccionBanco'].setValidators([Validators.required]);
        }

        
        this.frmJ1.patchValue({
            IdSolicitud: this.IdSolicitud,
            Beneficiario1: this.data[0].BeneficiarioCuenta,
            Banco1: this.data[0].Banco,
            NumeroCuenta1: this.data[0].Cuenta,
            Clabe_Swift: this.data[0].Clabe_Swift,
            Referencia1: this.data[0].Referencia,
            DireccionBanco: this.data[0].DireccionBanco,
            Importe1: this.data[0].ImportePorDesembolsar,
            Beneficiario2: this.data[1] !== undefined ? this.data[1].BeneficiarioCuenta : null,
            Banco2: this.data[1] !== undefined ? this.data[1].Banco : null,
            NumeroCuenta2: this.data[1] !== undefined ? this.data[1].Cuenta : null,
            Clabe2: this.data[1] !== undefined ? this.data[1].Clabe_Swift : null,
            Referencia2: this.data[1] !== undefined ? this.data[1].Referencia : null,
            Importe2: this.data[1] !== undefined ? this.data[1].ImportePorDesembolsar : null,
            DesembolsoExtranjero: ! this.data[0].banco_nacional ? 'true': 'false',
            DesembolsoDosPartes: this.data[1] !== undefined ? 'true' : 'false'
        })

        if ( this.secondMember ) {
            this.sum = Math.round(100*(parseFloat(this.frmJ1.controls['Importe1'].value) + parseFloat(this.frmJ1.controls['Importe2'].value)))/100;
        }
        else{
            this.sum = parseFloat(this.frmJ1.controls['Importe1'].value);
        }
        this.edoCuenta=false;
    } 

    createFormJ1(){
        return this.fb.group({
            EdoCuenta:[
                null,
            ],
            DesembolsoExtranjero:[
                null,
            ],
            DesembolsoDosPartes:[
                null
            ],
            IdSolicitud:[
                null
            ],
            Beneficiario1:[
                null,
                Validators.required
            ],
            Banco1:[
                null,
                Validators.required
            ],
            NumeroCuenta1:[
                null,
                Validators.required
            ],
            Clabe_Swift:[
                null,
                //Validators.required
            ],
            Referencia1:[
                null,
                Validators.required
            ],
            DireccionBanco:[
                null
            ],
            Importe1:[
                null,
                [Validators.required, Validators.min(0)]
            ],
            Beneficiario2:[
                null
            ],
            Banco2:[
                null
            ],
            NumeroCuenta2:[
                null
            ],
            Clabe2:[
                null
            ],
            Referencia2:[
                null
            ],
            Importe2:[
                null
            ],
        });
    }

    onSubmit(modalTarget){
        if ( this.sum != this.montoDesembolsoParent ) {
            alert('La cantidad a pagar debe de ser igual al importe a desembolsar.');
            return;
        }

        if (!this.frmJ1.valid) {
            Object.keys(this.frmJ1.value).forEach(element => {
              if ( this.frmJ1.get(element).errors != null ) {
                this.frmJ1.get(element).markAsDirty();
              }
            });
            return;
        }
        let sendData=toFormData(this.frmJ1.value)
        this.loading = true;
        this.J1Service.sendData(sendData).subscribe(
            response =>{
                this.loading = false;
                this.datosGuardados.emit(response);
            }, 
            errors =>{
                this.loading = false;

                this.errorMessages = errors.error.message;
                this.modalErrors.open(modalTarget, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        );
    }

    validateTotal(){
        if ( this.secondMember ) {
            this.sum = parseFloat(this.frmJ1.controls['Importe1'].value) + parseFloat(this.frmJ1.controls['Importe2'].value);
        }
        else{
            this.sum = parseFloat(this.frmJ1.controls['Importe1'].value);
        }
    }

    handleExtranjero(event){
        const extranjero=event.target.value;
        if (extranjero==='true'){
            this.nacional=false;
        }
        else{
            this.nacional=true;
        }
    }

    handlePartes(event){
        const dosPartes=event.target.value;
        if (dosPartes==='true'){
            this.secondMember=true;
            this.secondNacional=true;
        }
        else{
            this.secondMember=false;
            this.secondNacional=false;
            this.frmJ1.patchValue({
                Importe2: null,
            })
        }
    }

    resetValue(id) {
        this.frmJ1.controls[id].patchValue(null);
        document.querySelector("#" + id).querySelectorAll('input')[0]
        document.querySelector("#" + id).querySelectorAll('input')[0].value = null;
    }

    changeEdoCuenta(event){
        this.edoCuenta = event.target.checked?true:false;
        if(this.edoCuenta){
            this.frmJ1.controls['EdoCuenta'].setValidators([Validators.required, requiredMultipleFileType(['jpg','pdf', 'png']), requiredFileSize()]);
        }
        this.frmJ1.controls['EdoCuenta'].clearValidators();
    }

    formatResponseParent(obj){

        return;
    }

    parentEmitCancelEditionMode(): void {
        this.eventoCancelado.emit();
    }


}