import { Component, OnInit } from "@angular/core";


@Component({
    selector: 'app-admin-2-catalogos',
    templateUrl: './admin-2-catalogos.component.html'
})

export class Admin2CatalogosComponent implements OnInit {
    
    public items=[
        {
            id:1,
            fullName:"test1",
            shortName:"kTest1",
            date:"04/10/2019"
        },
        {
            id:2,
            fullName:"test2",
            shortName:"kTest2",
            date:"04/10/2019"
        },
        {
            id:3,
            fullName:"test3",
            shortName:"kTest3",
            date:"04/10/2019"
        },
        {
            id:4,
            fullName:"test4",
            shortName:"kTest4",
            date:"04/10/2019"
        },
        {
            id:5,
            fullName:"test5",
            shortName:"kTest5",
            date:"04/10/2019"
        },

    ];


    constructor() {
        
    }

    ngOnInit(): void {
    }
}