import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})

export class ClientGuard implements CanActivate {

  constructor( private auth: AuthService) {

  }

  canActivate(): boolean {
    if (this.auth.isClient()) {
      return true;
    } else {
      return false;
    }
  }

}
