import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, AfterViewInit } from "@angular/core";
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { Collection } from 'src/app/models/collection';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { PromotorD2RevisionDestino } from 'src/app/services/promotor/D2DestinoCredito.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CollectionsService } from 'src/app/services/colecctions.service';
import { Alert } from 'selenium-webdriver';
import { CustomValidators } from 'src/app/validators/validators';
import { PromotorD3RevisionAcreditacion } from 'src/app/services/promotor/D3RevisionAcreditacionService';

@Component({
    selector: 'app-d3-revision-acreditacion-edit',
    templateUrl: './d3-revision-acreditacion-edit.component.html',
})

export class D3RevisionAcreditacionEditComponent implements OnInit, OnDestroy {

    @Input('dataParent') dataParent: any;
    @Output() eventoCancelado = new EventEmitter<any>();
    @Output() datosGuardados = new EventEmitter<any>();
    public IdSolicitud: number;
    public data: any;
    public collectionsNacionalidad: Collection;
    public collectionsPaisesNacimiento: Collection;
    public colectionsEstadosNacimiento: Collection;
    public collectionsGenero: Collection;
    public collectionsEstadosNacimiento: any;
    public frmD3: FormGroup;
    public valid_country_birth: boolean;
    public valid_country_address: boolean;
    public collectionsRegimenFiscal: Collection;
    /* Colecciones para direccion */
    public collectionsEstadosDireccion: Collection;
    public collectionsCiudadesDireccion: Collection;
    public collectionsMunicipiosDireccion: Collection;
    public collectionsColoniasDireccion: Collection;
    public address_id = ['IdCiudad', 'IdEstado', 'IdMunicipio', 'IdColonia'];
    public address = ['Ciudad', 'Estado', 'Municipio', 'Colonia'];

    public loading: boolean;
    public errorMessages: string;


    constructor(private fb: FormBuilder,
        private collectionsService: CollectionsService,
        private utilitiesService: UtilitiesService,
        public route: ActivatedRoute, 
        private D3Service : PromotorD3RevisionAcreditacion,
        private modalErrors: NgbModal,
        private router: Router,
    ) {
        this.IdSolicitud = this.route.snapshot.params.id;
        this.loading = false;
    }

    ngOnInit() {
        this.frmD3 = this.createFormD3();
        this.data = { ...this.dataParent.previousData }
        this.collectionsNacionalidad = this.dataParent.collections.NacionalidadNacimiento;
        this.collectionsPaisesNacimiento = this.dataParent.collections.PaisesNacimiento;
        this.collectionsGenero = this.dataParent.collections.Generos;
        this.collectionsRegimenFiscal = this.dataParent.collections.RegimenFiscal;
        this.frmD3.patchValue({IdPaisNacionalidad: this.data.IdPaisNacionalidad});

        if (this.data.PaisNacimiento !== null) {
            this.collectionsService.getStateByCountryId(this.data.IdPaisNacimiento).subscribe(
                response => {
                    this.collectionsEstadosNacimiento = response.data;
                }
            );
            this.valid_country_birth = true;
        } else {
            this.valid_country_birth = false;
        }

        if (this.data.ÏdPais !== null && parseInt(this.data.IdPais) === 1) {
            this.collectionsService.getStateByCountryId(this.data.IdPais).subscribe(
                response => {
                    this.collectionsEstadosDireccion = response.data;
                    this.valid_country_address = true;
                },
                errors => {
                    this.valid_country_address = false;
                }
            );
            if (this.data.IdEstado !== null) {
                this.collectionsService.getCitiesById(this.data.IdPais, this.data.IdEstado).subscribe(
                    response => {
                        this.collectionsCiudadesDireccion = response.data;
                        this.valid_country_address = true;
                    },
                    errors => {
                        this.valid_country_address = false;
                    }
                );
                this.collectionsService.getMunicipalitiesById(this.data.IdPais, this.data.IdEstado).subscribe(
                    response => {
                        this.collectionsMunicipiosDireccion = response.data;
                        this.valid_country_address = true;
                    },
                    errors => {
                        this.valid_country_address = false;
                    }
                );
                if (this.data.IdMunicipio !== null) {
                    this.collectionsService.getCollectionsColoniasByPais(this.data.IdPais, this.data.IdEstado, this.data.IdMunicipio).subscribe(
                        data => {
                            this.collectionsColoniasDireccion = data.data;
                        }, error => {
                            // this.valid_country_address = false;
                        }
                    )
                } else {
                    this.valid_country_address = false;
                }
            } else {
                this.valid_country_address = false;
            }
        } else {
            this.valid_country_address = false;
        }

        this.frmD3.patchValue(this.data);

        if (parseInt(this.data.IdPais) === 1) {
            this.utilitiesService.removeControlsValidity(this.frmD3, this.address);
        } else {
            this.utilitiesService.removeControlsValidity(this.frmD3, this.address_id);
        }
        if (this.data.FechaNacimiento) {
            var formatFechaNacimiento = this.utilitiesService.formatDateObject(this.data.FechaNacimiento);
            this.data.FechaNacimiento = formatFechaNacimiento;
            this.frmD3.patchValue({FechaNacimiento: this.data.FechaNacimiento});
        }
        if ( this.data.NoSerieFiel != null && this.data.NoSerieFiel != '' ) {
            this.frmD3.get('NoSerieFiel').setValidators([Validators.required,  Validators.compose([
                null,
                CustomValidators.patternValidator(
                    /^[0-9]{20}$/,
                    {
                        validFiel: true
                    }
                )
            ])]);
        }
        else{
            this.utilitiesService.removeControlsValidity(this.frmD3, ['NoSerieFiel']);
        }

    }

    ngOnDestroy() {

    }

    onlyDigits(event){
        if (event.which != 8 && event.which != 0 && event.which < 48 || event.which > 57)
        {
            event.preventDefault();
        }
    }

    getFormValidationErrors() {
        Object.keys(this.frmD3.controls).forEach(key => {

            const controlErrors: ValidationErrors = this.frmD3.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                });
            }
        });
    }

    handleCountryBirth(evt){
        var index = evt.target.selectedIndex;
        var textCountry = evt.target[index].text;
        var indexCountry = evt.target.value;
        if (indexCountry === "1" && textCountry === "MEXICO") {
            this.collectionsService.getStateByCountryId(indexCountry).subscribe(
                data => {
                    this.valid_country_birth = true;
                    this.collectionsEstadosNacimiento = data.data;
                    this.utilitiesService.removeControlsValidity(this.frmD3, ['EstadoNacimiento']);
                    this.frmD3.get('IdEstadoNacimiento').setValidators([Validators.required, Validators.min(1)]);
                }, error => {
                    this.valid_country_birth = false;
                    this.utilitiesService.removeControlsValidity(this.frmD3, ['IdEstadoNacimiento']);
                    this.frmD3.get('EstadoNacimiento').setValidators(Validators.required);
                }
            )
        }
        else{
            this.valid_country_birth = false;
            this.utilitiesService.removeControlsValidity(this.frmD3, ['IdEstadoNacimiento']);
            this.frmD3.get('EstadoNacimiento').setValidators(Validators.required);
        }
    }

    changeFiel(evt){
        var target = evt.target;

        if (target.checked) {
            this.utilitiesService.removeControlsValidity(this.frmD3, ['NoSerieFiel']);
            this.frmD3.patchValue({NoSerieFiel: null});
        } else {
            this.frmD3.controls["NoSerieFiel"].setValidators([
                Validators.compose([
                    Validators.required,
                    CustomValidators.patternValidator(
                        /^[0-9]{20}$/,
                        {
                            validFiel: true
                        }
                    )
                ])
            ]);
            this.frmD3.get("NoSerieFiel").updateValueAndValidity();
        }
    }

    handleCountry(evt) {
        var index = evt.target.selectedIndex;
        var textCountry = evt.target[index].text;
        var indexCountry = evt.target.value;
        if (indexCountry === "1" && textCountry === "MEXICO") {
            this.address_id.forEach(element => {
                this.frmD3.get(element).setValidators(Validators.min(1));
              });
              this.frmD3.patchValue({CodigoPostal: ''});
            this.collectionsService.getStateByCountryId(indexCountry).subscribe(
                data => {
                    this.valid_country_address = true;
                    this.collectionsEstadosDireccion = data.data;
                    this.utilitiesService.removeControlsValidity(this.frmD3, this.address);
                }, error => {
                    this.valid_country_address = false;
                    this.utilitiesService.removeControlsValidity(this.frmD3, this.address_id);
                }
            )

        } else {
            this.valid_country_address = false;
            this.utilitiesService.removeControlsValidity(this.frmD3, this.address_id);
        }
    }

    handleState(evt) {
        var indexState = evt.target.value;
        var indexCountry = this.frmD3.controls['IdPais'].value;
        if (indexState !== "0") {
            this.frmD3.patchValue({IdCiudad: 0, IdMunicipio: 0, IdColonia: "", CodigoPostal: ''});
            this.collectionsService.getCitiesById(indexCountry, indexState).subscribe(
                data => {
                    this.valid_country_address = true;
                    this.collectionsCiudadesDireccion = data.data;
                }, error => {
                    this.valid_country_address = false;
                }
            )
            this.collectionsService.getMunicipalitiesById(indexCountry, indexState).subscribe(
                data => {
                    this.valid_country_address = true;
                    this.collectionsMunicipiosDireccion = data.data;
                }, error => {
                    this.valid_country_address = false;
                }
            )

        }

    }

    handleTown() {
        var IdPais = this.frmD3.controls['IdPais'].value;
        var IdEstado = this.frmD3.controls['IdEstado'].value;
        var IdMunicipio = this.frmD3.controls['IdMunicipio'].value;

        if (this.frmD3.controls['IdPais'].value && this.frmD3.controls['IdEstado'].value && this.frmD3.controls['IdMunicipio'].value) {
            this.frmD3.patchValue({IdColonia: "", CodigoPostal: ''});
            this.collectionsService.getCollectionsColoniasByPais(IdPais, IdEstado, IdMunicipio).subscribe(
                data => {
                    this.collectionsColoniasDireccion = data.data;
                }, error => {
                    // this.valid_country_address = false;
                }
            )
        }
    }

    createFormD3() {
        return this.fb.group(
            {
                Rfc: [
                    null,
                    Validators.compose([
                        Validators.required,
                        CustomValidators.patternValidator(
                            /^[A-Z]{4}[0-9]{6}[A-Z0-9]{3}$/,
                            {
                                validRFC: true
                            }
                        )
                    ])
                ],
                Curp: [
                    null,
                    Validators.compose([
                        null,
                        CustomValidators.patternValidator(
                            /^[A-Z]{4}[0-9]{6}[A-Z]{3}[A-Z0-9]{5}$/,
                            {
                                validCURP: true
                            }
                        )
                    ])
                ],
                IdSolicitud: [
                    null
                ],
                Nombre: [
                    null
                ],
                ApellidoPaterno: [
                    null
                ],
                ApellidoMaterno: [
                    null
                ],
                IdGenero: [
                    null
                ],
                FechaNacimiento: [
                    null
                ],
                IdPaisNacimiento: [
                    null
                ],
                IdEstadoNacimiento: [
                    null
                ],
                IdPaisNacionalidad: [
                    null
                ],
                EstadoNacimiento: [
                    null
                ],
                PaisNacionalidad: [
                    null
                ],
                Telefono: [
                    null
                ],
                Celular: [
                    null
                ],
                HorarioContacto: [
                    null
                ],
                NoSerieFiel: [
                    Validators.compose([
                        Validators.required,
                        CustomValidators.patternValidator(
                            /^[0-9]{20}$/,
                            {
                                validFiel: true
                            }
                        )
                    ])
                ],
                IdRegimenFiscal: [
                    null
                ],
                Calle: [
                    null
                ],
                NoExterior: [
                    null
                ],
                NoInterior: [
                    null
                ],
                IdPais: [
                    null
                ],
                IdColonia: [
                    null
                ],
                IdMunicipio: [
                    null
                ],
                IdCiudad: [
                    null
                ],
                IdEstado: [
                    null
                ],
                Colonia: [
                    null
                ],
                Municipio: [
                    null
                ],
                Ciudad: [
                    null
                ],
                Estado: [
                    null
                ],
                CodigoPostal: [
                    null
                ],
                Email: [
                    null,
                    // : true
                ]
            }
        );
    }

    parentEmitCancelEditionMode(): void {
        this.eventoCancelado.emit();
    }

    changeColonia(colonia){
        var index = colonia.target.selectedIndex;
        if ( colonia.target[index].getAttribute('CodigoPostal') != null ) {
          this.frmD3.patchValue({CodigoPostal: colonia.target[index].getAttribute('CodigoPostal')});
        }
      }

    onSubmit(modalTarget) {
        if (this.valid_country_address) {
            this.frmD3.patchValue(
                {
                    Estado: null,
                    Ciudad: null,
                    Municipio: null,
                    Colonia: null,
                    IdSolicitud: this.IdSolicitud
                }
            );

        } else {
            this.frmD3.patchValue(
                {
                    IdEstado: null,
                    IdCiudad: null,
                    IdMunicipio: null,
                    IdColonia: null,
                    IdSolicitud: this.IdSolicitud
                }
            );
        }

        if (!this.frmD3.valid) {
            Object.keys(this.frmD3.value).forEach(element => {
              if ( this.frmD3.get(element).errors != null ) {
                this.frmD3.get(element).markAsDirty();
              }
            });
            return;
        }

        var sendData = this.frmD3.value;
        this.loading = true;
        var FormatFechaNacimiento;
        if (sendData.FechaNacimiento) {
            FormatFechaNacimiento = this.utilitiesService.parseDate(sendData.FechaNacimiento);
            sendData.FechaNacimiento = FormatFechaNacimiento;
        }

        let fiel = <HTMLInputElement>document.getElementById('NoSerieFiel');
        sendData.NoSerieFiel = sendData.NoSerieFiel != '' ? fiel.value : null;
        this.D3Service.sendUpdate(sendData).subscribe(
            data=>{
                this.loading = false;
                this.datosGuardados.emit(data)
            },errors =>{
                this.loading = false;
                var msgError = errors.error.error;
                if (msgError) {
                    if (msgError.indexOf("token") > -1) {
                        localStorage.clear();
                        this.router.navigateByUrl("/login");
                    } else {
                        this.errorMessages = errors.error.message;
                        this.modalErrors.open(modalTarget, { ariaLabelledBy: 'modal-basic-title', centered: true });
                    }
                }
            }
        );

    }

}