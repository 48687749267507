import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { UsuarioModel } from '../../../models/usuario.model';
import { AuthService } from '../../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-a2-inicio-sesion',
    templateUrl: './a2-inicio-sesion.component.html',
})
export class A2InicioSesionComponent implements OnInit {
    @ViewChild('modalValidMail', {static: true}) modalMail:ElementRef;
    @ViewChild('modalRegistred', {static: true}) modalRegistred:ElementRef;
    @ViewChild('modalEmailVerified', {static: true}) modalEmailVerified:ElementRef;
    
    infoUser: any[] = [];
    error: boolean;
    errorMsgs: string;
    closeResult: string;

    usuario: UsuarioModel;

    API_URL: string;

    params: any;

    constructor(private auth: AuthService, private router: Router, private modalService: NgbModal, private utilities: UtilitiesService, private route: ActivatedRoute, private http: HttpClient) {
        this.error = false;

        this.API_URL = environment.API_URL;

        if ( this.auth.isLogged() ) {
            if ( this.auth.isClient() ) {
                this.router.navigateByUrl('client/dashboard');
            }
            else{
                this.router.navigateByUrl('promotor/dashboard');
            }
            return;
        }
    }

    ngOnInit() {
        this.usuario = new UsuarioModel();
        this.route.queryParams.subscribe(params => {
            this.params = params;
        });

        if ( this.params['register'] ) {
            this.modalService.open(this.modalRegistred, { 
                ariaLabelledBy: 'modal-basic-title', 
                centered: true,
            });
        }
        if ( this.params['success'] && this.params['success'] == 'mail_verified' ) {
            this.modalService.open(this.modalEmailVerified, { 
                ariaLabelledBy: 'modal-basic-title', 
                centered: true,
            });
        }
    }

    renvioCorreo(modalRegistred){
        const headerStruct = {
            'Authorization': 'Bearer ' + localStorage.getItem("temp_token")
        }
        
        const requestOptions = {
            headers: new HttpHeaders(headerStruct),
        };
      
        this.http.get(this.API_URL + 'reenvio/correo', requestOptions).subscribe(
            response => {
                this.modalService.dismissAll();
                this.modalService.open(modalRegistred, { 
                    ariaLabelledBy: 'modal-basic-title', 
                    centered: true,
                });
            },
            errors => {
            }
        );
    }

    onSubmit(content) {
        this.auth.login(this.usuario).subscribe(
            response => {
                if ( ! response['email_verified'] ) {
                    localStorage.clear();
                    localStorage.setItem('temp_token', response['access_token']);
                    this.auth.logout().subscribe(
                        response => {
                            this.modalService.open(this.modalMail, { 
                                ariaLabelledBy: 'modal-basic-title', 
                                centered: true,
                            });
                            this.router.navigateByUrl('/login?error=mail_not_verify');
                        },
                        errorService => {
                          localStorage.clear();
                          this.router.navigateByUrl('/login');
                        }
                      );
                }
                else{
                    var user_role = parseInt(localStorage.getItem("userRole"));
                    localStorage.setItem('roles', response['user'].roles);
                    localStorage.setItem('permissions', response['user'].permissions);

                    if ( user_role !== undefined ){
                        let isClient = this.utilities.hasPermission('acceso_cliente');
                        if( isClient ){
                            if ( localStorage.getItem('IdPerson') === '' ) {
                                this.router.navigateByUrl('client/datos_personales');
                            }
                            else{
                                if (response.hasOwnProperty("numSolicitudes")){
                                    /* Ya tiene solicitudes creadas */
                                    if (parseInt(response['numSolicitudes']) >0){
                                        /* Checamos que tenga mas de una soliciltud creada (Si la llave proceso no viene nua entonces tiene solo una solicitud y hay que redirigir al paso que le corresponde, si viene nula tiene mas de una solicitud redirigir a dashboard) */
                                        if (parseInt(response['numSolicitudes']) > 1 ){
                                            /* Más de una solicitud, redirigir a dashboard */
                                            this.router.navigateByUrl('client/dashboard');
                                        }else if( parseInt(response['numSolicitudes']) === 1 ){
                                            /* Solo una solicitud redirigir a paso actual */
                                            this.router.navigateByUrl('client/dashboard');
                                        }
                                    }else{
                                        this.router.navigateByUrl('client/destino_credito');
                                    }
                                }else{
                                    this.router.navigateByUrl('client/dashboard');
                                }
                            }
                        }else{
                            // Obtener los roles y devolver el dashboard del cleinte o del promotor

                            let isPromotor = this.utilities.isPromotor();
                            let isDirective = this.utilities.isDirective();
                            if (isPromotor || isDirective) {
                                this.router.navigateByUrl('promotor/dashboard');
                            }
                            else{
                                this.router.navigateByUrl('client/dashboard');
                            }
                        }
                    }
                }
            },
            errorService => {
                this.modalService.open(content, { 
                    ariaLabelledBy: 'modal-basic-title', 
                    centered: true,
                });
            }
        );
    }
}
