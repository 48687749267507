import { Component, OnInit, Input, ɵConsole } from "@angular/core";
import { MessagesService } from 'src/app/services/MessagesService.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { parse } from 'url';
import { K2ContratosService } from 'src/app/services/K2ContratosService.service';

@Component({
    selector: 'app-temp-messages-success',
    templateUrl: './temp-messages-success.component.html',
})
export class TempMessagesSuccessComponent implements OnInit {
    @Input('messages') messages: any;
    public sendSolicitud = {};
    public dataContrato: any;
    public Contratos: any;
    public loading: boolean;
    public IdSolicitud: number;
    constructor(private messagesService: MessagesService, private utilities: UtilitiesService, private router: Router, private route: ActivatedRoute, private K2Service: K2ContratosService) {

    }

    ngOnInit() {
        this.sendSolicitud = {
            IdSolicitud: this.messages.solicitud
        }
        this.loading = false;
        this.route.data.subscribe((data: Data) => {
            this.dataContrato = data['proceso'].data.Contrato;
            this.Contratos = data['proceso'].data.revision_documentacion_legales;
        });
    }

    downloadCarta(){
        this.K2Service.downloadCarta(this.messages.solicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Carta de Bienvenida.pdf');
                a.click();
            },
            errors => {
            },
          )
    }

    downloadLegal(){
        this.loading = true;
        this.utilities.imprimirSolicitud2(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Solicitud de crédito.pdf');
                a.click();
            },
            errors => {
            }
        );
    }

    handleFunctionSuccess() {
        switch ( parseInt(this.messages.proceso.etapa) ) {
            case 2:
                switch (parseInt(this.messages.proceso.flag)) {

                    case 2:
                        this.messagesService.setEtapaC1(this.sendSolicitud).subscribe(
                            resp => {
                                let redirect = this.utilities.handleRedirect(resp['proceso']);
                                this.router.navigateByUrl(redirect+"/"+this.messages.solicitud);
                            }
                        )
                        break;

                    default:
                        break;
                }
                break;

            case 3:
                switch (parseInt(this.messages.proceso.flag)) {
                    case 2:
                        this.messagesService.setEtapaE1(this.sendSolicitud).subscribe(
                            resp => {
                                let redirect = this.utilities.handleRedirect(resp['proceso']);
                                this.router.navigateByUrl(redirect+"/"+this.messages.solicitud);
                            }
                        )
                        break;

                    default:
                        break;
                }
                break;

            case 4:
                switch (parseInt(this.messages.proceso.flag)) {
                    case 2:
                        this.messagesService.setEtapaK1(this.sendSolicitud).subscribe(
                            resp => {
                                let redirect = this.utilities.handleRedirect(resp['proceso']);
                                this.router.navigateByUrl(redirect+"/"+this.messages.solicitud);
                            }
                        )
                        break;

                    default:
                        break;
                }
                break;

            default:
                break;
        }
    }
}
