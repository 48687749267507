import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { C13InformacionEconomica } from '../../../models/C13InformacionEconomica.model';
import { C13InformacionEconomicaService } from "../../../services/C13InformacionEconomicaService.service";
import { NgbDateStruct, NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/validators/validators';
import { UtilitiesService } from 'src/app/services/utilities_services.service';

@Component({
    selector: 'app-c13-informacion-economica',
    templateUrl: './c13-informacion-economica.component.html',
    styleUrls: ['./c13-informacion-economica.component.scss']
})
  export class C13InformacionEconomicaComponent implements OnInit,AfterViewInit {
    public data: C13InformacionEconomica;
    public errorMessages
    public status: number;
    public frmC13: FormGroup;
    public IdSolicitud;
    public loading:boolean;
    constructor(
      private router: Router,
      private route: ActivatedRoute,
      private C1Service: C13InformacionEconomicaService,
      private modalErrors: NgbModal,
      private fb: FormBuilder,
      private utilities: UtilitiesService
    ) { 
        this.status = 2;
        this.IdSolicitud = route.snapshot.params.id;
        this.data = new C13InformacionEconomica();
        this.frmC13 = this.createC13Form();        
        this.frmC13.patchValue({
          IngresosNetos:0,
          OtrosIngresos:0,
          TarjetasBancarias:0,
          TarjetasComerciales:0,
          CreditoHipotecario:0,
          CreditoPersonal:0,
          CreditoAutomotriz:0,
          IdSolicitud: this.IdSolicitud,
          IdSolicitudParticipante: this.IdSolicitud
        });
    }

    ngAfterViewInit(): void {
      window.scrollTo(0, 0);
    }

    ngOnInit() {
      this.loading = false;
    }

    onSubmit(modalError){
      this.loading = true;
      this.C1Service.sendInformacionEconomica(this.frmC13.value).subscribe(
        resp => {
          let redirect = this.utilities.handleRedirect(resp['proceso']);
          this.router.navigateByUrl(redirect+"/"+this.IdSolicitud);
        },
        errors => {
          this.loading = false;
          this.errorMessages = errors.error.message;
          this.modalErrors.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
        },
      );
    }

    createC13Form(): FormGroup {
      return this.fb.group(
        {
          IngresosNetos: [
            Validators.compose([
              Validators.required,
              CustomValidators.patternValidator(
                / ^[0-9][0-9]*$/,
                {
                  atLeastZero: true
                }
              )
            ])
          ],
          OtrosIngresos:[
            Validators.compose([
              Validators.required,
              CustomValidators.patternValidator(
                / ^[0-9][0-9]*$/,
                {
                  atLeastZero: true
                }
              )
            ])
          ],
          TarjetasBancarias: [
            Validators.compose([
              Validators.required,
              CustomValidators.patternValidator(
                / ^[0-9][0-9]*$/,
                {
                  atLeastZero: true
                }
              )
            ])
          ],
          TarjetasComerciales: [
            Validators.compose([
              Validators.required,
              CustomValidators.patternValidator(
                / ^[0-9][0-9]*$/,
                {
                  atLeastZero: true
                }
              )
            ])
          ],
          CreditoHipotecario: [
            Validators.compose([
              Validators.required,
              CustomValidators.patternValidator(
                / ^[0-9][0-9]*$/,
                {
                  atLeastZero: true
                }
              )
            ])
          ],
          CreditoPersonal: [
            Validators.compose([
              Validators.required,
              CustomValidators.patternValidator(
                / ^[0-9][0-9]*$/,
                {
                  atLeastZero: true
                }
              )
            ])
          ],
          CreditoAutomotriz: [
            Validators.compose([
              Validators.required,
              CustomValidators.patternValidator(
                / ^[0-9][0-9]*$/,
                {
                  atLeastZero: true
                }
              )
            ])
          ],
          IdSolicitudParticipante:[
            null
          ]
        },
      );
    }

  }