import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { H1RevisionComite } from 'src/app/services/promotor/H1RevisionComite.service';
import { ActivatedRoute, Router, Data } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { K2ContratosService } from 'src/app/services/K2ContratosService.service';
import { Collection } from 'src/app/models/collection.model';

@Component({
    selector: 'app-h1-revision-comite',
    templateUrl: './h1-revision-comite.component.html'
})

export class H1RevisionComiteComponent implements OnInit {
    @ViewChild('modalCoincidencias1', {static: true}) modalCoincidencias1:ElementRef;
    @ViewChild('modalCoincidencias2', {static: true}) modalCoincidencias2:ElementRef;

    public status: number;
    public IdSolicitud: number;
    public loading: boolean;
    public frmH1: FormGroup;
    public errorMessages: string;
    public totalScore: number;
    public score: any;
    public role_user: number;
    public burosc: number;
    public motivoRechazo: Collection;

    /* COllections */

    public data: any;

    public InfoIngresos: any;
    public CapacidadPago: any;

    public estatus_qeq: number;
    public coincidencias: any;
    public comentariosqeq: string;
    public espep: string;

    public nameAval1: string;
    public nameAval2: string;

    public coincidenciasAval1: any;
    public coincidenciasAval2: string;

    public canChange: boolean;
    public canDecline: boolean;
    public canAuthorize: boolean;

    public PlazoMin: number;
    public PlazoMax: number;
    public PlazoArray: any = [];

    constructor(
        private fb : FormBuilder,
        private utilities: UtilitiesService,
        private H1Service: H1RevisionComite,
        private route: ActivatedRoute,
        private router: Router,
        private modalErrors: NgbModal,
        private K2Service: K2ContratosService
    ) {
        this.status = 6;
        this.loading = false;
        this.totalScore = 0;
        this.canChange = false;
        this.canDecline = false;
        this.canAuthorize = false;
    }

    ngOnInit(): void {
        this.frmH1 = this.createFrmH1();
        this.IdSolicitud = this.route.snapshot.params.id;
        this.route.data.subscribe((data: Data) => {
            this.data = data['collections'][0].data; 
        });

        this.canChange = this.utilities.hasPermission('Cambios al promotor');
        this.canDecline = this.utilities.hasPermission('Declinar Solicitud');
        this.canAuthorize = this.utilities.hasPermission('Autorizar solicitud comite');

        this.CapacidadPago = this.route.snapshot.data.solicitudPromotor.data.capacidad_pago;
        this.InfoIngresos = this.route.snapshot.data.solicitudPromotor.data.revision_preanalisis;

        this.estatus_qeq = this.route.snapshot.data.solicitudPromotor.data.estatus_qeq;

        this.coincidencias = this.route.snapshot.data.solicitudPromotor.data.coincidencias;

        this.comentariosqeq = this.route.snapshot.data.solicitudPromotor.data.ComentariosQeq;
        this.espep = this.route.snapshot.data.solicitudPromotor.data.EsPep;
        
        this.route.data.subscribe((data: Data) => {
            this.motivoRechazo = data['motivoRechazo'][0].data;
        });
        
        this.data.variablesScore.forEach(score => {
            this.totalScore = this.totalScore + parseInt(score.Puntos);
        });

        this.score = this.route.snapshot.data.solicitudPromotor.data.variable;
        this.burosc = this.route.snapshot.data.solicitudPromotor.data.burosc;

        this.data.avales.forEach((aval, key) => {
            if ( key == 0 ) {
                this.nameAval1 = aval.Nombre;
                this.coincidenciasAval1 = aval.coincidencias;
            }
            else{
                this.nameAval2 = aval.Nombre;
                this.coincidenciasAval2 = aval.coincidencias;
            }
        });

        this.role_user = parseInt( localStorage.getItem('userRole') );

        this.frmH1.patchValue({
            PlazoMin:this.data.Plazo,
            PlazoMax:this.data.Plazo,
            MontoMaximo:this.data.MontoFinanciar,
        })
        this.frmH1.controls['MontoMaximo'].setValidators([
            Validators.compose(
                [
                Validators.required,
                Validators.min(this.data.plan.MontoMinimo),
                Validators.max(this.data.plan.MontoMaximo),

                ]
            )
        ]);
        this.PlazoArray = [];
        for (let index = Number(this.data.plan.PlazoMin); index <= Number(this.data.plan.PlazoMax); index++) {
            this.PlazoArray.push( index );
        }
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    verCoincidenciasAval(index){
        if ( index == 1 ) {
            this.modalErrors.open(this.modalCoincidencias1, { ariaLabelledBy: 'modal-basic-title', centered: false, size: 'xl', scrollable: true });
        }
        else{
            this.modalErrors.open(this.modalCoincidencias2, { ariaLabelledBy: 'modal-basic-title', centered: false, size: 'xl', scrollable: true });
        }
    }

    verCoincidencias(modalCoincidenciasCliente){
        this.modalErrors.open(modalCoincidenciasCliente, { ariaLabelledBy: 'modal-basic-title', centered: false, size: 'xl', scrollable: true });
    }

    onSubmit(modalTarget){
        this.loading = true;
        this.H1Service.sendComments(this.frmH1.value).subscribe(
            response =>{
                this.loading = false;
            }, 
            errors =>{
                this.loading = false;

                this.errorMessages = errors.error.message;
                this.modalErrors.open(modalTarget, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        );
    }

    detalleCapacidadPago(modalCapacidadPago){
        this.modalErrors.open(modalCapacidadPago, { ariaLabelledBy: 'modal-basic-title', centered: false, size: 'xl', scrollable: true});
    }

    downloadBuro(){
        this.loading = true;
        this.K2Service.downloadBuro(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Buro.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
                // this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
    }

    declinarSubmit(MotivoRechazo){
        if ( MotivoRechazo.value == '' ) {
            alert('Debe selecciona un motivo de rechazo');
            return;
        }

        let data = {MotivoRechazo: MotivoRechazo.value};
        
        this.utilities.declinarSolicitud(this.IdSolicitud, data).subscribe(
            resp => {
                this.modalErrors.dismissAll();
                this.router.navigateByUrl("promotor/dashboard");
            },
            errors => {
            }
        );
    }

    declinar(modalDeclinar){
        this.modalErrors.open(modalDeclinar, { ariaLabelledBy: 'modal-basic-title', centered: false});

        // if ( window.confirm('¿Estás seguro que deseas declinar la solicitud?') ) {
        // }
    }

    getDocumentAvalBuro(IdPersona){
        this.loading = true;
        this.K2Service.downloadBuroAval(this.IdSolicitud, IdPersona).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Buro Aval.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
                // this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
    }

    SolicitarCambios(modalTarget){
        if (!this.frmH1.get('Comentarios').valid){
            if ( this.frmH1.get('Comentarios').errors != null ) {
                this.frmH1.get('Comentarios').markAsDirty();
            }
            return  
        }
        /*if (!this.frmH1.valid) {
            Object.keys(this.frmH1.value).forEach(element => {
              if ( this.frmH1.get(element).errors != null ) {
                this.frmH1.get(element).markAsDirty();
              }
            });
            return;
        }*/

        var sendData = {
            IdSolicitud: this.IdSolicitud,
            sendComments: this.frmH1.controls['Comentarios'].value
        }

        this.loading = true;

        this.H1Service.requestChanges(sendData).subscribe(
            response => {
                this.loading = false;
                this.router.navigateByUrl("promotor/dashboard");
            },
            errors => {
                this.loading = false;

                this.errorMessages = errors.error.message;
                this.modalErrors.open(modalTarget, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        );
    }

    authorize(modalTarget){
        if (!this.frmH1.valid) {
            Object.keys(this.frmH1.value).forEach(element => {
              if ( this.frmH1.get(element).errors != null ) {
                this.frmH1.get(element).markAsDirty();
              }
            });
            return;
        }
        
        this.loading = true;
        var sendData = {
            IdSolicitud: this.IdSolicitud,
            sendComments: this.frmH1.controls['Comentarios'].value,
            plazoMin:this.frmH1.controls['PlazoMin'].value,
            plazoMax:this.frmH1.controls['PlazoMax'].value,
            montoMaximo:this.frmH1.controls['MontoMaximo'].value,
        }
        this.H1Service.sendComments(sendData).subscribe(
            response =>{
                this.loading = false;
                this.router.navigateByUrl("promotor/envio_contratos/"+this.IdSolicitud);
            }, 
            errors =>{
                this.loading = false;

                this.errorMessages = errors.error.message;
                this.modalErrors.open(modalTarget, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        )
        
    }

    createFrmH1(){
        return this.fb.group({
            Comentarios: [
                null,
                Validators.required
            ],
            PlazoMin: [
                null,
                Validators.required
            ],
            PlazoMax: [
                null,
                Validators.required
            ],
            MontoMaximo: [
                null,
                Validators.required
            ],
        })
    }
}