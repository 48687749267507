import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin} from 'rxjs';
import { CollectionsService } from "../../services/colecctions.service";
import { Collection } from '../../models/collection.model';
import { Injectable } from '@angular/core';

@Injectable()
export class D5AcreditacionRevisionReferenciasResolver implements Resolve<Collection>{
    private Object = {};
    public idSolicitud: any;
    constructor (private CollectionsService: CollectionsService){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<any>{
        this.idSolicitud = route.paramMap.get('id');
        let test = forkJoin(
            this.CollectionsService.getSolicitudPromotorDetails(this.idSolicitud),
            this.CollectionsService.getCollectionsParentezco(),
            this.CollectionsService.getReferencia(),
        );

        return test;
    }
}