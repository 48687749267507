export class E1InformacionComplementaria{
    public IdNacionalidad: number;
    public IdPais:number;
    public IdEstado: number;
    public IdRegimenFiscal: number;
    public NombreConyugue: string;
    public TelefonoContacto: string;
    public Fiel: string;
    public TieneFiel: boolean;
    public IdPersona : number;
    public IdSolicitud: number;
    
    constructor(){}
}