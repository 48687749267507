import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin,of,throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Collection } from 'src/app/models/collection.model';
import { CollectionsService } from 'src/app/services/colecctions.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';

@Injectable()
export class F1CotizacionResolver implements Resolve<Collection>{
    private Object = {};
    public IdSolicitud: number;
    constructor (private CollectionsServive: CollectionsService, private utilities: UtilitiesService){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<any>{   
        this.IdSolicitud = parseInt(route.paramMap.get('id'));
        let test = forkJoin(
            this.CollectionsServive.getCollectionsPlan(),
            this.CollectionsServive.getCollectionsPeriodos(),
            this.CollectionsServive.getCollectionsAgencia(),
            this.CollectionsServive.getSolicitudPromotorDetails(this.IdSolicitud),
        );

        return test;
    }
}