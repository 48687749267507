import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin,of,throwError } from 'rxjs';
import { CollectionsService } from "../services/colecctions.service";
import { Collection } from '../models/collection.model';
import { Injectable } from '@angular/core';

@Injectable()
export class C1Resolver implements Resolve<Collection>{
    private Object = {};
    public IdSolicitud: number;
    constructor (private CollectionsServive: CollectionsService){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<any>{
        this.IdSolicitud = parseInt(route.paramMap.get('id'));
        let test = forkJoin(
            this.CollectionsServive.getCollectionsEstadoCivil(),
            this.CollectionsServive.getCollectionsRegimenConyugal(),
            this.CollectionsServive.getCollectionsParentezco(),
            this.CollectionsServive.getCollectionsTipoVivienda(),
            this.CollectionsServive.getParentezco( this.IdSolicitud ),
        );

        return test;
    }
}