import { Component, OnInit } from "@angular/core";

@Component({
    selector: 'app-temp-views',
    templateUrl: './temp-views.component.html'
})

export class TempViewsComponent implements OnInit {
    public status: number;

    constructor() {
    }

    ngOnInit(): void {
    }
}