import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { CollectionsService } from "../../services/colecctions.service";
import { Collection } from '../../models/collection.model';
import { Injectable } from '@angular/core';

@Injectable()
export class M1PromotorInformacionPersonalResolver implements Resolve<Collection>{
    private Object = {};
    constructor (private CollectionsServive: CollectionsService){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<any>{   
        let test = forkJoin(
            this.CollectionsServive.getCollectionsPaises(),
            this.CollectionsServive.getCollectionsNacionalidad(),
            this.CollectionsServive.getStateByCountryId(1),
        );

        return test;
    }
}