import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router, Data } from '@angular/router';
import { Collection } from 'src/app/models/collection';
import { CollectionsService } from 'src/app/services/colecctions.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomValidators } from 'src/app/validators/validators';
import { B2CapturaInformacionFiscalService } from 'src/app/services/aval/B2CapturaInformacionFiscalService.service';
import { UtilitiesAvalService } from 'src/app/services/aval/UtilitiesAvalService.service';

@Component({
    selector: 'app-b2-captura-informacion-fiscal',
    templateUrl: './b2-captura-informacion-fiscal.component.html',
})
export class B2CapturaInformacionFiscalComponent implements OnInit, AfterViewInit {
    public frmB2: FormGroup;
    public loading: boolean;
    public errorMessages: string;
    public IdSolicitud: number;
    public checkFiel: boolean;
    public _prevFunctionsExternal: boolean;
    public _prevFunctionsFamiliarExternal: boolean;


    /* Collections from resolver */
    public collectionsRegimenFiscal: Collection;
    public data: any;

    /* End Collections from resolver */

    /* permissions */
    public IdRoleAval: any;
    public formatedPermissions: any;

    public informacionPropietario: any;

    public previous_data: any;
    public has_rfc:boolean;
    public has_curp:boolean;

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private collectionsService: CollectionsService,
        private utilitiesService: UtilitiesService,
        private modalError: NgbModal,
        private B2Service: B2CapturaInformacionFiscalService,
        private avalService: UtilitiesAvalService
    ) {
        this.loading = false;
        this._prevFunctionsExternal = true;
        this._prevFunctionsFamiliarExternal = true;
        
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    ngOnInit() {
        this.IdSolicitud = this.route.snapshot.params.id;
        this.route.data.subscribe((data: Data) => {
            this.collectionsRegimenFiscal = data['collections'][0].data;
            this.IdRoleAval = data['permissions'][0].data.IdTipoRelacion;
            this.data = data['data'][0].data.informacion_fiscal;
            this.informacionPropietario = data['data'][0].data.informacion_propietario;
            this.previous_data = data['data'][0].data;
        });

        this.formatedPermissions = this.avalService.getPermissions(this.IdRoleAval);


        this.frmB2 = this.createFrmB2();
        this.frmB2.patchValue({
            IdSolicitud: this.IdSolicitud,
            IdRegimenFiscal: ""
        });
        if (this.formatedPermissions.indexOf("Buro") > -1) {
            this.frmB2.patchValue({
                etapa: 1,
                paso: 3,
                flag: 1,
            });
        }else{
            if(this.formatedPermissions.indexOf("InformacionFamiliar") > -1){
                this.frmB2.patchValue({
                    etapa: 2,
                    paso: 1,
                    flag: 1,
                });
            }else{
                if(this.formatedPermissions.indexOf("ComprobacionIdentidad") > -1){
                    this.frmB2.patchValue({
                        etapa: 2,
                        paso: 4,
                        flag: 1,
                    });
                }else{
                    if(this.formatedPermissions.indexOf("Documentacion") > -1){
                        this.frmB2.patchValue({
                            etapa: 2,
                            paso: 5,
                            flag: 1,
                        });
                    }
                }
            }
        }

        this.frmB2.patchValue(this.data);



        if ( ! this.previous_data['has_informacion_personal'] ) {
            this.loading = true;
            this.router.navigateByUrl("aval/capturar_informacion/" + this.IdSolicitud);
        }

        if (this.data.TieneNoSerieFiel == "1") {
            this.frmB2.controls["NoSerieFiel"].clearValidators();
            this.frmB2.controls["NoSerieFiel"].setValidators([
                Validators.compose([
                    Validators.required,
                    CustomValidators.patternValidator(
                        /^[0-9]{20}$/,
                        {
                            validFiel: true
                        }
                    )
                ])
            ]);
            this.frmB2.get("NoSerieFiel").updateValueAndValidity();
            this.checkFiel = true;
        } else {
            this.frmB2.controls["NoSerieFiel"].clearValidators();
            this.frmB2.get("NoSerieFiel").updateValueAndValidity();
            this.checkFiel =  false ;
        }

        if ( this.data.PoliticaExpuesta == 1 ) {
            this._prevFunctionsExternal = false;
            this.frmB2.patchValue({PoliticaExpuesta: 'yes'});
        }
        else{
            this._prevFunctionsExternal = true;
            this.frmB2.patchValue({PoliticaExpuesta: 'no'});
        }

        if ( this.data.FamiliarPoliticaExpuesta == 1 ) {
            this._prevFunctionsFamiliarExternal = false;
            this.frmB2.patchValue({FamiliarPoliticaExpuesta: 'yes'});
        }
        else{
            this._prevFunctionsFamiliarExternal = true;
            this.frmB2.patchValue({FamiliarPoliticaExpuesta: 'no'});
        }
        //this.has_rfc=this.data.Rfc.length===13;
        //this.has_curp=this.data.Curp.length==18;

        this.getFormValidationErrors()
    }

    createFrmB2() {
        return this.fb.group(
            {
                IdSolicitud: [
                    null
                ],
                IdRegimenFiscal: [
                    null
                ],
                Rfc: [
                    null,
                    Validators.compose([
                        Validators.required,
                        CustomValidators.patternValidator(
                            /^[A-Z]{4}[0-9]{6}[A-Z0-9]{3}$/,
                            {
                                validRFC: true
                            }
                        )
                    ])
                ],
                Curp: [
                    null,
                    Validators.compose([
                        null,
                        CustomValidators.patternValidator(
                            /^[A-Z]{4}[0-9]{6}[A-Z]{3}[A-Z0-9]{5}$/,
                            {
                                validCURP: true
                            }
                        )
                    ])
                ],
                NoSerieFiel: [
                    null
                ],
                TieneNoSerieFiel: [
                    Validators.required
                ],
                etapa:[
                    null
                ],
                paso:[
                    null
                ],
                flag:[
                    null
                ],
                PoliticaExpuesta: [Validators.required],
                PuestoPoliticaExpuesta: [null],
                PeriodoPoliticaExpuesta: [null],
                FamiliarPoliticaExpuesta: [Validators.required],
                PuestoFamiliarPoliticaExpuesta: [null],
                PeriodoFamiliarPoliticaExpuesta: [null],
            }
        );
    }

    handleChangeFunctionsExternal(evt) {
        var target = evt.target;
        if (target.value == "yes") {
          this._prevFunctionsExternal = false;
          let controls = {
            PuestoPoliticaExpuesta: [
              Validators.required,
              null
            ],
            PeriodoPoliticaExpuesta: [
              Validators.required,
              null
            ],
          }
          this.utilitiesService.addControlsValidity(this.fb, controls);
        } else {
          this._prevFunctionsExternal = true;
          let controls = ['PuestoPoliticaExpuesta', 'PeriodoPoliticaExpuesta'];
          this.utilitiesService.removeControlsValidity(this.frmB2, controls);
          this.frmB2.controls["PuestoPoliticaExpuesta"].setValue(null);
          this.frmB2.controls["PeriodoPoliticaExpuesta"].setValue(null);
        }
      }

      handleChangeFunctionsFamiliarExternal(evt) {
        var target = evt.target;
        if (target.value == "yes") {
          this._prevFunctionsFamiliarExternal = false;
          let controls = {
            PuestoFamiliarPoliticaExpuesta: [
              Validators.required,
              null
            ],
            PeriodoFamiliarPoliticaExpuesta: [
              Validators.required,
              null
            ],
          }
          this.utilitiesService.addControlsValidity(this.fb, controls);
        } else {
          this._prevFunctionsFamiliarExternal = true;
          let controls = ['PuestoFamiliarPoliticaExpuesta', 'PeriodoFamiliarPoliticaExpuesta'];
          this.utilitiesService.removeControlsValidity(this.frmB2, controls);
          this.frmB2.controls["PeriodoFamiliarPoliticaExpuesta"].setValue(null);
          this.frmB2.controls["PuestoFamiliarPoliticaExpuesta"].setValue(null);
        }
      }

    handleFiel(event) {
        if (!event.target.checked) {
            this.frmB2.controls["NoSerieFiel"].clearValidators();
            this.frmB2.controls["NoSerieFiel"].setValidators([
                Validators.compose([
                    Validators.required,
                    CustomValidators.patternValidator(
                        /^[0-9]{20}$/,
                        {
                            validFiel: true
                        }
                    )
                ])
            ]);
            this.frmB2.get("NoSerieFiel").updateValueAndValidity();
        } else {
            this.frmB2.patchValue({NoSerieFiel: null});
            this.frmB2.controls["NoSerieFiel"].clearValidators();
            this.frmB2.get("NoSerieFiel").updateValueAndValidity();
        }
    }

    onSubmit(modalError) {
        if (!this.frmB2.valid) {
            Object.keys(this.frmB2.value).forEach(element => {
                if ( this.frmB2.get(element).errors != null ) {
                this.frmB2.get(element).markAsDirty();
                }
            });
            return;
        }

        this.loading = true;
        let sendData = this.frmB2.value;
        let fiel = <HTMLInputElement>document.getElementById('NoSerieFiel');
        sendData.NoSerieFiel = sendData.TieneNoSerieFiel == 0 ? fiel.value : null;

        this.B2Service.sendData(sendData).subscribe(
          resp => {
            if (this.formatedPermissions.indexOf("Buro") > -1) {
                this.router.navigateByUrl("aval/capturar_informacion_buro/" + this.IdSolicitud);
            }else{
                if(this.formatedPermissions.indexOf("InformacionFamiliar") > -1){
                    this.router.navigateByUrl("aval/informacion_personal/" + this.IdSolicitud);
                }else{
                    if(this.formatedPermissions.indexOf("ComprobacionIdentidad") > -1){
                        this.router.navigateByUrl("aval/confirmacion_identidad/" + this.IdSolicitud);
                    }else{
                        if(this.formatedPermissions.indexOf("Documentacion") > -1){
                            this.router.navigateByUrl("aval/documentacion/" + this.IdSolicitud);
                        }
                    }
                }
            }
          },
          errors => {
            this.loading = false;
            this.errorMessages = errors.error.message;
            this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
          },
        )
    }
    
    getFormValidationErrors() {
        Object.keys(this.frmB2.controls).forEach(key => {
      
        const controlErrors: ValidationErrors = this.frmB2.get(key).errors;
        if (controlErrors != null) {
              Object.keys(controlErrors).forEach(keyError => {
              });
            }
          });
        }
}