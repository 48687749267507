import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import { Data, ActivatedRoute, Router } from '@angular/router';
import { MessagesService } from 'src/app/services/MessagesService.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';

@Component({
    selector: 'app-temp-messages-aval-error',
    templateUrl: './temp-messages-error.component.html',
})
export class TempMessagesErrorAvalComponent implements OnInit {
    @Input('messages') messages: any;
    constructor(private messagesService: MessagesService, private utilities: UtilitiesService, private router: Router, private route: ActivatedRoute) {

    }

    ngOnInit() {
        
    }
}