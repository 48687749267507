import { Component, OnInit, ɵConsole } from '@angular/core';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { Collection } from 'src/app/models/collection';
import { ADatosPersonales } from '../../../models/ADatosPersonales.model';
import { ADatosPersonalesService } from "../../../services/ADatosPersonalesService.model";
import { NgbDateStruct, NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from '../../../validators/validators';

@Component({
  selector: 'app-a12-datos-personales',
  templateUrl: './a12-datos-personales.component.html'
})
export class A12DatosPersonalesComponent implements OnInit {
  public collectionsGenero: Collection;
  public collectionsEnteradoPor: Collection;
  public model: NgbDateStruct;
  public date: { year: number, month: number };
  public errorMessages: string;
  public modeltarget: any;
  public frmPersonalData: FormGroup;
  public loading: boolean;
  public lastBlankName: boolean;
  public lastBlankAP: boolean;
  public lastBlankAM: boolean;
  public minDate: any;
  public edadMin:number;
  public edadMax:number;

  public maxDate: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ADatosPersonalesService: ADatosPersonalesService,
    private modalErrors: NgbModal,
    private utilitiesService: UtilitiesService,
    private fb: FormBuilder
  ) {
    this.loading = false;
    this.errorMessages = "";
    this.frmPersonalData = this.createPersonalDataForm();

  }

  ngOnInit() {
    this.route.data.subscribe( (data: Data) => {
      this.collectionsGenero = data['collections'][0].data;
      this.collectionsEnteradoPor = data['collections'][1].data;
      let edades=data['collections'][3].data;
      this.edadMin=Number(edades.find(element => element.variable == 'Edad Minima Contratante').valor);
      this.edadMax=Number(edades.find(element => element.variable == 'Edad Maxima Contratante').valor);
      
    } );
    this.frmPersonalData.patchValue({IdEnteradoPor : "", IdGenero : "" }); 

    this.maxDate = {
      year: new Date().getFullYear() - this.edadMin,
      month: new Date().getMonth() + 1,
      day: new Date().getDate()
    };

    this.minDate = {
      year: new Date().getFullYear() - this.edadMax-1,
      month: new Date().getMonth() + 1,
      day: new Date().getDate()+1
    };
  }

  validName(event) {
    if ( this.lastBlankName ){
      if ( event.which == 32 ) {
        event.preventDefault();
      }
    }

    if ( event.which == 32 ) {
      this.lastBlankName = true;
    }
    else{
      this.lastBlankName = false;
    }
  }

  validAP(event) {
    if ( this.lastBlankAP ){
      if ( event.which == 32 ) {
        event.preventDefault();
      }
    }

    if ( event.which == 32 ) {
      this.lastBlankAP = true;
    }
    else{
      this.lastBlankAP = false;
    }
  }

  validAM(event) {
    if ( this.lastBlankAM ){
      if ( event.which == 32 ) {
        event.preventDefault();
      }
    }

    if ( event.which == 32 ) {
      this.lastBlankAM = true;
    }
    else{
      this.lastBlankAM = false;
    }
  }

  onSubmit(modalTarget){
    this.loading = true;
    let user = this.frmPersonalData.value;
    user.FechaNacimiento = this.utilitiesService.parseDate(user.FechaNacimiento);
    user.Nombre = this.utilitiesService.textToUppercase(user.Nombre);
    user.ApellidoMaterno = this.utilitiesService.textToUppercase(user.ApellidoMaterno);
    user.ApellidoPaterno = this.utilitiesService.textToUppercase(user.ApellidoPaterno);
    user.Celular = user.Celular.toString();
    const personalData = new ADatosPersonales(user);
    
    this.ADatosPersonalesService.sendRegistroPersona(personalData).subscribe(
      response => {
        localStorage.setItem("IdPerson", response['data']['IdPersona']);
        localStorage.setItem('person', JSON.stringify(response['data']));
        this.router.navigateByUrl('client/destino_credito');
      },
      errors => {
        this.loading = false;
        this.errorMessages = errors.error.message;
        this.modalErrors.open(modalTarget, { ariaLabelledBy: 'modal-basic-title',centered: true, });
      }
    );
    
  }

  createPersonalDataForm (): FormGroup {
    return this.fb.group(
      {
          Nombre: [
            null,
            Validators.compose([
                Validators.required
            ])
          ],
          ApellidoPaterno: [
            null,
            Validators.compose([
                Validators.required,
            ])
          ],
          ApellidoMaterno: [
            null,
            Validators.compose([
                Validators.required,
            ])
          ],
          IdGenero: [
            null,
            Validators.compose([
                Validators.required,
            ])
          ],
          FechaNacimiento: [
            null,
            Validators.compose([
                Validators.required,
            ])
          ],
          Celular: [
            null,
            Validators.compose([
                Validators.required,
            ])
          ],
          IdEnteradoPor: [
            null,
            Validators.compose([
                Validators.required,
            ])
          ],
          
      },
    );
}

}
