import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { Collection } from 'src/app/models/collection';
import { AvalC2InformacionLaboralService } from 'src/app/services/aval/C2InfromacionLaboralService.service';
import { CollectionsService } from 'src/app/services/colecctions.service';

@Component({
    selector: 'app-c2-informacion-laboral-aval',
    templateUrl: './c2-informacion-laboral-aval.component.html'
})
export class C2InformacionLaboralAval implements OnInit, AfterViewInit {

    public loading: boolean;
    public frmC2: FormGroup;
    public IdSolicitud: number;
    public showRegimenConyugal: boolean;
    public errorMessages: string;
    public data:any;
    public informacionPropietario: any;

    public collectionsPais: Collection;
    public collectionsEstado: Collection;
    public collectionsCiudad: Collection;
    public collectionsMunicipio: Collection;
    public collectionsColonia: Collection;
    public valid_country: boolean;

    public previous_data: any;

    /* collections from resolver */
    public collectionsSituacionLaboral: Collection;
    public collectionsOcupaciones: Collection;
    public errorFecha: boolean;
    public showData: boolean;
    public dataLaboral = ['NombreEmpresa', 'IdOcupacion', 'FechaAntiguedad', 'Puesto'];
    public address_id = ['IdCiudad', 'IdEstado', 'IdMunicipio', 'IdColonia'];
    public address = ['Ciudad', 'Estado', 'Municipio', 'Colonia'];
    public infoEmpleado = ['IdCiudad', 'IdEstado', 'IdMunicipio', 'IdColonia', 'IdPais','Telefono', 'CodigoPostal', 'Calle' , 'NoExterior'];
    public dataFormat = {
      Extension: null,
      Telefono: null,
      Calle: null,
      NoExterior: null,
      NoInterior: null,
      IdPais: null,
      IdColonia: null,
      IdMunicipio: null,
      IdCiudad: null,
      IdEstado: null,
      Colonia: null,
      Municipio: null,
      Ciudad: null,
      Estado: null,
      CodigoPostal: null,
      IdSolicitud: null,
    };

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private C2Service: AvalC2InformacionLaboralService,
        private modalErrors: NgbModal,
        private fb: FormBuilder,
        private utilitiesService: UtilitiesService,
        private colectionsService: CollectionsService,
    ) {
        this.errorFecha = false;
        this.showData = true;
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    ngOnInit() {
        this.route.data.subscribe( (data: Data) => {
            this.collectionsSituacionLaboral = data['collections'][0].data;
            this.collectionsOcupaciones = data['collections'][1].data;
            this.data = data['data'][0].data.informacion_laboral;
            this.informacionPropietario = data['data'][0].data.informacion_propietario;
            this.previous_data = data['data'][0].data;
            this.collectionsPais = data['direcciones'][0].data;
            this.collectionsColonia = data['direcciones'][1].data;
        });
        this.IdSolicitud = this.route.snapshot.params.id;

        if ( ! this.previous_data.has_informacion_familiar ) {
            this.loading = true;
            this.router.navigateByUrl('/aval/informacion_personal/' + this.IdSolicitud);
        }
        
        this.frmC2 = this.createC1Form();

        if (this.previous_data) {
          if (this.previous_data.informacion_laboral.IdPais.toString() == "1") {
            this.valid_country = true;
            this.colectionsService.getStateByCountryId(this.previous_data.informacion_laboral.IdPais).subscribe(
              data => {
                this.collectionsEstado = data.data;
              }
            )
            this.colectionsService.getCitiesById(this.previous_data.informacion_laboral.IdPais, this.previous_data.informacion_laboral.IdEstado).subscribe(
              data => {
                this.collectionsCiudad = data.data;
              }
            )
            this.colectionsService.getMunicipalitiesById(this.previous_data.informacion_laboral.IdPais, this.previous_data.informacion_laboral.IdEstado).subscribe(
              data => {
                this.collectionsMunicipio = data.data;
              }
            )
            this.colectionsService.getCollectionsColoniasByPais(this.previous_data.informacion_laboral.IdPais, this.previous_data.informacion_laboral.IdEstado, this.previous_data.informacion_laboral.IdMunicipio).subscribe(
              data => {
                this.collectionsColonia = data.data;
              }, error => {
                // this.valid_country = false;
              }
            )
          } else {
            this.valid_country = false;
          }
    
          this.dataFormat.Telefono = this.previous_data.informacion_laboral.Telefono;
          this.dataFormat.Extension =  this.previous_data.informacion_laboral.Extension;
          this.dataFormat.IdPais = this.previous_data.informacion_laboral.IdPais;
          this.dataFormat.IdEstado = this.previous_data.informacion_laboral.IdEstado;
          this.dataFormat.IdCiudad = this.previous_data.informacion_laboral.IdCiudad;
          this.dataFormat.IdMunicipio = this.previous_data.informacion_laboral.IdMunicipio;
          this.dataFormat.IdColonia = this.previous_data.informacion_laboral.IdColonia;
          this.dataFormat.Estado = this.previous_data.informacion_laboral.Estado;
          this.dataFormat.Ciudad = this.previous_data.informacion_laboral.Ciudad;
          this.dataFormat.Municipio = this.previous_data.informacion_laboral.Municipio;
          this.dataFormat.Colonia = this.previous_data.informacion_laboral.Colonia;
          this.dataFormat.CodigoPostal = this.previous_data.informacion_laboral.CodigoPostal;
          this.dataFormat.Calle = this.previous_data.informacion_laboral.Calle;
          this.dataFormat.NoExterior = this.previous_data.informacion_laboral.NoExterior;
          this.dataFormat.NoInterior = this.previous_data.informacion_laboral.NoInterior;
          
    
    
        this.dataFormat.IdSolicitud = this.IdSolicitud;
    
          this.frmC2.patchValue(this.dataFormat);
        } else {
    
          this.valid_country = true;
          this.frmC2.patchValue({IdPais: 1});
    
          this.colectionsService.getStateByCountryId(1).subscribe(
            data => {
              this.collectionsEstado = data.data;
            }
          )

        this.frmC2.patchValue({
            IdSolicitud: this.IdSolicitud,
            etapa: 2,
            paso:3,
            flag:1,
            IdEstado: 0,
            IdCiudad: 0,
            IdMunicipio: 0,
            IdColonia: 0,
        });
        }
        
        if(this.data.FechaAntiguedad){
            var formatFechaAntiguedad = this.utilitiesService.formatDateObject(this.data.FechaAntiguedad);
            this.data.FechaAntiguedad = formatFechaAntiguedad;
        }
        this.frmC2.patchValue(this.data);

        if ( this.data.IdSituacionLaboral == 2 || this.data.IdSituacionLaboral == 5 || this.data.IdSituacionLaboral == 6 ) {
            this.showData = false;
            this.utilitiesService.removeControlsValidity(this.frmC2, this.dataLaboral);
            this.utilitiesService.removeControlsValidity(this.frmC2, this.infoEmpleado );
            this.frmC2.get('IdOcupacion').setValidators([Validators.required, Validators.min(1)]);
            if ( this.data.IdSituacionLaboral == 2 ) {
              this.frmC2.patchValue({
                IdOcupacion: 13
              });
            }
            else{
              if ( this.data.IdSituacionLaboral == 5 ) {
                this.frmC2.patchValue({
                  IdOcupacion: 3
                });
              }
              else{
                if ( this.data.IdSituacionLaboral == 6 ) {
                  this.frmC2.patchValue({
                    IdOcupacion: 16
                  });
                }
              }
            }
          }
          else{
            this.dataLaboral.forEach(element => {
              if ( element == 'IdOcupacion' ) {
                this.frmC2.get(element).setValidators([Validators.required, Validators.min(1)]);
              }
              else{
                this.frmC2.get(element).setValidators([Validators.required]);
              }
            });
            this.infoEmpleado.forEach(element => {
              if ( element.startsWith('Id')) {
                this.frmC2.get(element).setValidators([Validators.required, Validators.min(1)]);
              } else if (element == 'Telefono') {
                this.frmC2.get(element).setValidators([Validators.required, Validators.maxLength(10),Validators.minLength(10)]);
              }
              else{
                this.frmC2.get(element).setValidators([Validators.required]);
              }
            });
            this.showData = true;
    
            if ( this.data.IdSituacionLaboral == 4 ) {
              this.frmC2.patchValue({
                IdOcupacion: 23
              });
            }
            else{
              this.frmC2.patchValue({
                IdOcupacion: 0
              });
              
            }
            
          }
    }


    handleSituacionLaboral(evt){
        var valueSituacionLaboral = evt.target.value;
        if ( valueSituacionLaboral == 2 || valueSituacionLaboral == 5 || valueSituacionLaboral == 6 ) {
          this.showData = false;
          this.utilitiesService.removeControlsValidity(this.frmC2, this.dataLaboral);
          this.utilitiesService.removeControlsValidity(this.frmC2, this.infoEmpleado);
          this.utilitiesService.removeControlsValidity(this.frmC2, this.address);
          this.frmC2.get('IdOcupacion').setValidators([Validators.required, Validators.min(1)]);
          if ( valueSituacionLaboral == 2 ) {
            this.frmC2.patchValue({
              IdOcupacion: 13
            });
          }
          else{
            if ( valueSituacionLaboral == 5 ) {
              this.frmC2.patchValue({
                IdOcupacion: 3
              });
            }
            else{
              if ( valueSituacionLaboral == 6 ) {
                this.frmC2.patchValue({
                  IdOcupacion: 16
                });
              }
            }
          }
        }
        else{
          this.dataLaboral.forEach(element => {
            if ( element == 'IdOcupacion' ) {
              this.frmC2.get(element).setValidators([Validators.required, Validators.min(1)]);
            }
            else{
              this.frmC2.get(element).setValidators([Validators.required]);
            }
          });
          this.infoEmpleado.forEach(element => {
            if ( element.startsWith('Id')) {
              this.frmC2.get(element).setValidators([Validators.required, Validators.min(1)]);
            }else if (element == 'Telefono') {
              this.frmC2.get(element).setValidators([Validators.required, Validators.maxLength(10),Validators.minLength(10)]);
            }
            else{
              this.frmC2.get(element).setValidators([Validators.required]);
            }
          });
          this.showData = true;
  
          if ( valueSituacionLaboral == 4 ) {
            this.frmC2.patchValue({
              IdOcupacion: 23
            });
          }
          else{
            this.frmC2.patchValue({
              IdOcupacion: 0
            });
          } 
        }
      }

      handleCountry(evt) {
        var index = evt.target.selectedIndex;
        var textCountry = evt.target[index].text;
        var indexCountry = evt.target.value;
        if (indexCountry == "1" && textCountry == "MEXICO") {
          this.address_id.forEach(element => {
            this.frmC2.get(element).setValidators(Validators.min(1));
          });
          this.frmC2.patchValue({CodigoPostal: ''});
    
          this.colectionsService.getStateByCountryId(indexCountry).subscribe(
            data => {
              this.valid_country = true;
              this.collectionsEstado = data.data;
              this.utilitiesService.removeControlsValidity(this.frmC2, this.address);
            }, error => {
              this.valid_country = false;
              this.utilitiesService.removeControlsValidity(this.frmC2, this.address_id);
            }
          )
    
        } else {
          this.valid_country = false;
          this.utilitiesService.removeControlsValidity(this.frmC2, this.address_id);
        }
      }
    
      handleState(evt) {
        var indexState = evt.target.value;
        var indexCountry = this.frmC2.controls['IdPais'].value;
        if (indexState != "0") {
          this.frmC2.patchValue({IdCiudad: 0, IdMunicipio: 0, IdColonia: 0, CodigoPostal: ''});
          this.colectionsService.getCitiesById(indexCountry, indexState).subscribe(
            data => {
              this.valid_country = true;
              this.collectionsCiudad = data.data;
            }, error => {
              this.valid_country = false;
            }
          )
          this.colectionsService.getMunicipalitiesById(indexCountry, indexState).subscribe(
            data => {
              this.valid_country = true;
              this.collectionsMunicipio = data.data;
            }, error => {
              this.valid_country = false;
            }
          )
    
        }
    
      }
    
      handleTown(){
        var IdPais = this.frmC2.controls['IdPais'].value ;
        var IdEstado = this.frmC2.controls['IdEstado'].value;
        var IdMunicipio = this.frmC2.controls['IdMunicipio'].value;
    
          if ( this.frmC2.controls['IdPais'].value && this.frmC2.controls['IdEstado'].value && this.frmC2.controls['IdMunicipio'].value ) {
            this.frmC2.patchValue({IdColonia: 0, CodigoPostal: ''});
            this.colectionsService.getCollectionsColoniasByPais(IdPais, IdEstado, IdMunicipio).subscribe(
              data => {
                this.collectionsColonia = data.data;
              }, error => {
                // this.valid_country = false;
              }
            )
          }
      }


    
      changeColonia(colonia){
        var index = colonia.target.selectedIndex;
        if ( colonia.target[index].getAttribute('CodigoPostal') != null ) {
          this.frmC2.patchValue({CodigoPostal: colonia.target[index].getAttribute('CodigoPostal')});
        }
      }

    createC1Form() {
        return this.fb.group({
            NombreEmpresa: [
                null
            ],
            IdSituacionLaboral: [
                Validators.required
            ],
            Puesto:[
              null
            ],
            FechaAntiguedad: [
                Validators.required
            ],
            IdOcupacion: [
                [Validators.required, Validators.min(1)]
            ],
            IdSolicitud: [
                null
            ],
            Extension: [
              Validators.required
            ],
            Telefono: [
              Validators.required
            ],
            etapa:[
                null
            ],
            paso:[
                null
            ],
            flag:[
                null
            ],
            Calle: [
              null
            ],
            NoExterior: [
              null
            ],
            NoInterior: [
              null
            ],
            IdPais: [
              Validators.required,
              Validators.min(1)
            ],
            IdColonia: [
              Validators.required,
              Validators.min(1)
            ],
            IdMunicipio: [
              Validators.required,
              Validators.min(1)
            ],
            IdCiudad: [
              Validators.required,
              Validators.min(1)
            ],
            IdEstado: [
              Validators.required,
              Validators.min(1)
            ],
            Colonia: [
              null
            ],
            Municipio: [
              null
            ],
            Ciudad: [
              null
            ],
            Estado: [
              null
            ],
            CodigoPostal: [
              null
            ],
        })
    }

    onSubmit(modalError) {
        if (!this.frmC2.valid) {
            Object.keys(this.frmC2.value).forEach(element => {
              if ( this.frmC2.get(element).errors != null ) {   
                this.frmC2.get(element).markAsDirty();
                }
            });
            return;
        }
        this.loading = true;
        var sendData = this.frmC2.value;
        sendData.FechaAntiguedad = this.utilitiesService.parseDate(sendData.FechaAntiguedad);

    if ( ! this.valid_country) {
      delete sendData["IdEstado"];
      delete sendData["IdCiudad"];
      delete sendData["IdColonia"];
      delete sendData["IdMunicipio"];
    }
    else{
      delete sendData["Estado"];
      delete sendData["Ciudad"];
      delete sendData["Colonia"];
      delete sendData["Municipio"];
    }
        this.C2Service.sendData(sendData).subscribe(
          resp => {
            this.router.navigateByUrl("aval/informacion_economica/" + this.IdSolicitud);
          },
          errors => {
            this.loading = false;
            this.errorMessages = errors.error.message;
            this.modalErrors.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
          },
        )
    }
}