import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Data, Router } from '@angular/router';
import { FormGroup, Validators ,FormControl, FormBuilder} from '@angular/forms';
import { N1CambiosEstructura } from "../../../services/promotor/N1CambioEstructura.service";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Collection } from 'src/app/models/collection.model';
import { Location } from "@angular/common";

@Component({
    selector: 'app-n1-cambio-estructura',
    templateUrl: './n1-cambio-estructura.component.html',
    styleUrls: ['./n1-cambio-estructura.component.scss']
})

export class N1CambioEstructura implements OnInit {
    public status: number;
    public titular: any;
    public loading: boolean;
    public collectionsRolesAval: Collection;
    public collectionsTipoAvales: Collection;
    public collectionsParentezco: Collection;

    public infoSolicitud: any;
    public IdSolicitud: number;
    public errorMessages: string;
    public avales: any;

    public eliminarRPP: boolean;
    public eliminarAuto: boolean;
    public cambiarTitular: boolean;
    public cambiarRolAval: any;
    public eliminarAval:any;
    public etiquetasAvales:any;
    public indexNuevoTitular:number;

    public frmN1 = new FormGroup({
        IdSolicitud: new FormControl(null),
        NoAvales:new FormControl(null),
        Titular:new FormControl(null),
        NuevoTitular:new FormControl(null),
        RolTitular:new FormControl(null),
        CambiarTitular:new FormControl(null),
        EliminarAuto:new FormControl(null),
        EliminarRPP:new FormControl(null),
        Parentesco:new FormControl(null),
    });
    
    constructor(
        public route: ActivatedRoute,
        private N1Service: N1CambiosEstructura,
        private modalError: NgbModal,
        private modalConfirm: NgbModal,
        private router: Router,
        private location:Location,
    ) {
        this.status = 2;
        this.loading = false;
    }

    ngOnInit(): void {
        this.IdSolicitud = this.route.snapshot.data.solicitudPromotor.data.IdSolicitud;
        this.avales = this.route.snapshot.data.solicitudPromotor.data.avales;
        this.titular=this.route.snapshot.data.solicitudPromotor.data.persona;
        this.route.data.subscribe( (data: Data) => {
            this.collectionsRolesAval = data['collections'][0].data.roles;
            this.collectionsTipoAvales = data['collections'][0].data.avales;
            this.infoSolicitud=data['solicitudPromotor'].data;
            this.collectionsParentezco = data['collections'][2].data;
          } );
        this.frmN1.patchValue({
            IdSolicitud:this.IdSolicitud,
            NoAvales:this.avales.length,
            Titular:this.titular.IdPersona,
            NuevoTitular:null,
            RolTitular:null,
            CambiarTitular:false,
            EliminarAuto:false,
            EliminarRPP:false,
            Parentesco:null,
        });
        this.cambiarTitular=false;
        this.eliminarAuto=false;
        this.eliminarRPP=false;
        this.indexNuevoTitular=-1;

        this.eliminarAval=[];
        this.cambiarRolAval=[];
        for (let i=1;i<=this.avales.length;i++){ 
            this.avales[i-1].rol='RolAval'+i;
            this.cambiarRolAval.push(false);
            this.eliminarAval.push(false);
            this.frmN1.addControl('Aval'+i, new FormControl(null));
            this.frmN1.addControl('RolAval'+i, new FormControl(null,[Validators.min(2)]));
            this.frmN1.addControl('EliminarAval'+i, new FormControl(null));
            this.frmN1.addControl('CambiarRolAval'+i, new FormControl(null));
            this.frmN1.controls['Aval'+i].patchValue(this.avales[i-1].IdSolicitudParticipante);
            this.frmN1.controls['RolAval'+i].patchValue(this.avales[i-1].IdTipoAval>2?this.avales[i-1].IdTipoAval:0);
            this.frmN1.controls['EliminarAval'+i].patchValue(false);
            this.frmN1.controls['CambiarRolAval'+i].patchValue(false);
        }
    }

   
   
    onSubmit(modalError,modalConfirm){
        if (!this.frmN1.valid) {
            Object.keys(this.frmN1.value).forEach(element => {
              if ( this.frmN1.get(element).errors != null ) {
                this.frmN1.get(element).markAsDirty();
              }
            });
            return;
          }
          this.modalConfirm.open(modalConfirm, { ariaLabelledBy: 'modal-basic-title', centered: true });
    }

    sendData(modalError){
        this.loading = true;
        let data = this.frmN1.value;
        this.N1Service.cambiarEstructura( data ).subscribe(
            resp => {
                this.loading = false;
                this.modalConfirm.dismissAll();
                // Redirect
                this.router.navigateByUrl("promotor/revision_solicitud/"+this.IdSolicitud);
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
                this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
        );
    }

    deleteRPP(){
        this.eliminarRPP=!this.eliminarRPP;
        this.frmN1.controls['EliminarRPP'].patchValue(this.eliminarRPP);
    }

    deleteAuto(){
        this.eliminarAuto=!this.eliminarAuto;
        this.frmN1.controls['EliminarAuto'].patchValue(this.eliminarAuto);
    }

    deleteAval(index){
        this.eliminarAval[index]=!this.eliminarAval[index];
        this.cambiarRolAval[index]=false;
        this.frmN1.controls['CambiarRolAval'+(index+1)].patchValue(false);
        this.frmN1.controls['EliminarAval'+(index+1)].patchValue(this.eliminarAval[index]);
    }

    cambiarAval(index){
        if (index!=this.indexNuevoTitular){
        this.cambiarRolAval[index]=!this.cambiarRolAval[index];
        this.eliminarAval[index]=false;
        this.frmN1.controls['EliminarAval'+(index+1)].patchValue(false);
        this.frmN1.controls['CambiarRolAval'+(index+1)].patchValue(this.cambiarRolAval[index]);
        if (this.cambiarRolAval[index]){
            this.frmN1.controls['RolAval'+(index+1)].setValidators([Validators.required,Validators.min(2)]);   
        }else{
            this.frmN1.controls['RolAval'+(index+1)].clearValidators();
            this.frmN1.get('RolAval'+(index+1)).updateValueAndValidity();
        }
        }
    }

    changeTitular(){
        this.cambiarTitular=! this.cambiarTitular;
        this.frmN1.patchValue({CambiarTitular:this.cambiarTitular,
            NuevoTitular:0,
            RolTitular:0,
            Parentesco:0})
        this.indexNuevoTitular=-1;
        if (this.cambiarTitular){
            this.frmN1.controls['NuevoTitular'].setValidators([Validators.required]);
            this.frmN1.controls['RolTitular'].setValidators([Validators.required,Validators.min(2)]);
            this.frmN1.controls['Parentesco'].setValidators([Validators.required]);
        }else{
            this.frmN1.controls['NuevoTitular'].clearValidators();
            this.frmN1.get('NuevoTitular').updateValueAndValidity();
            this.frmN1.controls['RolTitular'].clearValidators();
            this.frmN1.get('RolTitular').updateValueAndValidity();
            this.frmN1.controls['Parentesco'].clearValidators();
            this.frmN1.get('Parentesco').updateValueAndValidity();
        }
    }
    handleChangeTitular(event){
        const id=event.target.value;
        if(id>0){
            this.frmN1.controls['NuevoTitular'].patchValue(event.target.value);
            const index=this.avales.findIndex((element)=>element.IdSolicitudParticipante==id);
            this.frmN1.controls['CambiarRolAval'+(index+1)].patchValue(false);
            this.frmN1.controls['EliminarAval'+(index+1)].patchValue(false);
            this.eliminarAval[index]=false;
            this.cambiarRolAval[index]=false;
            this.indexNuevoTitular=index;
        }
    }
    back(){
        this.location.back();
    }
}