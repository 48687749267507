import { Component, OnInit, AfterViewInit } from "@angular/core";
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { FormControl, FormGroup, Validators} from '@angular/forms';
import { pipe } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { requiredFileSize } from '../../../directives/upload-file-size-validator';
import { ActivatedRoute, Router, Data } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AvalC4ConfirmacionIdentidadService } from 'src/app/services/aval/C4ConfirmacionIdentidadService.service';
import { UtilitiesAvalService } from 'src/app/services/aval/UtilitiesAvalService.service';
import { requiredMultipleFileType } from 'src/app/directives/upload-file-multiple-validator';
import { UtilitiesService } from 'src/app/services/utilities_services.service';

export function uploadProgress<T>(cb: (progress: number) => void) {
  return tap((event: HttpEvent<T>) => {
    if (event.type === HttpEventType.UploadProgress) {
      cb(Math.round((100 * event.loaded) / event.total));
    }
  });
}

export function toResponseBody<T>() {
  return pipe(
    filter((event: HttpEvent<T>) => event.type === HttpEventType.Response),
    map((res: HttpResponse<T>) => res.body)
  );
}

@Component({
  selector: 'app-c4-confirmacion-identidad-aval',
  templateUrl: './c4-confirmacion-identidad-aval.component.html',
})
export class C4ConfirmacionIdentidadComponent implements OnInit,AfterViewInit {
  public status: number;
  public IdSolicitud: any;
  public loading: boolean;
  public errorMessages: any;
  public data:any;
  public previousImage:boolean;
  frmC4 = new FormGroup({
    ConfirmacionIdentidad: new FormControl(null, [Validators.required, requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]),
    IdSolicitud: new FormControl(null),
    etapa: new FormControl(null),
    paso: new FormControl(null),
    flag: new FormControl(null),
  });

  /* permissions */
  public IdRoleAval: any;
  public formatedPermissions: any;
  public informacionPropietario: any;

  public previous_data: any;

  constructor(
    private route: ActivatedRoute,
    private C4Service: AvalC4ConfirmacionIdentidadService, 
    private router: Router,
    private modalError: NgbModal,
    private avalService: UtilitiesAvalService,
    private utilities:UtilitiesService,
  ) {
    this.status = 3;
    this.loading = false;
    this.previousImage = false;
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.IdSolicitud = this.route.snapshot.params.id;
    this.frmC4.patchValue({
      IdSolicitud:this.IdSolicitud,
      etapa: 2,
      paso:5,
      flag:1
    });

    this.route.data.subscribe((data: Data) => {
      this.IdRoleAval = data['permissions'][0].data.IdTipoRelacion;
      this.data = data['data'][0].data.confirmar_identidad;
      this.informacionPropietario = data['data'][0].data.informacion_propietario;
      this.previous_data = data['data'][0].data;
    });

    this.formatedPermissions = this.avalService.getPermissions(this.IdRoleAval);
    if ( this.formatedPermissions.indexOf('InformacionFamiliar') > -1 ) {
      if ( ! this.previous_data.has_informacion_economica ) {
        this.loading = true;
        this.router.navigateByUrl('/aval/informacion_economica/' + this.IdSolicitud);
      }
    }


    if(this.data.comprobacion_identidad !== null){
      this.previousImage = true;
    }
  }

  resetValue(id) {
    this.frmC4.controls[id].patchValue(null);
    document.querySelector("#" + id).querySelectorAll('input')[0]
    document.querySelector("#" + id).querySelectorAll('input')[0].value = null;
  }

  showEdit(){
    this.previousImage = false;
  }

  onSubmit(event: any, modalError) {
    event.preventDefault();
    if (!this.frmC4.valid) {
      Object.keys(this.frmC4.value).forEach(element => {
          if ( this.frmC4.get(element).errors != null ) {
          this.frmC4.get(element).markAsDirty();
          }
      });
      return;
    }
    this.loading = true;
    let sendData = toFormData(this.frmC4.value);
    this.C4Service.sendData(sendData).subscribe(
      resp => {
        let redirect = this.utilities.handleRedirectAval(resp['proceso']);
        this.router.navigateByUrl(redirect+"/"+this.IdSolicitud);
        //this.router.navigateByUrl("aval/documentacion/" + this.IdSolicitud);
      },
      errors => {
        this.loading = false;

        if ( errors.status == 0 ) {
          this.errorMessages = 'Error al cargar los documentos. La solicitud es muy pesada. Favor de subir documentos de menor tamaño.';
        }
        else{
          this.errorMessages = errors.error.message;
        }
        this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
      },
    )
  }

}

export function markAllAsDirty(form: FormGroup) {
  for (const control of Object.keys(form.controls)) {
    form.controls[control].markAsDirty();
  }
}

export function toFormData<T>(formValue: T) {
  const formData = new FormData();
  let total_mb = 0;
  for (const key of Object.keys(formValue)) {
    const value = formValue[key];
    if ( value !== null ) {
      if ( value instanceof File ) {
        total_mb = total_mb + (value.size/1000000);
      }
      formData.append(key, value);
    }
  }

  return formData;
}