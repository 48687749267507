import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Data, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { K1MesaControl } from 'src/app/services/promotor/K1MesaControl.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { requiredMultipleFileType } from '../../../directives/upload-file-multiple-validator';
import { requiredFileSize } from '../../../directives/upload-file-size-validator';

@Component({
    selector: 'app-k1-mesa-control',
    templateUrl: './k1-mesa-control.component.html'
})

export class K1MesaControlComponent implements OnInit {
    public status: number;
    public IdSolicitud: number;
    public errorMessages: string;
    public frmK1: FormGroup;
    public Contrato: number;
    public StatusContrato: number;
    public Anexo: number;
    public StatusAnexo: number;
    public Pagare: number;
    public StatusPagare: number;
    public Desembolso: number;
    public StatusDesembolso: number;
    public Seguro: number;
    public StatusSeguro: number;
    public SolicitudCredito: number;
    public StatusSolicitudCredito: number;
    public Prenda:any;
    public StatusPrenda:any;
    public StatusAllPrenda:boolean;
    public loading: boolean;
    public previous_ine:any;
    public hasValidacion:any;
    public hasComment:boolean;

    public role_user: number;

    /* collections */
    public currentState:any;
    public resumen: any;
    public garantes:any;

    API_URL: string;

    public canEdit: boolean;

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private K1Service: K1MesaControl,
        private modalErrors: NgbModal,
        private router: Router,
        private utilities: UtilitiesService
    ) {
        this.status = 8;
        this.API_URL = environment.API_URL.replace('api/v1/', '');
        this.canEdit = false;
    } 

    ngOnInit(): void {
        this.IdSolicitud = this.route.snapshot.params.id;
        this.route.data.subscribe((data: Data) => {
            this.currentState = data['collections'][0].data; 
            this.resumen = data['collections'][1].data;
            this.previous_ine= data['collections'][1].data.ValidacionIne; //valida si hay validacion previa
        });
        
        this.frmK1 = this.createFrmK1();
        this.frmK1.patchValue({
            IdSolicitud:this.IdSolicitud
        })

        this.StatusAllPrenda=true;
        
        if (! this.previous_ine ){
        this.frmK1.addControl("ValidacionIne", new FormControl(null,[Validators.required, requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]));
    
        }
        

        this.canEdit = this.utilities.hasPermission('Enviar tesoreria');

        let noaval=0;
        
        for (let aval of this.resumen.avales){
            noaval++;
            if (! aval.ValidacionIne){
                this.frmK1.addControl("ValidacionIneAval"+noaval , new FormControl(null,[Validators.required, requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]));
                aval.formName="ValidacionIneAval"+noaval;
                this.frmK1.addControl("solicitudParticipanteAval"+noaval , new FormControl(null));
                this.frmK1.controls['solicitudParticipanteAval'+noaval ].patchValue(aval.idSolicitudParticipante);
                }   
        }

        this.SolicitudCredito = null;
        this.StatusSolicitudCredito = 3;

        this.Prenda=[];
        this.StatusPrenda=[];
        this.garantes=[];
        
        this.currentState.revision_documentacion_legales.forEach(documento => {
            if ( documento._Contrato ) {
                this.Contrato = documento._Contrato;
                this.StatusContrato = parseInt(documento.EstatusContrato);
            }

            else if ( documento._IdAnexo ) {
                this.Anexo = documento._IdAnexo;
                this.StatusAnexo = parseInt(documento.EstatusAnexo);
            }

            else if ( documento._IdPagare ) {
                this.Pagare = documento._IdPagare;
                this.StatusPagare = parseInt(documento.EstatusPagare);
            }

            else if ( documento._IdDesembolso ) {
                this.Desembolso = documento._IdDesembolso;
                this.StatusDesembolso = parseInt(documento.EstatusDesembolso);
            }

            else if ( documento._IdSeguro ) {
                this.Seguro = documento._IdSeguro;
                this.StatusSeguro = parseInt(documento.EstatusSeguro);
            }

            else if ( documento._IdSolicitudCredito ) {
                this.SolicitudCredito = documento._IdSolicitudCredito;
                this.StatusSolicitudCredito = parseInt(documento.EstatusSolicitudCredito);
            }
            else{
                this.Prenda[parseInt(documento.numero)-1] = documento['_IdPrenda'+documento.numero];
                this.StatusPrenda[parseInt(documento.numero)-1] = parseInt(documento.estatus);
                this.garantes[parseInt(documento.numero)-1] = documento;
                if (parseInt(documento.estatus)!==3){
                    this.StatusAllPrenda=false;
                }
            }

        });

        this.role_user = parseInt( localStorage.getItem('userRole') );
        this.hasComment=false;

    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    changeDocumento(tipo_documento, event){
        let data = {IdDocumento: null, Estatus: null};
        switch (tipo_documento) {
            case 'contrato':
                data.IdDocumento = this.Contrato;
                data.Estatus = event.target.checked ? 1 : 0;
                this.StatusContrato = event.target.checked ? 3 : 0;
                break;

            case 'anexo':
                data.IdDocumento = this.Anexo;
                data.Estatus = event.target.checked ? 1 : 0;
                this.StatusAnexo = event.target.checked ? 3 : 0;
                break;

            case 'pagare':
                data.IdDocumento = this.Pagare;
                data.Estatus = event.target.checked ? 1 : 0;
                this.StatusPagare = event.target.checked ? 3 : 0;
                break;

            case 'desembolso':
                data.IdDocumento = this.Desembolso;
                data.Estatus = event.target.checked ? 1 : 0;
                this.StatusDesembolso = event.target.checked ? 3 : 0;
                break;

            case 'seguro':
                data.IdDocumento = this.Seguro;
                data.Estatus = event.target.checked ? 1 : 0;
                this.StatusSeguro = event.target.checked ? 3 : 0;
                break;

            case 'solicitudcredito':
                data.IdDocumento = this.SolicitudCredito;
                data.Estatus = event.target.checked ? 1 : 0;
                this.StatusSolicitudCredito = event.target.checked ? 3 : 0;
                break;
        }

        this.K1Service.saveDocumentoEstatus(data).subscribe(
            resp => {
            },
            errors => {
            }
        );
    }

    changePrenda(index, event){
        let data = {IdDocumento: null, Estatus: null};
        data.IdDocumento = this.Prenda[index];
        data.Estatus = event.target.checked ? 1 : 0;
        this.StatusPrenda[index] = event.target.checked ? 3 : 0;
        this.K1Service.saveDocumentoEstatus(data).subscribe(
            resp => {
                this.StatusAllPrenda=this.StatusPrenda.every((estatus)=>estatus===3)
            },
            errors => {
            }
        );
    }

    submitK1(event:any){
        event.preventDefault();
        this.loading=true
        let sendData = formatData(this.frmK1.value);
        
        //this.K1Service.sendData({IdSolicitud: this.IdSolicitud}).subscribe(
        this.K1Service.sendData(sendData).subscribe(
            resp => {
                this.loading=false
                this.router.navigateByUrl("promotor/tesoreria/"+this.IdSolicitud);
            },
            errors => {
                this.loading=false
            }
        );
    }

    
    resetValue(id,index) {
        this.frmK1.controls[id].patchValue(null);
        document.querySelector("#" + id).querySelectorAll('input')[0]
        document.querySelector("#" + id).querySelectorAll('input')[0].value = null;
        this.hasValidacion[index]=false;
    }

    createFrmK1(){
        return this.fb.group({
            IdSolicitud:[
                null
            ],
            ComentarioCambio: [
                null,
            ],

        });
    }
    showReturnPromotor(){
        this.hasComment=true;
    }
    returnPromotor(modalErrors){
        if (this.frmK1.controls['ComentarioCambio'].untouched)
        {  
            this.errorMessages = "Falta un comentario para el promotor.";
            this.modalErrors.open(modalErrors, { ariaLabelledBy: 'modal-basic-title', centered: true });
        }
        if (this.frmK1.controls['ComentarioCambio'].value.length<5)
        {  
            this.errorMessages = "El comentario es muy corto.";
            this.modalErrors.open(modalErrors, { ariaLabelledBy: 'modal-basic-title', centered: true });
        }
        this.loading = true;
        let sendData = {
            IdSolicitud: this.IdSolicitud,
            ComentarioCambio:this.frmK1.controls['ComentarioCambio'].value,
            TipoComentario:3
        };

        this.K1Service.returnPromotor(sendData).subscribe(
            resp => {
                this.router.navigateByUrl("promotor/dashboard");
                this.loading = false;
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
                this.modalErrors.open(modalErrors, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        );
    }
}
export function formatData<T>(formValue: T) {
    const formData = new FormData();
    let total_mb = 0;
    for (const key of Object.keys(formValue)) {
    const value = formValue[key];
    if ( value !== null ) {
        if ( value instanceof File ) {
        total_mb = total_mb + (value.size/1000000);
        }
        formData.append(key, value);
    }
    }
    return formData;
}
