import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { ActivatedRoute, Router, Data } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CollectionsService } from 'src/app/services/colecctions.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Collection } from 'src/app/models/collection.model';
import { CustomValidators } from 'src/app/validators/validators';
import { PromotorD5RevisionAcreditacionPreanalisis } from 'src/app/services/promotor/D5RevisionAcreditacionPreanalisis.service';

@Component({
    selector: 'app-d5-revision-acreditacion-preanalisis-edit',
    templateUrl: './d5-revision-acreditacion-preanalisis-edit.component.html'
})

export class D5RevisionAcreditacionPreanalisisEditComponent implements OnInit {
    @Input('dataParent') dataParent: any;
    @Output() eventoCancelado = new EventEmitter<any>();
    @Output() datosGuardados = new EventEmitter<any>();

    public status: number;
    public IdSolicitud: number;
    public editMode: boolean;
    public frmD5: FormGroup;
    public _prevSelectedPersonCredit: boolean;
    public requiredParentezco: boolean;
    public showRegimenConyugal: boolean;
    public loading: boolean;
    public errorMessages: string;

    public controls = ['PorcentajeOtraPersona', 'Nombre', 'ApellidoPaterno', 'ApellidoMaterno', 'FechaNacimiento', 'Email', 'Celular'];
    public controlsReset = [
        {
            name: "PorcentajeOtraPersona",
            rules: [
                Validators.compose([
                    Validators.required,
                    CustomValidators.patternValidator(
                        /\b([1-9]|[1-8][0-9]|9[0-9]|100)\b/,
                        {
                            invalidPercentage: true
                        }
                    )
                ])
            ]
        },
        {
            name: "Nombre",
            rules: [
                Validators.required
            ]
        },
        {
            name: "ApellidoPaterno",
            rules: [
                Validators.required
            ]
        },
        {
            name: "ApellidoMaterno",
            rules: [
                Validators.required
            ]
        },
        {
            name: "FechaNacimiento",
            rules: [
                Validators.required
            ]
        },
        {
            name: "Email",
            rules: [
                Validators.required,
                Validators.email
            ]
        },
        {
            name: "Celular",
            rules: [
                Validators.compose([
                    Validators.required,
                    CustomValidators.patternValidator(
                        /[0-9]{10}/,
                        {
                            invalidCellphone: true
                        }
                    )
                ])
            ]
        }
    ];

    /* Collections */
    public data: any;
    public collectionsEstadoCivil: Collection;
    public collectionsRegimenConyugal: Collection;
    public collectionsParentezco: Collection;
    public collectionsTipoVivienda: Collection;
    public requieredConyugue: boolean;

    constructor(
        private fb: FormBuilder,
        private collectionsService: CollectionsService,
        private utilitiesService: UtilitiesService,
        public route: ActivatedRoute,
        private modalErrors: NgbModal,
        private router: Router,
        private D5RevisionPreanalisisService: PromotorD5RevisionAcreditacionPreanalisis
    ) {
        this.status = 2;
        this.editMode = false;
        this.loading = false;
        this.requieredConyugue = false;

    }

    ngOnInit(): void {
        this.IdSolicitud = this.route.snapshot.params.id;
        this.data = { ...this.dataParent }
        this.frmD5 = this.createFormD5();

        this.route.data.subscribe((data: Data) => {
            this.collectionsEstadoCivil = data['collections'][1].data;
            this.collectionsRegimenConyugal = data['collections'][2].data;
            this.collectionsParentezco = data['collections'][3].data;
            this.collectionsTipoVivienda = data['collections'][4].data;
        });

        if (this.data.FechaAntiguedadVivienda) {
            var formatFechaAntiguedad = this.utilitiesService.formatDateObject(this.data.FechaAntiguedadVivienda);
            this.data.FechaAntiguedadVivienda = formatFechaAntiguedad;
        }
        if (this.data.FechaNacimiento) {
            var formatFechaNacimiento = this.utilitiesService.formatDateObject(this.data.FechaNacimiento);
            this.data.FechaNacimiento = formatFechaNacimiento;
        }
        this.data.IdSolicitud = this.IdSolicitud;
        this.data.UnicaPersona = this.data.UnicaPersona == "0" ? "0" : "1";

        if ( this.data.UnicaPersona == "1" ) {
            this._prevSelectedPersonCredit = false;
        }
        else{
            this._prevSelectedPersonCredit = true;
        }


        if (this.data.IdEstadoCivil == "1") {
            this.showRegimenConyugal = true;
            this.frmD5.controls["IdRegimenConyugal"].setValidators([
                Validators.required, Validators.min(1)
            ]);

            this.frmD5.controls["NombreConyuge"].setValidators([
                Validators.required
            ]);

            if ( this.data.IdRegimenConyugal == 2 ) {
                this.requieredConyugue = true;
            }
        } else {
            this.showRegimenConyugal = false;
            this.frmD5.controls["IdRegimenConyugal"].clearValidators();
            this.frmD5.get("IdRegimenConyugal").updateValueAndValidity();
        }
        this.data.IdSolicitud = this.IdSolicitud;

        this.frmD5.patchValue(this.data)

    }

    changeConyugue(evt){

        if ( evt.target.value == 2) {
            this.requieredConyugue = true;
        }
        else{
            this.requieredConyugue = false;
        }
    }

    createFormD5() {
        return this.fb.group(
            {
                IdSolicitud: [
                    null
                ],
                IdEstadoCivil: [
                    null
                ],
                IdRegimenConyugal: [
                    null
                ],
                NumeroDependientes: [
                    Validators.compose([
                        CustomValidators.patternValidator(
                            /^[0-9][0-9]*$/,
                            {
                                atLeastOne: true
                            }
                        )
                    ])
                ],
                IdParentescoConEstudiante: [
                    null
                ],
                IdTipoVivienda: [
                    null
                ],
                FechaAntiguedadVivienda: [
                    null
                ],
                UnicaPersona: [
                    null
                ],
                PorcentajeOtraPersona: [
                    null
                ],
                Nombre: [
                    null
                ],
                ApellidoPaterno: [
                    null
                ],
                ApellidoMaterno: [
                    null
                ],
                FechaNacimiento: [
                    null
                ],
                Celular: [
                    null
                ],
                Email: [
                    null
                ],
                NombreConyuge: [
                    null
                ],
                IngresosNetos: [
                    null
                ],
                OtrosIngresos: [
                    null
                ],
                TarjetasBancarias: [
                    null
                ],
                TarjetasComerciales: [
                    null
                ],
                CreditoHipotecario: [
                    null
                ],
                CreditoPersonal: [
                    null
                ],
                CreditoAutomotriz: [
                    null
                ],
            }
        );
    }

    handleChangeBeneficent(evt) {
        var target = evt.target;
        if (target.value == "0") {
            this._prevSelectedPersonCredit = true;
            this.nuevaValidator(this.frmD5, this.controlsReset);
        } else {
            this._prevSelectedPersonCredit = false;
            this.utilitiesService.removeControlsValidity(this.frmD5, ['PorcentajeOtraPersona', 'Nombre', 'ApellidoPaterno', 'ApellidoMaterno', 'FechaNacimiento', 'Email', 'Celular']);
            this.frmD5.patchValue({
                PorcentajeOtraPersona: null,
                Nombre: null,
                ApellidoPaterno: null,
                FechaNacimiento: null,
                Email: null,
                Celular: null
            });
        }
    }

    handleChangeEstadoCivil(evt) {
        var target = evt.target;
        if (target.value == "1") {
            this.showRegimenConyugal = true;
            this.frmD5.controls["IdRegimenConyugal"].setValidators([
                Validators.required, Validators.min(1)
            ]);
            this.frmD5.controls["NombreConyuge"].setValidators([
                Validators.required
            ]);
        } else {
            this.showRegimenConyugal = false;
            this.frmD5.controls["IdRegimenConyugal"].clearValidators();
            this.frmD5.get("IdRegimenConyugal").updateValueAndValidity();

            this.frmD5.controls["NombreConyuge"].clearValidators();
            this.frmD5.get("NombreConyuge").updateValueAndValidity();
        }
    }

    nuevaValidator(frm, arrayRules) {
        arrayRules.forEach(element => {
            frm.controls[element.name].setValidators(element.rules);
        });
    }

    parentEmitCancelEditionMode(): void {
        this.eventoCancelado.emit();
    }

    onSubmit(modalTarget) {
        if (!this.frmD5.valid) {
            Object.keys(this.frmD5.value).forEach(element => {
              if ( this.frmD5.get(element).errors != null ) {
                this.frmD5.get(element).markAsDirty();
              }
            });
            return;
        }

        var sendData = this.frmD5.value;
        this.loading = true;

        var FormatFechaAntiguedadVivienda;
        var FormatFechaNacimiento;

        if (sendData.FechaAntiguedadVivienda) {
            FormatFechaAntiguedadVivienda = this.utilitiesService.parseDate(sendData.FechaAntiguedadVivienda);
            sendData.FechaAntiguedadVivienda = FormatFechaAntiguedadVivienda;
        }
        if (sendData.FechaNacimiento) {
            FormatFechaNacimiento = this.utilitiesService.parseDate(sendData.FechaNacimiento);
            sendData.FechaNacimiento = FormatFechaNacimiento;
        }

        this.D5RevisionPreanalisisService.sendUpdate(sendData).subscribe(
            data => {
                this.loading = false;
                this.datosGuardados.emit(data)
            }, errors => {
                this.loading = false;
                var msgError = errors.error.error; 
                this.errorMessages = errors.error.message;
                this.modalErrors.open(modalTarget, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        );

    }

}