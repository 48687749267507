import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { G1LineaCreditoService } from 'src/app/services/promotor/G1LineaCreditoService.service';

@Component({
    selector: 'app-g1-linea-credito',
    templateUrl: './g1-linea-credito.component.html'
})

export class G1LineaCreditoComponent implements OnInit {
    public status: number;
    public persona: any;
    public IdSolicitud: number;
    public frmG1: FormGroup;
    public loading: boolean;
    public errorMessages: string;
    public MontoFinanciar: number;
    public Mensualidad: number;
    public Plazo: number;
    public LineaCredito: number;

    public proceso: number;

    public canDecline: boolean;

    constructor(
        public route: ActivatedRoute,
        private fb: FormBuilder,
        private modalError: NgbModal,
        private utilities: UtilitiesService,
        private G1Service: G1LineaCreditoService,
        private router: Router,
    ) {
        this.status = 5;
        this.canDecline = false;
    }

    ngOnInit(): void {
        this.loading=false;
        this.proceso = this.route.snapshot.data.solicitudPromotor.proceso;
        this.IdSolicitud = this.route.snapshot.data.solicitudPromotor.data.IdSolicitud;

        this.canDecline = this.utilities.hasPermission('Declinar Solicitud');

        if ( this.route.snapshot.data.solicitudPromotor.data.linea_credito == null ) {
            this.router.navigateByUrl('promotor/cotizacion/' + this.IdSolicitud);
        }

        this.LineaCredito = this.route.snapshot.data.solicitudPromotor.data.linea_credito.Consecutivo;
        this.persona = this.route.snapshot.data.solicitudPromotor.data.persona;
        this.MontoFinanciar = this.route.snapshot.data.solicitudPromotor.data.detallesSolicitud.MontoSolicitado;
        this.Mensualidad = this.route.snapshot.data.solicitudPromotor.data.detallesSolicitud.Mensualidades;
        this.Plazo = this.route.snapshot.data.solicitudPromotor.data.detallesSolicitud.Plazo;

        this.frmG1 = this.createF1Form();

        this.frmG1.patchValue({NoLinea: this.LineaCredito});

        if ( this.route.snapshot.data.solicitudPromotor.data.has_comentarios ) {
            let dateStart = this.utilities.formatDateObject( this.route.snapshot.data.solicitudPromotor.data.linea_credito.FechaVigencia );
            this.frmG1.patchValue({FechaVigencia: dateStart});
        }

        if ( this.route.snapshot.data.solicitudPromotor.data.linea_credito.FechaVigencia != null ) {
            let dateStart = this.utilities.formatDateObject( this.route.snapshot.data.solicitudPromotor.data.linea_credito.FechaVigencia );
            this.frmG1.patchValue({FechaVigencia: dateStart});
            this.frmG1.patchValue({
                Comentario: this.route.snapshot.data.solicitudPromotor.data.linea_credito.Comentario,
            });
        }

    }

    onSubmit(modalError){
        if (!this.frmG1.valid) {
            Object.keys(this.frmG1.value).forEach(element => {
              if ( this.frmG1.get(element).errors != null ) {
                this.frmG1.get(element).markAsDirty();
              }
            });
            return;
        }

        this.loading = true;
        this.frmG1.patchValue({IdSolicitud: this.IdSolicitud, FechaVigencia: this.utilities.parseDate( this.frmG1.value.FechaVigencia )});
        let sendData = this.frmG1.value;

        this.G1Service.sendLineaCredito( sendData ).subscribe(
            resp => {
                this.loading = false;
                this.router.navigateByUrl('promotor/dashboard');
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
                this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
                
            }
        );
    }

    createF1Form() {
        return this.fb.group({
            NoLinea: [
                null
            ],
            IdSolicitud: [
                null
            ],
            FechaVigencia: [
                null,
                Validators.required
            ],
            Comentario: [
                null,
                Validators.required
            ],
        })
    }
}