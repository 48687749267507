import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Collection } from 'src/app/models/collection.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { F1CotizacionService } from 'src/app/services/promotor/F1CotizacionService.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { K2ContratosService } from 'src/app/services/K2ContratosService.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { Location } from "@angular/common";

@Component({
    selector: 'app-h2-cambio-condiciones',
    templateUrl: './h2-cambio-condiciones.component.html'
})

export class H2CambioCondicionesComponent implements OnInit {
    public status: number;
    public IdSolicitud: number;
    public data:any;
    public persona: any;
    public collectionsPlanes: any;
    public collectionsPeriodos: Collection;
    public diferida: boolean;
    public disposiciones: boolean;
    public loading: boolean;
    public frmH2: FormGroup;
    
    public clickCotizacion: boolean;
    public errorMessages: string;
    public Mensualidad: number;
    public PagoInicial: number;
    public cotizacion: any;
    public PorcentajeTasa: any;
    public AplicaDiferida: boolean;
    public AplicaTasaEspecial: boolean;
    public Meses: any = [];
    public MaxMeses: number;
    public PlazoMin: number;
    public PlazoMax: number;
    public PlazoArray: any = [];
    public minDate: any;
    public proceso: any;
    public has_comment: boolean;
    public comment: string;

    public legales: any;
    public linea:any;
    public plan: any;
    public sendData:any;
    public garantes:any;

    constructor(
        public route: ActivatedRoute,
        private fb: FormBuilder,
        private F1Service: F1CotizacionService,
        private modalService: NgbModal,
        private K2Service: K2ContratosService,
        private utilities: UtilitiesService,
        private router: Router,
        private location:Location,
    ) {
        this.status = 7;
        this.legales=['Carta de bienvenida','Solicitud de crédito','Carátula y contrato',
        'Anexo','Pagaré','Carta de instrucción de desembolso','Seguro']
    }

    ngOnInit(): void {
        this.frmH2 = this.createF1Form();

        this.route.data.subscribe((data: Data) => {
            this.data = data['infoContrato'][0].data;
        });

        this.IdSolicitud = this.data.IdSolicitud;
        this.persona = this.data.persona;        
        this.comment = this.data.autorizacion_comite.Comentario;
        this.garantes=this.data.garanteAuto;
        this.loading = false;

        this.cotizacion = this.data.cotizacion;
        this.AplicaTasaEspecial=this.cotizacion.AplicaTasaEspecial==='1'
        this.Mensualidad = this.cotizacion.MontoPago;
        this.PagoInicial = this.cotizacion.MontoPagoInicial;
        this.PorcentajeTasa = this.cotizacion.PorcentajeTasa;
                
        this.clickCotizacion = false;

        this.linea=this.data.linea_autorizada;
        this.PlazoMin = parseInt(this.linea.PlazoMin);
        this.PlazoMax = parseInt(this.linea.PlazoMax);

        for (let index = this.PlazoMin; index <= this.PlazoMax; index++) {
            this.PlazoArray.push( index );
        }

        this.plan=this.data.plan;
        this.AplicaDiferida = parseInt(this.plan.ComisionDiferida)===1;
        this.MaxMeses = parseInt(this.plan.MaxMesesComision);
        if (this.AplicaDiferida){
            for (let index = 1; index <= this.MaxMeses; index++) {
                this.Meses.push( index );
            }
        }
        this.frmH2.controls["MontoSolicitado"].clearValidators();
        this.frmH2.controls['MontoSolicitado'].setValidators([
            Validators.compose(
                [
                Validators.required,
                Validators.min(this.plan.MontoMinimo),
                Validators.max(this.linea.MontoMaximo),
                ]
            )
        ]);
        this.frmH2.get("MontoSolicitado").updateValueAndValidity();

        this.frmH2.controls["PorcentajeComision"].clearValidators();
        this.frmH2.controls['PorcentajeComision'].setValidators([
            Validators.compose(
                [
                Validators.required,
                Validators.min(this.plan.PorcComisionMin),
                Validators.max(this.plan.PorcComisionMax),

                ]
            )
        ]);
        this.frmH2.get("PorcentajeComision").updateValueAndValidity();

        
        var date = this.utilities.formatDateObject( this.cotizacion.FechaPrimerPago );
        let fechas=[new Date(this.cotizacion.FechaPrimerPago),new Date()];
        let min=new Date(Math.min.apply(null,fechas));
        this.minDate={
            year: min.getFullYear(),
            month: min.getMonth() + 1,
            day: min.getDate()
        }
        this.diferida = this.cotizacion.EsDiferida;
        this.frmH2.patchValue({
            IdSolicitud: this.IdSolicitud,
            MontoSolicitado: this.cotizacion.MontoFinanciar, 
            IdPlan: this.cotizacion.PlanSeleccionado, 
            Plazo: this.cotizacion.Plazo, 
            PorcentajeComision: this.cotizacion.PorcentajeComision, 
            FechaInicio: date,
            EsDiferida: this.cotizacion.EsDiferida, 
            MesesDiferidos: this.cotizacion.DiferidaMeses,
            RazonCambio:''
        });
    }

    back(){
        this.location.back();
    }

    changeDiferida(event){
        var target = event.target;

        if (target.checked) {
            this.diferida = true;
            this.frmH2.controls['MesesDiferidos'].setValidators([Validators.required, Validators.min(1)]);
        } else {
            this.diferida = false;
            this.utilities.removeControlsValidity(this.frmH2, ['MesesDiferidos']);
        }
    }

    calcularCotizacion(modalConfirm){
        if (!this.frmH2.valid) {
            Object.keys(this.frmH2.value).forEach(element => {
              if ( this.frmH2.get(element).errors != null ) {
                this.frmH2.get(element).markAsDirty();
              }
            });
            return;
        }
        this.sendData=this.frmH2.value;
        this.modalService.open(modalConfirm, { ariaLabelledBy: 'modal-basic-title', centered: true });
    }

    sendChange(modalError){
        if (!this.frmH2.valid) {
            Object.keys(this.frmH2.value).forEach(element => {
              if ( this.frmH2.get(element).errors != null ) {
                this.frmH2.get(element).markAsDirty();
              }
            });
            return;
        }
        this.loading = true;
        this.frmH2.patchValue({FechaInicio: this.utilities.parseDate( this.frmH2.value.FechaInicio )});
        let sendData = this.frmH2.value;


        this.F1Service.cambiarCondiciones( sendData ).subscribe(
            resp => {
                let date = this.utilities.formatDateObject( resp['data']['FechaInicio'] );

                this.frmH2.patchValue({FechaInicio: date})
                this.Mensualidad = resp['data']['MontoPago'];
                this.PagoInicial = resp['data']['MontoPagoInicial'];
                this.PorcentajeTasa = resp['data']['PorcentajeTasa'];
                this.clickCotizacion = true;
                this.loading = false;
                this.modalService.dismissAll();
            },
            errors => {
                this.errorMessages = errors.error.message;
                this.modalService.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
                this.loading = false;
            }
        );
    }

    onSubmit(modalCorreo){
        this.modalService.open(modalCorreo, { ariaLabelledBy: 'modal-basic-title', centered: true });        
    }
    sendMail(modalError){
        this.loading = true;

        let sendData = this.frmH2.value;
        this.F1Service.sendMail( sendData ).subscribe(
            resp => {
                this.loading = false;
                this.modalService.dismissAll();
                this.router.navigateByUrl("promotor/revision_contratos/"+this.IdSolicitud);
            },
            errors => {
                this.errorMessages = errors.error.message;
                this.modalService.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
                this.loading = false;
            }
        );

    }

    downloadCotizacion(){
        this.loading = true;
        this.K2Service.downloadCotizacion(this.IdSolicitud).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Formato de cotización.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
                // this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
    }

    createF1Form() {
        return this.fb.group({
            RazonCambio: [
                null,
                Validators.required
            ],
            MontoSolicitado: [
                null
            ],
            Plazo: [
                null
            ],
            IdPlan: [
                null
            ],
            FechaInicio: [
                null
            ],
            PorcentajeComision: [
                null
            ],
            IdSolicitud: [
                null
            ],
            MesesDiferidos: [
                null
            ],
            EsDiferida: [
                null
            ]
        })
    }

    download(i){
        //this.legales=['Carta de bienvenida','Solicitud de crédito','Carátula y contrato',
        //'Anexo','Pagaré','Carta de instrucción de desembolso','Seguro']
        this.loading = true;        
        switch (i){
            case 0:
                this.K2Service.downloadCarta(this.IdSolicitud).subscribe(
                    resp => {
                        var a = <HTMLInputElement>document.getElementById('self_file');
                        this.loading = false;
                        const url = window.URL.createObjectURL(resp);
                        a.setAttribute('href', url);
                        a.setAttribute('download', 'Carta de Bienvenida.pdf');
                        a.click();
                    },
                    errors => {
                        this.loading = false;
                        this.errorMessages = errors.error.message;
                    //   this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
                    },
                )
                break;
            case 1:
                this.utilities.imprimirSolicitud2(this.IdSolicitud).subscribe(
                    resp => {
                        var a = <HTMLInputElement>document.getElementById('self_file');
                        this.loading = false;
                        const url = window.URL.createObjectURL(resp);
                        a.setAttribute('href', url);
                        a.setAttribute('download', 'Solicitud de crédito.pdf');
                        a.click();
                    },
                    errors => {
                        this.loading = false;
                        this.errorMessages = errors.error.message;
                    }
                );
                break;
            case 4:
                this.K2Service.downloadPagare(this.IdSolicitud).subscribe(
                    resp => {
                        var a = <HTMLInputElement>document.getElementById('self_file');
                        this.loading = false;
                        const url = window.URL.createObjectURL(resp);
                        a.setAttribute('href', url);
                        a.setAttribute('download', 'Pagare.pdf');
                        a.click();
                    },
                    errors => {
                        this.loading = false;
                        this.errorMessages = errors.error.message;
                        //this.modalError.open('modalError', { ariaLabelledBy: 'modal-basic-title', centered: true });
                    },
                )
                break;
            case 2:
                this.K2Service.downloadContrato(this.IdSolicitud).subscribe(
                    resp => {
                        var a = <HTMLInputElement>document.getElementById('self_file');
                        this.loading = false;
                        const url = window.URL.createObjectURL(resp);
                        a.setAttribute('href', url);
                        a.setAttribute('download', 'Contrato.pdf');
                        a.click();
                    },
                    errors => {
                        this.loading = false;
                        this.errorMessages = errors.error.message;
                        //this.modalError.open('modalError', { ariaLabelledBy: 'modal-basic-title', centered: true });
                    },
                )
                break;
            case 3:
                this.K2Service.downloadAnexo(this.IdSolicitud).subscribe(
                    resp => {
                        var a = <HTMLInputElement>document.getElementById('self_file');
                        this.loading = false;
                        const url = window.URL.createObjectURL(resp);
                        a.setAttribute('href', url);
                        a.setAttribute('download', 'Anexo.pdf');
                        a.click();
                    },
                    errors => {
                        this.loading = false;
                        this.errorMessages = errors.error.message;
                        //this.modalError.open('modalError', { ariaLabelledBy: 'modal-basic-title', centered: true });
                    },
                )
                break;
            case 5:
                this.K2Service.downloadDesembolso(this.IdSolicitud).subscribe(
                    resp => {
                        var a = <HTMLInputElement>document.getElementById('self_file');
                        this.loading = false;
                        const url = window.URL.createObjectURL(resp);
                        a.setAttribute('href', url);
                        a.setAttribute('download', 'Instrucciones de Desembolso.pdf');
                        a.click();
                    },
                    errors => {
                        this.loading = false;
                        this.errorMessages = errors.error.message;
                        //this.modalError.open('modalError', { ariaLabelledBy: 'modal-basic-title', centered: true });
                    },
                )
                break;
            case 6:
                this.loading=false;
                var a = <HTMLInputElement>document.getElementById('self_file');
                a.setAttribute('download', 'Seguro.pdf');
                a.setAttribute('target', '_blank');
                a.setAttribute('href', 'https://apifinanciamiento.edupass.mx/Seguro.pdf');
                a.click();
                break;
            default:
                this.loading=false;
                break;
        }
    
    }
    downloadPrenda(IdGarantia){
        this.loading = true;
        this.K2Service.downloadPrenda(IdGarantia).subscribe(
            resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                this.loading = false;
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Prenda_'+IdGarantia+'.pdf');
                a.click();
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
            //   this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
          )
    }
}