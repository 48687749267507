import { Component, OnInit, Input, ɵConsole } from "@angular/core";
import { MessagesService } from 'src/app/services/MessagesService.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { parse } from 'url';

@Component({
    selector: 'app-temp-messages-aval-success',
    templateUrl: './temp-messages-success.component.html',
})
export class TempMessagesSuccessAvalComponent implements OnInit {
    @Input('messages') messages: any;
    constructor(private messagesService: MessagesService, private utilities: UtilitiesService, private router: Router, private route: ActivatedRoute) {

    }

    ngOnInit() {
        
    }
}