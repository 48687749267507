import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Collection } from 'src/app/models/collection';
import { CollectionsService } from 'src/app/services/colecctions.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PromotorD4RevisionAcreditacionEmpleo } from 'src/app/services/promotor/D4RevisionAcreditacionEmpleo.service';

@Component({
    selector: 'app-d4-revision-acreditacion-empleo',
    templateUrl: './d4-revision-acreditacion-empleo.component.html'
})

export class D4RevisionAcreditacionEmpleoComponent implements OnInit, AfterViewInit {
    public status: number;
    public IdSolicitud:number;
    public editMode : boolean;
    public valid_country: boolean;
    public frm: FormGroup;
    public loading : boolean;
    public errorMessages : string;

    /* Collections */
    public data: any;
    public currentState:any;
    public collectionsOcupaciones: Collection;
    public collectionsSituacionLaboral: Collection;
    public collectionsPaises: Collection;
    public collectionsEstado:Collection;
    public collectionsCiudad:Collection;
    public collectionsMunicipio:Collection;
    public collectionsColonia: Collection;

    public motivoRechazo: Collection;

    /* Names */
    public actividad: string;
    public situacionLaboral: string;
    public Pais:string;
    public Estado:string;
    public Ciudad:string;
    public Municipio:string;
    public Colonia:string;
    public valid: boolean;

    public estadoDireccion: string;
    public ciudadDireccion: string;
    public municipioDireccion: string;
    public coloniaDireccion: string;

    public PaisDireccion: string;

    public validSituacionLaboral: boolean;
    public canEdit: boolean;
    public canDecline: boolean;

    constructor(private route: ActivatedRoute,
        private collectionsService: CollectionsService,
        private utilities: UtilitiesService,
        private fb: FormBuilder,
        private modalErrors: NgbModal,
        private D4Service : PromotorD4RevisionAcreditacionEmpleo,
        private router: Router
    ) {
        this.status = 2;
        this.IdSolicitud = this.route.snapshot.params.id;
        this.editMode = false;
        this.loading = false;
        this.validSituacionLaboral = true;
        this.canEdit = false;
        this.canDecline = false;
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: Data) => {
            this.data = data['collections'][0].data.revision_laboral;
            this.valid = data['collections'][0].data.has_revision_laboral;
            this.currentState = data['collections'][0].data;
            this.collectionsOcupaciones = data['collections'][1].data;
            this.collectionsSituacionLaboral = data['collections'][2].data;
            this.collectionsPaises = data['collections'][3].data;
        });

        this.canEdit = this.utilities.hasPermission('Edicion informacion');
        this.canDecline = this.utilities.hasPermission('Declinar Solicitud');

        if ( this.data.IdSituacionLaboral == 2 || this.data.IdSituacionLaboral == 5 || this.data.IdSituacionLaboral == 6 ) {
            this.validSituacionLaboral = false;
        }
        else{
            this.validSituacionLaboral = true;
        }

        this.route.data.subscribe((data: Data) => {
            this.motivoRechazo = data['motivoRechazo'][0].data;
        });

        this.PaisDireccion = this.data.LabelPais;
        this.estadoDireccion = this.data.LabelEstado;
        this.ciudadDireccion = this.data.LabelCiudad;
        this.municipioDireccion = this.data.LabelMunicipio;
        this.coloniaDireccion = this.data.LabelColonia;

        if ( ! this.valid )
            this.router.navigateByUrl("promotor/revision_solicitud/"+this.IdSolicitud)

        if(parseInt(this.data.IdPais) !== null){
            this.collectionsService.getStateByCountryId(this.data.IdPais).subscribe(
                data=>{
                    if (data.data.length !== 0){
                        this.collectionsEstado = data.data;
                        this.Estado = this.utilities.getFieldFromCollection(data.data, this.data.IdEstado, "name");
                        this.valid_country = true;
                    }else{
                        this.valid_country = false;
                    }
                },
                errors =>{
                    this.valid_country = false;
                }
            );
            this.collectionsService.getCitiesById(this.data.IdPais, this.data.IdEstado).subscribe(
                data=>{
                    if (data.data.length !== 0){
                        this.collectionsCiudad = data.data;
                        this.Ciudad = this.utilities.getFieldFromCollection(data.data, this.data.IdCiudad, "name");
                        this.valid_country = true;
                    }else{
                        this.valid_country = false;
                    }
                },
                errors =>{
                    this.valid_country = false;
                }
            );
            this.collectionsService.getMunicipalitiesById(this.data.IdPais,this.data.IdEstado).subscribe(
                data=>{
                    if (data.data.length !== 0){
                        this.collectionsMunicipio = data.data;
                        this.Municipio = this.utilities.getFieldFromCollection(data.data, this.data.IdMunicipio, "name");
                        this.valid_country = true;
                    }else{
                        this.valid_country = false;
                    }
                },
                errors =>{
                    this.valid_country = false;
                }
            );
            this.collectionsService.getCollectionsColoniasByPais(this.data.IdPais, this.data.IdEstado,this.data.IdMunicipio).subscribe(
                data=>{
                    if (data.data.length !== 0){
                        this.collectionsColonia = data.data;
                        this.Colonia = this.utilities.getFieldFromCollection(data.data, this.data.IdColonia, "name");
                        this.valid_country = true;
                    }else{
                        this.valid_country = false;
                    }
                    
                },
                errors =>{
                    this.valid_country = false;
                }
            );
        }
        this.actividad = this.utilities.getFieldFromCollection(this.collectionsSituacionLaboral, this.data.IdOcupacion, "name");
        this.situacionLaboral =  this.utilities.getFieldFromCollection(this.collectionsOcupaciones, this.data.IdSituacionLaboral, "name");
        this.Pais = this.utilities.getFieldFromCollection(this.collectionsPaises, this.data.IdPais, "name");

        this.frm  = this.createForm();
    }

    declinarSubmit(MotivoRechazo){
        if ( MotivoRechazo.value == '' ) {
            alert('Debe selecciona un motivo de rechazo');
            return;
        }

        let data = {MotivoRechazo: MotivoRechazo.value};
        
        this.utilities.declinarSolicitud(this.IdSolicitud, data).subscribe(
            resp => {
                this.modalErrors.dismissAll();
                this.router.navigateByUrl("promotor/dashboard");
            },
            errors => {
            }
        );
    }

    declinar(modalDeclinar){
        this.modalErrors.open(modalDeclinar, { ariaLabelledBy: 'modal-basic-title', centered: false});

        // if ( window.confirm('¿Estás seguro que deseas declinar la solicitud?') ) {
        // }
    }

    triggerEdit(){
        this.editMode = true;
    }

    recibirCancelacion($event){
        this.editMode = false;
    } 

    createForm(){
        return this.fb.group({
            confirmData:[
                null
            ]
        })
    }

    onSubmit(modalTarget){
        this.loading = true;
        this.D4Service.sendCheckedData(this.IdSolicitud).subscribe(
            response =>{
                this.loading = false;
                this.router.navigateByUrl("promotor/revision_acreditacion_preanalisis/"+this.IdSolicitud)
            }, 
            errors =>{
                this.loading = false;

                this.errorMessages = errors.error.message;
                this.modalErrors.open(modalTarget, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        );
    }

    recibirGuardado($event){
        this.editMode = false;
        this.data = $event.data;

        this.PaisDireccion = this.data.LabelPais;
        this.estadoDireccion = this.data.LabelEstado;
        this.ciudadDireccion = this.data.LabelCiudad;
        this.municipioDireccion = this.data.LabelMunicipio;
        this.coloniaDireccion = this.data.LabelColonia;

        this.actividad = this.utilities.getFieldFromCollection(this.collectionsSituacionLaboral, $event.data.IdOcupacion, "name");
        this.situacionLaboral = this.utilities.getFieldFromCollection(this.collectionsOcupaciones, $event.data.IdSituacionLaboral, "name");
        this.Pais = this.utilities.getFieldFromCollection(this.collectionsPaises, $event.data.IdPais, "name");
        if(parseInt($event.data.IdPais) !== null){
            this.collectionsService.getStateByCountryId($event.data.IdPais).subscribe(
                data=>{
                    if (data.data.length !== 0){
                        this.collectionsEstado = data.data;
                        this.Estado = this.utilities.getFieldFromCollection(data.data, $event.data.IdEstado, "name");
                        this.valid_country = true;
                    }else{
                        this.valid_country = false;
                    }
                },
                errors =>{
                    this.valid_country = false;
                }
            );
            this.collectionsService.getCitiesById($event.data.IdPais, $event.data.IdEstado).subscribe(
                data=>{
                    if (data.data.length !== 0){
                        this.collectionsCiudad = data.data;
                        this.Ciudad = this.utilities.getFieldFromCollection(data.data, $event.data.IdCiudad, "name");
                        this.valid_country = true;
                    }else{
                        this.valid_country = false;
                    }
                },
                errors =>{
                    this.valid_country = false;
                }
            );
            this.collectionsService.getMunicipalitiesById($event.data.IdPais,$event.data.IdEstado).subscribe(
                data=>{
                    if (data.data.length !== 0){
                        this.collectionsMunicipio = data.data;
                        this.Municipio = this.utilities.getFieldFromCollection(data.data, $event.data.IdMunicipio, "name");
                        this.valid_country = true;
                    }else{
                        this.valid_country = false;
                    }
                },
                errors =>{
                    this.valid_country = false;
                }
            );
            this.collectionsService.getCollectionsColoniasByPais($event.data.IdPais, $event.data.IdEstado,$event.data.IdMunicipio).subscribe(
                data=>{
                    if (data.data.length !== 0){
                        this.collectionsColonia = data.data;
                        this.Colonia = this.utilities.getFieldFromCollection(data.data, $event.data.IdColonia, "name");
                        this.valid_country = true;
                    }else{
                        this.valid_country = false;
                    }
                    
                },
                errors =>{
                    this.valid_country = false;
                }
            );
        }
    }
}