import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { Collection } from '../models/collection.model';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { B1Model } from '../models/B1.model';
import { environment } from '../../environments/environment';
import { delay } from 'q';
import { tap, catchError, mergeMap } from 'rxjs/operators';
import { UtilitiesService } from '../services/utilities_services.service';

const ERROR_STATUS = "0";
const PENDING_STATUS = "1";
const APPROVE_STATUS = "2";

@Injectable()
export class MessagesResolver implements Resolve<any>{
    public IdSolicitud: number;

    constructor(
        private route: ActivatedRoute,
        private http: HttpClient,
        private router: Router,
        private utilities: UtilitiesService
    ) {
    }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, ): Observable<any> | Promise<any> | any {
        this.IdSolicitud = parseInt(route.paramMap.get('id'));
        return this.http.get<Collection>(environment.API_URL + 'solicitud/' + this.IdSolicitud).pipe(catchError(error => {
            return [];
        }), mergeMap(resp => {
                if (resp) {
                    return of(resp)
                } else {
                    return [];
                }
            })
        )
    }
}