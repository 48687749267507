export class ADatosPersonales{
    public Nombre:string;
    public ApellidoPaterno:string;
    public ApellidoMaterno: string;
    public IdGenero:string;
    public FechaNacimiento: string;
    public Celular: string;
    public IdEnteradoPor: string;
    
    constructor( user ){
        this.Nombre = user.Nombre;
        this.ApellidoPaterno = user.ApellidoPaterno;
        this.ApellidoMaterno = user.ApellidoMaterno;
        this.IdGenero = user.IdGenero;
        this.FechaNacimiento = user.FechaNacimiento;
        this.Celular = user.Celular;
        this.IdEnteradoPor = user.IdEnteradoPor;
    }
    

    set setNombre(nombre: string){
        this.Nombre = nombre
    }
    set setApellidoPaterno(apellidoPaterno: string){
        this.ApellidoPaterno = apellidoPaterno;
    }
    set setApellidoMaterno(ApellidoMaterno: string){
        this.ApellidoMaterno = ApellidoMaterno;
    }
    set setIdGenero(IdGenero: string){
        this.IdGenero = IdGenero;
    }
    set setFechaNacimiento(FechaNacimiento: string){
        this.FechaNacimiento = FechaNacimiento;
    }
    set setCelular(Celular: string){
        this.Celular = Celular;
    }
    set setIdEnteradoPor(IdEnteradoPor: string){
        this.IdEnteradoPor = IdEnteradoPor;
    }

    
}