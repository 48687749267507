import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable, of } from 'rxjs';
import { UtilitiesService } from '../services/utilities_services.service';

@Injectable({
  providedIn: 'root'
})

export class PermissionsGuard implements CanActivate {

  constructor(
    private router: Router,
    private utilities: UtilitiesService
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    let permissions = route.data.permission.split(',');
    let canAccess = false;
    let has_permission = false;
    permissions.forEach(permission => {
      has_permission = this.utilities.hasPermission(permission);

      if ( has_permission ){
        canAccess = true;
      }
    });

    if ( canAccess ) {
      return of(true);
    }
    else{
      this.utilities.renewPermissions();
      let permission_error = 0;
      switch ( permissions[0] ) {
        case 'Revision informacion comite':
          permission_error = 1;
          break;
        
        case 'Envio y revision de contratos':
          permission_error = 2;
          break;
        
        case 'Enviar tesoreria':
          permission_error = 3;
          break;
        
        case 'Importe de pago':
          permission_error = 4;
          break;

        default:
          permission_error = 0;
          break;
      }
      this.router.navigateByUrl('promotor/permisos/' + permission_error);
      // Actualizar los permisos y guardarlos en localstorage
      // alert('No tienes permisos para acceder a esta pantalla y/o la etapa no corresponde a tu rol')
      return of(false);
    }
  }

}
