import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-permissions-messages',
    templateUrl: './permissions.component.html'
})
export class PermissionsComponent implements OnInit {

    public permission: string;
    public title: string;
    public message: string;

    public permissions: any;
    constructor(
        private route: ActivatedRoute
    ) {
        this.permission = this.route.snapshot.params.permission;

        this.permissions = {
            0: '',
            1: 'Revision informacion comite',
            2: 'Envio y revision de contratos',
            3: 'Enviar tesoreria',
            4: 'Importe de pago'
        };
    }

    ngOnInit() {
        switch ( this.permissions[ this.permission ] ) {
            case 'Envio y revision de contratos':
                this.title = 'Línea autorizada';
                this.message = 'Se ha autorizado la  línea de crédito y los contratos legales ya están generados';
                break;

            case 'Enviar tesoreria':
                this.title = 'Solicitud actualizada existosamente';
                this.message = 'Se ha enviado la solicitud a Mesa de Control';
                break;
            
            case 'Importe de pago':
                this.title = 'Solicitud actualizada existosamente';
                this.message = 'Se ha enviado la solicitud a tesorería';
                break;
        
            default:
                this.title = 'Error de permisos';
                this.message = 'No tienes permisos para acceder a esta pantalla y/o la etapa no corresponde a tu rol';
                break;
        }
    }
}
