import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Collection } from 'src/app/models/collection';
import { CollectionsService } from 'src/app/services/colecctions.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { D9GarantiaRpp } from 'src/app/services/D9GarantiaRpp.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-d9-evidencia-inmueble',
    templateUrl: './d9-evidencia-inmueble.component.html'
})

export class D9EvidenciaInmueble implements OnInit, AfterViewInit {
    public status: number;
    public IdSolicitud: number;
    public IdPersona: number;
    public persona: any;
    public SeguroRpp: string;
    public NombreArchivo: string;
    public FechaArchivo: string;
    public valid_country: boolean;
    public frmD9: FormGroup;
    public errorMessages: string;

    public collectionsTipoInmueble: Collection;
    public collectionsPaises: Collection;
    public collectionsEstados: Collection;
    public collectionsCiudades: Collection;
    public collectionsMunicipios: Collection;
    public collectionsColonias: Collection;

    public canEdit: boolean;
    public canDecline: boolean;

    public loading: boolean;

    public address_id = ['IdCiudad', 'IdEstado', 'IdMunicipio', 'IdColonia'];
    public address = ['Ciudad', 'Estado', 'Municipio', 'Colonia'];

    public previous_data: any;


    constructor(
        public route: ActivatedRoute,
        private colectionsService: CollectionsService,
        private fb: FormBuilder,
        private utilitiesService: UtilitiesService,
        private D9Service: D9GarantiaRpp,
        private modalError: NgbModal,
        private router: Router
    ) {
        this.status = 2;
        this.canEdit = false;
        this.canDecline = false;
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    ngOnInit(): void {
      this.route.params.forEach(param=>this.IdPersona=param['idPersona'])
        this.valid_country = true;
        this.IdSolicitud = this.route.snapshot.data.infoGarantia.data.IdSolicitud;
        this.persona = this.route.snapshot.data.infoGarantia.data.persona;
        this.canEdit = this.utilitiesService.hasPermission('Edicion informacion');
        this.canDecline = this.utilitiesService.hasPermission('Declinar Solicitud');

        this.SeguroRpp = this.route.snapshot.data.infoGarantia.data.E5.SeguroRpp;

        this.previous_data = this.route.snapshot.data.infoGarantia.data;

        if ( this.SeguroRpp === '' ) {
            this.router.navigateByUrl("promotor/revision_solicitud/"+this.IdSolicitud);
        }
        this.NombreArchivo = this.route.snapshot.data.infoGarantia.data.E5.NombreSeguroRpp;
        this.FechaArchivo = this.route.snapshot.data.infoGarantia.data.E5.fechaSeguroRpp;

        this.route.data.subscribe((data: Data) => {
            this.collectionsTipoInmueble = data['collections'][0].data;
            this.collectionsPaises = data['collections'][1].data;
        });

        this.frmD9 = this.createD9Form();

        if ( this.previous_data.data_garantia_rpp != null ) {
            let garantia = this.previous_data.data_garantia_rpp;
            var FormatFechaEscritura = this.utilitiesService.formatDateObject(garantia.FechaEscritura);
            var FormatFechaInscripcion = this.utilitiesService.formatDateObject(garantia.FechaInscripcion);

            this.frmD9.patchValue({
                IdTipoInmueble: garantia.IdTipoInmueble,
                ValorEstimadoActual: garantia.ValorEstimadoActual,
                ValorCompra: garantia.ValorCompra,
                NumeroEscritura: garantia.NumeroEscritura,
                FechaEscritura: FormatFechaEscritura,
                FolioRpp: garantia.FolioRPP,
                FechaInscripcion: FormatFechaInscripcion,
                Libro:garantia.Libro,
                Volumen:garantia.Volumen,
                PartidaOSeccion:garantia.PartidaOSeccion,
                FolioElectronico:garantia.FolioElectronico,
                Calle: garantia.Calle,
                NumeroExterior: garantia.NumeroExterior,
                NumeroInterior: garantia.NumeroInterior,
                CodigoPostal: garantia.CodigoPostal,
                IdPais: garantia.IdPais
                
            });

            if ( garantia.IdPais == 1 ) {
                this.valid_country = true;
                this.colectionsService.getStateByCountryId(1).subscribe(
                    data => {
                        this.collectionsEstados = data.data;
                    }
                )

                this.colectionsService.getCitiesById(garantia.IdPais, garantia.IdEstado).subscribe(
                    data => {
                      this.collectionsCiudades = data.data;
                    }
                  )
                  this.colectionsService.getMunicipalitiesById(garantia.IdPais, garantia.IdEstado).subscribe(
                    data => {
                      this.collectionsMunicipios = data.data;
                    }
                  )
        
                  this.colectionsService.getCollectionsColoniasByPais(garantia.IdPais, garantia.IdEstado, garantia.IdMunicipio).subscribe(
                    data => {
                      this.collectionsColonias = data.data;
                    }, error => {
                      // this.valid_country = false;
                    }
                  )

                  this.frmD9.patchValue({
                      IdEstado: garantia.IdEstado,
                      IdMunicipio: garantia.IdMunicipio,
                      IdCiudad: garantia.IdCiudad,
                      IdColonia: garantia.IdColonia
                  });

                  this.valid_country = true;

                this.utilitiesService.removeControlsValidity(this.frmD9, this.address);
                this.address_id.forEach(element => {
                    this.frmD9.get(element).setValidators(Validators.min(1));
                });
            }
            else{
                this.valid_country = false;
                this.utilitiesService.removeControlsValidity(this.frmD9, this.address_id);
                this.address.forEach(element => {
                    this.frmD9.get(element).setValidators(Validators.required);
                });

                this.frmD9.patchValue({
                    Estado: garantia.Estado,
                    Municipio: garantia.Municipio,
                    Ciudad: garantia.Ciudad,
                    Colonia: garantia.Colonia
                });
            }
        }
        else{
            this.frmD9.patchValue({
                IdTipoInmueble: "",
                ValorEstimadoActual: "",
                ValorCompra: '',
                NumeroEscritura: "",
                FechaEscritura: "",
                FolioRpp: "",
                FechaInscripcion: "",
                Libro:'',
                Volumen:'',
                PartidaOSeccion:'',
                FolioElectronico:'',
                Calle: "",
                NumeroExterior: "",
                NumeroInterior: "",
                CodigoPostal: null,
                
            });
            this.frmD9.patchValue({IdPais: 1});

            this.valid_country = true;
            this.colectionsService.getStateByCountryId(1).subscribe(
                data => {
                    this.collectionsEstados = data.data;
                }
            )

            this.utilitiesService.removeControlsValidity(this.frmD9, this.address);
              this.address_id.forEach(element => {
                this.frmD9.get(element).setValidators(Validators.min(1));
              });
        }
    }

    handleCountry(evt) {
        var index = evt.target.selectedIndex;
        var textCountry = evt.target[index].text;
        var indexCountry = evt.target.value;
        if (indexCountry === "1" && textCountry === "MEXICO") {
    
          this.colectionsService.getStateByCountryId(indexCountry).subscribe(
            data => {
              this.valid_country = true;
              this.collectionsEstados = data.data;
              this.utilitiesService.removeControlsValidity(this.frmD9, this.address);
              this.address_id.forEach(element => {
                this.frmD9.get(element).setValidators(Validators.min(1));
              });
              this.frmD9.patchValue({CodigoPostal: ''});
            }, error => {
              this.valid_country = false;
              this.utilitiesService.removeControlsValidity(this.frmD9, this.address_id);
            }
          )
    
        } else {
          this.valid_country = false;
          this.utilitiesService.removeControlsValidity(this.frmD9, this.address_id);
        }
      }
    
      handleState(evt) {
        var indexState = evt.target.value;
        var indexCountry = this.frmD9.controls['IdPais'].value;
        this.frmD9.patchValue({IdCiudad: 0, IdMunicipio: 0, IdColonia: 0, CodigoPostal: ''});
        if (indexState !== "0") {
          this.colectionsService.getCitiesById(indexCountry, indexState).subscribe(
            data => {
              this.valid_country = true;
              this.collectionsCiudades = data.data;
            }, error => {
              this.valid_country = false;
            }
          )
          this.colectionsService.getMunicipalitiesById(indexCountry, indexState).subscribe(
            data => {
              this.valid_country = true;
              this.collectionsMunicipios = data.data;
            }, error => {
              this.valid_country = false;
            }
          )
    
        }
    
      }
    
      handleTown() {
        var IdPais = this.frmD9.controls['IdPais'].value;
        var IdEstado = this.frmD9.controls['IdEstado'].value;
        var IdMunicipio = this.frmD9.controls['IdMunicipio'].value;
    
        if (this.frmD9.controls['IdPais'].value && this.frmD9.controls['IdEstado'].value && this.frmD9.controls['IdMunicipio'].value) {
          this.frmD9.patchValue({IdColonia: 0, CodigoPostal: ''});
          this.colectionsService.getCollectionsColoniasByPais(IdPais, IdEstado, IdMunicipio).subscribe(
            data => {
              this.collectionsColonias = data.data;
            }, error => {
              // this.valid_country = false;
            }
          )
        }
      }
    
      changeColonia(colonia){
        var index = colonia.target.selectedIndex;
        if ( colonia.target[index].getAttribute('CodigoPostal') != null ) {
          this.frmD9.patchValue({CodigoPostal: colonia.target[index].getAttribute('CodigoPostal')});
        }
      }

    onlyDigits(event){
        if (event.which != 8 && event.which != 0 && event.which < 48 || event.which > 57)
        {
            event.preventDefault();
        }
    }

    onSubmit(modalError){
        if (!this.frmD9.valid) {
            Object.keys(this.frmD9.value).forEach(element => {
              if ( this.frmD9.get(element).errors != null ) {
                this.frmD9.get(element).markAsDirty();
              }
            });
            return;
        }

        this.loading = true;
        this.frmD9.patchValue({IdSolicitud: this.IdSolicitud, IdPersona:this.IdPersona});
        let sendData = this.frmD9.value;

        this.D9Service.sendGarantiaRpp(sendData).subscribe(
            resp => {
                this.loading = false;
                this.router.navigateByUrl("promotor/revision_solicitud/"+this.IdSolicitud);
            },
            errors => {
                this.errorMessages = errors.error.message;
                this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
                this.loading = false;
            }
        );

    }

    createD9Form(){
        return this.fb.group({
            IdSolicitud: [
                null
            ],
            IdPersona: [
              null
            ],
            IdTipoInmueble: [
                null,
                [Validators .required, Validators.min(1)],
            ],
            ValorEstimadoActual: [
                null,
                Validators.required,
            ],
            NumeroEscritura: [
                null,
                Validators.required,
            ],
            FechaEscritura: [
                null,
                Validators.required,
            ],
            FolioRpp: [
                null,
                Validators.required,
            ],
            FechaInscripcion: [
                null,
                Validators.required,
            ],
            Libro: [
              null,
            ],
            Volumen: [
              null,
            ],
            PartidaOSeccion: [
              null,
            ],
            FolioElectronico: [
              null,
            ],
            ValorCompra: [
                null,
                Validators.required,
            ],
            Calle: [
                null,
                Validators.required,
            ],
            NumeroExterior: [
                null,
                Validators.required,
            ],
            NumeroInterior: [
                null,
            ],
            IdColonia: [
                null,
            ],
            Colonia: [
                null
            ],
            IdEstado: [
                null
            ],
            Estado: [
                null
            ],
            IdMunicipio: [
                null
            ],
            Municipio: [
                null
            ],
            IdCiudad: [
                null
            ],
            Ciudad: [
                null
            ],
            CodigoPostal: [
                null,
                Validators.required,
            ],
            IdPais: [
                null,
                [Validators.required, Validators.min(1)]
            ]
        });
    }
}