import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Collection } from 'src/app/models/collection';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { E5Service } from "../../../services/E5DocumentacionService.service";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { requiredMultipleFileType } from '../../../directives/upload-file-multiple-validator';
import { requiredFileSize } from '../../../directives/upload-file-size-validator';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { UtilitiesAvalService } from 'src/app/services/aval/UtilitiesAvalService.service';

@Component({
  selector: 'app-c5-documentacion-aval',
  templateUrl: './c5-documentacion.component.html'
})
export class C5DocumentacionAvalComponent implements OnInit,AfterViewInit {
  public status: number;
  public collectionsIdentificacion: Collection;
  public collectionsIdentificacion2: Collection;
  public collectionsComprobanteDomicilio: Collection;
  public collectionsComprobanteIngresos: Collection;
  public collectionsCedula: Collection;
  public IdSolicitud: any;
  public loading: boolean;
  public errorMessages: string;
  public GarantiaAuto: boolean;
  public GarantiaRPP: boolean;
  public TipoIdentificacionVista:any;
  public NoComprobantes:number;
  public index:any;
  public data = { ConfirmacionD: null, IdSolicitud: null };

  frmE5 = new FormGroup({
    TipoIdentificacion: new FormControl(Validators.required),
    IdentificacionVigente: new FormControl(null, [requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]),
    IdentificacionVigente2: new FormControl(null, [requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]),
    TipoComprobante: new FormControl(Validators.required),
    ComprobanteDomicilio: new FormControl(null, [requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]),
    GrantiaAuto: new FormControl(null, [requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]),
    EvidenciaInmueble: new FormControl(null, [requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]),
    IdSolicitud: new FormControl(null),
    etapa: new FormControl(null),
    paso: new FormControl(null),
    flag: new FormControl(null),
    ConfirmacionD: new FormControl(null),
  });

  /* permissions */
  public IdRoleAval: any;
  public formatedPermissions: any;
  public filesPermissions: any;
  public informacionPropietario: any;

  public documentsRejected: any;

  public rejectedStatus = {
    RejectedIdentificacion: false,
    RejectedIdentificacion2: false,
    RejectedComprobante: false,
    RejectedGrantiaAuto: false,
    RejectedEvidenciaInmueble: false,
  };

  public rejectedMessages = {
    Identificacion: false,
    Identificacion2: false,
    Comprobante: false,
    GrantiaAuto: false,
    EvidenciaInmueble: false,
  };

  public previous_data: any;

  constructor(
    private E5Service: E5Service,
    private route: ActivatedRoute,
    private router: Router,
    private modalError: NgbModal,
    private utilities: UtilitiesService,
    private avalService: UtilitiesAvalService
  ) {
    this.status = 3;
    this.loading = false;
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.IdSolicitud = this.route.snapshot.params.id;
    this.data.ConfirmacionD = false;
    this.frmE5.patchValue({
      IdSolicitud: this.IdSolicitud,
      etapa: 2,
      paso:5,
      flag:2
    })
    this.route.data.subscribe((data: Data) => {
      this.collectionsIdentificacion = data['collections'][0].data;
      this.collectionsComprobanteDomicilio = data['collections'][1].data;
      this.collectionsComprobanteIngresos = data['collections'][2].data;
      this.collectionsCedula = data['collections'][3].data;
      this.GarantiaAuto = data['collections'][4].data.GarantiaAuto;
      this.GarantiaRPP = data['collections'][4].data.GarantiaRPP;
      this.IdRoleAval = data['permissions'][0].data.IdTipoRelacion;
      this.informacionPropietario = data['data'][0].data.informacion_propietario;
      this.documentsRejected = data['data'][0].data.documentos_rechazados;
      this.NoComprobantes=data['data'][0].data.NoComprobantes;
      this.previous_data = data['data'][0].data;
    });

    this.formatedPermissions = this.avalService.getPermissions(this.IdRoleAval);


    if ( ! this.previous_data['has_confirmar_identidad'] ) {
      if ( this.formatedPermissions.indexOf('ComprobacionIdentidad') < 0 ) {
      }
      else{
        this.loading = true;
        this.router.navigateByUrl('/aval/confirmacion_identidad/' + this.IdSolicitud);
      }
    }

    this.filesPermissions = this.avalService.getFiles(this.IdRoleAval);

    this.index=[];

    if(this.filesPermissions.indexOf('ComprobanteIngresos')>-1 || this.documentsRejected.length>0){
      for (let i=1;i<=this.NoComprobantes;i++){
        this.index.push({mes:i,tipo:'TipoComprobanteMes'+i,comprobante:'ComprobanteIngresosMes'+i,rejected:'RejectedComprobanteMes'+i});
        this.frmE5.addControl('TipoComprobanteMes'+i, new FormControl(null, [Validators.min(1)]));
        this.frmE5.addControl('ComprobanteIngresosMes'+i, new FormControl(null, [requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]));
        this.rejectedStatus['RejectedComprobanteMes'+i]= false
        this.rejectedMessages['ComprobanteMes'+i]=''
      }
    }

    if (this.documentsRejected.length > 0){
      let mes=0;
      for (let index = 0; index < this.documentsRejected.length; index++) {
        const element = this.documentsRejected[index];
        switch (element.nombreform){
          case "IdentificacionVigente":
            this.rejectedStatus.RejectedIdentificacion = true;
            this.rejectedMessages.Identificacion = element.comentario;
          break;
          case "IdentificacionVigente2":
            this.rejectedStatus.RejectedIdentificacion2 = true;
            this.rejectedMessages.Identificacion2 = element.comentario;

          break;
          case "ComprobanteDomicilio":
            this.rejectedStatus.RejectedComprobante = true;
            this.rejectedMessages.Comprobante = element.comentario;
          break;
          case "GrantiaAuto":
            this.rejectedStatus.RejectedGrantiaAuto = true;
            this.rejectedMessages.GrantiaAuto = element.comentario;
          break;
          case "EvidenciaInmueble":
            this.rejectedStatus.RejectedEvidenciaInmueble = true;
            this.rejectedMessages.EvidenciaInmueble = element.comentario;
          break;
          default:
            if(element.nombreform.includes("ComprobanteIngresos")){
              mes=element.nombreform.slice(22);
              this.rejectedStatus['RejectedComprobanteMes'+mes] = true;
              this.rejectedMessages['ComprobanteMes'+mes] = element.comentario;
            }
          break;

        }
      }
    }else{
      this.rejectedStatus.RejectedIdentificacion =  true;
      this.rejectedStatus.RejectedIdentificacion2 =  true;
      this.rejectedStatus.RejectedComprobante =  true;
      this.rejectedStatus.RejectedGrantiaAuto =  true;
      this.rejectedStatus.RejectedEvidenciaInmueble =  true;
      if( this.filesPermissions.indexOf('ComprobanteIngresos')>-1){
      for (let i=1;i<=this.NoComprobantes;i++){
        this.rejectedStatus['RejectedComprobanteMes'+i]=true;
      }
    }
    }

    if ( this.filesPermissions.indexOf('Identificacion') > -1 && this.rejectedStatus.RejectedIdentificacion) {
      this.frmE5.patchValue({TipoIdentificacion: 0});
      this.frmE5.get('IdentificacionVigente').setValidators([Validators.required,requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]);
      this.frmE5.get('TipoIdentificacion').setValidators(Validators.min(1));
      this.frmE5.get('IdentificacionVigente2').setValidators([Validators.required,requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]);
    }

    if ( this.filesPermissions.indexOf('ComprobanteDomicilio') > -1 && this.rejectedStatus.RejectedComprobante) {
      this.frmE5.patchValue({TipoComprobante: 0});
      this.frmE5.get('ComprobanteDomicilio').setValidators([Validators.required,requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]);
      this.frmE5.get('TipoComprobante').setValidators(Validators.min(1));
    }

    if ( this.filesPermissions.indexOf('ComprobanteIngresos') > -1) {
      for (let i=1;i<=this.NoComprobantes;i++){
        if (this.rejectedStatus['RejectedComprobanteMes'+i]){
          this.frmE5.controls['TipoComprobanteMes'+i].patchValue(0);
          this.frmE5.get('ComprobanteIngresosMes'+i).setValidators([Validators.required,requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]);
          this.frmE5.get('TipoComprobanteMes'+i).setValidators([Validators.required,Validators.min(1)]);
        }
      }
    }

    if ( this.filesPermissions.indexOf('GarantiaAuto') > -1 && this.rejectedStatus.RejectedGrantiaAuto) {
      this.frmE5.get('GrantiaAuto').setValidators([Validators.required,requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]);
    }

    if ( this.filesPermissions.indexOf('GarantiaRPP') > -1 && this.rejectedStatus.RejectedEvidenciaInmueble ) {
      this.frmE5.get('EvidenciaInmueble').setValidators([Validators.required,requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]);
    }
  }

  redirect(){
    this.router.navigateByUrl("client/dashboard");
    this.modalError.dismissAll();
  }

  onSubmit(event: any, modalError, modalSuccess) {
    if (!this.frmE5.valid) {
      Object.keys(this.frmE5.value).forEach(element => {
        if ( this.frmE5.get(element).errors != null ) {
          this.frmE5.get(element).markAsDirty();
          }
      });
      return;
    }
    event.preventDefault();
    this.loading = true;
    let sendData = toFormData(this.frmE5.value);
    this.E5Service.sendAvalDoc(sendData).subscribe(
      resp => {
        this.loading = false;
        this.modalError.open(modalSuccess, { ariaLabelledBy: 'modal-basic-title', centered: true });
      },
      errors => {
        this.loading = false;

        if ( errors.status == 0 ) {
          this.errorMessages = 'Error al cargar los documentos. La solicitud es muy pesada. Favor de subir documentos de menor tamaño.';
        }
        else{
          this.errorMessages = errors.error.message;
        }

        this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
      },
    )
  }

  resetValue(id) {
    this.frmE5.controls[id].patchValue(null);
    document.querySelector("#" + id).querySelectorAll('input')[0]
    document.querySelector("#" + id).querySelectorAll('input')[0].value = null;
  }
  handleTipo(event){
    this.TipoIdentificacionVista=!(event.target.value=='2' || event.target.value=='0')?true:false;
    if (! this.TipoIdentificacionVista){
      //this.utilities.removeControlsValidity(this.frmE5, ['IdentificacionVigente2']);
      this.frmE5.controls["IdentificacionVigente2"].clearValidators();
      this.frmE5.get("IdentificacionVigente2").updateValueAndValidity();
    }else{
      this.frmE5.controls["IdentificacionVigente2"].setValidators(
        [Validators.required, requiredMultipleFileType(['jpg','pdf','png']), requiredFileSize()]);
    }
}
}


export function markAllAsDirty(form: FormGroup) {
  for (const control of Object.keys(form.controls)) {
    form.controls[control].markAsDirty();
  }
}

export function toFormData<T>(formValue: T) {
  const formData = new FormData();
  let total_mb = 0;
  for (const key of Object.keys(formValue)) {
    const value = formValue[key];

    if ( value !== null ) {
      if ( value instanceof File ) {
        total_mb = total_mb + (value.size/1000000);
      }
      formData.append(key, value);
    }
  }

  return formData;
}
