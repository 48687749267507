import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin,of,throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Collection } from 'src/app/models/collection.model';
import { UtilitiesService } from 'src/app/services/utilities_services.service';

@Injectable({
    providedIn: 'root'
})
export class G1LineaCreditoResolver implements Resolve<Collection>{
    private Object = {};
    public IdSolicitud: number;

    constructor (private utilities: UtilitiesService){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<any>{   
        this.IdSolicitud = parseInt(route.paramMap.get('id'));
        let test = forkJoin(
            this.utilities.getQuery('get', 'promotor/lineaCredito/' + this.IdSolicitud, ''),
        );

        return test;
    }
}