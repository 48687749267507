import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-a-preguntas-frecuentes',
  templateUrl: './a-preguntas-frecuentes.component.html'
})
export class APreguntasFrecuentes implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
