import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { Collection } from 'src/app/models/collection.model';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { M4PromotorInformacionLaboralService } from 'src/app/services/promotor/M4PromotorInformacionLaboralService.service';
import { CustomValidators } from 'src/app/validators/validators';
import { M5PromotorInformacionEconomicaService } from 'src/app/services/promotor/M5PromotorInformacionEconomicaService.service';

@Component({
    selector: 'app-m5-promotor-aval-informacion-economica-edit',
    templateUrl: './m5-promotor-aval-informacion-economica-edit.component.html',
})

export class M5PromotorAvalInformacionEconomicaEditComponent implements OnInit, AfterViewInit {
    @Input('dataParent') dataParent: any;
    @Output() eventoCancelado = new EventEmitter<any>();
    @Output() datosGuardados = new EventEmitter<any>();
    public data: any;
    public errorMessages
    public status: number;
    public frmM5: FormGroup;
    public IdSolicitud: number;
    public IdPersona: number;
    public tipoAval: number;
    public loading: boolean;
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private M5Service: M5PromotorInformacionEconomicaService,
        private modalErrors: NgbModal,
        private fb: FormBuilder,
        private utilities: UtilitiesService
    ) {

    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    ngOnInit() {
        this.loading = false;
        this.IdSolicitud = this.route.snapshot.params.id_solicitud;
        this.IdPersona = this.route.snapshot.params.id_persona;
        this.tipoAval = this.route.snapshot.params.tipo_aval;
        this.data = {...this.dataParent}
        this.frmM5 = this.createM5Form();
        this.frmM5.patchValue(this.data);
        
        this.frmM5.patchValue({
            IdSolicitud: this.IdSolicitud,
            IdPersona: this.IdPersona,
        });
    }

    onSubmit(modalError) {
        if (!this.frmM5.valid) {
            Object.keys(this.frmM5.value).forEach(element => {
              if ( this.frmM5.get(element).errors != null ) {
                this.frmM5.get(element).markAsDirty();
              }
            });
            return;
        }

        this.loading = true;
        this.M5Service.sendInformacionEconomica(this.frmM5.value).subscribe(
            resp => {
                this.datosGuardados.emit(resp);
                this.loading = false;
            },
            errors => {
                this.loading = false;
                this.errorMessages = errors.error.message;
                this.modalErrors.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
            },
        );
    }

    createM5Form(): FormGroup {
        return this.fb.group(
            {
                IngresosNetos: [
                    Validators.compose([
                        Validators.required,
                        CustomValidators.patternValidator(
                            / ^[0-9][0-9]*$/,
                            {
                                atLeastZero: true
                            }
                        )
                    ])
                ],
                OtrosIngresos: [
                    Validators.compose([
                        Validators.required,
                        CustomValidators.patternValidator(
                            / ^[0-9][0-9]*$/,
                            {
                                atLeastZero: true
                            }
                        )
                    ])
                ],
                TarjetasBancarias: [
                    Validators.compose([
                        Validators.required,
                        CustomValidators.patternValidator(
                            / ^[0-9][0-9]*$/,
                            {
                                atLeastZero: true
                            }
                        )
                    ])
                ],
                TarjetasComerciales: [
                    Validators.compose([
                        Validators.required,
                        CustomValidators.patternValidator(
                            / ^[0-9][0-9]*$/,
                            {
                                atLeastZero: true
                            }
                        )
                    ])
                ],
                CreditoHipotecario: [
                    Validators.compose([
                        Validators.required,
                        CustomValidators.patternValidator(
                            / ^[0-9][0-9]*$/,
                            {
                                atLeastZero: true
                            }
                        )
                    ])
                ],
                CreditoPersonal: [
                    Validators.compose([
                        Validators.required,
                        CustomValidators.patternValidator(
                            / ^[0-9][0-9]*$/,
                            {
                                atLeastZero: true
                            }
                        )
                    ])
                ],
                CreditoAutomotriz: [
                    Validators.compose([
                        Validators.required,
                        CustomValidators.patternValidator(
                            / ^[0-9][0-9]*$/,
                            {
                                atLeastZero: true
                            }
                        )
                    ])
                ],
                IdSolicitud: [
                    null
                ],
                IdPersona: [
                    null
                ]
            },
        );
    }


    parentEmitCancelEditionMode(): void {
        this.eventoCancelado.emit();
    }

    

}