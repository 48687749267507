import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { NgbDateStruct, NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/validators/validators';
import { UtilitiesService } from 'src/app/services/utilities_services.service';

import { C13InformacionEconomica } from 'src/app/models/C13InformacionEconomica.model';
import { AvalC3InformacionEconomica } from 'src/app/services/aval/C3InformacionEconomicaService.service';

@Component({
  selector: 'app-c3-informacion-economica-aval',
  templateUrl: './c3-informacion-economica-aval.component.html'
})
export class C3InformacionEconomicaComponent implements OnInit, AfterViewInit {
  public data: any;
  public errorMessages
  public status: number;
  public frmC3: FormGroup;
  public IdSolicitud;
  public loading: boolean;
  public informacionPropietario: any;
  public previous_data: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private C3Service: AvalC3InformacionEconomica,
    private modalErrors: NgbModal,
    private fb: FormBuilder,
    private utilities: UtilitiesService
  ) {
    
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    this.loading = false;
    this.IdSolicitud = this.route.snapshot.params.id;
    this.frmC3 = this.createC3Form();
    this.route.data.subscribe((data: Data) => {
      this.data = data['data'][0].data.informacion_economica;
      this.informacionPropietario = data['data'][0].data.informacion_propietario;
      this.previous_data = data['data'][0].data;
    });
    if ( ! this.previous_data.has_informacion_laboral ) {
      this.loading = true;
      this.router.navigateByUrl('/aval/informacion_laboral/' + this.IdSolicitud);
    }

    if(this.data){
      this.frmC3.patchValue(this.data);
      this.frmC3.patchValue({
        IdSolicitud: this.IdSolicitud,
      });
    }else{
      this.frmC3.patchValue({
        IngresosNetos: 0,
        OtrosIngresos: 0,
        TarjetasBancarias: 0,
        TarjetasComerciales: 0,
        CreditoHipotecario: 0,
        CreditoPersonal: 0,
        CreditoAutomotriz: 0,
        IdSolicitud: this.IdSolicitud,
      });
    }
    
    this.frmC3.patchValue({
      etapa: 2,
      paso: 4,
      flag: 1
    });
  }

  onSubmit(modalError) {
    if (!this.frmC3.valid) {
      Object.keys(this.frmC3.value).forEach(element => {
          if ( this.frmC3.get(element).errors != null ) {
          this.frmC3.get(element).markAsDirty();
          }
      });
      return;
    }
    this.loading = true;
    this.C3Service.sendData(this.frmC3.value).subscribe(
      resp => {
        let redirect = this.utilities.handleRedirectAval(resp['proceso']);
        this.router.navigateByUrl(redirect+"/"+this.IdSolicitud);
        //this.router.navigateByUrl("aval/confirmacion_identidad/" + this.IdSolicitud);
      },
      errors => {
        this.loading = false;
        this.errorMessages = errors.error.message;
        this.modalErrors.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
      },
    );
  }

  createC3Form(): FormGroup {
    return this.fb.group(
      {
        IngresosNetos: [
          Validators.compose([
            Validators.required,
            CustomValidators.patternValidator(
              / ^[0-9][0-9]*$/,
              {
                atLeastZero: true
              }
            )
          ])
        ],
        OtrosIngresos: [
          Validators.compose([
            Validators.required,
            CustomValidators.patternValidator(
              / ^[0-9][0-9]*$/,
              {
                atLeastZero: true
              }
            )
          ])
        ],
        TarjetasBancarias: [
          Validators.compose([
            Validators.required,
            CustomValidators.patternValidator(
              / ^[0-9][0-9]*$/,
              {
                atLeastZero: true
              }
            )
          ])
        ],
        TarjetasComerciales: [
          Validators.compose([
            Validators.required,
            CustomValidators.patternValidator(
              / ^[0-9][0-9]*$/,
              {
                atLeastZero: true
              }
            )
          ])
        ],
        CreditoHipotecario: [
          Validators.compose([
            Validators.required,
            CustomValidators.patternValidator(
              / ^[0-9][0-9]*$/,
              {
                atLeastZero: true
              }
            )
          ])
        ],
        CreditoPersonal: [
          Validators.compose([
            Validators.required,
            CustomValidators.patternValidator(
              / ^[0-9][0-9]*$/,
              {
                atLeastZero: true
              }
            )
          ])
        ],
        CreditoAutomotriz: [
          Validators.compose([
            Validators.required,
            CustomValidators.patternValidator(
              / ^[0-9][0-9]*$/,
              {
                atLeastZero: true
              }
            )
          ])
        ],
        IdSolicitud: [
          null
        ],
        etapa: [
          null
        ],
        paso: [
          null
        ],
        flag: [
          null
        ],
      },
    );
  }

  

}