import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { Collection } from 'src/app/models/collection';
import { C12InformacionLaboral } from '../../../models/C12InformacionLaboral.model';
import { C12InformacionLaboralService } from "../../../services/C12InformacionLaboralService.service";
import { NgbDateStruct, NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtilitiesService } from 'src/app/services/utilities_services.service';

@Component({
    selector: 'app-c2-informacion-laboral',
    templateUrl: './c2-informacion-laboral.component.html',
    styleUrls: ['./c2-informacion-laboral.component.scss']
})
  export class C2InformacionLaboralComponent implements OnInit,AfterViewInit {
    public _prevSelectedPersonCredit: boolean;

    public collectionsSituacionLaboral: Collection;
    public collectionsOcupaciones: Collection;
    public data: C12InformacionLaboral;
    public errorMessages: string;
    public status: number;
    public frmC2: FormGroup;
    public IdSolicitud: number;
    public previous_data: any;
    public loading: boolean;
    public errorFecha: boolean;
    public showData: boolean;
    public dataLaboral = ['NombreEmpresa', 'IdOcupacion', 'FechaAntiguedad', 'Puesto'];

    constructor(
      private router: Router,
      private route: ActivatedRoute,
      private C2Service: C12InformacionLaboralService,
      private modalErrors : NgbModal,
      private fb: FormBuilder,
      private utilitiesService: UtilitiesService
    ) { 
        this.status = 2;
        this.loading = false;
        this.errorFecha = false;
        this.showData = true;
    }

    ngAfterViewInit(): void {
      window.scrollTo(0, 0);
    }

    ngOnInit() { 
      this.IdSolicitud = this.route.snapshot.params.id;

      this.route.data.subscribe( (data: Data) => {
        this.collectionsSituacionLaboral = data['collections'][0].data;
        this.collectionsOcupaciones = data['collections'][1].data;
      });

      this.frmC2 = this.createC2Form();

      if (this.route.snapshot.data.IdSolicitud.data) {
        this.previous_data = this.route.snapshot.data.IdSolicitud.data.C2;
        if (this.previous_data !== undefined){
          if  (this.previous_data.FechaAntiguedad){
            var formatFechaAntiguedad = this.utilitiesService.formatDateObject(this.previous_data.FechaAntiguedad);
            this.previous_data.FechaAntiguedad = formatFechaAntiguedad;
          }
          this.previous_data.IdSolicitudParticipante = this.IdSolicitud;

          if ( this.previous_data.IdSituacionLaboral == 2 || this.previous_data.IdSituacionLaboral == 5 || this.previous_data.IdSituacionLaboral == 6 ) {
            this.showData = false;
            this.utilitiesService.removeControlsValidity(this.frmC2, this.dataLaboral);
            if ( this.previous_data.IdSituacionLaboral == 2 ) {
              this.frmC2.patchValue({
                IdOcupacion: 13
              });
            }
            else{
              if ( this.previous_data.IdSituacionLaboral == 5 ) {
                this.frmC2.patchValue({
                  IdOcupacion: 3
                });
              }
              else{
                if ( this.previous_data.IdSituacionLaboral == 6 ) {
                  this.frmC2.patchValue({
                    IdOcupacion: 16
                  });
                }
              }
            }
          }
          else{
            this.dataLaboral.forEach(element => {
              this.frmC2.get(element).setValidators([Validators.required]);
            });
            this.showData = true;
    
            if ( this.previous_data.IdSituacionLaboral == 4 ) {
              this.frmC2.patchValue({
                IdOcupacion: 23
              });
            }
            else{
              this.frmC2.get('IdOcupacion').setValidators([Validators.required, Validators.min(1)]);
              this.frmC2.patchValue({
                IdOcupacion: 0
              });
            }
          }

          this.frmC2.patchValue(this.previous_data);
        }
      }else{
        this.frmC2.patchValue({
          NombreEmpresa: "",
          Puesto: "",
          IdSituacionLaboral: 0,
          FechaAntiguedad: "",
          IdOcupacion: 0,
        });
      }

      
    }

    handleSituacionLaboral(evt){
      var valueSituacionLaboral = evt.target.value;
      if ( valueSituacionLaboral == 2 || valueSituacionLaboral == 5 || valueSituacionLaboral == 6 ) {
        this.showData = false;
        this.utilitiesService.removeControlsValidity(this.frmC2, this.dataLaboral);
        if ( valueSituacionLaboral == 2 ) {
          this.frmC2.patchValue({
            IdOcupacion: 13
          });
        }
        else{
          if ( valueSituacionLaboral == 5 ) {
            this.frmC2.patchValue({
              IdOcupacion: 3
            });
          }
          else{
            if ( valueSituacionLaboral == 6 ) {
              this.frmC2.patchValue({
                IdOcupacion: 16
              });
            }
          }
        }
      }
      else{
        this.dataLaboral.forEach(element => {
          this.frmC2.get(element).setValidators([Validators.required]);
        });
        this.showData = true;

        if ( valueSituacionLaboral == 4 ) {
          this.frmC2.patchValue({
            IdOcupacion: 23
          });
        }
        else{
          this.frmC2.get('IdOcupacion').setValidators([Validators.required, Validators.min(1)]);
          this.frmC2.patchValue({
            IdOcupacion: 0
          });
        }
      }
    }

    onSubmit(modalError){

      if ( this.frmC2.controls["IdSituacionLaboral"].value == 2 || this.frmC2.controls["IdSituacionLaboral"].value == 5 || this.frmC2.controls["IdSituacionLaboral"].value == 6 ) {
        this.frmC2.patchValue({
          FechaAntiguedad: '',
          Puesto: '',
          NombreEmpresa: ''
        });
      }
      else{
        if ( this.frmC2.controls["FechaAntiguedad"].value == null || this.frmC2.controls["FechaAntiguedad"].value.year == undefined ) {
          this.errorFecha = true;
          return;
        }

        var FechaFinalAntiguedad = this.utilitiesService.parseDate(this.frmC2.controls["FechaAntiguedad"].value);
      }

      if (!this.frmC2.valid) {
        Object.keys(this.frmC2.value).forEach(element => {
          if ( this.frmC2.get(element).errors != null ) {
            this.frmC2.get(element).markAsDirty();
          }
        });
        return;
      }

      let sendData = this.frmC2.value;
      this.loading = true;

      sendData.FechaAntiguedad= FechaFinalAntiguedad;
      sendData.IdSolicitud= this.IdSolicitud;
      sendData.IdSolicitudParticipante= this.IdSolicitud;

      this.C2Service.sendInformacionLaboral(sendData).subscribe(
        resp => {
          let redirect = this.utilitiesService.handleRedirect(resp['proceso']);
          this.router.navigateByUrl(redirect+"/"+this.IdSolicitud);
          this.loading = false;
        },
        errors => {
          this.loading = false;
          this.errorMessages = errors.error.message;
          this.modalErrors.open(modalError, { ariaLabelledBy: 'modal-basic-title' });
        },
      );
    }

    createC2Form(): FormGroup {
      return this.fb.group(
        {
          NombreEmpresa: [
            null
          ],
          IdSituacionLaboral:[
            null,
            [Validators.required, Validators.min(1)]
          ],
          Puesto:[
            null
          ],
          FechaAntiguedad: [
            Validators.required 
          ],
          IdOcupacion: [
            null,
            [Validators.required, Validators.min(1)]
          ],
          IdSolicitudParticipante: [
            Validators.required
          ],
          IdSolicitud: [
            null
          ],
        },
      );
    }

  }