import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Data } from '@angular/router';
import { Collection } from 'src/app/models/collection';
import { CollectionsService } from 'src/app/services/colecctions.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { B1CapturaInformacionService } from 'src/app/services/aval/B1CapturaInformacionService.service';
import { UtilitiesAvalService } from 'src/app/services/aval/UtilitiesAvalService.service';

@Component({
    selector: 'app-b1-captura-informacion',
    templateUrl: './b1-captura-informacion.component.html',
})
export class B1CapturaInformacionComponent implements OnInit, AfterViewInit {
    public frmB1: FormGroup;
    public loading: boolean;
    public errorMessages: string;
    public IdSolicitud: number;

    public dataFormat = {
        Telefono: null,
        HorarioContacto: null,
    };


    /* Collections from resolver */
    public collectionsPaises: Collection;
    public data: any;
    public informacionPropietario: any;
    public informacionTelefono: Collection;
    /* End Collections from resolver */

    /* Collections for nacionality */
    public collectionsPaisesNacionalidad: Collection;
    public collectionsEstadosNacionalidad: Collection;
    /* END Collections for nacionality */

    /* Boolean for valid birth country */
    public validBirthCountry: boolean;
    /* END Boolean for valid birth country */

    /* Boolean for valid Direction country */
    public validDirectionCountry: boolean;
    /* END Boolean for valid Direction country */

    /* Collections for address data */
        public collectionsEstado: Collection;
        public collectionsCiudad: Collection;
        public collectionsMunicipio: Collection;
        public collectionsColonia: Collection;
    /* END Collections for address data */

    public address_id = ['IdCiudad', 'IdEstado', 'IdMunicipio', 'IdColonia'];
    public address = ['Ciudad', 'Estado', 'Municipio', 'Colonia'];

    /* permissions */
    public IdRoleAval: any;
    public formatedPermissions: any;

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private collectionsService: CollectionsService,
        private utilitiesService: UtilitiesService,
        private modalError: NgbModal,
        private B1Service: B1CapturaInformacionService,
        private avalService: UtilitiesAvalService
    ) {
        this.loading = false;
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    ngOnInit() {
        this.IdSolicitud = this.route.snapshot.params.id;
        this.validBirthCountry = true;
        this.validDirectionCountry = true;
        this.route.data.subscribe((data: Data) => {
            this.collectionsPaises = data['collections'][0].data;
            this.collectionsPaisesNacionalidad = data['collections'][1].data;
            this.collectionsEstadosNacionalidad = data['collections'][2].data;
            this.collectionsEstado = data['collections'][2].data;
            this.IdRoleAval = data['permissions'][0].data.IdTipoRelacion;
            this.data = data['data'][0].data.informacion_personal;
            this.informacionPropietario = data['data'][0].data.informacion_propietario;            
        }); 
        this.formatedPermissions = this.avalService.getPermissions(this.IdRoleAval);

        
        this.frmB1 = this.createFrmB1();

        this.frmB1.patchValue({
            IdSolicitud: this.IdSolicitud,
            etapa: 1,
            paso: 2,
            flag:1,
            Telefono: this.data.Telefono,
            HorarioContacto: this.data.HorarioContacto,
        });

        /* Obtener colecciones de estado ciudad */
        if (this.data !== null){
            if (parseInt(this.data.IdPais) === 1) {
                this.collectionsService.getStateByCountryId(this.data.IdPais).subscribe(
                    data => {
                        this.validBirthCountry = true;
                        this.collectionsEstadosNacionalidad = data.data;
                        this.utilitiesService.removeControlsValidity(this.frmB1, ['EstadoNacimiento']);
                    }, error => {
                        this.validBirthCountry = false;
                        this.utilitiesService.removeControlsValidity(this.frmB1, ['IdEstadoNacimiento']);
                    }
                );
                if (this.data.IdEstado !== null) {
                    this.collectionsService.getCitiesById(this.data.IdPais, this.data.IdEstado).subscribe(
                        data => {
                            this.validDirectionCountry = true;
                            this.collectionsCiudad = data.data;
                        }, error => {
                            this.validDirectionCountry = false;
                        }
                    )
                    this.collectionsService.getMunicipalitiesById(this.data.IdPais, this.data.IdEstado).subscribe(
                        data => {
                            this.validDirectionCountry = true;
                            this.collectionsMunicipio = data.data;
                        }, error => {
                            this.validDirectionCountry = false;
                        }
                    )
                    this.collectionsService.getCollectionsColoniasByPais(this.data.IdPais, this.data.IdEstado, this.data.IdMunicipio).subscribe(
                        data => {
                            this.collectionsColonia = data.data;
                        }, error => {
                            // this.validDirectionCountry = false;
                        }
                    )
        
                }
                this.data.IdPais = this.data.IdPais == "" ? '1' : this.data.IdPais; 
                this.frmB1.patchValue(this.data);

            } else {
                this.collectionsService.getStateByCountryId(1).subscribe(
                    data => {
                        this.validBirthCountry = true;
                        this.collectionsEstadosNacionalidad = data.data;
                        this.utilitiesService.removeControlsValidity(this.frmB1, ['EstadoNacimiento']);
                    }, error => {
                        this.validBirthCountry = false;
                        this.utilitiesService.removeControlsValidity(this.frmB1, ['IdEstadoNacimiento']);
                    }
                );
                this.utilitiesService.removeControlsValidity(this.frmB1, ['IdEstadoNacimiento']);
                this.frmB1.patchValue({
                    IdPaisNacimiento:1,
                    IdPaisNacionalidad:1,
                });
            }
        }
    }

    changeBirthCountry(evt) {
        var index = evt.target.selectedIndex;
        var textCountry = evt.target[index].text;
        var indexCountry = evt.target.value;
        if (indexCountry === "1" && textCountry === "MEXICO") {
            this.collectionsService.getStateByCountryId(indexCountry).subscribe(
                data => {
                    this.validBirthCountry = true;
                    this.collectionsEstadosNacionalidad = data.data;
                    this.utilitiesService.removeControlsValidity(this.frmB1, ['EstadoNacimiento']);
                }, error => {
                    this.validBirthCountry = false;
                    this.utilitiesService.removeControlsValidity(this.frmB1, ['IdEstadoNacimiento']);
                }
            );

        } else {
            this.validBirthCountry = false;
            this.utilitiesService.removeControlsValidity(this.frmB1, ['IdEstadoNacimiento']);
        }
    }

    handleCountry(evt) {
        var index = evt.target.selectedIndex;
        var textCountry = evt.target[index].text;
        var indexCountry = evt.target.value;
        if (indexCountry === "1" && textCountry === "MEXICO") {

            this.collectionsService.getStateByCountryId(indexCountry).subscribe(
                data => {
                    this.validDirectionCountry = true;
                    this.collectionsEstado = data.data;
                    this.utilitiesService.removeControlsValidity(this.frmB1, this.address);
                }, error => {
                    this.validDirectionCountry = false;
                    this.utilitiesService.removeControlsValidity(this.frmB1, this.address_id);
                }
            )

            this.address_id.forEach(element => {
                this.frmB1.get(element).setValidators(Validators.min(1));
            });
            this.frmB1.patchValue({CodigoPostal: '', IdCodigoPostal: ''});

        } else {
            this.validDirectionCountry = false;
            this.utilitiesService.removeControlsValidity(this.frmB1, this.address_id);
        }
    }

    handleState(evt) {
        var indexState = evt.target.value;
        var indexCountry = this.frmB1.controls['IdPais'].value;
        if (indexState !== "0") {
            this.collectionsService.getCitiesById(indexCountry, indexState).subscribe(
                data => {
                    this.validDirectionCountry = true;
                    this.collectionsCiudad = data.data;
                }, error => {
                    this.validDirectionCountry = false;
                }
            )
            this.collectionsService.getMunicipalitiesById(indexCountry, indexState).subscribe(
                data => {
                    this.validDirectionCountry = true;
                    this.collectionsMunicipio = data.data;
                }, error => {
                    this.validDirectionCountry = false;
                }
            )

            this.frmB1.patchValue({IdCiudad: 0, IdMunicipio: 0, IdColonia: 0, CodigoPostal: '', IdCodigoPostal: ''});

        }

    }

    handleTown() {
        var IdPais = this.frmB1.controls['IdPais'].value;
        var IdEstado = this.frmB1.controls['IdEstado'].value;
        var IdMunicipio = this.frmB1.controls['IdMunicipio'].value;

        if (this.frmB1.controls['IdPais'].value && this.frmB1.controls['IdEstado'].value && this.frmB1.controls['IdMunicipio'].value) {
            this.collectionsService.getCollectionsColoniasByPais(IdPais, IdEstado, IdMunicipio).subscribe(
                data => {
                    this.collectionsColonia = data.data;
                }, error => {
                    // this.validDirectionCountry = false;
                }
            )
            this.frmB1.patchValue({IdColonia: 0, CodigoPostal: '', IdCodigoPostal: ''});
        }
    }

    changeColonia(colonia){
        var index = colonia.target.selectedIndex;
        if ( colonia.target[index].getAttribute('CodigoPostal') != null ) {
          this.frmB1.patchValue({IdCodigoPostal: colonia.target[index].getAttribute('CodigoPostal'), CodigoPostal: colonia.target[index].getAttribute('CodigoPostal')});
        }
      }

    createFrmB1() {
        return this.fb.group({
            IdPaisNacionalidad: [
                null,
                [Validators.required, Validators.min(1)]
            ],
            IdPaisNacimiento: [
                null,
                [Validators.required, Validators.min(1)]
            ],
            IdEstadoNacimiento: [
                null
            ],
            EstadoNacimiento: [
                null
            ],
            // 
            Calle: [
                null
            ],
            NoExterior: [
                null
            ],
            NoInterior: [
                null
            ],
            IdPais: [
                null,
                Validators.min(1)
            ],
            IdColonia: [
                null,
                Validators.min(1)
            ],
            IdMunicipio: [
                null,
                Validators.min(1)
            ],
            IdCiudad: [
                null,
                Validators.min(1)
            ],
            IdEstado: [
                null,
                Validators.min(1)
            ],
            IdCodigoPostal: [
                null
            ],
            Colonia: [
                null
            ],
            Municipio: [
                null
            ],
            Ciudad: [
                null
            ],
            Estado: [
                null
            ],
            CodigoPostal: [
                null
            ],
            IdSolicitud:[
                null
            ],
            etapa:[
                null
            ],
            paso:[
                null
            ],
            flag:[
                null
            ],
            Telefono: [
            null,
            ],
            HorarioContacto: [
            null]
        });
    }

    onlyDigits(event){
        if (event.which != 8 && event.which != 0 && event.which < 48 || event.which > 57)
        {
            event.preventDefault();
        }
    }

    onSubmit(modalError) {
        if (!this.frmB1.valid) {
            Object.keys(this.frmB1.value).forEach(element => {
                if ( this.frmB1.get(element).errors != null ) {
                this.frmB1.get(element).markAsDirty();
                }
            });
            return;
        }

        this.loading = true;
        let sendData = this.frmB1.value;
        
    
        if (!this.validDirectionCountry) {
          delete sendData["IdEstado"];
          delete sendData["IdCiudad"];
          delete sendData["IdColonia"];
          delete sendData["IdMunicipio"];
          delete sendData["IdCodigoPostal"];
        }

        this.B1Service.sendData(sendData).subscribe(
          resp => {
            this.router.navigateByUrl("aval/capturar_informacion_fiscal/" + this.IdSolicitud);
          },
          errors => {
            this.loading = false;
            this.errorMessages = errors.error.message;
            this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
          },
        )
      }
}