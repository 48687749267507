import { Component, OnInit, EventEmitter, Input, Output, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router, Data } from '@angular/router';
import { Collection } from 'src/app/models/collection';
import { CollectionsService } from 'src/app/services/colecctions.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilitiesAvalService } from 'src/app/services/aval/UtilitiesAvalService.service';
import { M1PromotorInformacionService } from 'src/app/services/promotor/M1PromotorInformacionPersonal.service';

@Component({
    selector: 'app-m1-promotor-aval-informacion-personal-edit',
    templateUrl: './m1-promotor-aval-informacion-personal-edit.component.html',
})
export class M1PromotorAvalInformacionPersonalEditComponent implements OnInit, AfterViewInit {
    @Input('dataParent') dataParent: any;
    @Output() eventoCancelado = new EventEmitter<any>();
    @Output() datosGuardados = new EventEmitter<any>();
    public frmB1: FormGroup;
    public loading: boolean;
    public errorMessages: string;
    public IdSolicitud: number;
    public IdPersona:number;
    public selected_one_value: boolean; 


    /* Collections from resolver */
    public collectionsPaises: Collection;
    /* End Collections from resolver */

    /* Collections for nacionality */
    public collectionsPaisesNacionalidad: Collection;
    public collectionsEstadosNacionalidad: Collection;
    /* END Collections for nacionality */

    /* Boolean for valid birth country */
    public validBirthCountry: boolean;
    /* END Boolean for valid birth country */

    /* Boolean for valid Direction country */
    public validDirectionCountry: boolean;
    /* END Boolean for valid Direction country */

    /* Collections for address data */
        public collectionsEstado: Collection;
        public collectionsCiudad: Collection;
        public collectionsMunicipio: Collection;
        public collectionsColonia: Collection;
    /* END Collections for address data */

    public address_id = ['IdCiudad', 'IdEstado', 'IdMunicipio', 'IdColonia'];
    public address = ['Ciudad', 'Estado', 'Municipio', 'Colonia']; 

    /* permissions */
    public IdRoleAval: any;
    public formatedPermissions: any;
    public data:any;

    constructor(
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private collectionsService: CollectionsService,
        private utilitiesService: UtilitiesService,
        private modalError: NgbModal,
        private M1Service: M1PromotorInformacionService,
        private avalService: UtilitiesAvalService
    ) {
        this.loading = false;
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    ngOnInit() {
        this.IdSolicitud = this.route.snapshot.params.id_solicitud;
        this.IdPersona= this.route.snapshot.params.id_persona;
        this.validBirthCountry = true;
        this.validDirectionCountry = true;
        this.formatedPermissions = this.avalService.getPermissions(this.IdRoleAval);
        this.data = {...this.dataParent}
        this.frmB1 = this.createFrmB1();
        this.route.data.subscribe((data: Data) => {
            this.collectionsPaises = data['collections'][0].data;
            this.collectionsPaisesNacionalidad = data['collections'][1].data;
        });
        
        this.frmB1.patchValue({
            IdSolicitud: this.IdSolicitud,
            IdPersona: this.IdPersona,
            Telefono: this.data.Telefono,
            HorarioContacto: this.data.HorarioContacto,
        });
        if(this.data.IdPais == 1){
            this.validDirectionCountry = true;
            this.collectionsService.getStateByCountryId(this.data.IdPais).subscribe(
                data => {
                    this.collectionsEstado = data.data;                    
                }, error => {
                    
                }
            );
            this.collectionsService.getStateByCountryId(this.data.IdPaisNacimiento).subscribe(
                data => {
                    this.collectionsEstadosNacionalidad = data.data;                    
                }, error => {
                    
                }
            )
            this.collectionsService.getCitiesById(this.data.IdPais, this.data.IdEstado).subscribe(
                data => {
                    this.collectionsCiudad = data.data;                    
                }, error => {
                    
                }
            )
            this.collectionsService.getMunicipalitiesById(this.data.IdPais, this.data.IdEstado).subscribe(
                data => {
                    this.collectionsMunicipio = data.data;                    
                }, error => {
                    
                }
            )
            this.collectionsService.getCollectionsColoniasByPais(this.data.IdPais, this.data.IdEstado, this.data.IdMunicipio).subscribe(
                data => {
                    this.collectionsColonia = data.data;                    
                }, error => {
                }
            )

        }else{
            this.validDirectionCountry = false;
        }

        this.frmB1.patchValue(this.data);
    }

    changeBirthCountry(evt) {
        var index = evt.target.selectedIndex;
        var textCountry = evt.target[index].text;
        var indexCountry = evt.target.value;
        if (indexCountry === "1" && textCountry === "MEXICO") {
            this.collectionsService.getStateByCountryId(indexCountry).subscribe(
                data => {
                    this.validBirthCountry = true;
                    this.collectionsEstadosNacionalidad = data.data;
                    this.utilitiesService.removeControlsValidity(this.frmB1, ['EstadoNacimiento']);
                }, error => {
                    this.validBirthCountry = false;
                    this.utilitiesService.removeControlsValidity(this.frmB1, ['IdEstadoNacimiento']);
                }
            )

        } else {
            this.validBirthCountry = false;
            this.utilitiesService.removeControlsValidity(this.frmB1, ['IdEstadoNacimiento']);
        }
    }

    handleCountry(evt) {
        var index = evt.target.selectedIndex;
        var textCountry = evt.target[index].text;
        var indexCountry = evt.target.value;
        if (indexCountry === "1" && textCountry === "MEXICO") {
            this.address_id.forEach(element => {
                this.frmB1.get(element).setValidators(Validators.min(1));
            });
            this.frmB1.patchValue({CodigoPostal: '', IdCodigoPostal: ''});
            this.collectionsService.getStateByCountryId(indexCountry).subscribe(
                data => {
                    this.validDirectionCountry = true;
                    this.collectionsEstado = data.data;
                    this.utilitiesService.removeControlsValidity(this.frmB1, this.address);
                }, error => {
                    this.validDirectionCountry = false;
                    this.utilitiesService.removeControlsValidity(this.frmB1, this.address_id);
                }
            )

        } else {
            this.validDirectionCountry = false;
            this.utilitiesService.removeControlsValidity(this.frmB1, this.address_id);
        }
    }

    handleState(evt) {
        var indexState = evt.target.value;
        var indexCountry = this.frmB1.controls['IdPais'].value;
        if (indexState !== "0") {
            this.frmB1.patchValue({IdCiudad: 0, IdMunicipio: 0, IdColonia: 0, CodigoPostal: ''});
            this.collectionsService.getCitiesById(indexCountry, indexState).subscribe(
                data => {
                    this.validDirectionCountry = true;
                    this.collectionsCiudad = data.data;
                }, error => {
                    this.validDirectionCountry = false;
                }
            )
            this.collectionsService.getMunicipalitiesById(indexCountry, indexState).subscribe(
                data => {
                    this.validDirectionCountry = true;
                    this.collectionsMunicipio = data.data;
                }, error => {
                    this.validDirectionCountry = false;
                }
            )

        }

    }

    handleTown() {
        var IdPais = this.frmB1.controls['IdPais'].value;
        var IdEstado = this.frmB1.controls['IdEstado'].value;
        var IdMunicipio = this.frmB1.controls['IdMunicipio'].value;

        if (this.frmB1.controls['IdPais'].value && this.frmB1.controls['IdEstado'].value && this.frmB1.controls['IdMunicipio'].value) {
            this.frmB1.patchValue({IdColonia: "", CodigoPostal: ''});
            this.collectionsService.getCollectionsColoniasByPais(IdPais, IdEstado, IdMunicipio).subscribe(
                data => {
                    this.collectionsColonia = data.data;
                }, error => {
                    // this.validDirectionCountry = false;
                }
            )
        }
    }

    changeColonia(colonia){
        var index = colonia.target.selectedIndex;
        if ( colonia.target[index].getAttribute('CodigoPostal') != null ) {
          this.frmB1.patchValue({CodigoPostal: colonia.target[index].getAttribute('CodigoPostal')});
        }
      }

    createFrmB1() {
        return this.fb.group({
            IdPaisNacionalidad: [
                null
            ],
            IdPaisNacimiento: [
                null
            ],
            IdEstadoNacimiento: [
                null
            ],
            EstadoNacimiento: [
                null
            ],
            // 
            Calle: [
                null
            ],
            NoExterior: [
                null
            ],
            NoInterior: [
                null
            ],
            IdPais: [
                null
            ],
            IdColonia: [
                null
            ],
            IdMunicipio: [
                null
            ],
            IdCiudad: [
                null
            ],
            IdEstado: [
                null
            ],
           /*  IdCodigoPostal: [
                null
            ], */
            Colonia: [
                null
            ],
            Municipio: [
                null
            ],
            Ciudad: [
                null
            ],
            Estado: [
                null
            ],
            CodigoPostal: [
                null
            ],
            IdSolicitud:[
                null
            ],
            IdPersona:[
                null
            ],
            Telefono: [
                null
            ],
            HorarioContacto: [
                null
            ],
            Nombre: [
                null
            ],
            ApellidoPaterno: [
                null
            ],
            ApellidoMaterno: [
                null
            ]

        });
    }

    onSubmit(modalError) {
        if (!this.frmB1.valid) {
            Object.keys(this.frmB1.value).forEach(element => {
              if ( this.frmB1.get(element).errors != null ) {
                this.frmB1.get(element).markAsDirty();
              }
            });
            return;
        }

        this.loading = true;
        let sendData = this.frmB1.value;

        this.M1Service.sendInformacionPersonal(sendData).subscribe(
          resp => {
              this.loading = false;
              this.datosGuardados.emit(resp);
          },
          errors => {
            this.loading = false;
            this.errorMessages = errors.error.message;
            this.modalError.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
          },
        )
    }

    parentEmitCancelEditionMode(): void {
        this.eventoCancelado.emit();
    }
}