export class C111InformacionFamiliarRecursos{
    public IdEstadoCivil: number;
    public IdRegimenConyugal: number;
    public NumeroDependientes: number;
    public IdParentescoConEstudiante: number;
    public IdTipoVivienda: number;
    public FechaAntiguedadVivienda: string;
    public UnicaPersona: string;
    public IdSolicitudParticipante: number;
    public PorcentajeOtraPersona: number;
    public Nombre: string;
    public ApellidoPaterno: string;
    public ApellidoMaterno: string;
    public FechaNacimiento: string;
    public Correo: string;
    public Celular: number;
    public IdSolicitud: number;
    public IdPersona: number;
    
    constructor(){}
}