import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Router, Data } from '@angular/router';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { Collection } from 'src/app/models/collection.model';
import { CollectionsService } from 'src/app/services/colecctions.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PromotorD3RevisionAcreditacion } from 'src/app/services/promotor/D3RevisionAcreditacionService';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-d3-revision-acreditacion',
    templateUrl: './d3-revision-acreditacion.component.html'
})

export class D3RevisionAcreditacionComponent implements OnInit, AfterViewInit {

    public dataToChild = {
        previousData: null,
        collections:{
            Generos:null,
            EstadoCivil:null,
            RegimenConyugal:null,
            Parentezco:null,
            TipoVivienda:null,
            RegimenFiscal:null,
            PaisesNacimiento:null,
            NacionalidadNacimiento:null
        }
    }

    public editMode: boolean;
    public status: number;
    public IdSolicitud: number;
    public previousData: any;
    public currentState:any;
    public collectionsGeneros: Collection;
    public collectionsEstadoCivil: Collection;
    public collectionsRegimenConyugal: Collection;
    public collectionsParentezco: Collection;
    public collectionsTipoVivienda: Collection;
    public collectionsRegimenFiscal: Collection;

    public frm : FormGroup;
    public loading: boolean;
    public errorMessages: string;

    public motivoRechazo: Collection;


    /* Colecciones Direccion */
    public collectionsColonias: Collection;
    public collectionsCiudades: Collection;
    public collectionsMunicipios: Collection;

    /* Colecciones para nacimiento */
    public collectionsPaisesNacimiento: Collection;
    public collectionsEstadosNacimiento: Collection;
    public collectionsNacionalidad: Collection;
    public collectionsEstadosDireccion: Collection;
    public collectionsCiudadesDireccion: Collection;
    public collectionsMunicipiosDireccion: Collection;
    public collectionsColoniasDireccion: Collection;



    /* Nombres a partir de ids de colecciones */

    public birthCountryName: string;
    public birthStateName: string
    public nacionality: string;
    public gender: string;
    public regimenFiscal: string;
    public estadoDireccion: string;
    public ciudadDireccion: string;
    public municipioDireccion: string;
    public coloniaDireccion: string;
    public FechaNacimiento:any;

    public PaisDireccion: string;

    public canEdit: boolean;
    public canDecline: boolean;


    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private utilities: UtilitiesService,
        private collectionsService: CollectionsService,
        private fb: FormBuilder,
        private modalErrors: NgbModal,
        private D3Service: PromotorD3RevisionAcreditacion,
    ) {
        this.status = 2;
        this.IdSolicitud = this.route.snapshot.params.id;
        this.editMode = false;
        this.canEdit = false;
        this.canDecline = false;
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: Data) => {
            this.previousData = data['collections'][0].data.revision_personal;
            this.currentState = data['collections'][0].data;
            this.collectionsPaisesNacimiento = data['collections'][1].data;
            this.collectionsColonias = data['collections'][2].data;
            this.collectionsGeneros = data['collections'][3].data;
            this.collectionsEstadoCivil = data['collections'][4].data;
            this.collectionsRegimenConyugal = data['collections'][5].data;
            this.collectionsParentezco = data['collections'][6].data;
            this.collectionsTipoVivienda = data['collections'][7].data;
            this.collectionsNacionalidad = data['collections'][8].data;
            this.collectionsRegimenFiscal = data['collections'][9].data;
        });

        this.canEdit = this.utilities.hasPermission('Edicion informacion');
        this.canDecline = this.utilities.hasPermission('Declinar Solicitud');

        this.route.data.subscribe((data: Data) => {
            this.motivoRechazo = data['motivoRechazo'][0].data;
        });

        if ( ! this.previousData ) {
            this.router.navigateByUrl("promotor/revision_solicitud/"+this.IdSolicitud)
        }

        const [year, month, day] = this.previousData.FechaNacimiento.split('-');
        const obj = {
          year: parseInt(year), month: parseInt(month), day:
            parseInt(day.split(' ')[0].trim())
        };
        this.FechaNacimiento = obj;
        
        this.birthCountryName = this.utilities.getFieldFromCollection(this.collectionsPaisesNacimiento, this.previousData.IdPaisNacimiento, "name");
        this.birthStateName = this.utilities.getFieldFromCollection(this.collectionsPaisesNacimiento, this.previousData.IdPaisNacimiento, "name");
        this.nacionality = this.utilities.getFieldFromCollection(this.collectionsNacionalidad, this.previousData.IdPaisNacionalidad, "name");
        this.gender = this.utilities.getFieldFromCollection(this.collectionsGeneros, this.previousData.IdGenero, "name");
        this.regimenFiscal = this.utilities.getFieldFromCollection(this.collectionsRegimenFiscal, this.previousData.IdRegimenFiscal, "name");

        this.PaisDireccion = this.previousData.LabelPais;
        this.estadoDireccion = this.previousData.LabelEstado;
        this.ciudadDireccion = this.previousData.LabelCiudad;
        this.municipioDireccion = this.previousData.LabelMunicipio;
        this.coloniaDireccion = this.previousData.LabelColonia;

        if (this.previousData.IdPaisNacimiento !== null && parseInt(this.previousData.IdPaisNacimiento) === 1) {
            this.collectionsService.getStateByCountryId(this.previousData.IdPais).subscribe(
                response => {
                    this.collectionsEstadosDireccion = response.data;
                    // this.estadoDireccion = this.utilities.getFieldFromCollection(this.collectionsEstadosDireccion,this.previousData.IdEstado,"name");
                },
                errors => {
                }
            );
            if (this.previousData.IdEstado !== null) {
                this.collectionsService.getCitiesById(this.previousData.IdPais, this.previousData.IdEstado).subscribe(
                    response => {
                        this.collectionsCiudadesDireccion = response.data;
                        // this.ciudadDireccion = this.utilities.getFieldFromCollection(this.collectionsCiudadesDireccion,this.previousData.IdCiudad,"name");
                    },
                    errors => {
                    }
                );
                this.collectionsService.getMunicipalitiesById(this.previousData.IdPais, this.previousData.IdEstado).subscribe(
                    response => {
                        this.collectionsMunicipiosDireccion = response.data;
                        // this.municipioDireccion = this.utilities.getFieldFromCollection(this.collectionsMunicipiosDireccion,this.previousData.IdMunicipio,"name");
                    },
                    errors => {
                    }
                );
                if (this.previousData.IdMunicipio !== null) {
                    this.collectionsService.getCollectionsColoniasByPais(this.previousData.IdPais, this.previousData.IdEstado, this.previousData.IdMunicipio).subscribe(
                        data => {
                            this.collectionsColoniasDireccion = data.data;
                            // this.coloniaDireccion = this.utilities.getFieldFromCollection(this.collectionsColoniasDireccion,this.previousData.IdColonia,"name");
                        }, error => {
                        
                        }
                    )
                } 
            } 
        }
        this.frm = this.createForm();
    }

    declinarSubmit(MotivoRechazo){
        if ( MotivoRechazo.value == '' ) {
            alert('Debe selecciona un motivo de rechazo');
            return;
        }

        let data = {MotivoRechazo: MotivoRechazo.value};
        
        this.utilities.declinarSolicitud(this.IdSolicitud, data).subscribe(
            resp => {
                this.modalErrors.dismissAll();
                this.router.navigateByUrl("promotor/dashboard");
            },
            errors => {
            }
        );
    }

    declinar(modalDeclinar){
        this.modalErrors.open(modalDeclinar, { ariaLabelledBy: 'modal-basic-title', centered: false});

    }

    ngAfterViewInit():void{
        window.scrollTo(0, 0);
        this.formatChildData();
    }

    triggerEdit(){
        this.editMode = true;
    }

    recibirCancelacion($event){
        this.editMode = false;
    } 


    onSubmit(modalTarget){
        this.loading = true;
        this.D3Service.sendCheckedData(this.IdSolicitud).subscribe(
            response =>{
                this.loading = false;
                this.router.navigateByUrl("promotor/revision_acreditacion_empleo/"+this.IdSolicitud)
            }, 
            errors =>{
                this.loading = false;

                this.errorMessages = errors.error.message;
                this.modalErrors.open(modalTarget, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        );
    }

    createForm(){
        return this.fb.group({
            confirmData:[
                null
            ]
        })
    }


    formatChildData():void{
        this.dataToChild.previousData = this.previousData;
        this.dataToChild.collections.EstadoCivil = this.collectionsEstadoCivil;
        this.dataToChild.collections.Generos = this.collectionsGeneros;
        this.dataToChild.collections.Parentezco = this.collectionsParentezco;
        this.dataToChild.collections.RegimenConyugal = this.collectionsRegimenConyugal;
        this.dataToChild.collections.RegimenFiscal = this.collectionsRegimenFiscal;
        this.dataToChild.collections.TipoVivienda = this.collectionsTipoVivienda;
        this.dataToChild.collections.PaisesNacimiento = this.collectionsPaisesNacimiento;
        this.dataToChild.collections.NacionalidadNacimiento = this.collectionsNacionalidad;
    }

    recibirGuardado($event){
        this.editMode = false;
        this.previousData = $event.data;
        this.estadoDireccion = this.previousData.LabelEstado;
        this.ciudadDireccion = this.previousData.LabelCiudad;
        this.municipioDireccion = this.previousData.LabelMunicipio;
        this.coloniaDireccion = this.previousData.LabelColonia;
        this.birthCountryName = this.utilities.getFieldFromCollection(this.collectionsPaisesNacimiento, this.previousData.IdPaisNacimiento, "name");
        this.birthStateName = this.utilities.getFieldFromCollection(this.collectionsPaisesNacimiento, this.previousData.IdPaisNacimiento, "name");
        this.nacionality = this.utilities.getFieldFromCollection(this.collectionsNacionalidad, this.previousData.IdPaisNacionalidad, "name");
        this.gender = this.utilities.getFieldFromCollection(this.collectionsGeneros, this.previousData.IdGenero, "name");
        this.regimenFiscal = this.utilities.getFieldFromCollection(this.collectionsRegimenFiscal, this.previousData.IdRegimenFiscal, "name");
        if (this.previousData.IdPaisNacimiento !== null && parseInt(this.previousData.IdPaisNacimiento) === 1) {
            this.collectionsService.getStateByCountryId(this.previousData.IdPais).subscribe(
                response => {
                    this.collectionsEstadosDireccion = response.data;
                    // this.estadoDireccion = this.utilities.getFieldFromCollection(this.collectionsEstadosDireccion,this.previousData.IdEstado,"name");
                },
                errors => {
                }
            );
            if (this.previousData.IdEstado !== null) {
                this.collectionsService.getCitiesById(this.previousData.IdPais, this.previousData.IdEstado).subscribe(
                    response => {
                        this.collectionsCiudadesDireccion = response.data;
                        // this.ciudadDireccion = this.utilities.getFieldFromCollection(this.collectionsCiudadesDireccion,this.previousData.IdCiudad,"name");
                    },
                    errors => {
                    }
                );
                this.collectionsService.getMunicipalitiesById(this.previousData.IdPais, this.previousData.IdEstado).subscribe(
                    response => {
                        this.collectionsMunicipiosDireccion = response.data;
                        // this.municipioDireccion = this.utilities.getFieldFromCollection(this.collectionsMunicipiosDireccion,this.previousData.IdMunicipio,"name");
                    },
                    errors => {
                    }
                );
                if (this.previousData.IdMunicipio !== null) {
                    this.collectionsService.getCollectionsColoniasByPais(this.previousData.IdPais, this.previousData.IdEstado, this.previousData.IdMunicipio).subscribe(
                        data => {
                            this.collectionsColoniasDireccion = data.data;
                            // this.coloniaDireccion = this.utilities.getFieldFromCollection(this.collectionsColoniasDireccion,this.previousData.IdColonia,"name");
                        }, error => {
                        
                        }
                    )
                } 
                window.location.reload()
            } 
        }
        this.formatChildData();
    }
}