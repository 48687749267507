import { FormControl } from '@angular/forms';

export function requiredMultipleFileType( types: Array<String> ) {
  return function ( control: FormControl ) {
    const file = control.value;
    if ( file ) {
      const extension = file.name.split('.');
      const indexExtension = extension.length - 1;
      const finalExtension = extension[indexExtension]

      if ( types.indexOf (finalExtension.toLowerCase()) < 0 ) {
        return {
          requiredFileType: true
        };
      }

      return null;
    }

    return null;
  };
}
