import { Injectable, ɵConsole } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FormGroup } from '@angular/forms';
import { ErrorsService } from './errors.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';


const ERROR_STATUS = 0;
const PENDING_STATUS = 1;
const APPROVE_STATUS = 2;
const PENDING_PROMOTOR = 3;
const PENDING_APPROVE = 4;

@Injectable({
  providedIn: 'root',
})

export class UtilitiesService {

  API_URL: string;

  constructor(private http: HttpClient, private errorService: ErrorsService, private router: Router) {
    this.API_URL = environment.API_URL;
  }

  imprimirSolicitud(IdSolicitud){
    const url = `imprimir/solicitud/${IdSolicitud}`;

    return this.getQuery('imprimir', url, '');
  }

  imprimirSolicitud2(IdSolicitud){
    const url = `generar/solicitud/${IdSolicitud}`;

    return this.getQuery('imprimir', url, '');
  }
  detalle(IdSolicitud){
    const url = `promotor/detalle/documentos/${IdSolicitud}`;

    return this.getQuery('imprimir', url, '');
  }

  getQuery(type: string, query: string, parameters: string, ) {
    const url = `${this.API_URL}${query}`;

    switch (type) {
      case 'post':
        return this.http.post(url, parameters);
      case 'get':
        return this.http.get(url);
      case 'put':
        return this.http.put(url, parameters);
      case 'delete':
        return this.http.delete(url);
      case 'patch':
        return this.http.patch(url, parameters);
      case 'imprimir':
          return this.http.get(url, { responseType: 'blob' });
      default:
        return new Observable();

    }
  }

  parseDate(date) {
    if (typeof date === "string") {
      return date;
    }
    if (date === null) {
      return null;
    }
    let finalDate = "";
    finalDate += date.year.toString() + "-";
    date.month <= 9 ? finalDate += "0" + date.month.toString() + "-" : finalDate += date.month.toString() + "-";
    date.day <= 9 ? finalDate += "0" + date.day.toString() : finalDate += date.day.toString();

    return finalDate;
  }

  formatDateObject(date) {
    if (date === null || date === undefined) {
      return null;
    }
    let obj = {
      year: null,
      month: null,
      day: null
    }
    let temp = date.split(" ")[0].split("-");
    obj.year = parseInt(temp[0]);
    obj.month = parseInt(temp[1]);
    obj.day = parseInt(temp[2]);
    return obj;
  }

  textToUppercase(text: string): string {
    let textReturn = "";
    textReturn = text.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    return textReturn;
  }

  sortObject(unordered) {
    const ordered = {};
    Object.keys(unordered).sort().forEach(function (key) {
      ordered[key] = unordered[key];
    });
    return ordered
  }

  removeControlsValidity(fg, arrayControls) {
    arrayControls.forEach(element => {
      fg.controls[element].clearValidators();
      fg.get(element).updateValueAndValidity();
    });
  }

  addControlsValidity(fb, arrayControls) {
    fb.group(arrayControls)
  }

  resetValues(fb, resetValues) {
    fb.patchValue(resetValues);
  }

  handleRedirect(process): string {
    if (process instanceof Object) {
      let redirect: string;

      switch (parseInt(process.etapa)) {
        /* Paso 1 Consulta a buró*/
        case 2:
          switch (parseInt(process.paso)) {
            case 1:
              redirect = "client/destino_credito";
              break;
            case 2:
              redirect = "client/requisitos_credito";
              break;
            case 3:
              if (parseInt(process.flag) !== null) {
                /* Falta preguntar como van a venir los tipos de flag (si vamos a   tener un diccionario de constantes o alguna otra cosa) */
                switch (parseInt(process.flag)) {
                  case ERROR_STATUS:
                    redirect = "client/messages";
                    break;
                  case PENDING_STATUS:
                    redirect = "client/autorizar_buro";
                    break;
                  case PENDING_PROMOTOR:
                    redirect = "client/messages";
                    break;
                  default:
                    break;
                }
              } else {
                redirect = "client/autorizar_buro";
              }
              break;
            case 4:
              if (parseInt(process.flag) !== null) {
                /* Falta preguntar como van a venir los tipos de flag (si vamos a   tener un diccionario de constantes o alguna otra cosa) */
                switch (parseInt(process.flag)) {
                  case ERROR_STATUS:
                    redirect = "client/messages";
                    break;
                  case PENDING_STATUS:
                    redirect = "client/autorizar_buro";
                    break;
                  case APPROVE_STATUS:
                    redirect = "client/messages";
                    break;
                  case PENDING_PROMOTOR:
                    redirect = "client/messages";
                    break;
                  default:
                    break;
                }
              } else {
                redirect = "client/autorizar_buro";
              }
              break;
            default:
              break;
          }
          break;
        /* Paso 2 Preaprobación*/
        case 3:
          switch (parseInt(process.paso)) {
            case 1:
              redirect = "client/informacion_familiar";
              break;
            case 2:
              if (parseInt(process.flag) !== null) {
                /* Falta preguntar como van a venir los tipos de flag (si vamos a tener un diccionario de constantes o alguna otra cosa) */
                switch (parseInt(process.flag)) {
                  case ERROR_STATUS:
                    redirect = "client/messages";
                    break;
                  default:
                    redirect = "client/informacion_laboral";
                    break;
                }
              } else {
                redirect = "client/informacion_laboral";
              }
              break;
            case 3:
              if (parseInt(process.flag) !== null) {
                /* Falta preguntar como van a venir los tipos de flag (si vamos a tener un diccionario de constantes o alguna otra cosa) */
                switch (parseInt(process.flag)) {
                  case ERROR_STATUS:
                    redirect = "client/messages";
                    break;
                  case PENDING_STATUS:
                    redirect = "client/informacion_economica";
                    break;
                  case APPROVE_STATUS:
                    redirect = "client/messages";
                    break;
                  case PENDING_PROMOTOR:
                    redirect = "client/messages";
                    break;
                  default:
                    break;
                }
              } else {
                redirect = "client/informacion_economica";
              }
              break;
            default:
              break;
          }
          break;
        case 4:
          switch (parseInt(process.paso)) {
            case 1:
              redirect = "client/informacion_personal_extra";
              break;
            case 2:
              redirect = "client/informacion_laboral_extra";
              break;
            case 3:
              redirect = "client/referencias";
              break;
            case 4:
              redirect = "client/confirmacion_identidad";
              break;
            case 5:
              if (parseInt(process.flag) !== null) {
                /* Falta preguntar como van a venir los tipos de flag (si vamos a   tener un diccionario de constantes o alguna otra cosa) */
                switch (parseInt(process.flag)) {
                  case ERROR_STATUS:
                    redirect = "client/messages";
                    break;
                  case PENDING_STATUS:
                    redirect = "client/documentacion";
                    break;
                  case APPROVE_STATUS:
                    redirect = "client/messages";
                    break;
                  case PENDING_PROMOTOR:
                    redirect = "client/messages";
                    break;
                  case PENDING_APPROVE:
                    redirect = "client/informacion_aval";
                    break;
                  default:
                    break;
                }
              } else {
                redirect = "client/documentacion";
              }
              break;

            default:
              break;
          }
          break;
        case 5:
          switch (parseInt(process.paso)) {
            case 1:
              if (parseInt(process.flag) !== null) {
                /* Falta preguntar como van a venir los tipos de flag (si vamos a tener un diccionario de constantes o alguna otra cosa) */
                switch (parseInt(process.flag)) {
                  case ERROR_STATUS:
                    redirect = "client/carga_documentos";
                    break;
                  case APPROVE_STATUS:
                    redirect = "client/messages";
                    break;
                  default:
                    break;
                }
              }
              break;
            default:
              break;
          }
        case 6:
          switch (parseInt(process.paso)) {
            case 1:
              redirect = "client/instruccion_deposito";
              break;
            case 2:
              redirect = "client/contratos";
              break;

            //   if (parseInt(process.flag) !== null) {
            //     switch (parseInt(process.flag) ) {
            //       case ERROR_STATUS:
            //         redirect = "client/messages";
            //         break;
            //       case PENDING_STATUS:
            //         redirect = "client/messages";
            //         break;
            //       case APPROVE_STATUS:
            //         redirect = "client/messages";
            //         break;
            //         case PENDING_PROMOTOR:
            //             redirect = "client/messages";
            //             break;
            //       default:
            //         break;
            //   }
            // }
            default:
              redirect = "client/messages";
              break;
          }
          break;


        default:
          redirect = "client/dashboard"
          break;
      }

      return redirect;
    } else {
      return "/client/dashboard";
    }

  }

  handleBRouting(state, process, idSolicitud) {
    let redirectUrl: string;
    switch (process.etapa) {
      case 1:
        if (state.url.includes('client/destino_credito')) { // peso 1
        }
        else {
          if (state.url.includes('client/requisitos_credito')) {
            if (2 > process.paso) {
              redirectUrl = 'client/destino_credito/' + idSolicitud;
            }
          }
          else {
            if (state.url.includes('client/informacion_personal')) {
              if (3 > process.paso) {
                switch (process.paso) {
                  case 1:
                    redirectUrl = 'client/destino_credito/' + idSolicitud;
                    break;

                  default:
                    redirectUrl = 'client/requisitos_credito/' + idSolicitud;
                    break;
                }
              }
            }
            else {
              if (state.url.includes('client/autorizar_buro')) {
                if (4 > process.paso) {
                  switch (process.paso) {
                    case 1:
                      redirectUrl = 'client/destino_credito/' + idSolicitud;
                      break;

                    case 2:
                      redirectUrl = 'client/requisitos_credito/' + idSolicitud;
                      break;

                    default:
                      redirectUrl = 'client/informacion_personal/' + idSolicitud;
                      break;
                  }
                }
              }
            }
          }
        }
        break;

      default:
        // TODO: Redirigir a lo que devuelva el servicio de redirect o routing
        break;
    }
  }

  handleStateMessages(proceso, idSolicitud, usuario): any {
    var MessagesObject = {
      message: "",
      submessage: "",
      status: "",
      solicitud: idSolicitud,
      proceso: proceso,
      substatus: 0,
      revision_docs: false,
    };

    switch (parseInt(proceso.etapa)) {
      case 2:
        switch (parseInt(proceso.flag)) {
          case ERROR_STATUS:
            MessagesObject.message = "¡Lo sentimos, no cumples con los requisitos!";
            MessagesObject.submessage = `No cumples con los requisitos para solicitar un crédito con nosotros. Puedes consultarlos aquí.
                        <br>
                        <br>
                        Para mayor información comunicate al 01 55 6387 1129.`;
            MessagesObject.status = "error";
            MessagesObject.solicitud = idSolicitud;
            break;

          case APPROVE_STATUS:
            MessagesObject.message = "¡Gracias " + this.capitalize(usuario) + "! <br> La consulta se realizó de manera exitosa.";
            MessagesObject.submessage = `Continúa con el proceso para la preaprobación del crédito.`;
            MessagesObject.status = "success";
            MessagesObject.solicitud = idSolicitud;
            break;

          case PENDING_PROMOTOR:
            MessagesObject.message =
              "Muchas gracias " +
              this.capitalize(usuario) +
              "! <br> Tu solicitud está en revisión.";
            MessagesObject.submessage = ``;
            MessagesObject.status = "wait";
            MessagesObject.solicitud = idSolicitud;
          break;

          default:
            break;
        }

        break;

      case 3:
        switch (parseInt(proceso.flag)) {
          case ERROR_STATUS:
            MessagesObject.message =
              "¡Lo sentimos, no cumples con los requisitos!";
            MessagesObject.submessage = `No cumples con los requisitos para solicitar un crédito con nosotros. Puedes consultarlos aquí.
                            <br>
                            <br>
                            Para mayor información comunicate al 01 55 6387 1129.`;
            MessagesObject.status = "error";
            MessagesObject.solicitud = idSolicitud;
            break;
          case APPROVE_STATUS:
            MessagesObject.message =
              "Hola " +
              this.capitalize(usuario) +
              "! <br> Por favor continúa con el proceso.";
            MessagesObject.submessage = `Continúa ingresando tus datos para completar tu solicitud.`;
            MessagesObject.status = "success";
            MessagesObject.solicitud = idSolicitud;
            break;

          case PENDING_PROMOTOR:
            MessagesObject.message =
              "!" +
              this.capitalize(usuario) +
              "! <br> Continúa con el llenado de tu información complementaria.";
            MessagesObject.submessage = ``;
            MessagesObject.status = "wait";
            MessagesObject.solicitud = idSolicitud;
            break;

          default:
            break;
        }
        break;

      case 4:
        switch (parseInt(proceso.flag)) {
          case ERROR_STATUS:
            MessagesObject.message = "¡Hola " + this.capitalize(usuario) + "!<br>Te hemos enviado un correo con los puntos a completar o corregir";
            MessagesObject.submessage = `<div class="em-label-title">Por favor completa lo solicitado, para iniciar la contratación</div>
                <br>
                <br>
                <div class="em-label-title">Cordialmente Equipo EDUPASS.</div>`;
                MessagesObject.status = "error";
                MessagesObject.solicitud = idSolicitud;
                MessagesObject.revision_docs = true;
              break;

              case PENDING_PROMOTOR:
                MessagesObject.message =
                    "¡" + this.capitalize(usuario) +
                    "! <br>Concluiste con el llenado de tu solicitud.";
                MessagesObject.submessage = `<p>La revisaremos y te notificaremos su estatus en breve.</p>
                <br><br>
                <p>Cordialmente Equipo EDUPASS.</p>
                `;
            MessagesObject.status = "wait";
            MessagesObject.solicitud = idSolicitud;
            break;

          case APPROVE_STATUS:
            MessagesObject.message = "¡ Felicidades " + this.capitalize(usuario) + ", tu crédito ha sido aprobado!";
            MessagesObject.submessage = `A continuación te brindamos los documentos legales necesarios para finalizar tu proceso.`;
            MessagesObject.status = "success";
            MessagesObject.solicitud = idSolicitud;
            break;
        }
        break;

      case 6:
        switch (parseInt(proceso.flag)) {
          case ERROR_STATUS:
            MessagesObject.message = this.capitalize(usuario) + ", tenemos que corregir algunos datos para continuar.";
            MessagesObject.submessage = `<div class="em-label-title">Algunos de tus documentos tienen problemas, da click en Subir Documentos para más detalle.</div>
                <br>
                <div class="em-label-title">Por favor, vuelve a subir los documentos procurando que no sean fotografías, se vean claros con todos los datos y tengan la vigencia correspondiente.</div>`;
            MessagesObject.status = "error";
            MessagesObject.solicitud = idSolicitud;
            MessagesObject.revision_docs = true;
            break;

          case PENDING_PROMOTOR:
            MessagesObject.message = this.capitalize(usuario) + ", estamos a un paso de concluir el proceso.";
            MessagesObject.submessage = `<p>Para concluir envía tus documentos originales a la siguiente dirección:
                <br><br>
                Calle Euler No. 135,<br>
                Col. Polanco<br>
                c.p. 11560<br>
                Miguel Hidalgo<br>
                CDMX<br>
                <br><br>
                En cuanto los recibamos y validemos, tu solicitud habrá finalizado.</p>
                `;
            MessagesObject.status = "wait";
            MessagesObject.solicitud = idSolicitud;
            break;

          case APPROVE_STATUS:
            MessagesObject.message = "¡Felicidades, tu crédito ha sido aprobado y tu solicitud finalizada!";
            MessagesObject.submessage = `Tu crédito ha sido aprobado. A continuación podrás ver un resumen y descargar los documentos.`;
            MessagesObject.status = "success";
            MessagesObject.solicitud = idSolicitud;
            MessagesObject.substatus = 1;
            break;
        }
        break;

      default:
        MessagesObject.message = "¡Felicidades, tu crédito ha sido aprobado y tu solicitud finalizada!";
        MessagesObject.submessage = `Tu crédito ha sido aprobado. A continuación podrás ver un resumen y descargar los documentos.`;
        MessagesObject.status = "success";
        MessagesObject.solicitud = idSolicitud;
        MessagesObject.substatus = 1;
        break;
    }

    return MessagesObject;
  }

  handleStateMessagesPromotor(proceso, idSolicitud, proceso_cliente = null){
    var MessagesObject = {
      message: "",
      submessage: "",
      status: "",
      solicitud: idSolicitud,
      proceso: proceso,
      substatus: 0,
    };

    if ( parseInt(proceso.etapa) == 8 ) {
      switch ( parseInt(proceso.flag) ) {
        case 5:
          MessagesObject.status = 'success';
          MessagesObject.message = '¡Solicitud finalizada!';
          MessagesObject.submessage = '';
          MessagesObject.substatus = 1;
          break;

        default:
          break;
      }
    }

    return MessagesObject;
  }

  capitalize(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  getNameAlianzaFromId(collectionsAlianza, idAlianza) {
    var alianzaName: string;
    collectionsAlianza.map(function (element) {
      if (element.id === idAlianza) {
        alianzaName = element.name;
      }
    });
    return alianzaName;
  }

  getNameIdDestinoCredito(collectionsIdDestinoCredito, selectedIds) {
    var IdDestinoCreditoName: string = "";
    selectedIds.split(",").map(function (element) {
      collectionsIdDestinoCredito.map(function (subElement) {
        if (parseInt(subElement.id) === parseInt(element)) {
          IdDestinoCreditoName += subElement.name + ", ";
        }
      });
    });
    return IdDestinoCreditoName;
  }

  getPeriodicidadName(collectionPeriodicidad, idPeriodicidad) {
    let namePeriodicidad: string;
    collectionPeriodicidad.map(function (element) {
      if (element.id === idPeriodicidad) {
        namePeriodicidad = element.Periodicidad;
      }
    })
    return namePeriodicidad;
  }

  getPeriodosName(collectionPeriodicidad, idPeriodicidad) {
    let namePeriodos: string;
    collectionPeriodicidad.map(function (element) {
      if (element.id === idPeriodicidad) {
        namePeriodos = element.name;
      }
    })
    return namePeriodos;
  }

  getCountryName(collectionCountry, idCountry) {
    let nameCountry: string;
    collectionCountry.map(function (element) {
      if (element.id === idCountry) {
        nameCountry = element.name;
      }
    })
    return nameCountry;
  }

  getGeneroName(collectionGenero, idGenero) {
    let nameGenero: string;
    collectionGenero.map(function (element) {
      if (parseInt(element.id) === parseInt(idGenero)) {
        nameGenero = element.name;
      }
    })
    return nameGenero;
  }

  getFieldFromCollection(collection,searchTerm, fieldToGet){
    let name:string ;
    collection.map(function(element){
      if(element.id == searchTerm){
        name = element[fieldToGet];
      }
    })
    return name;
  }

  handleStatusSolicitudPromotorDashboard(IdSolicitud) {
    return this.getQuery('get', 'promotor/solicitud/' + IdSolicitud, null);
  }

  declinarSolicitud(IdSolicitud, data){
    return this.getQuery('post', 'promotor/declinar/solicitud/' + IdSolicitud, data);
  }

  handleStatePromotorRedirect(proceso: any, proceso_cliente = null) {
    let redirect: string;

    if ( proceso_cliente.flag == 0 && proceso.flag == 0 ) {
      redirect = "promotor/espera/documentos";
      return redirect;
    }
    switch (parseInt(proceso.etapa)) {
      case 1:
        switch (parseInt(proceso.paso)) {
          case 1:
            redirect = "promotor/preanalisis";
            break;
          case 2:
            redirect = "promotor/avales";
        }
        break;

      case 2:
        switch (parseInt(proceso.paso)) {
          default:
            redirect = "promotor/revision_solicitud";
            break;
        }
        break;

      case 3:
        redirect = "promotor/entrevista";
        break;
      case 4:
        switch (parseInt(proceso.paso)) {
          case 1:
            redirect = "promotor/cotizacion";
            break;
          case 2:
            redirect = "promotor/linea_credito";
        }
        break;
      case 5:
        switch (parseInt(proceso.paso)) {
          case 1:
            redirect = "promotor/enviar_comite";
            break;
        }
        break;

      case 6:
        switch (parseInt(proceso.paso)) {
          case 1:
            redirect = "promotor/revision_comite";
            break;
        }
        break;

      case 7:
        switch (parseInt(proceso.paso)) {
          case 1:
            redirect = "promotor/envio_contratos";
            break;
          case 2:
            redirect = "promotor/revision_contratos";
            break;
        }
        break;

      case 8:
        if ( parseInt(proceso.flag) >= 5 ) {
          redirect = "promotor/messages";
        }
        else{
          switch (parseInt(proceso.paso)) {
            case 1:
              redirect = "promotor/solicitud_desembolso";
              break;
            case 2:
              redirect = "promotor/mesa_control";
              break;
            case 3:
              redirect = "promotor/tesoreria";
              break;
          }
        }
        break;

    }
    return redirect;
  }

  handleRedirectAval(proceso) {
    var redirect;
    if (proceso === null) {
      redirect = "aval/capturar_informacion";
    } else {
      switch (parseInt(proceso.etapa)) {
        case 1:
          switch (parseInt(proceso.paso)) {
            case 1:
                redirect = "aval/capturar_informacion";
              break;
            case 2:
                redirect = "aval/capturar_informacion_fiscal";
              break;
            case 3:
                redirect = "aval/capturar_informacion_buro";
              break;
          }
          break;
        case 2:
          switch (parseInt(proceso.paso)) {
            case 1:
              redirect = "aval/informacion_personal";
            break;

            case 2:
              redirect = "aval/informacion_laboral";
            break;

            case 3:
              redirect = "aval/informacion_economica";
            break;

            case 4:
              redirect = "aval/confirmacion_identidad";
            break;
            case 5:
              switch(parseInt(proceso.flag)){
                case 0:
                  redirect = "aval/messages";
                break;
                case 1:
                  redirect = "aval/documentacion";
                break;
                case 2:
                  redirect = "aval/messages"
                break;
              }
            break;

          }
          break;
      }
    }
    return redirect;
  }

  hasPermission(permission){
    if ( localStorage.getItem('permissions') != '' && localStorage.getItem('permissions') != null ) {
      let permissions = localStorage.getItem('permissions').split(',');

      return permissions.indexOf(permission) >= 0;
    }
    else{
      return false;
    }
  }

  renewPermissions(){
    this.getQuery('get', 'user/permisos', '').subscribe(
      res => {
        localStorage.setItem('roles', res['data'].roles);
        localStorage.setItem('permissions', res['data'].permissions);
      },
      errors => {
      }
    );
  }

  isClient(){
    let clientPermissions = [
      'acceso_cliente',
      'acceso_aval_estudiante_sin_buro',
      'acceso_aval_estudiante_con_buro',
      'acceso_aval_proveedor_recursos',
      'acceso_aval_complemento_ingresos',
      'acceso_aval_reforzar_buro',
      'acceso_aval_garantia_auto',
      'acceso_aval_garantia_rrp',
      'acceso_aval_conyugue'
    ];

    let isClient = false;
    clientPermissions.forEach(permission => {
      let has_permission = false;

      has_permission = this.hasPermission(permission);

      if ( has_permission ) {
        isClient = true;
      }
    });

    return isClient;
  }

  isPromotor(){
    let promotorPermissions = [
      'Listado Solicitudes',
      'Reasignar Solicitudes',
      'Declinar Solicitud',
      'Preanalisis y asignacion de avales y garantias',
      'Revision informacion',
      'Edicion informacion',
      'Cotizacion en revision',
      'Entrevista',
      'Cotizacion',
      'Linea de credito',
      'Envio y revision de contratos',
      'Subir documentacion contratacion',
      'Subir documentacion inicial',
      'Solicitud desembolso',
      'Cambiar condiciones',
    ];

    let isPromotor = false;
    promotorPermissions.forEach(permission => {
      let has_permission = false;

      has_permission = this.hasPermission(permission);

      if ( has_permission ) {
        isPromotor = true;
      }
    });

    return isPromotor;
  }

  isDirective(){
    let directivaPermissions = [
      'Revision informacion comite',
      'Edicion informacion comite',
      'Autorizar solicitud comite',
      'Cambios al promotor',
      'Enviar tesoreria',
      'Importe de pago',
      'Actualizar Seguro',
    ];

    let isDirective = false;
    directivaPermissions.forEach(permission => {
      let has_permission = false;

      has_permission = this.hasPermission(permission);

      if ( has_permission ) {
        isDirective = true;
      }
    });

    return isDirective;
  }

}
