import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor( private auth: AuthService, private router: Router) {

  }

  canActivate(): boolean {
    if ( this.auth.isLogged() ) {
        if ( this.auth.isClient() ) {
            this.router.navigateByUrl('client/dashboard');
        }
        else{
            this.router.navigateByUrl('promotor/dashboard');
        }
        return false;
    }

    return true;
  }

}
