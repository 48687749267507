import { Component, OnInit, Input } from "@angular/core";


@Component({
    selector: '[catalogos-row]',
    templateUrl: './admin-2-catalogos-row.component.html'
})

export class CatalogosRowComponent implements OnInit {
    @Input('data') data: any;
    public editMode: boolean;

    constructor() {
        this.editMode = false;
    }

    showEditMode(){
        this.editMode = true;
    }

    saveRecord(id){
        this.editMode = false;
    }

    ngOnInit(): void {
    }
}