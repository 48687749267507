import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin} from 'rxjs';
import { Collection } from '../../models/collection.model';
import { Injectable } from '@angular/core';
import { UtilitiesService } from 'src/app/services/utilities_services.service';

@Injectable()
export class H2CambioCondicionesResolver implements Resolve<Collection>{
    private Object = {};
    public idSolicitud: number;
    constructor (private utilities: UtilitiesService){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<any>{
        this.idSolicitud = parseInt(route.paramMap.get('idSolciitud'));
        let test = forkJoin(
            this.utilities.getQuery('get', 'promotor/info_cambiar_condiciones/' + this.idSolicitud, ''),
        );
        return test;
    }
}