import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, forkJoin,of,throwError } from 'rxjs';
import { CollectionsService } from "../services/colecctions.service";
import { Collection } from '../models/collection.model';
import { Injectable } from '@angular/core';
import { catchError, mergeMap } from 'rxjs/operators';

@Injectable()
export class E1Resolver implements Resolve<Collection>{
    private Object = {};
    public IdSolicitud: number;
    constructor (private CollectionsServive: CollectionsService,private router: Router){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):Observable<any>{
        this.IdSolicitud = parseInt(route.paramMap.get('id'));
        let test = forkJoin(
            this.CollectionsServive.getCollectionsNacionalidad(),
            this.CollectionsServive.getCollectionsPaises(),
            this.CollectionsServive.getCollectionsRegimenFiscal(),
            this.CollectionsServive.getPersonalInformation(this.IdSolicitud)
        ).pipe(
            catchError(error => {
                
                if(error.error.error){
                    var msgError = error.error.error;
                    if(msgError.indexOf("token") > -1){
                        localStorage.clear();
                        this.router.navigateByUrl("/login");
                    }
                }
                if(error.error.indexOf('token') ){
                    localStorage.clear();
                    this.router.navigateByUrl("/login");
                }
                return [];
            }), mergeMap(
                resp => {
                    return of(resp);
                }
            )
        );

        return test;
    }
}