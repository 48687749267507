import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Collection } from 'src/app/models/collection';
import { CollectionsService } from 'src/app/services/colecctions.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { M1PromotorInformacionService } from 'src/app/services/promotor/M1PromotorInformacionPersonal.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PromotorC1PreanalisiService } from 'src/app/services/promotor/C1PreanalisisService.service';
import { K2ContratosService } from 'src/app/services/K2ContratosService.service';

@Component({
    selector: 'app-m1-promotor-aval-informacion-personal',
    templateUrl: './m1-promotor-aval-informacion-personal.component.html',
})

export class M1PromotorAvalInformacionPersonalComponent implements OnInit, AfterViewInit {
    public status: number;
    public IdSolicitud: number;
    public tipoAval: number;
    public IdPersona: number;
    public editMode:boolean;
    public errorMessages: string;
    public loading: boolean;

    /* Collections from resolver */
        public collectionsPaises: Collection;
    /* End Collections from resolver */

    /* Collections for nacionality */
        public collectionsPaisesNacionalidad: Collection;
        public collectionsEstadosNacionalidad: Collection;
    /* END Collections for nacionality */

    /* Collections for address data */
        public collectionsEstado: Collection;
        public collectionsCiudad: Collection;
        public collectionsMunicipio: Collection;
        public collectionsColonia: Collection;
    /* END Collections for address data */


    /* Strings for names of collections */
    public PaisNacionalidad;
    public PaisNacimiento;
    public EstadoNacimiento;
    public PaisDireccion;
    public EstadoDireccion;
    public CiudadDireccion;
    public MunicipioDireccion;
    public ColoniaDireccion;
    
    public data: any;
    public dataPersonSolicitud: any;

    public burosc: any;
    public score: any;
    public estatus_qeq: number;
    public coincidencias: any;

    public frmM1: FormGroup;

    public canEdit: boolean;
    public canDecline: boolean;

    constructor(
        private route: ActivatedRoute,
        private collectionsService: CollectionsService,
        private utilities: UtilitiesService,
        private M1Service: M1PromotorInformacionService,
        private router: Router,
        private modalCoincidencias: NgbModal,
        private fb: FormBuilder,
        private C1Service: PromotorC1PreanalisiService,
        private K2Service: K2ContratosService
    ) {
        this.status = 2;
        this.canEdit = false;
        this.canDecline = false;
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    ngOnInit() {
        this.IdSolicitud = this.route.snapshot.params.id_solicitud;
        this.tipoAval = this.route.snapshot.params.tipo_aval;
        this.IdPersona = this.route.snapshot.params.id_persona;
        this.editMode = false;
        this.route.data.subscribe((data: Data) => {
            this.collectionsPaises = data['collections'][0].data;
            this.collectionsPaisesNacionalidad = data['collections'][1].data;
            this.collectionsEstadosNacionalidad = data['collections'][2].data;
            this.collectionsEstado = data['collections'][2].data;
            this.data = data['data'][0].data.informacion_personal;
            this.dataPersonSolicitud = data['data'][0].data.informacion_propietario;

            this.burosc = data['data'][0].data.burosc;
            this.score = data['data'][0].data.variable;
            this.estatus_qeq = data['data'][0].data.estatus_qeq;
            this.coincidencias = data['data'][0].data.coincidencias;
        });
    
        this.canEdit = this.utilities.hasPermission('Edicion informacion');
        this.canDecline = this.utilities.hasPermission('Declinar Solicitud');

        this.PaisNacionalidad = this.utilities.getFieldFromCollection(this.collectionsPaisesNacionalidad, this.data.IdPaisNacionalidad, "name");
        this.PaisNacimiento = this.utilities.getFieldFromCollection(this.collectionsPaises, this.data.IdPaisNacimiento, "name");
        this.PaisDireccion = this.utilities.getFieldFromCollection(this.collectionsPaises, this.data.IdPais, "name");
        if(this.data.IdPais == 1){
            
            this.collectionsService.getStateByCountryId(this.data.IdPais).subscribe(
                data => {
                    this.collectionsEstado = data.data;
                    this.EstadoDireccion = this.utilities.getFieldFromCollection(data.data, this.data.IdEstado, "name");
                }, error => {
                    
                }
            );
            this.collectionsService.getStateByCountryId(this.data.IdPaisNacimiento).subscribe(
                data => {
                    this.collectionsEstadosNacionalidad = data.data;
                    this.EstadoNacimiento = this.utilities.getFieldFromCollection(data.data, this.data.IdEstadoNacimiento, "name");
                }, error => {
                    
                }
            )
            this.collectionsService.getCitiesById(this.data.IdPais, this.data.IdEstado).subscribe(
                data => {
                    this.collectionsCiudad = data.data;
                    this.CiudadDireccion = this.utilities.getFieldFromCollection(data.data, this.data.IdCiudad, "name");
                }, error => {
                    
                }
            )
            this.collectionsService.getMunicipalitiesById(this.data.IdPais, this.data.IdEstado).subscribe(
                data => {
                    this.collectionsMunicipio = data.data;
                    this.MunicipioDireccion = this.utilities.getFieldFromCollection(data.data, this.data.IdMunicipio, "name");
                }, error => {
                    
                }
            )
            this.collectionsService.getCollectionsColoniasByPais(this.data.IdPais, this.data.IdEstado, this.data.IdMunicipio).subscribe(
                data => {
                    this.collectionsColonia = data.data;
                    this.ColoniaDireccion = this.utilities.getFieldFromCollection(data.data, this.data.IdColonia, "name");
                }, error => {
                }
            )

        }

        this.frmM1 = this.createC1Form();

        this.frmM1.patchValue({
            EstatusQeq: this.estatus_qeq,
            IdSolicitud: this.IdSolicitud,
            IdPersona: this.IdPersona,
            EsPep: false,
            Telefono: this.data.Telefono,
            HorarioContacto: this.data.HorarioContacto,
        });
    }

    downloadBuro(){
        this.K2Service.downloadBuroAval(this.IdSolicitud, this.IdPersona).subscribe(
        resp => {
                var a = <HTMLInputElement>document.getElementById('self_file');
                const url = window.URL.createObjectURL(resp);
                a.setAttribute('href', url);
                a.setAttribute('download', 'Buro Aval.pdf');
                a.click();
            },
            errors => {
            },
          )
    }

    triggerEdit(){
        this.editMode = true;
    }

    verCoincidencias(modalCoincidencias){
        this.modalCoincidencias.open(modalCoincidencias, { ariaLabelledBy: 'modal-basic-title', centered: false, size: 'xl', scrollable: true });
    }

    onChangePep(evt){
        var target = evt.target;

        if (target.checked) {
            this.frmM1.patchValue({
                EsPep: true
            });
        } else {
            this.frmM1.patchValue({
                EsPep: false
            });
        }
    }

    recibirCancelacion($event){
        this.editMode = false;
    }

    onSubmit(modalError){
        this.loading = true;
        
        this.M1Service.setDataComplete(this.IdSolicitud,this.IdPersona).subscribe(
            resp=>{
                this.loading = false;
                this.router.navigateByUrl('promotor/revision/aval/fiscal/'+this.IdSolicitud+'/'+this.IdPersona+'/'+this.tipoAval);
            }
        )
    }

    saveCoincidencias(){
        this.loading = true;
        this.C1Service.sendCommentsQeq(this.frmM1.value).subscribe(
            response => {
                this.loading = false;
            },
            errors => {
                this.loading = false;
            }
        );
    }
    
    recibirGuardado($event){
        this.editMode = false;
        this.data = $event.data;
        this.PaisNacionalidad = this.utilities.getFieldFromCollection(this.collectionsPaisesNacionalidad, $event.data.IdPaisNacionalidad, "name");
        this.PaisNacimiento = this.utilities.getFieldFromCollection(this.collectionsPaises, $event.data.IdPaisNacimiento, "name");
        this.PaisDireccion = this.utilities.getFieldFromCollection(this.collectionsPaises, $event.data.IdPais, "name");
        if($event.data.IdPais == 1){
            
            this.collectionsService.getStateByCountryId($event.data.IdPais).subscribe(
                data => {
                    this.collectionsEstado = data.data;
                    this.EstadoDireccion = this.utilities.getFieldFromCollection(data.data, $event.data.IdEstado, "name");
                }, error => {
                    
                }
            );
            this.collectionsService.getStateByCountryId($event.data.IdPaisNacimiento).subscribe(
                data => {
                    this.collectionsEstadosNacionalidad = data.data;
                    this.EstadoNacimiento = this.utilities.getFieldFromCollection(data.data, $event.data.IdEstadoNacimiento, "name");
                }, error => {
                    
                }
            )
            this.collectionsService.getCitiesById($event.data.IdPais, $event.data.IdEstado).subscribe(
                data => {
                    this.collectionsCiudad = data.data;
                    this.CiudadDireccion = this.utilities.getFieldFromCollection(data.data, $event.data.IdCiudad, "name");
                }, error => {
                    
                }
            )
            this.collectionsService.getMunicipalitiesById($event.data.IdPais, $event.data.IdEstado).subscribe(
                data => {
                    this.collectionsMunicipio = data.data;
                    this.MunicipioDireccion = this.utilities.getFieldFromCollection(data.data, $event.data.IdMunicipio, "name");
                }, error => {
                    
                }
            )
            this.collectionsService.getCollectionsColoniasByPais($event.data.IdPais, $event.data.IdEstado, $event.data.IdMunicipio).subscribe(
                data => {
                    this.collectionsColonia = data.data;
                    this.ColoniaDireccion = this.utilities.getFieldFromCollection(data.data, $event.data.IdColonia, "name");
                }, error => {
                }
            )

        }
    }

    createC1Form() {
        return this.fb.group({
            IdSolicitud: [
                null
            ],
            IdPersona: [
                null
            ],
            ComentariosQeq: [
                null
            ],
            EsPep: [
                null
            ],
            EstatusQeq: [
                null
            ],
            Telefono: [
                null,
            ],
            HorarioContacto: [
                null]
        })
    }
}