import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { UtilitiesService } from 'src/app/services/utilities_services.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {
  public errorMessages: string;
  public userData: Object;
  public isLogged: any;
  public userInfo:any;
  public isClient: any;
  public canReasignar: boolean;
  public isPromotor: boolean;
  constructor(
    private authService: AuthService,
    private router: Router,
    private login: AuthService,
    private utilities: UtilitiesService
  ) {
    this.errorMessages = "";
    this.userData = JSON.parse(localStorage.getItem('person'));
  }

  ngOnInit() {
    this.isLogged = this.login.isLogged();
    this.isClient = this.login.isClient();
    this.isPromotor = this.utilities.isPromotor();
    let tempUser;
    if (this.isLogged){
      this.canReasignar = this.utilities.hasPermission('Reasignar Solicitudes');
      tempUser = JSON.parse(localStorage.getItem("person"));
      if (tempUser){
        this.userInfo = tempUser.Nombre;
      }else{
        tempUser = JSON.parse(localStorage.getItem("user"));
        this.userInfo = tempUser.email;
      }
    }
  }

  clickLogOut(modalError){
    this.authService.logout().subscribe(
      response => {
        localStorage.clear();
        this.router.navigateByUrl('/login');
      },
      errorService => {
        localStorage.clear();
        this.router.navigateByUrl('/login');
      }
    );
  }

  cambiarContrasena(){
    this.router.navigateByUrl('/cambiar/contrasena');
  }

}
