import { Component, OnInit, AfterViewInit } from '@angular/core';
import { B2ConfirmacionRequisitos } from '../../../models/B2ConfirmacionRequisitos.model';
import { B2ConfirmacionRequisitosService } from "../../../services/B2ConfirmacionRequisitosService.service";
import { Router, ActivatedRoute, Data, Params, ActivatedRouteSnapshot } from '@angular/router';
import { NgbDateStruct, NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorsService } from 'src/app/services/errors.service';

@Component({
  selector: 'app-b2-confirmacion-requisitos',
  templateUrl: './b2-confirmacion-requisitos.component.html',
  styleUrls: ['./b2-confirmacion-requisitos.component.scss']
})
export class B2ConfirmacionRequisitosComponent implements OnInit,AfterViewInit {
  public status: number;
  public data: B2ConfirmacionRequisitos;
  public errorMessages: string;
  public IdSolicitud: number;
  public previous_data: any;
  public loading: boolean;

  constructor(private B2Service: B2ConfirmacionRequisitosService, private router: Router, private errors: ErrorsService, private route: ActivatedRoute, private modalErrors: NgbModal) {
    this.status = 1;
    this.data = new B2ConfirmacionRequisitos();
    this.IdSolicitud = this.route.snapshot.params.id;
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    if (this.route.snapshot.data.IdSolicitud) {
      this.previous_data = this.route.snapshot.data.IdSolicitud.data.B2;
      if (this.previous_data !== undefined){
        this.data.ConfirmacionRequisitos = this.previous_data.ConfirmacionRequisitos === "1" ? true : false;
      }
    }
  }

  submitB2() {
    this.onSubmit('modalError');
  }

  onSubmit(modalError) {
    this.loading = true;
    this.data.IdSolicitud = Number(this.IdSolicitud);
    this.B2Service.sendConfirmacionRequisitos(this.data).subscribe(
      resp => {
        this.router.navigateByUrl('client/informacion_personal/' + this.IdSolicitud);
      },
      errors => {
        var msgError = errors.error.errors;

        if(msgError && msgError.indexOf("token") > -1){
            localStorage.clear();
            this.router.navigateByUrl("/login");
        }else{
          this.loading = false;
          this.errorMessages = errors.error.message;
          this.modalErrors.open(modalError, { ariaLabelledBy: 'modal-basic-title', centered: true });
        }
      }
    )
  }

}
