import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Data, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { I2RevisionContratosService } from 'src/app/services/promotor/I2RevisionContratosService.service';
import { UtilitiesService } from 'src/app/services/utilities_services.service';
import { C3RevisionSolicitudComponent } from "../c3-revision-solicitud/c3-revision-solicitud.component";
import { connectableObservableDescriptor } from "rxjs/internal/observable/ConnectableObservable";

@Component({
    selector: 'app-i2-revision-contratos',
    templateUrl: './i2-revision-contratos.component.html'
})

export class I2RevisionContratosComponent implements OnInit {
    public status: number;
    public loading: boolean;
    public IdSolicitud: number;
    public frmi2: FormGroup;
    public errorMessages: string;
    public desembolso:boolean;

    /* Booleans Comentarios */

    public showCommentsContrato : boolean;
    public showCommentsAnexo : boolean;
    public showCommentsPagare : boolean;
    public showCommentsDesembolso : boolean;
    public showCommentsSeguro : boolean;
    public showCommentsSolicitudCredito : boolean;
    public showCommentsPrenda:any;

    public procesoCliente: any;

    /* collections */
    public currentData: any;
    public currentDocumentacion: any;
    public formatedData: any;
    public garantes:any;

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private modalError: NgbModal,
        private router: Router,
        private I2Service: I2RevisionContratosService,
        private utilities: UtilitiesService,
    ) {
        this.status = 7;
    }

    ngOnInit(): void {
        this.loading = false;
        this.IdSolicitud = this.route.snapshot.params.id;
        this.frmi2 = this.createFrm()
        this.route.data.subscribe((data: Data) => {
            this.currentData = data['collections'][0].data;
            this.currentDocumentacion = data['collections'][0].data.revision_documentacion_legales;
            this.procesoCliente = data['collections'][0].data.proceso_cliente;
            this.desembolso=data['collections'][0].data.desembolso.has_desembolso;
            this.garantes=data['collections'][0].data.garanteAuto;
        });

        if ( ! this.currentDocumentacion.length ) {
            this.router.navigateByUrl("promotor/espera/documentos/" + this.IdSolicitud);
            return;
        }
        else if ( this.procesoCliente.etapa == 4 && this.procesoCliente.paso == 5 && this.procesoCliente.flag == 2 ) {
            this.router.navigateByUrl("promotor/espera/documentos/" + this.IdSolicitud);
            return;
        }
        else if (! this.desembolso){
            this.router.navigateByUrl("promotor/instruccion_deposito/" + this.IdSolicitud);
            return; 
        }

        else if ( this.procesoCliente.etapa == 6 && this.procesoCliente.paso == 3 && this.procesoCliente.flag == 0 ) {
            this.router.navigateByUrl("promotor/espera/documentos/" + this.IdSolicitud);
            return;
        }
        else{
        this.formatedData = this.formatData(this.currentDocumentacion);
        const solicitudCredito={
            id:this.formatedData.solicitudcredito?this.formatedData.solicitudcredito._IdSolicitudCredito:0,
            estatus:this.formatedData.solicitudcredito?'' + this.formatedData.solicitudcredito.EstatusSolicitudCredito:'',
            comentario:this.formatedData.solicitudcredito?this.formatedData.solicitudcredito.ComentarioSolicitudCredito:''
        }
        this.frmi2.patchValue({
            IdSolicitud: this.IdSolicitud,
            _IdContrato: this.formatedData.contrato._Contrato,
            _IdAnexo: this.formatedData.anexo._IdAnexo,
            _IdPagare: this.formatedData.pagare._IdPagare,
            _IdDesembolso: this.formatedData.desembolso._IdDesembolso,
            _IdSeguro: this.formatedData.seguro._IdSeguro,
            _IdSolicitudCredito: solicitudCredito.id,
            EstatusContrato: '' + this.formatedData.contrato.EstatusContrato,
            EstatusAnexo: '' + this.formatedData.anexo.EstatusAnexo,
            EstatusPagare: '' + this.formatedData.pagare.EstatusPagare,
            EstatusDesembolso: '' + this.formatedData.desembolso.EstatusDesembolso,
            EstatusSeguro: '' + this.formatedData.seguro.EstatusSeguro,
            EstatusSolicitudCredito: solicitudCredito.estatus,
            ComentarioContrato: this.formatedData.contrato.ComentarioContrato,
            ComentarioAnexo: this.formatedData.anexo.ComentarioAnexo,
            ComentarioPagare: this.formatedData.pagare.ComentarioPagare,
            ComentarioDesembolso: this.formatedData.desembolso.ComentarioDesembolso,
            ComentarioSeguro: this.formatedData.seguro.ComentarioSeguro,
            ComentarioSolicitudCredito: solicitudCredito.comentario,
        });

        this.showCommentsPrenda=[]
        for (let i=1;i<=this.garantes.length;i++){
            this.frmi2.addControl('IdGarantia'+i, new FormControl(null, [Validators.required, Validators.min(1)]));
            this.frmi2.addControl('_IdPrenda'+i, new FormControl(null, [Validators.required, Validators.min(1)]));
            this.frmi2.controls['_IdPrenda'+i].patchValue(this.formatedData['prenda'+i]['_IdPrenda'+i]);
            this.frmi2.controls['IdGarantia'+i].patchValue(this.formatedData['prenda'+i].IdGarantia);
            this.frmi2.addControl('ComentarioPrenda'+i, new FormControl(null));
            this.frmi2.controls['ComentarioPrenda'+i].patchValue(this.formatedData['prenda'+i].comentario);
            this.frmi2.addControl('EstatusPrenda'+i, new FormControl(null, [Validators.required]));
            this.frmi2.controls['EstatusPrenda'+i].patchValue(this.formatedData['prenda'+i].estatus);
            this.showCommentsPrenda.push(parseInt(this.formatedData['prenda'+i].estatus) == 1 ? false : true)
        }

        this.showCommentsContrato = parseInt(this.formatedData.contrato.EstatusContrato) == 1 ? false : true;
        this.showCommentsAnexo = parseInt(this.formatedData.anexo.EstatusAnexo) == 1 ? false : true;
        this.showCommentsPagare = parseInt(this.formatedData.pagare.EstatusPagare) == 1 ? false : true;
        this.showCommentsDesembolso = parseInt(this.formatedData.desembolso.EstatusDesembolso) == 1 ? false : true;
        this.showCommentsSeguro = parseInt(this.formatedData.seguro.EstatusSeguro) == 1 ? false : true;
        this.showCommentsSolicitudCredito =this.formatedData.solicitudcredito && parseInt(this.formatedData.solicitudcredito.EstatusSolicitudCredito) == 1 ? false : true;
        }
    }

    ngAfterViewInit(): void {
        window.scrollTo(0, 0);
    }

    changeCommentsContrato(evt){
        var target = evt.target;
        if (target.value == "1") {
            this.showCommentsContrato = false;
            this.utilities.removeControlsValidity(this.frmi2, ['ComentarioContrato']);
        }else{
            this.showCommentsContrato = true;
            this.frmi2.controls['ComentarioContrato'].setValidators([Validators.required]);
        }
    }

    changeCommentsAnexo($event){
        var target = $event.target;
        if (target.value == "1") {
            this.showCommentsAnexo = false;
            this.utilities.removeControlsValidity(this.frmi2, ['ComentarioAnexo']);
        }else{
            this.showCommentsAnexo = true;
            this.frmi2.controls['ComentarioAnexo'].setValidators([Validators.required]);
        }
    }

    changeCommentsPagare($event){
        var target = $event.target;
        if (target.value == "1") {
            this.showCommentsPagare = false;
            this.utilities.removeControlsValidity(this.frmi2, ['ComentarioPagare']);
        }else{
            this.showCommentsPagare = true;
            this.frmi2.controls['ComentarioPagare'].setValidators([Validators.required]);
        }
    }

    changeCommentsPrenda($event,i){
        var target = $event.target;
        if (target.value == "1") {
            this.showCommentsPrenda[i] = false;
            this.utilities.removeControlsValidity(this.frmi2, ['ComentarioPrenda'+(i+1)]);
        }else{
            this.showCommentsPrenda[i] = true;
            this.frmi2.controls['ComentarioPrenda'+(i+1)].setValidators([Validators.required]);
        }
    }

    changeCommentsDesembolso($event){
        var target = $event.target;
        if (target.value == "1") {
            this.showCommentsDesembolso = false;
            this.utilities.removeControlsValidity(this.frmi2, ['ComentarioDesembolso']);
        }else{
            this.showCommentsDesembolso = true;
            this.frmi2.controls['ComentarioDesembolso'].setValidators([Validators.required]);
        }
    }
    changeCommentsSeguro($event){
        var target = $event.target;
        if (target.value == "1") {
            this.showCommentsSeguro = false;
            this.utilities.removeControlsValidity(this.frmi2, ['ComentarioSeguro']);
        }else{
            this.showCommentsSeguro = true;
            this.frmi2.controls['ComentarioSeguro'].setValidators([Validators.required]);
        }
    }
    changeCommentsSolicitudCredito($event){
        var target = $event.target;
        if (target.value == "1") {
            this.showCommentsSolicitudCredito = false;
            this.utilities.removeControlsValidity(this.frmi2, ['ComentarioSolicitudCredito']);
        }else{
            this.showCommentsSolicitudCredito = true;
            this.frmi2.controls['ComentarioSolicitudCredito'].setValidators([Validators.required]);
        }
    }


    createFrm(){
        return this.fb.group({
            IdSolicitud:[
                null
            ],
            _IdContrato:[
                null
            ],
            _IdAnexo:[
                null
            ],
            _IdPagare:[
                null
            ],
            _IdDesembolso:[
                null
            ],
            _IdSeguro:[
                null
            ],
            _IdSolicitudCredito:[
                null
            ],
            EstatusContrato:[
                null
            ],
            EstatusAnexo:[
                null
            ],
            EstatusPagare:[
                null
            ],
            EstatusDesembolso:[
                null
            ],
            EstatusSeguro:[
                null
            ],
            EstatusSolicitudCredito:[
                null
            ],
            ComentarioContrato:[
                null
            ],
            ComentarioAnexo:[
                null
            ],
            ComentarioPagare:[
                null
            ],
            ComentarioDesembolso:[
                null
            ],
            ComentarioSeguro:[
                null
            ],
            ComentarioSolicitudCredito:[
                null
            ],
        })
    }

    formatData(obj){
        var formatDataSkelleton = {
            contrato:null,
            pagare:null,
            anexo:null,
            desembolso:null,
            seguro:null,
            solicitudcredito:null,
        }
        obj.map(function(item){
            if(item.hasOwnProperty("_Contrato")){
                formatDataSkelleton.contrato = item;
            }
            else if(item.hasOwnProperty("_IdAnexo")){
                formatDataSkelleton.anexo = item;
            }
            else if(item.hasOwnProperty("_IdPagare")){
                formatDataSkelleton.pagare = item;
            }
            else if(item.hasOwnProperty("_IdDesembolso")){
                formatDataSkelleton.desembolso = item;
            }
            else if(item.hasOwnProperty("_IdSeguro")){
                formatDataSkelleton.seguro = item;
            }
            else if(item.hasOwnProperty("_IdSolicitudCredito")){
                formatDataSkelleton.solicitudcredito = item;
            }else if(item.hasOwnProperty("IdGarantia")){
                formatDataSkelleton['prenda'+item.numero]=item;
            }
        });
        return formatDataSkelleton;
    }

    onSubmit(modalTarget){
        if (!this.frmi2.valid) {
            Object.keys(this.frmi2.value).forEach(element => {
              if ( this.frmi2.get(element).errors != null ) {
                this.frmi2.get(element).markAsDirty();
              }
            });
            return;
        }
        this.loading = true;
        this.I2Service.sendData(this.frmi2.value).subscribe(
            response =>{
                this.loading = false;
                if ( response['data']['has_bad_file'] ) {
                    this.router.navigateByUrl("promotor/espera/documentos/" + this.IdSolicitud);
                }
                else{
                    this.router.navigateByUrl("promotor/solicitud_desembolso/"+this.IdSolicitud);
                }
            },
            errors =>{
                this.loading = false;

                this.errorMessages = errors.error.message;
                this.modalError.open(modalTarget, { ariaLabelledBy: 'modal-basic-title', centered: true });
            }
        );
    }
}
