import { Component, OnInit, Input } from "@angular/core";


@Component({
    selector: 'app-parametros-generales',
    templateUrl: './admin-parametros-generales.component.html'
})

export class AdminParametrosGeneralesComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }
}