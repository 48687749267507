import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { UsuarioModel } from '../../models/usuario.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  infoUser: any [] = [];
  error: boolean;
  errorMsgs: string;

  usuario: UsuarioModel;

  constructor( private auth: AuthService, private router: Router) {

    this.error = false;

    this.auth.getUser()
      .subscribe( (data: any) => {
        this.infoUser = data;
      }, (errorService) => {
        this.error = true;
        this.errorMsgs = errorService.error.error;
      });
  }

  ngOnInit() {
    this.usuario = new UsuarioModel();
    this.usuario.email = 'usuario@yopmail.com';
  }

  onSubmit() {

    this.auth.login(this.usuario)
      .subscribe( resp => {
      }, (errorService) => {
      });
  }

}
